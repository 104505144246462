<mat-expansion-panel
  class="bg-transparent  select-none pr-0"
  [expanded]="hasChildActive()"
  #panel
  hideToggle
>
  <mat-expansion-panel-header
    class="px-2 mx-2"
    [class.active-panel]="rla.isActive"
    [matTooltipDisabled]="!menuShrunk()"
    matTooltip="Dashboard"
    matTooltipPosition="right"
  >
    <mat-panel-title
      class="text-white flex justify-between mr-0"
      (click)="$event.stopPropagation()"
    >
      <a
        class="cursor-pointer flex-1 pl-2"
        #rla="routerLinkActive"
        routerLink="/dashboard"
        routerLinkActive
      >
        <mat-label class="text-white flex align-middle">
          <i
            class="text-white ml-2 mr-4 text-3xl"
            [ngClass]="rla.isActive ? 'icon-dashboard' : 'icon-dashboard-stroke'"
            matPrefix
          ></i>
          @if (!menuShrunk()) {
            <span class="text-sm">
              Dashboard
            </span>
          }
        </mat-label>
      </a>
      @if (!menuShrunk()) {
        <mat-icon
          class="chevron-icon mr-[3px]"
          (click)="panel.toggle()"
          [class.rotated]="panel.expanded"
        >
          expand_more
        </mat-icon>
      }
    </mat-panel-title>
  </mat-expansion-panel-header>

  @if (!menuShrunk()) {
    <ul class="ml-11 mr-4 pl-2 border-0 border-l border-white">
      @for (item of dashboards(); track item.id) {
        <li>
          <a
            class="link block p-2 text-white"
            [routerLink]="['dashboards', item.id]"
            routerLinkActive="active"
          >
            {{ item.name }}
          </a>
        </li>
      }
      <li
        [showForClientAdmin]="true"
        appRestrictedEl
      >
        <a
          class="p-2 text-ui-green-light"
          [queryParams]="{ mode: 'edit' }"
          queryParamsHandling="merge"
          routerLink="/dashboards/new"
        >
          + Add New Dashboard
        </a>
      </li>
    </ul>
  }
</mat-expansion-panel>
