<div class="w-[247px] bg-white text-ui-dark flex justify-between overflow-hidden">
  <div class="w-[calc(100%_-_24px)]">
    <h4
      class="font-poppins font-semibold text-base whitespace-nowrap overflow-hidden text-ellipsis flex items-center"
      [class.h-6]="status === eEntityStatus.Archived"
      [class.mb-1.5]="status !== eEntityStatus.Archived"
    >
      @if (status === eEntityStatus.Archived) {
        <i class="icon-add-to-archive text-ui-gray-100 text-3xl"></i>
      }

      @if (status === eEntityStatus.Paused) {
        <i class="icon-offline text-ui-gray-100 text-3xl"></i>
      }

      @if (status === eEntityStatus.Active) {
        @if (!incidentNew && !incidentInProgress) {
          <i class="icon-CheckCircleRounded text-ui-green-light text-3xl">
            <span class="path1"></span>
            <span class="path2"></span>
          </i>
        }

        @if (incidentNew > 0) {
          <i class="icon-alert text-ui-red-50 text-3xl"></i>
        }

        @if (incidentInProgress > 0 && !incidentNew) {
          <i class="icon-warning text-ui-yellow-100 text-3xl"></i>
        }
      }

      <a
        class="inline-flex ml-1.5 text-sm"
        [class]="spaceLink.length ? 'hover:underline cursor-pointer' : 'cursor-default'"
        [routerLink]="spaceLink"
      >{{ name }}</a>
    </h4>

    @if (address) {
      <p class="test-address font-nunito font-semibold text-xs mb-1.5 whitespace-nowrap overflow-hidden text-ellipsis w-full">
        {{ address }}
      </p>
    }

    @if (status === eEntityStatus.Active) {
      <div class="flex items-stretch mb-1.5 justify-start">
        <div>
          <p class="font-nunito text-xs leading-4 mb-1 flex items-center">Incidents:</p>
          <div class="flex">
            @if (incidentNew) {
              <app-incident-status-count
                class="mr-2"
                [amount]="incidentNew"
                [status]="eIncidentStatus.IN_QUEUE"
              />
            }
            @if (incidentInProgress) {
              <app-incident-status-count
                [amount]="incidentInProgress"
                [status]="eIncidentStatus.IN_PROGRESS"
              />
            }
          </div>
        </div>
        <div class="mx-2 my-1 border-l border-ui-gray-light"></div>
        <div>
          <p class="font-nunito text-xs leading-4 mb-1 flex items-center">Devices:</p>
          @if (status === eEntityStatus.Active) {
            <app-device-status-count
              [activeAmount]="deviceActive"
              [pausedAmount]="devicePaused"
              [showTooltip]="false"
            />
          }
        </div>
      </div>
    }
  </div>
  <div class="w-6 flex flex-col justify-end">
    @if (spaceLink.length) {
      <a
        class="flex"
        [class.mb-2]="status !== eEntityStatus.Archived"
        [routerLink]="spaceLink"
      >
        <i class="icon-arrow-right text-ui-dark text-3xl"></i>
      </a>
    }
  </div>
</div>
