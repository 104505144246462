import { Component, inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { incidentColumnOptions } from '@app-lib';
import { IncidentModel } from '@models';
import { Store } from '@ngrx/store';
import { isIncidentLoaded, selectAllIncidents } from '@ngrx-store';
import { AppService, IncidentsFilterService, IncidentsService } from '@services';
import { Observable, Subscription } from 'rxjs';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-incidents-main-page',
  templateUrl: './incidents-main-page.component.html',
})
export class IncidentsMainPageComponent implements OnDestroy {
  protected isIncidentLoaded$: Observable<boolean>;
  protected incidentsFilterService = inject(IncidentsFilterService);
  protected incidentsSubs$: Subscription | undefined;
  protected displayedColumns = incidentColumnOptions.map(option => option.value);
  protected openIncidents: IncidentModel[] = [];
  protected resolvedIncidentsLoading = false;
  private store = inject(Store);
  private incidentsService = inject(IncidentsService);
  private appService = inject(AppService);

  constructor() {
    this.store
      .select(selectAllIncidents)
      .pipe(takeUntilDestroyed())
      .subscribe(incidents => {
        this.openIncidents = incidents;
        this.incidentsFilterService.initOpenIncidents(incidents);
      });
    this.incidentsFilterService.initFilterTableType({
      displayedColumns: this.displayedColumns,
    });
    this.isIncidentLoaded$ = this.store.select(isIncidentLoaded);

    this.incidentsFilterService.resolvedDates$
      .pipe(takeUntilDestroyed())
      .subscribe(value => this.getResolvedIncidents(value));
  }

  ngOnDestroy(): void {
    this.incidentsSubs$?.unsubscribe();
    this.incidentsFilterService.resetState();
  }

  protected getResolvedIncidents(dateRange: { endDateTime: string; startDateTime: string }) {
    if (dateRange.startDateTime && dateRange.startDateTime) {
      this.resolvedIncidentsLoading = true;
      this.incidentsSubs$ = this.incidentsService
        .getAllResolvedIncidents(this.appService.currentClient, dateRange)
        .subscribe({
          error: () => {
            this.resolvedIncidentsLoading = false;
            // TODO: show notification error
          },
          next: data => {
            this.incidentsFilterService.initResolvedIncidents(data);
            this.resolvedIncidentsLoading = false;
          },
        });
    }
  }

}
