import { Component, DestroyRef, HostListener, inject, OnDestroy, OnInit, viewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AdditionalMenuItems, ConditionalMenuItems, MenuItems } from '@app/modules/main/components/main-menu/main-menu-items';
import { menuAnimation } from '@app/modules/main/components/main-menu/menu.animation';
import { DashboardsSelectorComponent } from '@dashboards';
import { environment } from '@env';
import { menuModeSwitched, menuShrunk } from '@layout';
import { ChildClient, MenuItemModel, UserStatus } from '@models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  AppSettingsActions,
  AppState,
  clientIdChanged,
  isSuperAdmin,
  loadChildClients,
  logout,
  selectChildClients,
  selectInProgressIncidentsAmount,
  selectNewIncidentsAmount,
} from '@ngrx-store';
import { AppService } from '@services';
import { Observable, Subscription } from 'rxjs';

@Component({
  animations: menuAnimation,
  selector: 'app-main-menu',
  styleUrls: ['./main-menu.component.scss'],
  templateUrl: './main-menu.component.html',
})
export class MainMenuComponent implements OnDestroy, OnInit {
  isSuperAdmin!: boolean;
  clientsList: ChildClient[] = [];
  logoutMenuItem: MenuItemModel = {
    icon: 'icon-log-out',
    itemIndex: 9,
    title: 'Log Out',
  };
  clientSearchValue = '';
  protected dashboardsSelector = viewChild(DashboardsSelectorComponent);
  protected readonly environment = environment;
  protected destroyRef = inject(DestroyRef);
  protected resizeTimeout!: ReturnType<typeof setTimeout>;
  protected menuShrunk = true;
  protected showMenuSizeToggle = false;
  protected selectedClient: ChildClient | undefined;
  protected menuItems: MenuItemModel[] = MenuItems;
  protected additionalMenuItems: MenuItemModel[] = AdditionalMenuItems;
  protected conditionalMenuItems = ConditionalMenuItems;
  protected isSuperAdminSubs$: Subscription | undefined;
  protected clientListSubs$: Subscription | undefined;
  protected actionsSubs$: Subscription;
  protected animationInProgress = false;
  protected newIncidentsAmount$: Observable<number | undefined>;
  protected inProgressIncidentsAmount$: Observable<number | undefined>;
  private readonly MENU_SHRINK_WIDTH = 700;
  private readonly RESIZE_DEBOUNCE_TIME = 1000;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.autosizeMenu();
  }

  constructor(
    private store: Store<AppState>,
    private appService: AppService,
    private router: Router,
    actions$: Actions,
  ) {
    this.store.select(menuShrunk).subscribe(menuShrunk => {
      localStorage.setItem('menuShrunk', JSON.stringify(menuShrunk));
      this.menuShrunk = menuShrunk;
    });
    this.actionsSubs$ = actions$.pipe(ofType(AppSettingsActions.clientIdChanged)).subscribe(() => {
      this.selectedClient = this.clientsList.find(({ id }) => id === this.appService.currentClient);
    });
    this.newIncidentsAmount$ = this.store.select(selectNewIncidentsAmount);
    this.inProgressIncidentsAmount$ = this.store.select(selectInProgressIncidentsAmount);
  }

  ngOnInit(): void {
    this.menuSettingInit();
    this.autosizeMenu();
  }

  protected toggleMenu() {
    this.store.dispatch(menuModeSwitched({ menuShrunk: !this.menuShrunk }));
  }

  protected menuSettingInit() {
    this.isSuperAdminSubs$ = this.store.select(isSuperAdmin).subscribe(isSuperAdmin => {
      this.isSuperAdmin = isSuperAdmin;
      if (isSuperAdmin) {
        this.store.dispatch(loadChildClients());
      }
    });

    this.clientListSubs$ = this.store.select(selectChildClients).subscribe(clientList => {
      this.clientsList = clientList || [];
      if (clientList && clientList.length) {
        this.clientsList = this.clientsList.filter(item => item.status !== UserStatus.ARCHIVED);
        const currentClientId = this.appService.currentClient;
        const currentClient = clientList?.find(client => client.id === currentClientId);

        if (currentClient) {
          this.selectedClient = currentClient;
        } else {
          this.selectedClient = clientList[0];
          this.appService.currentClient = this.selectedClient.id;
        }
      }
    });
  }

  private determineMenuState(): { menuShrunk: boolean; showMenuSizeToggle: boolean; } {
    const isMenuShrunk = window.innerWidth < this.MENU_SHRINK_WIDTH;

    return {
      menuShrunk: isMenuShrunk,
      showMenuSizeToggle: !isMenuShrunk,
    };
  }

  protected autosizeMenu(): void {
    clearTimeout(this.resizeTimeout);

    const { menuShrunk, showMenuSizeToggle } = this.determineMenuState();

    this.menuShrunk = menuShrunk;
    this.showMenuSizeToggle = showMenuSizeToggle;

    this.resizeTimeout = setTimeout(() => {
      this.store.dispatch(menuModeSwitched({ menuShrunk }));
    }, this.RESIZE_DEBOUNCE_TIME);
  }

  ngOnDestroy(): void {
    this.isSuperAdminSubs$?.unsubscribe();
    this.clientListSubs$?.unsubscribe();
    this.actionsSubs$?.unsubscribe();
    // this.socketService?.disconnect();
  }

  protected logout() {
    this.store.dispatch(logout());
  }

  protected sortByIndex() {
    const menuItems = environment.featureToggles.useDashboardsBuilder
      ? this.menuItems
      : [...this.conditionalMenuItems, ...this.menuItems];

    return menuItems.sort((a, b) => (a.itemIndex > b.itemIndex ? 1 : a.itemIndex === b.itemIndex ? 0 : -1));
  }

  protected clientChange(client: ChildClient) {
    this.appService.currentClient = client.id;
    this.selectedClient = client;
    this.store.dispatch(clientIdChanged());
    this.resetSearch();
    this.router.navigateByUrl('');
    this.dashboardsSelector()?.refresh();
  }

  protected resetSearch() {
    this.clientSearchValue = '';
  }

  protected clientSearch(value: string) {
    this.clientSearchValue = value;
  }
}
