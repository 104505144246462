<mat-button-toggle-group
  class="main-toggle-group"
  (change)="change.emit($event.value)"
  [value]="value()"
  aria-label="period of time"
  name="period"
>
  <mat-button-toggle [value]="ePeriodFilter.DAY">Day</mat-button-toggle>
  <mat-button-toggle [value]="ePeriodFilter.WEEK">Week</mat-button-toggle>
  <mat-button-toggle [value]="ePeriodFilter.MONTH">Month</mat-button-toggle>
</mat-button-toggle-group>
