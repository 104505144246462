import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceData } from '@models';
import { Store } from '@ngrx/store';
import { AppState, getDeviceById } from '@ngrx-store';
import { NewDeviceFormComponent } from '@standalone/new-device-form/new-device-form.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Observable } from 'rxjs';

@Component({
  imports: [NgxSkeletonLoaderModule, NewDeviceFormComponent, CommonModule],
  selector: 'avs-fe-edit-device',
  standalone: true,
  templateUrl: './edit-device.component.html',
})
export class EditDeviceComponent {
  device$: Observable<DeviceData | undefined> | undefined;

  constructor(private route: ActivatedRoute, private store: Store<AppState>) {
    const deviceId = this.route.snapshot.paramMap.get('deviceId');

    if (deviceId) {
      this.device$ = this.store.select(getDeviceById(deviceId));
    }
  }
}
