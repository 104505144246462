<div class="flex items-end">
  <div class="flex flex-col mr-6">
    <span class="flex font-nunito text-sm font-semibold text-ui-gray-100 mb-1">Select report period:*</span>
    <mat-form-field
      class="date-range-picker no-validation-errors middle-height"
      (click)="picker.open()"
    >
      <mat-label>Enter report period</mat-label>
      <mat-date-range-input
        [formGroup]="resolvedDateRange"
        [rangePicker]="picker"
      >
        <input
          [readonly]="true"
          formControlName="start"
          matStartDate
          placeholder="mm/dd/yyyy"
        />
        <input
          [readonly]="true"
          formControlName="end"
          matEndDate
          placeholder="mm/dd/yyyy"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        [for]="picker"
        matIconSuffix
      ></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>
  <button
    class="test-generate-report-btn btn-yellow btn-medium generate-report-btn mr-10"
    (click)="generateReport()"
    [disabled]="!(this.resolvedDateRange.value.start && this.resolvedDateRange.value.end) || resolvedIncidentsLoading"
  >
    @if (resolvedIncidentsLoading) {
      <mat-spinner
        class="mr-1"
        diameter="20"
      ></mat-spinner>
    }
    Generate Report
  </button>
  <button
    class="test-reset font-nunito h-10 font-semibold text-base text-ui-dark underline"
    (click)="reset()"
  >
    Reset
  </button>
</div>
@if (commonIncidents?.length) {
  <div class="test-filters-wrapper border-b-ui-gray-light border-t pt-6 mt-6">
    @for (location of locations; track $index; let i = $index) {
      <div class="test-location-item flex flex-row items-center mb-5">
        <label class="input-main-label mt-0">Building:</label>
        <div class="flex">
          <mat-form-field class="select-classic mr-3">
            <mat-select
              class="test-building-select"
              (selectionChange)="buildingChange($event, i)"
              [value]="location.building"
              panelClass="single-select-overlay"
              placeholder="Select Building"
            >
              @for (building of !i ? allBuildingOptions : (defaultBuildingOptions | sort : 'asc' : 'title' : 'all'); track building.value) {
                <mat-option [value]="building.value">
                  {{ building.title }}
                </mat-option>
              }
            </mat-select>
            <mat-icon
              class="arrow-down"
              matSuffix
            >keyboard_arrow_down</mat-icon>
            <mat-icon
              class="arrow-up"
              matSuffix
            >keyboard_arrow_up</mat-icon>
          </mat-form-field>

          @if (location.building) {
            <mat-form-field class="select-classic w-[120px] mr-3">
              <mat-select
                (selectionChange)="floorChange($event, i)"
                [value]="location.floor"
                panelClass="single-select-overlay"
                placeholder="Floor"
              >
                @for (floor of location.floorOptions; track floor.value) {
                  <mat-option [value]="floor.value">
                    {{ floor.title }}
                  </mat-option>
                }
              </mat-select>
              <mat-icon
                class="arrow-down"
                matSuffix
              >keyboard_arrow_down</mat-icon>
              <mat-icon
                class="arrow-up"
                matSuffix
              >keyboard_arrow_up</mat-icon>
            </mat-form-field>
          }

          @if (location.floor) {
            <app-multiple-select
              class="mr-3"
              (selectDataEvent)="roomChange($event, i)"
              [options]="location.roomOptions"
              [showLabelPlaceholder]="false"
              [value]="location.rooms"
              label="Room"
              panelClass="room-overlay"
              selectClass="stroked-select-multiple-classic classic-form-item-wrapper w-[120px] mb-0"
            ></app-multiple-select>
          }

          @if (i === locations.length - 1) {
            <button
              class="mr-3 test-add-locaiton btn-green btn-medium w-10 px-0"
              (click)="addLocation()"
            >
              <mat-icon>add</mat-icon>
            </button>
          }
          @if (locations.length > 1) {
            <button
              class="test-remove-location btn-red btn-medium w-10 px-0"
              (click)="deleteLocation(i)"
            >
              <i class="icon-remove text-3xl"></i>
            </button>
          }
        </div>
      </div>
    }
    <div class="flex flex-row items-center mb-5">
      <label class="input-main-label mt-0">Device type:</label>
      <div>
        <app-multiple-select
          (selectDataEvent)="multiSelectChange('deviceTypeValue', $event)"
          [options]="deviceTypeOptions"
          [showLabelPlaceholder]="false"
          [value]="deviceTypeValue"
          label="All"
          selectClass="stroked-select-multiple-classic classic-form-item-wrapper mb-0"
          selectElementClass="test-device-select"
        ></app-multiple-select>
      </div>
    </div>
    <div class="flex flex-row items-center mb-5">
      <label class="input-main-label mt-0">Incident status:</label>
      <app-multiple-select
        (selectDataEvent)="multiSelectChange('incidentStatusValue', $event)"
        [options]="incidentStatusOptions"
        [showLabelPlaceholder]="false"
        [value]="incidentStatusValue"
        label="All"
        selectClass="stroked-select-multiple-classic classic-form-item-wrapper mb-0"
        selectElementClass="test-incident-select"
      ></app-multiple-select>
    </div>
  </div>
}
