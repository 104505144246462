<mat-card class="card h-full px-3 justify-end">
  @if ((isIncidentLoaded$ | async) && incidentManufacturerChartData) {
    <highcharts-chart
      class="highcharts-no-legend highcharts-small-title highcharts-classic-axes manufacturer-highchart h-full"
      [Highcharts]="Highcharts"
      [options]="incidentManufacturerChartData"
    />
  } @else {
    <ngx-skeleton-loader [theme]="{ height: '400px', marginBottom: 0 }" />
  }
</mat-card>
