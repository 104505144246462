import { Component, Inject } from '@angular/core';
import { EntityStatus, RoomSpaceModel } from '@models';
import { TIPPY_REF, TippyInstance } from '@ngneat/helipopper';
import { SpaceCardInfoComponent } from '@standalone/space-card-info/space-card-info.component';

@Component({
  imports: [SpaceCardInfoComponent],
  selector: 'avs-li-room-popover',
  standalone: true,
  templateUrl: './room-popover.component.html',
})
export class RoomPopoverComponent {
  locationId = '';
  floorId = '';
  room: RoomSpaceModel | null = null;
  allowNavigation = true;
  entityStatus = EntityStatus;
  hide: () => void;

  constructor(@Inject(TIPPY_REF) tippy: TippyInstance) {
    const { locationId, floorId, details, allowNavigation = true } = tippy.data;

    this.locationId = locationId;
    this.floorId = floorId;
    this.room = details;
    this.allowNavigation = allowNavigation;

    this.hide = tippy.hide;
  }
}
