/**
 * Constant that represents key in the LocalStorage for the current client ID
 */
export const CURRENT_CLIENT_ID_KEY = 'clientId';

/**
 * Constant that represents template property in the request url that will
 * be replaced with actual client ID
 *
 * NOTE: only for use in the http services
 */
export const CURRENT_CLIENT_TEMPLATE_VAR = '$currentClient';
