import { Component, inject, signal } from '@angular/core';
import { DeviceTableCols } from '@models';
import { SessionStorageService } from '@services';
import { DevicePopoverComponent } from '@standalone/device-popover/device-popover.component';

import { SpaceDetailsComponent } from '../space-details/space-details.component';

@Component({
  selector: 'avs-fe-room-details',
  templateUrl: './room-details.component.html',
})
export class RoomDetailsComponent extends SpaceDetailsComponent {
  protected devicePopover = DevicePopoverComponent;
  protected filters = signal<Record<string, string | string[]>>({});
  protected showBlockDiagram = true;
  protected showFloorPlan = false;
  protected TABLE_ID = 'room-details-device-table';

  private sessionStorageService = inject(SessionStorageService);

  constructor() {
    super();
    const columns = this.sessionStorageService.getColumns(this.TABLE_ID);

    if (columns) {
      this.devicesTableDisplayedColumns = [...new Set(columns)];
    }
  }

  protected columnsChanged(columns: string[]) {
    this.sessionStorageService.saveColumnsSettingsToSessionStorage(this.TABLE_ID, columns);

    this.devicesTableDisplayedColumns = [...new Set([
      DeviceTableCols.INDEX,
      DeviceTableCols.DEVICE_NAME,
      ...columns,
      DeviceTableCols.ACTIONS,
    ] as DeviceTableCols[])];
  }
}
