import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CURRENT_CLIENT_TEMPLATE_VAR,
  DeepPartial,
  EntityStatus,
  EntitySummary,
  ImageDocument,
  LocationBaseModel,
  LocationData,
  Space,
  SpaceplanTabType,
} from '@models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private http: HttpClient) {
  }

  public getLocationsList(clientId: string, includeArchived = true): Observable<LocationData[]> {
    const locationStatus = [EntityStatus.Active, EntityStatus.Paused];

    if (includeArchived) {
      locationStatus.push(EntityStatus.Archived);
    }
    const queryString = locationStatus.map(status => `status=${status}`).join('&');

    return this.http
      .get<{ data: LocationData[] }>(`clientId/${clientId}/locations?${queryString}`)
      .pipe(map(({ data }) => data));
  }

  public getLocationsSummaries(clientId: string, params: Record<string, any>) {
    return this.http
      .get<{ data: { id: string; summary: EntitySummary }[] }>(`clientId/${clientId}/locationsSummaries`, {
        params,
      })
      .pipe(map(({ data }) => data));
  }

  public addLocation(clientId: string, data: LocationBaseModel) {
    return this.http.post<{ data: LocationData }>(`clientId/${clientId}/location`, data).pipe(map(({ data }) => data));
  }

  public updateLocation(
    clientId: string,
    locationId: string,
    { id, name, clientFriendlyId, ...data }: DeepPartial<LocationData>,
  ) {
    return this.http
      .patch<{ data: LocationData }>(`clientId/${clientId}/location/${locationId}`, data)
      .pipe(map(({ data }) => data));
  }

  public getLocationInfoById(clientId: string, locationId: string): Observable<LocationData> {
    return this.http
      .get<{
        data: LocationData;
      }>(`clientId/${clientId}/location/${locationId}`, {
        params: {
          deviceStatus: ['active', 'paused'],
        },
      })
      .pipe(map(({ data }) => data));
  }

  public getSpacesList(locationId: string, params: Record<string, string> = {}): Observable<Space[]> {
    const queryParams = new URLSearchParams(params).toString();

    return this.http
      .get<{
        data: Space[];
      }>(`clientId/${CURRENT_CLIENT_TEMPLATE_VAR}/location/${locationId}/spaces/flat${queryParams ? '?' + queryParams : ''}`)
      .pipe(map(({ data }) => data));
  }

  public getSpaceInfoById(clientId: string, locationId: string, spaceId: string): Observable<Space> {
    return this.http
      .get<{
        data: Space;
      }>(`clientId/${clientId}/locations/${locationId}/spaces/${spaceId}`, {
        params: {
          deviceStatus: ['active', 'paused'],
        },
      })
      .pipe(map(({ data }) => data));
  }

  public addNewSpace<T = Space>(clientId: string, locationId: string, data: Partial<Space>) {
    return this.http
      .post<{ data: T }>(`clientId/${clientId}/location/${locationId}/space/create`, data)
      .pipe(map(({ data }) => data));
  }

  public updateSpace<T = Space>(clientId: string, locationId: string, spaceId: string, data: DeepPartial<Space>) {
    return this.http
      .patch<{ data: T }>(`clientId/${clientId}/location/${locationId}/space/${spaceId}`, data)
      .pipe(map(({ data }) => data));
  }

  public uploadDocumentToSpace({ clientId, locationId, spaceId, body, tab }: {
    body: File;
    clientId: string;
    locationId: string;
    spaceId: string;
    tab?: SpaceplanTabType;
  }) {
    const params: { [key: string]: string | boolean } = {};

    if (tab) {
      params['tab'] = tab;
    } else {
      params['setAsReferenceDocument'] = true;
    }

    return this.http
      .put<{ data: ImageDocument }>(`clientId/${clientId}/location/${locationId}/space/${spaceId}/document`, body, {
        headers: new HttpHeaders({
          'Content-Type': 'image/svg+xml',
          FileName: body.name,
        }),
        params,
      })
      .pipe(map(({ data }) => data));
  }

  public deleteDocumentFromSpace({ clientId, locationId, spaceId, filename }: { [_key: string]: string }) {
    return this.http.delete(
      `clientId/${clientId}/location/${locationId}/space/${spaceId}/document/${filename}?removeReference=true`,
    );
  }

  public getAllSpaceDocuments({ clientId, locationId, spaceId }: { [_key: string]: string }) {
    return this.http
      .get<{ data: ImageDocument[] }>(`clientId/${clientId}/location/${locationId}/space/${spaceId}/documents`)
      .pipe(map(({ data }) => data));
  }

  public getSpaceDocument({ clientId, locationId, spaceId, filename }: { [_key: string]: string }) {
    return this.http.get(`clientId/${clientId}/location/${locationId}/space/${spaceId}/document/${filename}`, {
      responseType: 'blob',
    });
  }

  public getSpacesSummaries(clientId: string, locationId: string, params: Record<string, any> = {}) {
    return this.http
      .get<{ data: { id: string; summary: EntitySummary }[] }>(
        `clientId/${clientId}/location/${locationId}/spacesSummaries`,
        {
          params,
        },
      )
      .pipe(map(({ data }) => data));
  }
}
