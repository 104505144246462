import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { BottomSheetMobileHeaderComponent } from '@standalone/bottom-sheet-mobile-header/bottom-sheet-mobile-header.component';

@Component({
  selector: 'avs-li-mobile-export-devices-sheet',
  templateUrl: './export-devices-sheet.component.html',
  standalone: true,
  imports: [BottomSheetMobileHeaderComponent],
})
export class ExportDevicesSheetV2Component {
  constructor(private _bottomSheetRef: MatBottomSheetRef<ExportDevicesSheetV2Component>) {}

  close(value?: 'csv' | 'json') {
    this._bottomSheetRef.dismiss(value);
  }
}
