import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { AfterContentInit, Component, Input, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import {
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate,
} from '@angular/material/datepicker';
import { MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatFormField, MatOption, MatSelect, MatSelectChange, MatSuffix } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { IncidentTabStatusFilter } from '@models';
import { PipesModule } from '@pipes';
import { IncidentsFilterService, SessionStorageService } from '@services';
import { MultipleSelectComponent } from '@standalone/multiple-select/multiple-select.component';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';

@Component({
  imports: [
    NgIf,
    MatButtonToggleGroup,
    MatButtonToggle,
    SearchInputComponent,
    MatFormField,
    MatSelect,
    ReactiveFormsModule,
    MatOption,
    MultipleSelectComponent,
    MatDateRangeInput,
    MatStartDate,
    MatEndDate,
    MatDatepickerToggle,
    MatDateRangePicker,
    FormsModule,
    PipesModule,
    NgForOf,
    MatIcon,
    MatProgressSpinner,
    MatSuffix,
    MatLabel,
    MatButton,
    MatTooltip,
    NgTemplateOutlet,
  ],
  selector: 'app-incidents-table-desktop-filters',
  standalone: true,
  templateUrl: './incidents-table-desktop-filters.component.html',
})
export class IncidentsTableDesktopFiltersComponent implements AfterContentInit {
  @Input() filtersEnabled = false;
  @Input() dropdownFilters = true;
  @Input() resolvedIncidentsLoading = false;
  @Input() tableId!: string;
  @ViewChild('columnSelectRef') columnSelectRef!: MatSelect;
  incidentTabStatusFilterEnum = IncidentTabStatusFilter;

  constructor(
    public incidentsFilterService: IncidentsFilterService,
    private sessionStorageService: SessionStorageService,
  ) {
  }

  columnListUpdated($event: MatSelectChange) {
    this.incidentsFilterService.columnsChange($event);
    this.sessionStorageService.saveColumnsSettingsToSessionStorage(this.tableId, $event.value);
    this.incidentsFilterService.setColumnsValues();
  }

  selectAllColumns() {
    this.columnSelectRef.options.forEach((item: MatOption) => item.select());
  }

  ngAfterContentInit(): void {
    const columns = this.sessionStorageService.getColumns(this.tableId);

    if (columns?.length) {
      this.incidentsFilterService.displayedColumns = columns;
      this.incidentsFilterService.columnsChange({value: columns} as MatSelectChange);
      this.columnListUpdated({value: columns} as MatSelectChange);
    }
  }
}
