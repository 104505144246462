@if (user && editor$ | async; as editor) {
  <div>
    <div class="flex items-center justify-between test-header pb-6">
      <div>
        <app-page-header>{{ user.firstName }} {{ user.lastName }}</app-page-header>
        <app-breadcrumbs [routeNames]="[user.firstName + ' ' + user.lastName]" />
      </div>

      @if (editor.id !== user.id) {
        <div
          class="flex items-center"
          [showForClientAdmin]="true"
          appRestrictedEl
        >
          @if (user.status === UserStatus.INVITED) {
            <button
              class="btn-blue btn-medium mr-3"
              (click)="resendInvitation(user)"
              [disabled]="(resendInvitationLoading$ | async) === true"
            >
              @if (resendInvitationLoading$ | async) {
                <mat-spinner
                  class="mr-2"
                  diameter="20"
                />
              }
              <i class="icon-resend-email text-3xl mr-2"></i>
              Resend Invitation
            </button>
          }
          @if (user.status !== UserStatus.ARCHIVED) {
            <button
              class="btn-gray btn-medium"
              (click)="archive(user)"
            >
              <i class="icon-add-to-archive text-3xl mr-2"></i>
              Archive
            </button>
          }
          @if (user.status === UserStatus.ARCHIVED) {
            <button
              class="btn-green btn-medium"
              (click)="restore(user)"
            >
              <i class="icon-restore text-3xl mr-2"></i>
              Restore
            </button>
          }
        </div>
      }
    </div>
    <app-user-details
      [editor]="editor"
      [user]="user"
    />
  </div>
} @else {
  <app-skeleton-page-header [buttonCount]="0" />
  <ngx-skeleton-loader [theme]="{ height: '600px' }" />
}
