import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DashboardMainComponent } from '@app/modules/dashboard/containers/dashboard-main/dashboard-main.component';
import { SpaceDetailsComponent } from '@app/modules/dashboard/containers/space-details/space-details.component';
import { RoleGuard } from '@app-lib';
import { MapComponent } from '@dashboards';
import { DevicesFiltersV2Component, DevicesTableV2Component } from '@devices';
import { DirectivesModule } from '@directives';
import { TippyDirective } from '@ngneat/helipopper';
import { addDeviceFormResolver, locationsResolver, locationStateResolver } from '@ngrx-store';
import { PipesModule } from '@pipes';
import { DeleteSpaceplanComponent } from '@standalone/_spaceplan/delete-spaceplan/delete-spaceplan.component';
import { SpaceplanComponent } from '@standalone/_spaceplan/spaceplan/spaceplan.component';
import { SpaceplanTabsComponent } from '@standalone/_spaceplan/spaceplan-tabs/spaceplan-tabs.component';
import { UploadSpaceplanComponent } from '@standalone/_spaceplan/upload-spaceplan/upload-spaceplan.component';
import { IncidentsTableComponent } from '@standalone/_tables/incidents-table/incidents-table.component';
import {
  IncidentsTableDesktopFiltersComponent,
} from '@standalone/_tables/incidents-table-desktop-filters/incidents-table-desktop-filters.component';
import { RoomListTableComponent } from '@standalone/_tables/room-list-table/room-list-table.component';
import { SiteTableComponent } from '@standalone/_tables/site-table/site-table.component';
import {
  SiteTableDesktopFiltersComponent,
} from '@standalone/_tables/site-table-desktop-filters/site-table-desktop-filters.component';
import { BreadcrumbsComponent } from '@standalone/breadcrumbs/breadcrumbs.component';
import { DeviceStatusCountComponent } from '@standalone/device-status-count/device-status-count.component';
import { EntityStatusComponent } from '@standalone/entity-status/entity-status.component';
import {
  GooglePlaceAutocompleteComponent,
} from '@standalone/google-place-autocomplete/google-place-autocomplete.component';
import { IncidentStatusCountComponent } from '@standalone/incident-status-count/incident-status-count.component';
import { MainInputFormComponent } from '@standalone/main-input-form/main-input-form.component';
import { MultipleSelectComponent } from '@standalone/multiple-select/multiple-select.component';
import { NewDeviceFormComponent } from '@standalone/new-device-form/new-device-form.component';
import { NoDataComponent } from '@standalone/no-data/no-data.component';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { SkeletonPageHeaderComponent } from '@standalone/skeleton-page-header/skeleton-page-header.component';
import { SkeletonTableComponent } from '@standalone/skeleton-table/skeleton-table.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { AddClientDialogComponent } from './components/add-client-dialog/add-client-dialog.component';
import { AddFloorsComponent } from './components/add-floors/add-floors.component';
import { AddRoomsComponent } from './components/add-rooms/add-rooms.component';
import { AddSiteDialogComponent } from './components/add-site-dialog/add-site-dialog.component';
import { AttachDeviceComponent } from './components/attach-device/attach-device.component';
import { BuildingInfoComponent } from './components/building-info/building-info.component';
import { BuildingPlanIncidentsComponent } from './components/building-plan-incidents/building-plan-incidents.component';
import {
  BuildingPlanVisualModeComponent,
} from './components/building-plan-visual-mode/building-plan-visual-mode.component';
import {
  DashboardLocationHeaderComponent,
} from './components/dashboard-location-header/dashboard-location-header.component';
import { DeviceListComponent } from './components/device-list/device-list.component';
import { EditRoomDialogComponent } from './components/edit-room-dialog/edit-room-dialog.component';
import { ExpandableFloorItemComponent } from './components/expandable-floor-item/expandable-floor-item.component';
import { FloorItemComponent } from './components/floor-item/floor-item.component';
import { HandleRoomsComponent } from './components/handle-rooms/handle-rooms.component';
import { NewSiteFormComponent } from './components/new-site-form/new-site-form.component';
import { RoomItemComponent } from './components/room-item/room-item.component';
import { AddNewSiteComponent } from './containers/add-new-site/add-new-site.component';
import {
  BuildingManagementPageComponent,
} from './containers/building-management-page/building-management-page.component';
import { EditFloorComponent } from './containers/edit-floor/edit-floor.component';
import { EditRoomComponent } from './containers/edit-room/edit-room.component';
import { EditSiteComponent } from './containers/edit-site/edit-site.component';
import { RoomDetailsComponent } from './containers/room-details/room-details.component';

@NgModule({
  declarations: [
    DashboardMainComponent,
    AddSiteDialogComponent,
    AddNewSiteComponent,
    NewSiteFormComponent,
    AddFloorsComponent,
    FloorItemComponent,
    AddRoomsComponent,
    ExpandableFloorItemComponent,
    RoomItemComponent,
    AttachDeviceComponent,
    BuildingInfoComponent,
    BuildingManagementPageComponent,
    BuildingPlanVisualModeComponent,
    AddClientDialogComponent,
    BuildingPlanIncidentsComponent,
    EditSiteComponent,
    HandleRoomsComponent,
    SpaceDetailsComponent,
    EditFloorComponent,
    EditRoomComponent,
    EditRoomDialogComponent,
    DashboardLocationHeaderComponent,
    DeviceListComponent,
    RoomDetailsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        component: DashboardMainComponent,
        path: '',
        resolve: {
          locations: locationsResolver,
        },
      },
      {
        canActivate: [RoleGuard],
        component: AddNewSiteComponent,
        data: { breadcrumb: 'Add New Building' },
        path: 'add-new-site',
        resolve: {
          addDeviceFormResolver,
        },
      },
      {
        component: BuildingManagementPageComponent,
        path: ':locationId',
        resolve: { locationStateResolver },
      },
      {
        canActivate: [RoleGuard],
        component: EditSiteComponent,
        path: ':locationId/edit',
        resolve: { locationStateResolver },
      },
      {
        component: SpaceDetailsComponent,
        path: ':locationId/:floorId',
        resolve: { locationStateResolver },
      },
      {
        component: EditFloorComponent,
        path: ':locationId/:floorId/edit',
        resolve: { locationStateResolver },
      },
      {
        component: RoomDetailsComponent,
        path: ':locationId/:floorId/:roomId',
        resolve: { locationStateResolver },
      },
      {
        component: EditRoomComponent,
        path: ':locationId/:floorId/:roomId/edit',
        resolve: { addDeviceFormResolver, locationStateResolver },
      },
    ]),
    GoogleMapsModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatButtonToggleModule,
    MatStepperModule,
    PipesModule,
    MatProgressSpinnerModule,
    TippyDirective,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    DirectivesModule,
    MatSlideToggleModule,
    NgxSkeletonLoaderModule,
    SkeletonTableComponent,
    SkeletonPageHeaderComponent,
    MainInputFormComponent,
    SearchInputComponent,
    BreadcrumbsComponent,
    EntityStatusComponent,
    MapComponent,
    MultipleSelectComponent,
    DeleteSpaceplanComponent,
    SpaceplanComponent,
    NoDataComponent,
    PageHeaderComponent,
    DeviceStatusCountComponent,
    IncidentStatusCountComponent,
    NewDeviceFormComponent,
    SiteTableDesktopFiltersComponent,
    SiteTableComponent,
    IncidentsTableDesktopFiltersComponent,
    GooglePlaceAutocompleteComponent,
    IncidentsTableComponent,
    RoomListTableComponent,
    UploadSpaceplanComponent,
    SpaceplanTabsComponent,

    // V2 tables
    DevicesTableV2Component,
    DevicesFiltersV2Component,
  ],
})
export class DashboardModule {
}
