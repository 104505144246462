import {Pipe, PipeTransform} from '@angular/core';
import {EntityStatus, FloorSpaceModel} from '@models';

@Pipe({
  name: 'filterFloorSpaceByName',
})
export class FilterFloorSpaceByNamePipe implements PipeTransform {

  transform(items: FloorSpaceModel[], searchText: string, showArchived = false, ascending = false): FloorSpaceModel[] {
    if (!items) {
      return [];
    }

    // Filter floors based on archived status
    const floorsToProcess = items.filter(floor => {
      if (showArchived) {
        return floor.status === EntityStatus.Archived || floor.rooms?.some(room => room.status === EntityStatus.Archived);
      }
      return floor.status !== EntityStatus.Archived;
    });

    // Sort floors naturally
    const sortedFloors = this.sortFloorsNaturally(floorsToProcess, ascending);

    // Return early if search text is not provided
    if (!searchText) {
      return sortedFloors;
    }

    // Filter by friendly name based on search text
    searchText = searchText.toLocaleLowerCase();
    return sortedFloors.filter(floor => floor.friendlyName.toLocaleLowerCase().includes(searchText));
  }

  private sortFloorsNaturally(arr: FloorSpaceModel[], ascending = false): FloorSpaceModel[] {
    return arr.sort((a, b) => {
      const comparison = a.friendlyName.localeCompare(b.friendlyName, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
      return ascending ? comparison : -comparison; // Reverse order if descending
    });
  }
}
