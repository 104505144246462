@let zoomPercent = 100 + zoom * zoomPercentStep * (isMobile ? 2 : 1);
@let zoomPercentValue = zoomPercent + '%';
<div
  (click)="onClick()"
  (mouseleave)="onMouseLeave($event)"
>
  <div
    (wheel)="onWheel($event)"
    [id]="documentTab === defaultDocumentTab ? 'app-spaceplan' : 'app-spaceplan' + '-' + documentTab"
    [ngClass]="{
      'fixed top-0 left-0 w-full z-[1000] bg-white floor-plan-full-screen': isFullScreen,
      relative: !isFullScreen,
      'overflow-x-hidden overflow-y-auto': zoom === minZoom,
      'overflow-auto': zoom !== minZoom,
      'max-h-[80vh]': !isFullScreen,
      'cursor-grab': !isDragging,
      'cursor-grabbing': isDragging,
      'select-none': true,
      'pointer-events-none': !isFullScreen && !canInteract,
    }"
    [style]="{ height: isFullScreen ? '100%' : containerHeight() + 'px' }"
    #wrapper
  >
    @if (clientId && locationId && spaceplan.id && imgUrl) {
      <div
        class="img-wrapper relative overflow-hidden"
        [style.width]="zoomPercentValue"
      >
        <img
          class="select-none pointer-events-none w-full"
          [class.h-0]="!isImgLoaded"
          [draggable]="false"
          [src]="imgUrl | srcSecurePipe | async"
          #img
          alt="floor plan"
        />

        @if (spaceplan.attachments) {
          @for (attachment of spaceplan.attachments
        | filterById : currentAttachmentId
        | filterAttachmentByType : documentTab : appService.activeAttachmentId(); track attachment.id) {
            <ng-container>
              @let isRoomAttachment = !!(attachment.type);
              <app-spaceplan-attachment
                class="pointer-events-none"
                (cancelEvent)="resetAttachmentId()"
                (confirmEvent)="confirm($event, isRoomAttachment)"
                [allowNavigation]="!currentAttachmentId"
                [attachment]="attachment"
                [canInteract]="canInteract"
                [containerHeight]="height"
                [containerWidth]="width"
                [documentTab]="documentTab"
                [editMode]="attachment.id === appService.activeAttachmentId()"
                [ngStyle]="{ 'z-index': attachment.id === appService.activeAttachmentId() ? 1 : 0 }"
                [popover]="popover"
                [popoverData]="canEdit
                  ? {
                  locationId,
                  attachmentName: attachment.friendlyName,
                  id: attachment.id,
                  dialogTitle: isRoomAttachment ? 'Unassign a Room' : 'Unassign a Device',
                  unstructuredDataReference: attachment.unstructuredDataReference,
                  documentTab: documentTab,
                  dialogText: isRoomAttachment
                  ? 'Are you sure you want to unassign a room [' + attachment.friendlyName + '] from the floor plan?'
                  : 'Are you sure you want to unassign a device [' + attachment.friendlyName + '] from the block diagram?'
                }
                : {
                  details: attachment,
                  floorId: spaceplan.id,
                  locationId: locationId,
                  allowNavigation: !currentAttachmentId
                }"
                [routerLink]="
                !!(attachment.type)
                  ? ['/dashboard', locationId, spaceplan.id, attachment.id]
                  : ['/devices', locationId, attachment.id]
                "
                [zoomPercent]="zoomPercent"
              />
            </ng-container>
          }
        }
      </div>
    }
  </div>
  @if (isFullScreen) {
    <button
      class="test-exit-fullscreen-btn exit-fullscreen-btn btn-gray-transparent btn-medium w-10 p-0 rounded-full fixed left-6 top-6 z-[1000]"
      (click)="exitFloorplanFullScreen()"
    >
      <i class="icon-arrow-left text-3xl"></i>
    </button>
  } @else {
    <button
      class="test-enter-fullscreen-btn btn-gray-transparent btn-medium w-10 p-0 absolute right-6 top-6 z-[1000]"
      (click)="enterFloorplanFullScreen()"
    >
      <i class="icon-ic_zoom1 text-3xl"></i>
    </button>
  }

  @if (showUIElements) {
    <div
      class="test-zoom-btn-wrapper zoom-btn-wrapper rounded-md flex flex-row items-center left-1/2 -translate-x-1/2 z-[1000]"
      [ngClass]="{
        'fixed': isFullScreen,
        'absolute': !isFullScreen,
        'bottom-6': isFullScreen || isMobile,
        'bottom-16': !isMobile && !isFullScreen,
      }"
    >
      <button
        class="test-decreese-zoom-btn btn-medium flex items-center justify-center w-10 p-0 text-3xl text-white disabled:bg-transparent disabled:opacity-50"
        (click)="zoomDecrease()"
        [disabled]="zoom === minZoom"
      >
        -
      </button>
      <span class="mx-1 font-nunito text-sm text-white"> {{ zoomPercentValue }} </span>
      <button
        class="test-increese-zoom-btn btn-medium flex items-center justify-center w-10 p-0 text-3xl text-white disabled:bg-transparent disabled:opacity-50"
        (click)="zoomIncrease()"
        [class.btn-disabled]="zoom === maxZoom"
        [disabled]="zoom === maxZoom"
      >
        +
      </button>
    </div>
  }
</div>
@if (!isImgLoaded) {
  <ngx-skeleton-loader [theme]="{ height: '400px' }" />
}
<div class="flex justify-between">
  @if (isImgLoaded && !isMobile && showUIElements) {
    <app-delete-spaceplan
      class="mt-4 ml-2"
      [documentReference]="spaceplan.documentReference"
      [floorId]="spaceplan.parentSpaceId || spaceplan.id"
      [locationId]="locationId"
      [roomId]="spaceplan.parentSpaceId ? spaceplan.id : ''"
    />
  }
</div>

