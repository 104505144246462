<cdk-virtual-scroll-viewport
  class="max-h-full"
  [itemSize]="TYPICAL_COL_HEIGHT"
  [style.zoom]="zoom()"
>
  @if (isReady()) {
    <mat-table
      class="main-table"
      (matSortChange)="devicesStore.updateSort($event)"
      [class.no-actions-table]="(userRole$ | async) === eUserRolesIds.ReadOnly"
      [dataSource]="dataSource"
      [matSortActive]="devicesStore.sort().active"
      [matSortDirection]="devicesStore.sort().direction"
      [multiTemplateDataRows]="true"
      matSort
    >
      <!-- Number Column -->
      <ng-container
        [sticky]="!config().isMobile"
        matColumnDef="index"
      >
        <mat-header-cell *matHeaderCellDef>№</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{ element._index + 1 }}</span>
        </mat-cell>
      </ng-container>

      <!-- Device Name Column -->
      <ng-container
        [sticky]="!config().isMobile"
        matColumnDef="deviceName"
      >
        <mat-header-cell
          [class.sticky-start]="!config().isMobile"
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by device name"
        >
          Device Name
        </mat-header-cell>
        <mat-cell
          [class.sticky-start]="!config().isMobile"
          *matCellDef="let element"
        >
          <a
            class="cursor-pointer"
            (click)="goToDevice(element)"
            [class.underline]="!config().isReportMode"
          >
            {{ element.deviceName }}
          </a>
        </mat-cell>
      </ng-container>

      <!-- Firmware Column -->
      <ng-container matColumnDef="firmware">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by firmware"
        >
          Firmware
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          mat-cell
        >
          <span>{{ element.firmware }}</span>
        </mat-cell>
      </ng-container>

      <!-- Building Column -->
      <ng-container matColumnDef="building">
        <mat-header-cell
          *matHeaderCellDef
          mat-header-cell
          mat-sort-header
          sortActionDescription="Sort by building"
        >
          Building
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a
            [class.underline]="!config().isReportMode"
            [routerLink]="config().isReportMode ? null : ['/dashboard', element.buildingId]"
          >
            {{ element.building || '-' }}
          </a>
        </mat-cell>
      </ng-container>

      <!-- Floor Column -->
      <ng-container matColumnDef="floor">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by floor"
        >
          Floor
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          @if (config().isReportMode || !element.floorId) {
            <span>{{ element?.floor || '-' }}</span>
          } @else {
            <a
              class="underline"
              [routerLink]="['/dashboard', element.buildingId, element.floorId]"
            >
              {{ element?.floor || '-' }}
            </a>
          }
        </mat-cell>
      </ng-container>

      <!-- Room Column -->
      <ng-container matColumnDef="room">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by room"
        >
          Room
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          @if (config().isReportMode || !element.roomId) {
            <span>{{ element?.room || '-' }}</span>
          } @else {
            <a
              class="underline"
              [routerLink]="['/dashboard', element.buildingId, element.floorId, element.roomId]"
            >
              {{ element?.room || '-' }}
            </a>
          }
        </mat-cell>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by status"
        >
          Monitoring Status
        </mat-header-cell>
        <mat-cell
          [class.is-offline]="element.status === eEntityStatus.Paused"
          *matCellDef="let element"
        >
          <app-entity-status [status]="element.status" />
        </mat-cell>
      </ng-container>

      <!-- Device Type Column -->
      <ng-container matColumnDef="deviceType">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by device type"
        >
          Device Type
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{ element.deviceType || '-' }}</span>
        </mat-cell>
      </ng-container>

      <!-- Manufacturer Column -->
      <ng-container matColumnDef="manufacturer">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by manufacturer"
        >
          Manufacturer
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{ element.manufacturer || '-' }}</span>
        </mat-cell>
      </ng-container>

      <!-- Model Column -->
      <ng-container matColumnDef="model">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by model"
        >
          Model
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{ element.model }}</span>
        </mat-cell>
      </ng-container>

      <!-- Serial Number Column -->
      <ng-container matColumnDef="serialNumber">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by serial number"
        >
          Serial №
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{ element.serialNumber || '-' }}</span>
        </mat-cell>
      </ng-container>

      <!-- IP address Column -->
      <ng-container matColumnDef="ipAddress">
        <mat-header-cell *matHeaderCellDef>IP address</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{ element.ipAddress || '-' }}</span>
        </mat-cell>
      </ng-container>

      <!-- Incident Column -->
      <ng-container matColumnDef="incidents">
        <mat-header-cell
          [hidden]="filters()?.status?.[0] === 'archived'"
          *matHeaderCellDef
        >
          Incidents
        </mat-header-cell>
        <mat-cell
          [hidden]="filters()?.status?.[0] === 'archived'"
          *matCellDef="let element"
        >
          @if (element.status === eEntityStatus.Active) {
            @if (element.incidentCountByStatuses.newCount > 0) {
              <app-incident-status-count
                class="mr-1"
                [amount]="element.incidentCountByStatuses.newCount"
                [status]="eIncidentStatus.IN_QUEUE"
              />
            }

            @if(element.incidentCountByStatuses.inProgressCount > 0){
              <app-incident-status-count
                [amount]="element.incidentCountByStatuses.inProgressCount"
                [status]="eIncidentStatus.IN_PROGRESS"
              />
            }
          }
        </mat-cell>
      </ng-container>

      <!-- Actions Column -->
      <ng-container
        [stickyEnd]="!config().isMobile"
        matColumnDef="actions"
      >
        <mat-header-cell
          [class.hidden]="filters()?.status?.[0] === 'archived' && (userRole$ | async) === eUserRolesIds.ClientAdmin"
          [class.sticky-end]="!config().isMobile"
          [showForClientAdmin]="true"
          *matHeaderCellDef
          appRestrictedEl
        ></mat-header-cell>
        <mat-cell
          [class.hidden]="filters()?.status?.[0] === 'archived' && (userRole$ | async) === eUserRolesIds.ClientAdmin"
          [class.sticky-end]="!config().isMobile"
          [showForClientAdmin]="true"
          *matCellDef="let element"
          appRestrictedEl
        >
          <div class="flex justify-end">
            @if (element.status !== eEntityStatus.Archived) {
              <i
                class="cursor-pointer text-ui-gray-100 text-3xl icon-edit"
                [routerLink]="['/devices', 'edit', element.buildingId, element.id]"
                appRestrictedEl
                matTooltip="Edit Device"
              ></i>
            }

            @if (element.status === eEntityStatus.Paused) {
              <i
                class="icon-play-16 action-icon"
                (click)="resumeDevice(element)"
                [matTooltip]="'Resume'"
                [showForClientAdmin]="true"
                appRestrictedEl
              ></i>
            }

            @if (element.status === eEntityStatus.Active) {
              <i
                class="icon-pause-16 action-icon"
                (click)="pauseDevice(element)"
                [matTooltip]="'Pause Device'"
                [showForClientAdmin]="true"
                appRestrictedEl
              ></i>
            }

            @if (element.status !== eEntityStatus.Archived) {
              <i
                class="icon-add-to-archive action-icon"
                (click)="archiveDevice(element)"
                appRestrictedEl
                matTooltip="Archive"
              ></i>
            }

            @if (element.status === eEntityStatus.Archived) {
              <i
                class="icon-restore action-icon"
                (click)="restoreDevice(element)"
                appRestrictedEl
                matTooltip="Restore"
              ></i>
            }
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns(); sticky: true"></mat-header-row>

      <mat-row
        [style.height.px]="dataSource.offset$ | async"
        *matRowDef="let row; columns: []; when: placeholderWhen"
      ></mat-row>

      <mat-row
        [class.is-offline]="row.status === eEntityStatus.Paused"
        *matRowDef="let row; columns: displayedColumns()"
      ></mat-row>

    </mat-table>

    @if (!devicesStore.entities().length && !isDataLoading()) {
      <app-no-data
        [hasBigPadding]="config().isNoDataBigSize || false"
        [resultContent]="filtersApplied()"
        noDataText="No devices yet"
      />
    }
  }
</cdk-virtual-scroll-viewport>


@if (isReady() && config().showZoomControls) {
  <app-table-zoom
    (zoomChanged)="zoom.set($event)"
    [tableId]="tableId()"
  />
}

@if (!isReady()) {
  <app-skeleton-table />
}
