@if (device(); as deviceData) {
  <mat-card class="test-location-widget card text-ui-dark">
    <ul class="flex flex-grow align-middle">
      <li class="test-building-block text-left text-base pr-4">
        <div class="pb-2 flex items-center">
          <i class="icon-ic_building text-3xl mr-3 text-ui-gray-100"></i>
          {{ deviceData.location.friendlyName }}
        </div>
        <div
          class="flex items-center"
          [matTooltip]="address()"
        >
          <i class="icon-ic_location text-3xl mr-3 text-ui-gray-100"></i>
          <span class="custom-text-ellipsis text-base flex-1">{{ address() }}</span>
        </div>
      </li>
      <li class="test-floor-block">
        <div class="pb-2 text-sm">Floor:</div>
        @if (deviceData.spacePath && deviceData.spacePath[0]) {
          <div
            class="custom-text-ellipsis font-medium text-base overflow-hidden whitespace-nowrap overflow-ellipsis px-1 font-poppins"
            [matTooltip]="deviceData.spacePath[0].friendlyName || ''"
          >
            {{ deviceData.spacePath[0].friendlyName || '-' }}
          </div>
        } @else {
          <div>-</div>
        }
      </li>
      <li class="test-room-block">
        <div class="pb-2 text-sm">Room:</div>
        @if (deviceData.spacePath && deviceData.spacePath[1]) {
          <div
            class="custom-text-ellipsis font-medium text-base overflow-hidden whitespace-nowrap overflow-ellipsis px-1 font-poppins"
            [matTooltip]="deviceData.spacePath[1].friendlyName || ''"
          >
            {{ deviceData.spacePath[1].friendlyName || '-' }}
          </div>
        } @else {
          <div>-</div>
        }
      </li>
      <li class="flex justify-evenly items-center">
        @if (deviceData.spacePath && deviceData.spacePath[0] && !deviceData.spacePath[1]) {
          <a
            class="underline text-sm font-normal"
            [routerLink]="['/dashboard', deviceData.location.id, deviceData.spacePath[0].id]"
          >
            View Floor
          </a>
        } @else if (deviceData.spacePath && deviceData.spacePath[0] && deviceData.spacePath[1]) {
          <a
            class="underline text-sm font-normal"
            [routerLink]="['/dashboard', deviceData.location.id, deviceData.spacePath[0].id, deviceData.spacePath[1].id]"
          >
            View Room
          </a>
        } @else {
          <a
            class="underline text-sm font-normal"
            [routerLink]="['/dashboard', deviceData.location.id]"
          > View Building </a>
        }
      </li>
    </ul>
  </mat-card>
}
