import { Component } from '@angular/core';
import { SkeletonPageHeaderComponent } from '@standalone/skeleton-page-header/skeleton-page-header.component';
import { SkeletonTableComponent } from '@standalone/skeleton-table/skeleton-table.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  imports: [SkeletonTableComponent, SkeletonPageHeaderComponent, NgxSkeletonLoaderModule],
  selector: 'avs-fe-device-details-skeleton-animation',
  standalone: true,
  templateUrl: './device-details-skeleton-animation.component.html',
})
export class DeviceDetailsSkeletonAnimationComponent {}
