import { type LocationAddress } from './locations';
import { EntityStatus, type Space } from './spaces';

export enum DeviceTableCols {
  STATUS = 'status',
  BUILDING = 'building',
  SELECT = 'select',
  INDEX = 'index',
  DEVICE_NAME = 'deviceName',
  ACTIONS = 'actions',
  MANUFACTURER = 'manufacturer',
  MODEL = 'model',
  SERIAL_NUMBER = 'serialNumber',
  DEVICE_TYPE = 'deviceType',
  INCIDENTS = 'incidents',
  FIRMWARE = 'firmware',
  FLOOR = 'floor',
  ROOM = 'room',
  IP_ADDRESS = 'ipAddress',
}

export const DeviceStatusActions = {
  [EntityStatus.Active]: 'activate',
  [EntityStatus.Paused]: 'pause',
  [EntityStatus.Archived]: 'archive',
  [EntityStatus.Deactivated]: 'deactivate',
};

export interface DeviceData {
  additionalAttributes?: DeviceAdditionalAttribute[];
  alertStatus: DeviceAlertStatus;
  createdOn: string;
  deviceModelInformation?: DeviceModel;
  friendlyName: string;
  id: string;
  importantDates?: {
    comissioningInfo: {
      dateTime: string;
    };
    decomissionedInfo: null | string;
    installationInfo: {
      dateTime: string;
    };
    provisioningInfo: null | string;
  };
  incidentCountByStatuses: {
    inProgressCount: number;
    newCount: number;
  };
  integrationDeviceId: null | string;
  latestStreamingTimestamp?: string;
  location: DeviceLocationData;
  locationId?: string;
  name: string;
  parentSpaceId?: string;
  physicalDeviceId: string;
  spacePath?: Space[];
  status: EntityStatus;
  tags: {
    [key: string]: string;
  } | null;
  unstructuredDataReference: string;
}

export interface ExtendedDeviceData extends DeviceData {
  position: null | Record<string, any>;
}

export enum DeviceAlertStatus {
  IN_ALERT = 'inAlert',
  IN_WARNING = 'inWarning',
  OK = 'ok',
}

export interface DeviceLocationData {
  address: LocationAddress;
  clientFriendlyId: string;
  friendlyName: string;
  id: string;
  name: string;
  status: EntityStatus;
}

export interface DeviceAdditionalAttribute {
  attributeType: DeviceAttributeType;
  friendlyName: string;
  name: string;
  recordedTimeStamp: string | null;
  value: string | null;
}

export enum DeviceAttributeType {
  PROPERTY = 'property',
  REALTIME = 'realTime',
}

export interface NormalizedDeviceData {
  building: string;
  buildingId: string;
  buildingStatus: string;
  deviceName: string;
  deviceType: string;
  firmware: string;
  floor: string;
  floorId: string;
  id: string;
  incidentCountByStatuses: {
    inProgressCount: number;
    newCount: number;
  };
  integrationDeviceId: string;
  ipAddress: string;
  manufacturer: string;
  model: string;
  room: string;
  roomId: string;
  serialNumber: string;
  status: EntityStatus;
}

export interface DeviceChartsPayload {
  fromDateTimeUtc: string;
  points: { name: string }[];
  toDateTimeUtc: string;
}

export interface DeviceChart {
  friendlyName: string;
  name: string;
  timeseries: { timeStamp: string; value: number; }[];
  unitOFMeasure: null | {
    display: string;
    displayShort: string;
    isStep: boolean;
  };
}

export interface DeviceManufacturer {
  id: string;
  name: string;
}

export interface DeviceType {
  id: string;
  name: string;
}

export interface DeviceModel {
  deviceType: string;
  documentReference: null | string | { [key: string]: string };
  id: string;
  make: DeviceManufacturer;
  name: string;
  ownerClientId: string;
  type: DeviceType;
}

export interface DeviceFullModel extends DeviceModel {
  standardAttributes: DeviceAttribute[];
}

export interface DeviceAttribute {
  attributeType: DeviceAttributeType;
  clientId: string;
  friendlyName: string;
  id: string;
  name: string;
}

export interface NewDeviceModelData {
  deviceType: DeviceModel['deviceType'];
  name: DeviceModel['name'];
  standardAttributeIds: DeviceAttribute['id'][];
}

export interface FilterOption {
  parentId?: string;
  title: string;
  value: string;
}
