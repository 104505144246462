import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { UserModel } from '@models';
import { Store } from '@ngrx/store';
import { AppState, isSuperAdmin, selectUser } from '@ngrx-store';
import { AlertSettingsComponent } from '@standalone/alert-settings/alert-settings.component';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';
import { PersonalProfileComponent } from '@standalone/personal-pofile/personal-profile.component';
import { Observable } from 'rxjs';

@Component({
  imports: [AlertSettingsComponent, PageHeaderComponent, MatCardModule, MatTabsModule, PersonalProfileComponent, CommonModule],
  selector: 'avs-fe-user-info',
  standalone: true,
  templateUrl: './user-info.component.html',
})
export class UserInfoComponent {
  user$: Observable<UserModel | undefined>;
  isSuperAdmin$: Observable<boolean>;

  constructor(private store: Store<AppState>) {
    this.user$ = this.store.select(selectUser);
    this.isSuperAdmin$ = this.store.select(isSuperAdmin);
  }
}
