import { NgClass } from '@angular/common';
import { Component, effect, ElementRef, inject, input, signal } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { EntityStatus, FloorSpaceModel, Space, SpaceType } from '@models';
import { PipesModule } from '@pipes';
import { AppService, LocationService } from '@services';
import { NoDataComponent } from '@standalone/no-data/no-data.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { map } from 'rxjs';

import { WidgetBuildingPlan } from '../../../../models';

@Component({
  imports: [
    NgxSkeletonLoaderModule,
    PipesModule,
    NoDataComponent,
    RouterLink,
    MatTooltip,
    NgClass,
  ],
  selector: 'avs-li-building-plan',
  standalone: true,
  styleUrls: ['./building-plan.component.scss'],
  templateUrl: './building-plan.component.html',
})

export class BuildingPlanComponent {
  config = input.required<WidgetBuildingPlan>();

  protected readonly EntityStatus = EntityStatus;
  protected appService = inject(AppService);
  protected locationService = inject(LocationService);
  protected roomFitsNumber = 0;
  protected elRef = inject(ElementRef);
  protected floorList = signal<FloorSpaceModel[]>([]);

  constructor() {
    effect(() => {
      if (this.config().locationId) {
        this.locationService.getSpacesList(this.config().locationId)
          .pipe(
            map(spaces => this.normalizeSpaceResponse(spaces)),
          )
          .subscribe(spaces => {
            this.floorList.set(spaces);
            this.calculateRoomFitsNumber();
          });
      }
    }, { allowSignalWrites: true });
  }

  protected calculateRoomFitsNumber() {
    const containerWidth = this.elRef.nativeElement.querySelector('.rooms-container')?.offsetWidth;
    const containerMargin = 16;
    const elementMargin = 4;
    const elementWidth = 24;
    const maxRoomCount = this.floorList().reduce((acc, floor) => {
      if (floor.rooms?.length) {
        const filteredRooms = floor.rooms.filter(room => room.status !== EntityStatus.Archived);

        return filteredRooms?.length > acc ? filteredRooms?.length : acc;
      }

      return acc;
    }, 0);

    if (containerWidth) {
      this.roomFitsNumber = Math.round((containerWidth - containerMargin) / (elementWidth + elementMargin));
      this.roomFitsNumber = this.roomFitsNumber > maxRoomCount ? this.roomFitsNumber : maxRoomCount;
    }
  }

  protected normalizeSpaceResponse(spaces: Space[]) {
    const tempSpaces: Record<string, FloorSpaceModel> = {};
    const rooms = spaces.filter(space => space.type === SpaceType.room);
    const floors = spaces.filter(space => space.type === SpaceType.floor) as FloorSpaceModel[];

    floors.forEach(floor => {
      tempSpaces[floor.id] = floor;
    });
    rooms.forEach(room => {
      if (room.parentSpaceId) {
        tempSpaces[room.parentSpaceId].rooms = [...tempSpaces[room.parentSpaceId].rooms || [], room];
      }
    });

    return Object.values(tempSpaces) as FloorSpaceModel[];
  }

}
