import { CdkCopyToClipboard } from '@angular/cdk/clipboard';
import { NgIf } from '@angular/common';
import { Component, computed, DestroyRef, effect, inject, input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatCard } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltip } from '@angular/material/tooltip';
import { DirectivesModule } from '@directives';
import { DeviceData, EntityStatus, IncidentModel, IncidentStatus } from '@models';
import { Store } from '@ngrx/store';
import { isDeviceUpdating, rebootDevice } from '@ngrx-store';
import { PipesModule } from '@pipes';
import { NotificationsService } from '@services';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';
import { ImgLoaderComponent } from '@standalone/img-loader/img-loader.component';
import { IncidentStatusComponent } from '@standalone/incident-status/incident-status.component';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  imports: [
    DirectivesModule,
    ImgLoaderComponent,
    IncidentStatusComponent,
    MatCard,
    MatIcon,
    MatProgressSpinner,
    MatSuffix,
    NgIf,
    NgxSkeletonLoaderModule,
    PageHeaderComponent,
    PipesModule,
    MatTooltip,
    CdkCopyToClipboard,

  ],
  selector: 'avs-li-device-management',
  standalone: true,
  styleUrl: './device-management.component.scss',
  templateUrl: './device-management.component.html',
})
export class DeviceManagementComponent {
  incidents = input.required<IncidentModel[]>();
  device = input.required<DeviceData>();
  clientId = input('');
  protected imgUrl = computed(() => {
    return 'clientId/' +
      this.clientId() +
      '/library/standardMake/' +
      this.device().deviceModelInformation!.make!.id +
      '/standardModel/' +
      this.device().deviceModelInformation!.id +
      '/document/' +
      this.device().deviceModelInformation!.documentReference;
  });
  protected defaultImgUrl = 'assets/icons/image-placeholder.svg';
  protected destroyRef = inject(DestroyRef);
  protected store = inject(Store);
  protected dialog = inject(MatDialog);
  protected notificationMessage = inject(NotificationsService);
  protected deviceIsUpdating = false;
  protected incidentStatus = IncidentStatus;
  protected tempSensor: string | null | undefined = undefined;
  protected readonly EntityStatus = EntityStatus;

  constructor() {
    this.store.select(isDeviceUpdating).subscribe(isUpdating => (this.deviceIsUpdating = isUpdating));
    effect(() => this.initDeviceAttributes());
  }

  protected copiedToClipboard() {
    this.notificationMessage.showSuccessMessage('Copied to clipboard');
  }

  protected getDeviceUrl(device: DeviceData): string {
    return device.tags?.['ipAddress'] + '/' + (device.tags?.['hyperlink'] || '');
  }

  protected initDeviceAttributes() {
    if (this.device()?.additionalAttributes) {
      this.tempSensor = this.device()?.additionalAttributes?.find(attribute => attribute.name === 'tempSensor')?.value;
    }
  }

  protected rebootDevice() {
    ConfirmationDialogComponent.open(this.dialog, {
      description: 'Are you sure you want to reboot the device? ',
      title: 'Reboot Device',
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.store.dispatch(rebootDevice({ deviceId: this.device()?.id, locationId: this.device()?.location.id }));
        }
      });
  }
}
