<div class="font-nunito flex justify-center items-center text-base leading-6">
  <span
    class="test-active-amount text-ui-green-light font-semibold"
    [matTooltipDisabled]="!showTooltip()"
    matTooltip="Active"
  >{{ activeAmount() }}</span>
  <span class="mx-1.5">|</span>
  <span
    class="test-paused-amount text-ui-gray-dark font-semibold"
    [matTooltipDisabled]="!showTooltip()"
    matTooltip="Paused"
  >{{ pausedAmount() }}</span>
</div>
