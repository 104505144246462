import { Component } from '@angular/core';
import { NewDeviceFormComponent } from '@standalone/new-device-form/new-device-form.component';

@Component({
  imports: [NewDeviceFormComponent],
  selector: 'avs-fe-add-device',
  standalone: true,
  template: '<app-new-device-form />',
})
export class AddDeviceComponent {}
