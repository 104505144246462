import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientManagementModule } from '@app/modules/client-management/client-management.module';
import { DashboardModule } from '@app/modules/dashboard/dashboard.module';
import { DevicesModule } from '@app/modules/devices/devices.module';
import { IncidentsModule } from '@app/modules/incidents/incidents.module';
import { ReportingModule } from '@app/modules/reporting/reporting.module';
import { UserManagementModule } from '@app/modules/user-management/user-management.module';
import { UserProfileModule } from '@app/modules/user-profile/user-profile.module';
import { AuthGuard, LoginGuard } from '@app-lib';
import { AuthModule } from '@auth/auth.module';
import { DashboardPageComponent } from '@dashboards';
import { Error404Component } from '@standalone/error-404/error-404.component';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@app/modules/auth/auth.module').then(() => AuthModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'user-profile',
    loadChildren: () => import('@app/modules/user-profile/user-profile.module').then(() => UserProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('@app/modules/dashboard/dashboard.module').then(() => DashboardModule),
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Dashboard' },
  },
  {
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Dashboards' },
    loadComponent: () => import('@dashboards')
      .then(() => DashboardPageComponent),
    path: 'dashboards/:dashboardId',
  },
  {
    path: 'devices',
    loadChildren: () => import('@app/modules/devices/devices.module').then(() => DevicesModule),
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Devices' },
  },
  {
    path: 'incidents',
    loadChildren: () => import('@app/modules/incidents/incidents.module').then(() => IncidentsModule),
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Incidents' },
  },
  {
    path: 'reporting',
    loadChildren: () => import('@app/modules/reporting/reporting.module').then(() => ReportingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'client-management',
    loadChildren: () =>
      import('@app/modules/client-management/client-management.module').then(() => ClientManagementModule),
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Client Management' },
  },
  {
    path: 'user-management',
    loadChildren: () => import('@app/modules/user-management/user-management.module').then(() => UserManagementModule),
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Users Management' },
  },
  {
    path: '**',
    pathMatch: 'full',
    component: Error404Component,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
