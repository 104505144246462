<div
  class="expandable-floor-item"
  [class.is-open]="floor.id === openedFloorId"
>
  <div
    class="test-title text-ui-dark flex items-center h-[44px] px-3 font-nunito text-sm cursor-pointer border-x border-b border-ui-gray-light"
    (click)="titleClick()"
  >
    <div class="w-[33.33%] flex items-center">
      <mat-icon class="item-closed">keyboard_arrow_down</mat-icon>
      <mat-icon class="item-open">keyboard_arrow_up</mat-icon>
      <span class="inline-flex ml-2">{{ floor.friendlyName }}</span>
    </div>
    <span
      class="test-placeholder w-[66.66%] text-ui-gray-100"
      *ngIf="roomsPlaceholder"
    >{{ roomsPlaceholder }} room{{ roomsPlaceholder > 1 ? 's' : '' }}</span>
  </div>
  <div
    class="expandable-floor-item-content"
    *ngIf="floor.id === openedFloorId"
  >
    <div class="p-6 border-x border-ui-gray-light">
      <app-upload-spaceplan
        *ngIf="!floor.documentReference && floor.id && locationId"
        [locationId]="locationId"
        uploadText="Upload Floor Plan"
        [floorId]="floor.id"
      />
      <app-spaceplan
        *ngIf="floor.documentReference && locationId"
        [spaceplan]="{
          id: floor.id,
          documentReference: floor.documentReference,
          attachments: floor.rooms || [],
          parentSpaceId: floor.parentSpaceId
        }"
        [locationId]="locationId"
        [popover]="editRoomPopover"
        [canEdit]="true"
      />
    </div>
    @if(locationId) {
      <avs-fe-handle-rooms
        [floor]="floor"
        [locationId]="locationId"
        [isPartOfExpandableItem]="true"
      />
    }
  </div>
</div>
