<mat-form-field
  class="search-input"
  [ngClass]="cssClass"
  [style]="{ width: inputWidth }"
>
  <i
    class="icon-search text-ui-gray-light text-2xl"
    matPrefix
  ></i>
  <input
    type="text"
    (ngModelChange)="modelChange($event)"
    [(ngModel)]="inputValue"
    [tp]="tpl"
    [value]="inputValue"
    #input
    autocomplete="off"
    matInput
    placeholder="{{ placeholder }}"
    tpPlacement="bottom"
    tpTrigger="click"
    tpVariation="popper"
  />
  <button
    class="test-clear-button"
    (click)="clear()"
    *ngIf="input.value"
    aria-label="Clear"
    mat-icon-button
    matSuffix
  >
    <i class="icon-close text-ui-dark text-3xl"></i>
  </button>
</mat-form-field>

<ng-template #tpl>
  <ul class="w-[280px]">
    @for (item of lastPickedItems; track item.title) {
      <li
        class="h-10 text-black font-nunito text-sm flex items-center hover:bg-ui-green-150 px-3 cursor-pointer whitespace-nowrap"
        [routerLink]="item.url"
      >
        <span class="overflow-hidden text-ellipsis">{{ item.title }}</span>
      </li>
    }
  </ul>
</ng-template>
