import { Component, computed, effect, inject, Injector, input, OnDestroy, OnInit, output, runInInjectionContext, untracked } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';

import { FiltersStore } from '../../stores';
import { DevicesFiltersSheetComponent } from './devices-filters-sheet.component';

interface FilterPreset {
  roomId: string;
  floorId: string;
  locationId: string;
}

@Component({
  selector: 'avs-li-mobile-devices-filters',
  standalone: true,
  imports: [SearchInputComponent],
  templateUrl: './mobile-devices-filters.component.html',
})
export class MobileDevicesFiltersComponent implements OnInit, OnDestroy {
  readonly filtersStore = inject(FiltersStore);

  tableId = input.required<string>();
  filtersChanged = output<Record<string, string | string[]>>();
  filtersPreset = input<FilterPreset>();
  showLocationFilter = input<boolean>(true);

  hasSelectedFilters = computed(() =>
    this.showLocationFilter()
      ? this.filtersStore.hasSelectedFilters()
      : this.filtersStore.deviceMaker().length || this.filtersStore.deviceType().length,
  );
  private bottomSheet = inject(MatBottomSheet);
  private injector = inject(Injector);

  constructor() {
    effect(() => {
      const filtersPreset = this.filtersPreset();
      let optionsParams;

      if (filtersPreset) {
        optionsParams = { space: filtersPreset.roomId };
        untracked(() =>
          this.filtersStore.initDefaultFilters({
            room: filtersPreset.roomId,
            floor: filtersPreset.floorId,
            building: filtersPreset.locationId,
          }),
        );
      }
      this.filtersStore.fetchOptions(optionsParams);
    });
  }

  ngOnInit() {
    this.filtersStore.setTableId(this.tableId());
    this.filtersStore.initDefaultFilters();

    runInInjectionContext(this.injector, () => {
      effect(() => this.filtersChanged.emit(this.filtersStore.appliedFilters()));
    });
  }

  ngOnDestroy() {
    this.filtersStore.resetState();
  }

  openDeviceFiltersBottomSheet() {
    this.bottomSheet.open(DevicesFiltersSheetComponent, {
      data: {
        tableId: this.tableId(),
        showLocationFilter: this.showLocationFilter(),
      },
    });
  }
}
