export interface UserModel {
  accessToken?: string;
  email: string;
  firstName: string;
  id: string;
  lastActivity: string;
  lastName: string;
  notificationsPreferences: {
    emailEnabled: boolean;
    smsEnabled: boolean;
  };
  organizationName: string;
  phone: {
    countryCode: string;
    countryNumberCode: string;
    number: string;
    smsOptIn: boolean;
  };
  role: {
    id: string;
    name: UserRolesTypes;
  };
  status: string;
  userClients?: UserClient[];
}

export interface AuthorizedUser extends UserModel {
  homeClientUserSettings: {
    userSessionTimeoutMinutes: number;
  };
}

export enum UserStatus {
  ACTIVE = 'active',
  INVITED = 'invited',
  INACTIVE = 'inactive',
  DEACTIVATED = 'deactivated',
  BLOCKED = 'blocked',
  ARCHIVED = 'archived',
}

export interface UserClient {
  clientName: string;
  clientShortName: string;
  description: string;
  friendlyName: string;
  id: string;
  isManagingClient: boolean;
  roleId: string;
  status: UserStatus;
}

export enum UserRolesIds {
  ClientAdmin = '1fe6e63e-3248-4fa1-b84c-80fde7ffa51b',
  SuperAdmin = 'aeeb3e72-8b0c-4900-9376-62b9199d0df1',
  ReadOnly = 'b287b693-f4b5-436c-af2e-2222696d15ff',
}

export enum UserRolesNamesById {
  '1fe6e63e-3248-4fa1-b84c-80fde7ffa51b' = 'ClientAdmin',
  'aeeb3e72-8b0c-4900-9376-62b9199d0df1' = 'SuperAdmin',
  'b287b693-f4b5-436c-af2e-2222696d15ff' = 'ReadOnly',
}

export enum RoleNames {
  ClientAdmin = 'Client Admin',
  SuperAdmin = 'Super Admin',
  ReadOnly = 'Basic User',
}

export enum UserRolesTypes {
  ClientAdmin = 'ClientAdmin',
  ReadOnly = 'ReadOnly',
  SuperAdmin = 'SuperAdmin',
  Empty = '',
}

export const userRoles = {
  [UserRolesTypes.ClientAdmin]: {
    id: UserRolesIds.ClientAdmin,
    name: RoleNames.ClientAdmin,
  },
  [UserRolesTypes.ReadOnly]: {
    id: UserRolesIds.ReadOnly,
    name: RoleNames.ReadOnly,
  },
  [UserRolesTypes.SuperAdmin]: {
    id: UserRolesIds.SuperAdmin,
    name: RoleNames.SuperAdmin,
  },
  [UserRolesTypes.Empty]: {
    id: '',
    name: '',
  },
};

export const rolesRules = {
  [UserRolesTypes.SuperAdmin]: [userRoles.SuperAdmin, userRoles.ReadOnly, userRoles.ClientAdmin],
  [UserRolesTypes.ClientAdmin]: [userRoles.ReadOnly, userRoles.ClientAdmin],
  [UserRolesTypes.ReadOnly]: [],
  [UserRolesTypes.Empty]: [],
};
