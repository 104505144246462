<div class="flex">
  <label class="input-main-label">Address:*</label>
  <mat-form-field class="input-classic">
    <input
      type="text"
      class="autocomplete-input"
      [(ngModel)]="autocompleteInput"
      #place
      matInput
      placeholder="Address line"
    />
    @if (autocompleteInput) {
      <button
        class="p-0"
        (click)="clearAutocomplete()"
        aria-label="Clear"
        matSuffix
      >
        <i class="icon-circle-close text-ui-dark text-3xl hover:text-ui-gray-dark"></i>
      </button>
    }
  </mat-form-field>
</div>
<google-map
  (mapInitialized)="mapInitialized($event)"
  [options]="mapOptions"
  height="250px"
  width="100%"
>
  @if (mapCenter) {
    <map-advanced-marker
      [content]="markerImg"
      [position]="mapCenter"
      #advancedMarker="mapAdvancedMarker"
    ></map-advanced-marker>
  }
</google-map>
