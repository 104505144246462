<h1 class="dialog-title">
  <span>Add new model</span>
  <button mat-dialog-close>
    <i class="icon-close"></i>
  </button>
</h1>

<mat-dialog-content class="dialog-content">
  <form
    id="model-form"
    (ngSubmit)="createModel()"
    [formGroup]="form"
  >
    <div class="flex flex-row">
      <label
        class="input-main-label"
        for="image"
      >Model image:</label>
      <div>
        <div class="classic-form-item-wrapper">
          @if (!imagePreviewSrc && !file) {
            <div class="w-[270px] h-[200px] flex justify-center items-center bg-ui-gray-50 text-ui-gray-100 relative border-dashed border-2 border-ui-gray-light mb-14">
              <div class="flex flex-col items-center justify-center">
                <i class="icon-upload-map text-5xl mb-1.5"></i>
                <span class="font-nunito font-semibold text-xs">Upload model image</span>
              </div>
              <input
                id="image"
                type="file"
                type="file"
                class="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer"
                (change)="onFileChange($event)"
                accept="image/*"
              />
            </div>
          }
          @if (imagePreviewSrc && file) {
            <div class="w-[270px] flex flex-col mb-4">
              <img
                class="mb-4"
                [src]="imagePreviewSrc"
                alt="preview"
              />
              <div class="text-ui-gray-100 font-nunito text-sm font-semibold flex items-center">
                <span class="overflow-hidden text-ellipsis whitespace-nowrap">{{ file.name }}</span>
                <mat-icon
                  class="test-button cursor-pointer ml-1"
                  (click)="deleteFile()"
                >close</mat-icon>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
    <div class="flex flex-row">
      <label
        class="input-main-label"
        for="name"
      >Model name:*</label>
      <div>
        <mat-form-field class="input-classic">
          <input
            id="name"
            type="text"
            formControlName="name"
            matInput
            placeholder="Enter model name"
          />
          @if (form.get('name')?.hasError('required')) {
            <mat-error>Input is not valid</mat-error>
          }
          @if (form.get('name')?.hasError('alreadyExist')) {
            <mat-error>Value already exist</mat-error>
          }
        </mat-form-field>
      </div>
    </div>
    <div class="flex flex-row items-center mb-5">
      <label class="input-main-label mt-0">Device type:*</label>
      <div>
        <mat-form-field class="select-classic">
          <mat-select
            formControlName="deviceType"
            panelClass="single-select-overlay"
            placeholder="Select device type"
          >
            <button
              class="btn-small btn-green mb-4 ml-3"
              (click)="openAddDeviceTypeDialog()"
            >
              <mat-icon>add</mat-icon>
              Add new device type
            </button>
            <div class="mb-1 mx-3">
              <app-search-input
                (searchDataEvent)="deviceTypeSearch($event)"
                inputWidth="100%"
                placeholder="Search device type"
              ></app-search-input>
            </div>
            @for (deviceType of deviceTypeOptions | filterSelectOptions : deviceTypeFilterValue; track deviceType.value) {
              <mat-option
                class="full-width-option"
                [value]="deviceType.value"
              >
                <div class="flex justify-between items-center">
                  {{ deviceType.title }}
                  <i
                    class="icon-button icon-edit text-3xl cursor-pointer text-ui-green-100 mx-2"
                    (click)="editDeviceType($event)"
                  ></i>
                </div>
              </mat-option>
            }
          </mat-select>
          <mat-icon
            class="arrow-down"
            matSuffix
          >keyboard_arrow_down</mat-icon>
          <mat-icon
            class="arrow-up"
            matSuffix
          >keyboard_arrow_up</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="flex flex-row items-center mb-5">
      <label class="input-main-label mt-0">Manufacturer:*</label>
      <div>
        <mat-form-field class="select-classic">
          <mat-select
            formControlName="manufacturer"
            panelClass="single-select-overlay"
            placeholder="Select manufacturer"
          >
            <button
              class="btn-small btn-green mb-4 ml-3"
              (click)="openAddManufacturerDialog()"
            >
              <mat-icon>add</mat-icon>
              Add new manufacturer
            </button>
            <div class="mb-1 mx-3">
              <app-search-input
                (searchDataEvent)="manufacturerSearch($event)"
                inputWidth="100%"
                placeholder="Search manufacturer"
              ></app-search-input>
            </div>
            @for (manufacturer of manufacturerOptions | filterSelectOptions : manufacturerFilterValue; track manufacturer.value) {
              <mat-option
                class="full-width-option"
                [value]="manufacturer.value"
              >
                <div class="flex justify-between items-center">
                  {{ manufacturer.title }}
                  <i
                    class="icon-button icon-edit text-3xl cursor-pointer text-ui-green-100 mx-2"
                    (click)="editManufacturer($event)"
                  ></i>
                </div>
              </mat-option>
            }
          </mat-select>
          <mat-icon
            class="arrow-down"
            matSuffix
          >keyboard_arrow_down</mat-icon>
          <mat-icon
            class="arrow-up"
            matSuffix
          >keyboard_arrow_up</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <mat-card-title class="card-title-small mb-5">Select existing data points or add new ones</mat-card-title>

    @if (attributes.length) {
      <div class="flex flex-row test-realtime-attribute-controls mb-5">
        <label class="input-main-label mt-0">Select existing data points:</label>
        <div>
          <ul class="classic-form-item-wrapper flex flex-wrap">
            @for (attribute of attributes; track attribute.id) {
              <li class="w-1/2">
                <mat-checkbox
                  class="main-checkbox max-w-full"
                  (change)="realtimeAttributeChange($event)"
                  [value]="attribute.id"
                  form="model-form"
                >{{ attribute.name }}
                </mat-checkbox>
              </li>
            }
          </ul>
        </div>
      </div>
    }
  </form>
  <app-new-attribute-form
    (newAttributeEvent)="createAttribute($event)"
    [isNewAttributeCreating]="(isNewAttributeCreating$ | async) || false"
    [realtimeAttributes]="attributes"
  />
</mat-dialog-content>
<mat-dialog-actions
  class="dialog-actions"
  align="end"
>
  <button
    class="test-btn-cancel btn-transparent btn-medium mr-4 font-bold"
    mat-dialog-close
  >CANCEL</button>
  <button
    type="submit"
    class="test-submit-btn btn-green btn-medium font-bold"
    [disabled]="(isDeviceModelCreating$ | async) === true || (isDeviceModelImageUploading$ | async) === true"
    form="model-form"
  >
    @if ((isDeviceModelCreating$ | async) === true || (isDeviceModelImageUploading$ | async) === true) {
      <mat-spinner
        class="mr-1"
        diameter="20"
      ></mat-spinner>
    }
    SAVE
  </button>
</mat-dialog-actions>
