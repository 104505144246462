import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, input, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/autocomplete';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatError, MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatSelect } from '@angular/material/select';
import { PeriodFilterEnum } from '@core';

import { StatsSupportedTypes, WidgetDeviceStats } from '../../../../models';
import { LocationOptionsComponent } from '../../../options';
import {
  DateRangeSelectorComponent,
  DeviceDataPointSelectorComponent,
  DeviceSelectorComponent,
} from '../../../selectors';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DateRangeSelectorComponent,
    DeviceDataPointSelectorComponent,
    DeviceSelectorComponent,
    LocationOptionsComponent,
    MatCheckbox,
    MatError,
    MatFormField,
    MatIcon,
    MatOption,
    MatSelect,
    MatSuffix,
    ReactiveFormsModule,
    TitleCasePipe,
  ],
  selector: 'avs-li-device-stats-options',
  standalone: true,
  templateUrl: './widget-device-stats-options.component.html',
})
export class WidgetDeviceStatsOptionsComponent {
  change = output<Partial<WidgetDeviceStats>>();
  config = input<WidgetDeviceStats>();

  protected dataType;
  protected form: FormGroup;
  protected optionsInitialized = false;
  protected tempOptions = ['min', 'max', 'average'];
  protected powerOptions = [...this.tempOptions, 'total'];
  protected selectedOptionList = this.powerOptions;
  protected dataTypes = [
    {
      title: 'Power',
      value: StatsSupportedTypes.POWER,
    },
    {
      title: 'Temperature',
      value: StatsSupportedTypes.THERMOSTAT,
    },
  ];

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      dataType: [('' as StatsSupportedTypes), Validators.required],
      datapointName: ['', Validators.required],
      dateRange: [PeriodFilterEnum, Validators.required],
      deviceId: ['', Validators.required],
      locationId: ['', Validators.required],
    });
    this.dataType = toSignal(this.form.get('dataType')!.valueChanges);
    this.createCheckboxFormControls();

    effect(() => {
      this.form.patchValue(this.config() as { [key: string]: any; });
      if (!this.optionsInitialized) {
        this.createCheckboxFormControls();
        this.optionsInitialized = true;

        if (this.config()?.displayOptions) {
          this.fillCheckboxOptions(this.config()!.displayOptions);
        }
      }
    });
  }

  protected createDisplayOptions($event: string) {
    this.patchForm('dataType', $event);
    this.patchForm('deviceId', '');
    this.createCheckboxFormControls();
    this.change.emit(this.form.valid ? this.form.value : null);
  }

  protected patchForm(key: string, value: string | Partial<WidgetDeviceStats>) {
    if (key === 'locationId') {
      this.form.reset();
    }
    if (key === 'dateRange') {
      this.form.get('dateRange')?.patchValue(value);
      this.change.emit(this.form.valid ? this.form.value : null);
    } else {
      this.form.patchValue({ [key]: value });
    }
    this.change.emit(this.form.valid ? this.form.value : null);
  }

  private fillCheckboxOptions(options: { [key: string]: boolean; }) {
    for (const option in options) {
      this.form.get('displayOptions')?.get(option)?.setValue(options[option]);
    }
  }

  private createCheckboxFormControls() {
    const dataType = this.form.get('dataType')?.value;
    const options: Record<string, boolean> = {};

    if (!dataType) {
      return;
    }
    (this.selectedOptionList = dataType === StatsSupportedTypes.POWER ? this.powerOptions : this.tempOptions).forEach(option => {
      options[option] = true;
    });
    this.form.setControl('displayOptions', this.fb.group(options));
  }
}
