import { NgClass, NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UserStatus } from '@models';

@Component({
  imports: [NgStyle, NgClass],
  selector: 'app-user-status',
  standalone: true,
  styleUrl: './user-status.component.scss',
  template: ` <span
    class="test-user-status inline-block font-nunito font-semibold	capitalize px-2 py-0.5 rounded-md"
    [ngStyle]="{ 'font-size': fontSize }"
    [ngClass]="{
    'status-active': status === eUserStatus.ACTIVE,
    'status-inactive': status === eUserStatus.INACTIVE,
    'status-invited': status === eUserStatus.INVITED,
    'status-blocked': status === eUserStatus.BLOCKED,
    'status-archived': status === eUserStatus.ARCHIVED,
    'status-deactivated': status === eUserStatus.DEACTIVATED,
  }"
    >{{ status }}</span
  >`,
})
export class UserStatusComponent {
  @Input() status: UserStatus = UserStatus.ACTIVE;
  @Input() fontSize = '0.75rem';
  protected readonly eUserStatus = UserStatus;
}
