import { Component, DestroyRef, inject } from '@angular/core';
import { IncidentModel, IncidentNoteModel, IncidentStatus } from '@models';
import { AppService, IncidentsService, NotificationsService } from '@services';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppState, loadAllIncidents } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { SocketEvents } from '@core';

@Component({
  selector: 'app-incident-details-page',
  templateUrl: './incident-details-page.component.html',
  styleUrls: ['./incident-details-page.component.scss'],
})
export class IncidentDetailsPageComponent {
  notes: IncidentNoteModel[] = [];
  incident: IncidentModel | undefined;
  noteDescription = '';
  incidentId = '';
  postNoteLoading = false;
  destroyRef = inject(DestroyRef);

  constructor(
    private notificationService: NotificationsService,
    private route: ActivatedRoute,
    private incidentService: IncidentsService,
    public appService: AppService,
    private store: Store<AppState>,
  ) {
    const incidentId = this.route.snapshot.paramMap.get('id');
    SocketEvents.pipe(takeUntilDestroyed()).subscribe(({ data }) => {
      const shouldBeRefreshed = Array.isArray(data)
        ? !!(data.find((incident) => incident.id === incidentId))
        : data.id === incidentId;
      if (shouldBeRefreshed) {
        this.getIncident();
      }
    });
    if (incidentId) {
      this.incidentId = incidentId;
      this.getIncident();
      this.incidentService
        .getIncidentNotes(this.appService.currentClient, incidentId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(data => {
          this.notes = data;
        });
    }
  }

  getIncident() {
    this.incidentService
      .getIncidentById(this.appService.currentClient, this.incidentId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: incident => {
          this.incident = incident;
        },
        error: error => {
          this.notificationService.showErrorMessage(error.message);
        },
      });
  }

  addNote() {
    this.postNoteLoading = true;
    this.incidentService
      .addIncidentNote(this.appService.currentClient, this.incidentId, { textValue: this.noteDescription })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: note => {
          this.postNoteLoading = false;
          this.noteDescription = '';
          this.notes.unshift(note);
          this.getIncident();
          this.store.dispatch(loadAllIncidents());
        },
        error: error => {
          this.postNoteLoading = false;
          this.notificationService.showErrorMessage(error.message);
        },
      });
  }

  protected readonly IncidentStatus = IncidentStatus;
}
