import { PhoneNumberClass } from '@abstraction';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatError } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import {
  COUNTRY_LIST,
  DEFAULT_COUNTRY,
  emailValidationPattern,
  passwordPolicy,
  removeSpacesValidator,
  userLockOutPolicyId,
} from '@app-lib';
import { DirectivesModule } from '@directives';
import { rolesRules, UserClient, UserModel, UserRolesIds } from '@models';
import { Store } from '@ngrx/store';
import { addClientUser, AppState, clientUserIsLoading, getClient, selectUser } from '@ngrx-store';
import { AppService } from '@services';
import { BreadcrumbsComponent } from '@standalone/breadcrumbs/breadcrumbs.component';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';
import { NgxMaskDirective } from 'ngx-mask';
import { Observable } from 'rxjs';

@Component({
  imports: [MatSlideToggleModule, MatCardModule, MatError, ReactiveFormsModule, NgxMaskDirective, BreadcrumbsComponent, PageHeaderComponent, MatIconModule, MatSelectModule, MatProgressSpinnerModule, DirectivesModule, CommonModule, MatInputModule],
  selector: 'avs-fe-user-data-form',
  standalone: true,
  templateUrl: './user-data-form.component.html',
})
export class UserDataFormComponent extends PhoneNumberClass {
  form: FormGroup;
  isLoading$: Observable<boolean>;
  user: UserModel | undefined;

  get formIsValid(): boolean {
    return this.form.valid;
  }

  get formValues(): { [key: string]: string } {
    return this.form.value;
  }

  protected readonly COUNTRY_LIST = COUNTRY_LIST;
  protected roleOptions = rolesRules;
  protected currentClient: Observable<UserClient | undefined>;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private appService: AppService,
  ) {
    super();
    this.form = this.fb.group({
      company: [''],
      email: ['', [Validators.required, Validators.pattern(emailValidationPattern)]],
      firstName: ['', [Validators.required, Validators.min(3), removeSpacesValidator]],
      lastName: ['', [Validators.required, Validators.min(3), removeSpacesValidator]],
      lockoutPolicyId: [userLockOutPolicyId],
      notificationsPreferences: this.fb.group({
        emailEnabled: [false, [Validators.required]],
        smsEnabled: [{ disabled: true, value: false }, [Validators.required]],
      }),
      passwordPolicyId: passwordPolicy,
      phone: this.fb.group({
        countryCode: [DEFAULT_COUNTRY.code, [Validators.required]],
        countryNumberCode: [DEFAULT_COUNTRY.phoneCode, [Validators.required]],
        number: ['', [Validators.required]],
      }),
      roleId: [UserRolesIds.ReadOnly, [Validators.required]],
    });
    this.store
      .select(selectUser)
      .pipe(takeUntilDestroyed())
      .subscribe(user => (this.user = user));
    this.currentClient = this.store.select(getClient(this.appService.currentClient));
    this.isLoading$ = this.store.select(clientUserIsLoading);
  }

  submitForm() {
    this.form.markAllAsTouched();
    if (this.formIsValid) {
      const countryCode = this.form.get('phone.countryCode');

      this.form
        .get('phone.countryNumberCode')
        ?.setValue(
          COUNTRY_LIST.find(country => country.code === countryCode?.value)?.phoneCode || DEFAULT_COUNTRY.phoneCode,
        );
      const clientId = this.route.snapshot.queryParams['clientId'];

      this.store.dispatch(addClientUser({ clientId, newUserData: this.form.value }));
    }
  }
}
