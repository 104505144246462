import { Injectable } from '@angular/core';
import { DeviceAttribute, DeviceData, DeviceManufacturer, DeviceModel, DeviceType } from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppService, DeviceService, NotificationsService } from '@services';
import { forkJoin, map, of, switchMap, withLatestFrom } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';

import { actionError, DevicesActions } from '../action-types';
import { AppState } from '../app-state';
import { loadAllIncidents } from '../incidents';
import { getLocationState } from '../locations';
import { getMyClient } from '../user';
import * as actions from './devices.actions';

@Injectable()
export class DevicesEffects {
  loadAllDevicesByClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.loadAllClientDevices),
      switchMap(() =>
        this.deviceService.getAllDevicesByClientId(this.appService.currentClient).pipe(
          map(devices => actions.allClientDevicesLoaded({ devices })),
          catchError(async error => actions.loadDevicesError({ error })),
        ),
      ),
    ),
  );

  resetDeviceData$ = createEffect(() => this.actions$.pipe(ofType(DevicesActions.resetDeviceEntity)), {
    dispatch: false,
  });

  addNewDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.addNewDevice),
      switchMap(action =>
        this.deviceService.addNewDevice(this.appService.currentClient, action.locationId, action.newDeviceData).pipe(
          map((device: DeviceData) => {
            this.notificationService.showSuccessMessage(
              'Device [' + device.friendlyName + '] was successfully created',
            );

            return actions.addNewDeviceSuccess({ newDevice: device });

          }),
          catchError(async error => {
            this.notificationService.showErrorMessage(error.message);

            return actions.addNewDeviceError();
          }),
        ),
      ),
    ),
  );

  loadOneDeviceDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.loadDeviceDetails),
      switchMap(action =>
        this.deviceService
          .getDeviceDetailsByDeviceId(action.locationId, action.deviceId)
          .pipe(
            map(device => actions.loadDeviceDetailsSuccess({ deviceItem: device })),
            catchError(async error => actionError({ error })),
          ),
      ),
    ),
  );

  updateDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DevicesActions.updateDeviceData),
      switchMap(action => {
        return this.deviceService
          .updateDeviceData({
            clientId: this.appService.currentClient,
            data: action.data,
            deviceId: action.deviceId,
            locationId: action.locationId,
          })
          .pipe(
            map(device => {
              this.notificationService.showSuccessMessage('Device - ' + device.friendlyName + ' was updated');

              return actions.deviceDataSuccessfullyUpdated({ device, oldData: action.data });
            }),
            catchError(async () => actions.updateDeviceDataError()),
          );
      }),
    );
  });

  loadDeviceCollectionByRoomId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.loadDeviceCollectionBySpaceId),
      switchMap(action =>
        this.deviceService
          .getAllDevicesByClientId(this.appService.currentClient, {
            locationId: action.locationId,
            spaceId: action.roomId,
          })
          .pipe(map(devices => actions.loadDeviceCollectionBySpaceIdSuccess({ devices }))),
      ),
    ),
  );

  deviceDataSuccessfullyUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.deviceDataSuccessfullyUpdated),
      // TODO: get rid of delay when api is ready
      delay(5000),
      map(action => {
        if (action.oldData.status) {
          this.store.dispatch(loadAllIncidents());
        }

        return getLocationState({
          excludeArchived: false,
          locationId: action.device.locationId || action.device.location.id,
        });
      }),
    ),
  );

  deviceInfoCollections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.loadDeviceCollections),
      withLatestFrom(this.store.select(getMyClient)),
      switchMap(([_, myClient]) =>
        forkJoin([
          this.deviceService.getManufacturers(myClient?.id || this.appService.currentClient),
          this.deviceService.getModels(myClient?.id || this.appService.currentClient),
          this.deviceService.getAttributes(myClient?.id || this.appService.currentClient),
          this.deviceService.getDeviceTypes(myClient?.id || this.appService.currentClient),
        ]).pipe(
          map(([manufacturers, models, attributes, deviceTypes]) =>
            DevicesActions.loadDeviceCollectionsSuccess({
              attributes,
              deviceTypes,
              manufacturers,
              models,
            }),
          ),
        ),
      ),
    ),
  );

  loadFullModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.loadDeviceFullModel),
      switchMap(action =>
        this.deviceService
          .getFullModel({
            clientId: action.clientId,
            manufacturerId: action.manufacturerId,
            modelId: action.modelId,
          })
          .pipe(map(fullModel => actions.loadDeviceFullModelSuccess({ fullModel }))),
      ),
    ),
  );

  addNewManufacturer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.addNewManufacturer),
      switchMap(action =>
        this.deviceService.addNewManufacturer(action.clientId, action.newManufacturerData).pipe(
          map((manufacturer: DeviceManufacturer) => {
            this.notificationService.showSuccessMessage(
              'Manufacturer [' + manufacturer.name + '] was successfully created',
            );

            return actions.addNewManufacturerSuccess({ manufacturer });
          }),
          catchError(async error => {
            this.notificationService.showErrorMessage(error.message);

            return actions.addNewManufacturerError();
          }),
        ),
      ),
    ),
  );

  addNewDeviceType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.addNewDeviceType),
      switchMap(action =>
        this.deviceService.addNewDeviceType(action.clientId, action.newDeviceTypeData).pipe(
          tap((deviceType: DeviceType) => {
            this.notificationService.showSuccessMessage(
              `Device type [${deviceType.name}] was successfully created`,
            );
          }),
          map(deviceType => actions.addNewDeviceTypeSuccess({ deviceType })),
          catchError(error => {
            this.notificationService.showErrorMessage(error.message);

            return of(actions.addNewDeviceTypeError());
          }),
        ),
      ),
    ),
  );

  addNewDeviceModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.addNewDeviceModel),
      switchMap(action =>
        this.deviceService.addNewDeviceModel(action.clientId, action.makeId, action.newDevicdeModelData).pipe(
          map((deviceModel: DeviceModel) => {
            this.notificationService.showSuccessMessage('Model [' + deviceModel.name + '] was successfully created');

            return actions.addNewDeviceModelSuccess({ deviceModel });
          }),
          catchError(async error => {
            this.notificationService.showErrorMessage(error.message);

            return actions.addNewDeviceModelError();
          }),
        ),
      ),
    ),
  );

  uploadImageToDeviceModel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DevicesActions.uploadDeviceModelImage),
      switchMap(action => {
        return this.deviceService
          .uploadDocumentToDeviceModel({
            body: action.file,
            clientId: action.clientId,
            deviceMakeId: action.deviceMakeId,
            deviceModelId: action.deviceModelId,
          })
          .pipe(
            map(document => {
              this.notificationService.showSuccessMessage('Device model image was successfully uploaded');

              return actions.uploadDeviceModelImageSuccess({ deviceModelId: action.deviceModelId, document });
            }),
            catchError(async error => {
              this.notificationService.showErrorMessage(error.message);

              return actions.uploadDeviceModelImageError({ error });
            }),
          );
      }),
    );
  });

  addNewAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.addNewAttribute),
      switchMap(action =>
        this.deviceService.addNewAttribute(action.clientId, action.data).pipe(
          map((attribute: DeviceAttribute) => {
            this.notificationService.showSuccessMessage('Attribute [' + attribute.name + '] was successfully created');

            return actions.addNewAttributeSuccess({ attribute });
          }),
          catchError(async error => {
            this.notificationService.showErrorMessage(error.message);

            return actions.addNewAttributeError();
          }),
        ),
      ),
    ),
  );

  rebootDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.rebootDevice),
      switchMap(action =>
        this.deviceService.rebootDevice(this.appService.currentClient, action.locationId, action.deviceId).pipe(
          tap(() => this.notificationService.showSuccessMessage('Device reboot initiated successfully.')),
          map(() => actions.rebootDeviceSuccess()),
          catchError(async () => {
            this.notificationService.showErrorMessage('An unexpected error occurred. Please try again later.');

            return actions.rebootDeviceError();
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private deviceService: DeviceService,
    private appService: AppService,
    private notificationService: NotificationsService,
    private store: Store<AppState>,
  ) {
  }
}
