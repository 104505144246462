export const DAY = 1;
export const WEEK = 7;
export const MONTH = 30;

export enum PeriodFilterEnum {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export interface DateRange {
  fromDateTimeUtc: string,
  toDateTimeUtc: string;
}
