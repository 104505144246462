import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BackButtonDirective } from './back-button.directive';
import { NoSpaceSelectDirective } from './no-space-select.directive';
import { PaginatorSettingsDirective } from './paginator-settings.directive';
import { RestrictedElementDirective } from './restricted-element.directive';
import { ScrollingDirective } from './scrolling.directive';
import { SelectBlurDirective } from './select-blur.directive';
import { SuperAdminRoleFilterDirective } from './super-admin-role-filter.directive';
import { UploadSpaceplanDirective } from './upload-spaceplan.directive';

@NgModule({
  declarations: [
    PaginatorSettingsDirective,
    RestrictedElementDirective,
    SuperAdminRoleFilterDirective,
    UploadSpaceplanDirective,
    BackButtonDirective,
    NoSpaceSelectDirective,
    SelectBlurDirective,
    ScrollingDirective,
  ],
  exports: [
    PaginatorSettingsDirective,
    RestrictedElementDirective,
    SuperAdminRoleFilterDirective,
    UploadSpaceplanDirective,
    BackButtonDirective,
    NoSpaceSelectDirective,
    SelectBlurDirective,
    ScrollingDirective,
  ],
  imports: [CommonModule],
})
export class DirectivesModule { }
