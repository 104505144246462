<div *ngIf="tabEnabled" class="flex justify-end">
  <mat-form-field class="select-classic max-w-[200px] mb-2">
    <mat-select placeholder="Select tab type" panelClass="single-select-overlay" [(value)]="selectedTabValue">
      <mat-option>None</mat-option>
      @for (option of tabSelectOptions; track option) {
      <mat-option [value]="option.value" [disabled]="option.disabled">{{ option.title }}</mat-option>
      }
    </mat-select>
    <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
    <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
  </mat-form-field>
</div>

@let disabled = tabEnabled && !selectedTabValue;
<div
  class="h-[316px] flex justify-center items-center bg-ui-gray-50 relative"
  [class]="disabled ? 'text-ui-gray-light' : 'text-ui-gray-100'">
  <div class="flex flex-col items-center justify-center">
    <i class="icon-upload-map text-5xl mb-1.5"></i>
    <span class="font-nunito font-semibold text-xs">{{ uploadText }}</span>
    <span class="font-nunito font-semibold text-xs">(SVG)</span>
  </div>
  <input
    class="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer"
    [class]="disabled ? 'pointer-events-none' : 'cursor-pointer'"
    type="file"
    appUploadSpaceplan
    (fileUploaded)="onFileUploaded()"
    accept="image/svg+xml"
    [tab]="selectedTabValue"
    [disabled]="disabled"
    [locationId]="locationId"
    [floorId]="floorId"
    [roomId]="roomId" />
</div>
