import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationService, SocketEvents } from '@core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppService, IncidentsFilterService, IncidentsService, NotificationsService } from '@services';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IncidentsActions } from '../action-types';
import { AppState } from '../app-state';
import { isLoggedIn } from '../user';
import { loadAllIncidentError, loadAllIncidentSuccess } from './incidents.actions';

@Injectable()
export class IncidentsEffects {
  loadAllIncidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentsActions.loadAllIncidents),
      concatMap(() =>
        this.incidentService.getAllIncidents(this.appService.currentClient).pipe(
          map(incidents => loadAllIncidentSuccess({ incidents })),
          catchError(async error => loadAllIncidentError({ error })),
        ),
      ),
    ),
  );
  locationErrorHandler = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IncidentsActions.loadAllIncidentError),
        withLatestFrom(this.store.select(isLoggedIn)),
        tap(([action, isLoggedIn]) => {
          if (isLoggedIn && action?.error?.message) {
            this.notificationService.showErrorMessage(action.error.message);
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private incidentService: IncidentsService,
    private notificationService: NotificationsService,
    private appService: AppService,
    private store: Store<AppState>,
    private navigationService: NavigationService,
    private incidentFilterService: IncidentsFilterService,
  ) {
    SocketEvents.pipe(
      takeUntilDestroyed(),
      map(({ data }) => Array.isArray(data) ? data : [data]),
    ).subscribe((data) => {
      const locId = this.navigationService.route()?.snapshot.params['locationId'];

      data
        .filter(({ device }) => device.locationId === locId)
        .forEach(_ => {
          this.store.dispatch(IncidentsActions.loadAllIncidents());
          this.incidentFilterService.resolvedIncidentsRequest(true);
        });
    });
  }
}
