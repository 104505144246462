import { InstanceTypes } from '@models';

export const environment = {
  api: 'https://be-dev.harmonyconnected.com/api/',
  featureToggles: {
    useDashboardsBuilder: true,
  },
  instance: InstanceTypes.DEV,
  mobileAppUrl: 'https://ashy-rock-09653f00f.5.azurestaticapps.net/',
  production: false,
  useContextApiVersioning: true,
  wsUrl: 'wss://be-dev.harmonyconnected.com',
};
