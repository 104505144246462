import { Component, input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  imports: [MatTooltip],
  selector: 'app-device-status-count',
  standalone: true,
  templateUrl: './device-status-count.component.html',
})
export class DeviceStatusCountComponent {
  showTooltip = input<boolean>(true);
  activeAmount = input<number>(0);
  pausedAmount = input<number>(0);
}
