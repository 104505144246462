@if (incidentDeviceModelChartData) {
  <mat-card class="card p-3 my-4">
    <div class="h-[400px] overflow-y-auto">
      <highcharts-chart
        class="highcharts-classic-axes block highcharts-small-title"
        [Highcharts]="Highcharts"
        [options]="incidentDeviceModelChartData"
      />
    </div>
  </mat-card>
}
