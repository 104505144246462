import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ReportingMainPageComponent } from './containers/reporting-main-page/reporting-main-page.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        component: ReportingMainPageComponent,
        path: '',
      },
    ]),
  ],
})
export class ReportingModule {}
