<div
  class="min-h-[65px] mx-auto text-center"
  [@inOutAnimationLogo]="!menuShrunk ? 'big' : 'small'"
  (@inOutAnimationLogo.start)="animationInProgress = true"
  (@inOutAnimationLogo.done)="animationInProgress = false"
>
  <a routerLink="/dashboard">
    <img
      class="py-4"
      src="assets/main-logo.png"
      alt="logo"
    />
  </a>
</div>
<div class="relative py-4 mt-[-8px] flex">
  <button
    *ngIf="showMenuSizeToggle"
    (click)="toggleMenu()"
    class="toggle-menu-btn absolute rounded-full bg-white max-h-[24px] shadow right-[-12px] top-0 hover:bg-ui-green-light text-white active: bg-ui-primary"
  >
    <mat-icon class="text-ui-primary hover:text-white active:text-white">
      {{ !menuShrunk ? 'chevron_left' : 'chevron_right' }}
    </mat-icon>
  </button>
  <app-instance-badge [minified]="!menuShrunk" />
</div>
<mat-nav-list class="main-menu flex py-0 flex-col h-[calc(100vh_-_5rem)]">
  <div
    *ngIf="clientsList.length && isSuperAdmin"
    class="px-4"
  >
    <div
      class="select-client-button"
      [class.simple]="menuShrunk || animationInProgress"
      [class.mx-auto]="menuShrunk || animationInProgress"
      [tp]="tpl"
      (tpVisible)="resetSearch()"
      tpTrigger="click"
      tpVariation="popper"
      [tpPlacement]="!menuShrunk ? 'bottom' : 'right-start'"
    >
      <span
        *ngIf="!menuShrunk"
        class="whitespace-nowrap overflow-hidden text-ellipsis max-w-[120px]"
      >{{
          selectedClient?.friendlyName
        }}</span>
      <span
        *ngIf="menuShrunk"
        class="whitespace-nowrap overflow-hidden"
      >{{
          selectedClient?.friendlyName?.charAt(0)
        }}</span>
      <mat-icon class="arrow-down">keyboard_arrow_down</mat-icon>
    </div>
  </div>
  <section class="flex-grow">
    @if ('useDashboardsBuilder' | isFeatureEnabled) {
      <avs-li-dashboards-selector [menuShrunk]="menuShrunk" />
    }
    <a
      *ngFor="let menuItem of sortByIndex()"
      [routerLink]="menuItem.url ? menuItem.url : undefined"
      routerLinkActive="main-menu-item-active"
      class="test-menu-item menu-item mr-2 ml-2 pr-2 w-auto relative"
      [class]="menuItem.class"
      [class.hidden]="menuItem.superAdminOnly && !isSuperAdmin"
      #rla="routerLinkActive"
      mat-list-item
      [matTooltip]="!menuShrunk ? '' : menuItem.title"
      matTooltipPosition="right"
    >
      <mat-label class="text-white flex align-middle relative">
        <i
          matPrefix
          [ngClass]="rla.isActive ? menuItem.icon : menuItem.icon + '-stroke'"
          class="text-white ml-2 mr-4 text-3xl"
        ></i>
        <span
          [@inOutAnimation]="!menuShrunk ? 'open' : 'closed'"
          class="text-sm"
        >{{ menuItem.title }}</span>
      </mat-label>
      @if (menuItem.showIncidentsAmount) {
        @if (!menuShrunk) {
          <span
            class="incidents-amount-indicator absolute top-3.5"
            [class]="(newIncidentsAmount$ | async) && (inProgressIncidentsAmount$ | async) === 0 ? 'right-3' : 'right-9'"
            *ngIf="newIncidentsAmount$ | async as newIncidentsAmount"
          >
            {{ newIncidentsAmount }}
          </span>
          <span
            class="incidents-amount-indicator bg-ui-yellow-100 text-ui-yellow-350 absolute right-3 top-3.5"
            *ngIf="inProgressIncidentsAmount$ | async as inProgressIncidentsAmount"
          >
            {{ inProgressIncidentsAmount }}
          </span>
        } @else if (menuShrunk && ((newIncidentsAmount$ | async) || (inProgressIncidentsAmount$ | async))) {
          <span class="absolute top-4 left-10 h-2 w-2 rounded-full bg-ui-red-100"></span>
        }
      }
    </a>
    <div
      class="my-6 border-t border-ui-green-300"
      appRestrictedEl
    ></div>
    <a
      *ngFor="let menuItem of additionalMenuItems"
      [routerLink]="menuItem.url ? menuItem.url : undefined"
      routerLinkActive="main-menu-item-active"
      class="test-menu-item menu-item mr-2 ml-2 pr-2 w-auto"
      [class]="menuItem.class"
      [class.hidden]="menuItem.superAdminOnly && !isSuperAdmin"
      #rla="routerLinkActive"
      mat-list-item
      [matTooltip]="!menuShrunk ? '' : menuItem.title"
      matTooltipPosition="right"
    >
      <mat-label class="text-white flex align-middle">
        <i
          matPrefix
          [ngClass]="rla.isActive ? menuItem.icon : menuItem.icon + '-stroke'"
          class="text-white ml-2 mr-4 text-3xl"
        ></i>
        <span
          [@inOutAnimation]="!menuShrunk ? 'open' : 'closed'"
          class="text-sm"
        >{{ menuItem.title }}</span>
      </mat-label>
    </a>
  </section>
  <a
    class="test-logout-menu-item menu-item mr-2 ml-2 pr-2 w-auto mb-6"
    (click)="logout()"
    mat-list-item
    [matTooltip]="!menuShrunk ? '' : logoutMenuItem.title"
    matTooltipPosition="right"
  >
    <mat-label class="text-white flex align-middle">
      <i
        matPrefix
        class="text-white ml-2 mr-4 text-2xl"
        [ngClass]="logoutMenuItem.icon"
      ></i>
      <span
        [@inOutAnimation]="!menuShrunk ? 'open' : 'closed'"
        class="text-sm"
      >{{ logoutMenuItem.title }}</span>
    </mat-label>
  </a>
</mat-nav-list>

<ng-template
  #tpl
  let-hide
>
  <div class="w-[200px] py-2 test-client-list">
    <div class="mx-0.5">
      <app-search-input
        placeholder="Enter name"
        (searchDataEvent)="clientSearch($event)"
        inputWidth="100%"
        cssClass="no-border"
      ></app-search-input>
    </div>
    <div>
      <ul class="max-h-[320px] overflow-y-auto">
        <li
          *ngFor="let client of clientsList | sort : 'asc' : 'friendlyName' | filterClientsByName : clientSearchValue"
          class="h-10 text-black font-nunito text-sm flex items-center hover:bg-ui-green-150 px-3 cursor-pointer whitespace-nowrap"
          [class.text-ui-green-light]="client.id === selectedClient?.id"
          [matTooltip]="client.friendlyName"
          matTooltipPosition="right"
          (click)="clientChange(client); hide()"
        >
          <span class="text-ellipsis overflow-hidden">{{ client.friendlyName }}</span>
        </li>
        <li
          class="flex justify-center text-ui-gray-100"
          *ngIf="!clientsList.length"
        >No Results
        </li>
      </ul>
    </div>
  </div>
</ng-template>
