import { Component, effect, inject, input, output, signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { DeviceService } from '@devices';
import { NormalizedDeviceData } from '@models';

@Component({
  imports: [
    MatIcon,
    MatSelectModule,
  ],
  selector: 'avs-li-device-selector',
  standalone: true,
  templateUrl: './device-selector.component.html',
})

export class DeviceSelectorComponent {
  change = output<string>();
  filters = input<{ dataPoint?: string, location?: string, space?: string; }>({});
  value = input<string>();

  protected deviceService = inject(DeviceService);
  protected devices = signal<NormalizedDeviceData[]>([]);

  constructor() {
    effect(() => {
      this.deviceService.list({ filters: this.filters() })
        .subscribe(({ data }) => {
          this.devices.set(data);
        });
    });
  }
}
