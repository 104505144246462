import type { AbstractControl, ValidationErrors } from '@angular/forms';
import type { MatSort } from '@angular/material/sort';
import { type AttachmentPosition, type Coordinates, type DeviceAdditionalAttribute, type DeviceAttributeType, type DeviceData, EntityStatus, type IncidentModel, type NormalizedDeviceData, type NormalizedIncident, SpaceplanTabType, type UserModel, UserRolesTypes } from '@models';
import { saveAs } from 'file-saver';

export function removeSpacesValidator(control: AbstractControl): ValidationErrors | null {
  if (control && control.value && !control.value.trim().length) {
    return { noSpaceAllowed: true };
  }

  return null;
}
export const MAIN_MENU_ANIMATION_DURATION = 300;
export const passwordValidationPattern: RegExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{12,}/;
export const emailValidationPattern: string = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$';
export const passwordPolicy = ['8009b138-6a61-4e12-85cc-7842b110acda'];
export const userLockOutPolicyId = '5bbe300c-f2d6-47d2-8072-bf11f5af3656';
export const attributeNameValidationPattern: RegExp = /^[a-zA-Z0-9_\-]+$/;

export const normalizeDeviceItem = (
  device: DeviceData,
  locationName: string = '',
  locationId: string = '',
): NormalizedDeviceData => {
  return {
    building: locationName || device?.location?.friendlyName || '',
    buildingId: locationId || device?.location?.id || device?.locationId || '',
    buildingStatus: locationName || device?.location?.status || '',
    deviceName: device.friendlyName,
    deviceType: device.deviceModelInformation?.deviceType || '',
    firmware: device.tags?.['firmware'] || '',
    floor: device?.spacePath?.[0]?.friendlyName || '',
    floorId: device.spacePath?.[0]?.id || '',
    id: device.id,
    incidentCountByStatuses: device.incidentCountByStatuses,
    integrationDeviceId: device.integrationDeviceId || '',
    ipAddress:
      device.additionalAttributes?.find(attribute => attribute.name === 'ipAddress')?.value ||
      device.tags?.['ipAddress'] ||
      '',
    manufacturer: device?.deviceModelInformation?.make.name || '',
    model: device?.deviceModelInformation?.name || '',
    room: device?.spacePath?.[1]?.friendlyName || '',
    roomId: device.spacePath?.[1]?.id || '',
    serialNumber: device?.physicalDeviceId || '',
    status: device.status,
  };
};

export const StatusTextColor = {
  [EntityStatus.Active]: 'text-ui-green-light',
  [EntityStatus.Paused]: 'text-ui-dark',
  [EntityStatus.Archived]: 'text-ui-gray-125',
  [EntityStatus.Deactivated]: 'text-ui-gray-125',
};

export const defaultSelectOption = {
  title: 'All',
  value: '',
};

export function isElementInViewport(element: HTMLElement, checkElemWidthPosition: boolean = false) {
  const elementRect = element.getBoundingClientRect();
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;

  return (
    elementRect.top >= 0 &&
    elementRect.bottom <= windowHeight &&
    (checkElemWidthPosition ? elementRect.left >= 0 : true) &&
    (checkElemWidthPosition ? elementRect.right <= windowWidth : true)
  );
}

export const capitalize = ([first, ...rest]: string) =>
  first ? first.toUpperCase() + rest.join('').toLowerCase() : '';

export enum IncidentColumn {
  INDEX = 'index',
  TICKET_NUMBER = 'ticketNumber',
  STATUS = 'status',
  FAULT_NAME = 'faultname',
  DEVICE_ID = 'deviceId',
  MANUFACTURER = 'manufacturer',
  MODEL = 'model',
  SERIAL_NUMBER = 'serialNumber',
  BUILDING = 'building',
  FLOOR = 'floor',
  ROOM = 'room',
  DEVICE_TYPE = 'deviceType',
  REPORTED = 'reported',
  RESOLVED = 'resolved',
}

export const staticIncidentColumnTitle: Record<string, string> = {
  [IncidentColumn.INDEX]: '№',
  [IncidentColumn.TICKET_NUMBER]: 'Ticket №',
};

export const incidentColumnTitle: Record<string, string> = {
  [IncidentColumn.STATUS]: 'Status',
  [IncidentColumn.FAULT_NAME]: 'Fault Name',
  [IncidentColumn.DEVICE_ID]: 'Device Name',
  [IncidentColumn.DEVICE_TYPE]: 'Device Type',
  [IncidentColumn.MANUFACTURER]: 'Manufacturer',
  [IncidentColumn.MODEL]: 'Model',
  [IncidentColumn.SERIAL_NUMBER]: 'Serial №',
  [IncidentColumn.BUILDING]: 'Building Name',
  [IncidentColumn.FLOOR]: 'Floor',
  [IncidentColumn.ROOM]: 'Room',
  [IncidentColumn.REPORTED]: 'Reported',
  [IncidentColumn.RESOLVED]: 'Resolved',
};

export const incidentColumnOptions = Object.keys(incidentColumnTitle).map(key => ({
  title: incidentColumnTitle[key],
  value: key,
}));

export const dayInMiliseconds = 24 * 60 * 60 * 1000;

export const subtractMonths = (date: Date, months: number) => {
  const dateCopy = new Date(date);

  dateCopy.setMonth(dateCopy.getMonth() - months);

  return dateCopy;
};

export const normalizeIncidents = (incidents: IncidentModel[]): NormalizedIncident[] => {
  return incidents.map(incident => ({
    assignedUser: '',
    building: incident.device.location.friendlyName,
    buildingId: incident.device.location.id,
    deviceId: incident.device.id,
    deviceName: incident.device.friendlyName,
    deviceType: incident.device.deviceModelInformation?.deviceType || '',
    faultname: incident.fault.name,
    floor: incident.device.spacePath?.[0]?.friendlyName || '',
    floorId: incident.device.spacePath?.[0]?.id || '',
    id: incident.id,
    manufacturer: incident.device.deviceModelInformation?.make.name || '',
    model: incident.device.deviceModelInformation?.name || '',
    reported: new Date(incident.createdTimestamp).toLocaleString('en-US'),
    resolved: incident.resolvedTimestamp ? new Date(incident.resolvedTimestamp).toLocaleString('en-US') : '',
    room: incident.device.spacePath?.[1]?.friendlyName || '',
    roomId: incident.device.spacePath?.[1]?.id || '',
    serialNumber: incident.device.physicalDeviceId,
    status: incident.status,
    ticketNumber: `${incident.incidentNumber}`,
  }));
};

export function getMonthsData(year: number) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const monthsData: { endDate: string; startDate: string; }[] = [];

  for (const month of months) {
    const startDate = new Date(year, months.indexOf(month), 1);
    const endDate = new Date(year, months.indexOf(month) + 1, 0);

    endDate.setHours(23, 59, 59, 999);

    monthsData.push({
      endDate: endDate.toISOString(),
      startDate: startDate.toISOString(),
    });
  }

  return monthsData;
}

export const getUserClient = (user: UserModel) =>
  user.role.name === UserRolesTypes.SuperAdmin
    ? user.userClients?.find(user => user.isManagingClient)?.id
    : user.userClients?.at(0)?.id;

export const exportDataToCSV = (dataToRender: { [key: string]: string | null }[], fileName: string) => {
  if (dataToRender.length) {
    const replacer = (_key: string, value: string | null) => (value === null ? '' : value); // specify how to handle null values
    const header = Object.keys(dataToRender[0]);
    const csv = dataToRender.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));

    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], { type: 'text/csv' });

    saveAs(blob, `${fileName}.csv`);
  }
};

export const exportDataToJSON = (dataToRender: { [key: string]: string | null }[], fileName: string) => {
  if (dataToRender.length) {
    const blob = new Blob([JSON.stringify(dataToRender)], {
      type: 'application/json',
    });

    saveAs(blob, `${fileName}.json`);
  }
};

interface IMergeObject {
  id: string;
}

export function mergeArraysById(arr1: IMergeObject[], arr2: IMergeObject[]) {
  const idMap = new Map();

  for (const obj of arr1) {
    idMap.set(obj.id, obj);
  }

  for (const obj of arr2) {
    const existingObj = idMap.get(obj.id);

    if (existingObj) {
      Object.assign(existingObj, obj);
    }
  }

  return [...idMap.values()];
}

export const getUniqueArrayItemsByProperty = <T>(list: T[], key: keyof T) => {
  const map = new Map();

  for (const obj of list) {
    map.set(obj[key], obj);
  }

  return [...map.values()];
};

export const getDeviceAttributesByType = (attributes: DeviceAdditionalAttribute[], type: DeviceAttributeType) =>
  attributes.filter(attribute => attribute.attributeType === type);

export const getMapBoundariesByCountry = async (country: string): Promise<google.maps.GeocoderResult> => {
  const geocoder = new google.maps.Geocoder();
  const response = await geocoder.geocode({ address: country });

  return response.results[0];
};

export const naturalSorting = <T>(data: T[], sort: MatSort): T[] => {
  const active = sort.active;
  const direction = sort.direction;

  if (!active || direction === '') {
    return data;
  }

  return data.sort((a: any, b: any) => {
    let valueA = a[active];
    let valueB = b[active];

    if (sort.active === 'reported' || sort.active === 'resolved') {
      valueA = new Date(valueA).getTime() + '';
      valueB = new Date(valueB).getTime() + '';
    }

    const valueAType = typeof valueA;
    const valueBType = typeof valueB;

    // Not available fields check
    if (valueA === undefined || valueB === undefined) return -1;

    // Object sorting
    if (valueAType === 'object') {
      valueA = JSON.stringify(valueA).toLowerCase();
    }
    if (valueBType === 'object') {
      valueB = JSON.stringify(valueB).toLowerCase();
    }

    // To string numbers
    if (valueAType !== valueBType) {
      if (valueAType === 'number') {
        valueA += ''.toLowerCase();
      }
      if (valueBType === 'number') {
        valueB += ''.toLowerCase();
      }
    }

    // natural sorting logic
    const comparatorResult = valueA.localeCompare(valueB, undefined, {
      numeric: true,
      sensitivity: 'base',
    });

    // asc / desc implementation
    return comparatorResult * (direction == 'asc' ? 1 : -1);
  });
};

export const spaceplanImgType = 'image/svg+xml';

export const checkIsObject = (value: any) => {
  return typeof value === 'object' && value !== null;
};
export const checkIsArray = (value: any) => {
  return Array.isArray(value);
};

export const spaceplanTabSelectOptions = [
  {
    disabled: false,
    title: 'Audio',
    value: SpaceplanTabType.AUDIO,
  },
  {
    disabled: false,
    title: 'Control',
    value: SpaceplanTabType.CONTROL,
  },
  {
    disabled: false,
    title: 'Network',
    value: SpaceplanTabType.NETWORK,
  },
  {
    disabled: false,
    title: 'Video',
    value: SpaceplanTabType.VIDEO,
  },
];

export const generateDocumentType = (value: string | number) => `type-${value}`;

export const defaultDocumentTab = 'new';

export const isSpaceplanTabType = (documentTab: string): documentTab is SpaceplanTabType =>
  Object.values(SpaceplanTabType).includes(documentTab as SpaceplanTabType);

export const getAttachmentPosition = (unstructuredDataReference: string): AttachmentPosition | null => {
  return unstructuredDataReference ? (JSON.parse(unstructuredDataReference) as AttachmentPosition) : null;
};

export const getCoordinatesByTab = (unstructuredDataReference: string, documentTab?: string): Coordinates | null => {
  const position = getAttachmentPosition(unstructuredDataReference);

  return documentTab && isSpaceplanTabType(documentTab)
    ? (position?.coordinates[documentTab] as Coordinates)
    : (position?.coordinates as Coordinates);
};

export const prepareDataForDownloading = (columns: string[], items: { [key: string]: any }[]) => {
  const itemKeys = Object.keys(items[0]);
  const dataToRender: { [key: string]: string | null }[] = items.map(item => {
    const row: { [key: string]: string | null } = {};

    columns.forEach(column => {
      const itemColumn = itemKeys.find(key => key === column);

      if (itemColumn) {
        const value = item[column as keyof typeof item];

        if (typeof value !== 'object') {
          row[column] = value;
        }
      }
    });

    return row;
  });

  return dataToRender;
};

export function clearObject(target: object) {
  const result = {} as Record<string, unknown>;

  for (const [key, value] of Object.entries(target)) {
    if (value !== null && value !== undefined && value !== '') {
      result[key] = value;
    }
  }

  return result;
}

export function hasValue(obj: object) {
  return Object.values(obj).filter(value => Array.isArray(value) ? value.length > 0 : Boolean(value)).length;
}
