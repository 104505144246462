<div [class.mt-3]="config().reportMode">
  @if (!config().reportMode) {
    <div class="test-button-group border-b-ui-gray-light border-b flex justify-between items-center mb-3">
      <mat-button-toggle-group
        class="desktop-toggle-group"
        (change)="filtersStore.patchFilters({ status: $event.value})"
        [value]="filtersStore.status()"
        aria-label="Device Status"
      >
        <mat-button-toggle
          class="test-device-status-current"
          [value]="eEntityStatus.Active"
        >
          <span class="item-status-title">Current</span>
        </mat-button-toggle>
        <mat-button-toggle
          class="test-device-status-archived"
          [value]="eEntityStatus.Archived"
        >
          <span class="item-status-title">Archived</span>
        </mat-button-toggle>
      </mat-button-toggle-group>

      @if (queryFilterEnabled()) {
        <div class="test-search-panel flex justify-center">
          <app-table-search-input
            (searchDataEvent)="filtersStore.patchFilters({ query: $event })"
            [inputValue]="filtersStore.query()"
            [tableId]="tableId()"
            placeholder="Device Name, type & other"
          />
        </div>
      }
    </div>
  }

  <div
    class="test-filters-panel flex"
    [ngClass]="filtersEnabled() && !config().hide ? 'justify-between' : 'justify-end'"
  >
    @if (filtersEnabled() && !config().hide) {
      <div class="flex items-start flex-wrap">
        @if (config().deviceTypeFilter) {
          <app-multiple-select
            class="test-device-type-select mr-2"
            (selectDataEvent)="filtersStore.patchFilters({ deviceType: $event })"
            [options]="filtersStore.deviceTypeOptions()"
            [value]="filtersStore.deviceType()"
            label="Device Type"
            panelClass="device-type-overlay"
            selectClass="stroked-select-multiple mb-3"
          />
        }

        @if (config().manufacturerFilter) {
          <app-multiple-select
            class="test-manufacturer-select mr-2"
            (selectDataEvent)="filtersStore.patchFilters({ deviceMaker: $event })"
            [options]="filtersStore.deviceMakerOptions()"
            [value]="filtersStore.deviceMaker()"
            label="Manufacturer"
            panelClass="assigned-user-overlay"
            selectClass="stroked-select-multiple mb-3"
          />
        }

        @if (config().locationFilter) {
          <mat-form-field class="test-building-select stroked-select-single mb-3 mr-2">
            <mat-label>Building</mat-label>
            <mat-select
              (valueChange)="filtersStore.patchFilters({
                building: $event,
                floor: undefined,
                room: undefined,
              })"
              [value]="filtersStore.building()"
              panelClass="single-select-overlay"
            >
              @for (building of filtersStore.locationOptions() | sort : 'asc' : 'title' : 'all' ; track building){
                <mat-option [value]="building.value">
                  {{ building.title }}
                </mat-option>
              }
            </mat-select>
            <mat-icon
              class="arrow-down"
              matSuffix
            > keyboard_arrow_down </mat-icon>
            <mat-icon
              class="arrow-up"
              matSuffix
            > keyboard_arrow_up </mat-icon>
          </mat-form-field>

          @if (filtersStore.building()) {
            <mat-form-field class="test-floor-select stroked-select-single mb-3 mr-2">
              <mat-label>Floor</mat-label>
              <mat-select
                (valueChange)="filtersStore.patchFilters({
                  floor: $event,
                  room: undefined,
                })"
                [value]="filtersStore.floor()"
                panelClass="single-select-overlay"
              >
                @for (floor of filtersStore.floorOptions() | sort : 'asc' : 'title' : 'all'; track floor.value) {
                  <mat-option [value]="floor.value">
                    {{ floor.title }}
                  </mat-option>
                }
              </mat-select>
              <mat-icon
                class="arrow-down"
                matSuffix
              > keyboard_arrow_down </mat-icon>
              <mat-icon
                class="arrow-up"
                matSuffix
              > keyboard_arrow_up </mat-icon>
            </mat-form-field>
          }

          @if (filtersStore.floor()) {
            <mat-form-field class="test-room-select stroked-select-single mb-3 mr-2">
              <mat-label>Room</mat-label>
              <mat-select
                (valueChange)="filtersStore.patchFilters({ room: $event })"
                [value]="filtersStore.room()"
                panelClass="single-select-overlay device-room-filter-overlay"
              >
                @for (room of filtersStore.roomOptions() | sort : 'asc' : 'title' : 'all'; track room.value) {
                  <mat-option [value]="room.value">
                    {{ room.title }}
                  </mat-option>
                }
              </mat-select>
              <mat-icon
                class="arrow-down"
                matSuffix
              > keyboard_arrow_down </mat-icon>
              <mat-icon
                class="arrow-up"
                matSuffix
              > keyboard_arrow_up </mat-icon>
            </mat-form-field>
          }
        }

        @if (filtersStore.hasSelectedFilters()) {
          <button
            class="test-clear-all-filters font-nunito text-sm text-ui-dark font-normal underline h-9 ml-3 mb-3"
            (click)="filtersStore.clearFilters()"
          >
            Clear All
          </button>
        }
      </div>
    }

    @if (config().columnSelectOptions && queryFilterEnabled()) {
      <mat-form-field class="column-select mb-3 justify-self-end">
        <mat-label matTooltip="Column Select">
          <i class="icon-ic_column text-3xl"></i>
        </mat-label>
        <mat-select
          (valueChange)="selectedColumns.set($event)"
          [value]="selectedColumns()"
          multiple
          panelClass="multi-select-overlay column-select-overlay"
        >
          <div class="options-action-wrapper">
            <button
              class="options-action-button"
              (click)="selectAllColumns()"
            >
              Select All
            </button>
          </div>

          @for (option of config().columnSelectOptions; track option.value) {
            <mat-option [value]="option.value">
              {{ option.title }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
  </div>
</div>
