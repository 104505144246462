import { Component, input, output } from '@angular/core';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { PeriodFilterEnum } from '@core';


@Component({
  imports: [
    MatSelectModule,
    MatButtonToggle,
    MatButtonToggleGroup,
  ],
  selector: 'avs-li-date-range-selector',
  standalone: true,
  templateUrl: './date-range-selector.component.html',
})
export class DateRangeSelectorComponent {
  change = output<PeriodFilterEnum>();
  value = input<PeriodFilterEnum>(PeriodFilterEnum.DAY);
  protected ePeriodFilter = PeriodFilterEnum;
}
