@if (locationsLoaded$ | async) {
  <div class="test-dashboard-main">
    <div class="flex justify-between items-center mb-4">
      <app-page-header>
        <div class="leading-10">Dashboard</div>
      </app-page-header>
      <div class="flex items-center">
        <div class="flex mb-1">
          <mat-slide-toggle
            class="main-toggle mr-4"
            (change)="toggleMap($event)"
            [checked]="mapEnabled"
            labelPosition="before"
          >
            View Map
          </mat-slide-toggle>
        </div>
        <a
          class="btn-green btn-medium"
          appRestrictedEl
          routerLink="add-new-site"
        >
          <mat-icon fontIcon="add" />
          Add New Building
</a>
      </div>
    </div>

    <div
      class="test-map-container overflow-hidden rounded-lg"
      [class]="!mapEnabled ? 'invisible h-0' : 'mb-4 '"
    >
      <div
        class="relative h-[400px]"
        appScrolling
      >
        <app-multiple-select
          (selectDataEvent)="mapFilterChange($event)"
          [options]="mapFilterService.mapFilterOptions"
          [showSelectedValues]="false"
          [sortingEnabled]="false"
          label="Map Alerts"
          panelClass="multiselect-map-filter-overlay"
          selectClass="stroked-select-multiple mb-0 map-site-filter"
        />
        @if ((locationsData$ | async); as locationsData) {
          <avs-li-map [locations]="locationsData" />
        }
      </div>
    </div>

    <mat-card class="card pb-2">
      <mat-card-title class="card-title">All Buildings</mat-card-title>
      <mat-card-content class="p-0">
        <app-site-table-desktop-filters />
        <app-site-table
          [isInjected]="true"
          tableId="dashboard-site-table"
        />
      </mat-card-content>
    </mat-card>
    <div class="card-separator"></div>
  </div>
} @else {
  <div class="test-skeleton-animation">
    <app-skeleton-page-header [buttonCount]="1" />
    <ngx-skeleton-loader [theme]="{ height: '400px' }" />
    <app-skeleton-table />
  </div>
}
