import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, isDevMode, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import type { RippleGlobalOptions } from '@angular/material/core';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import type { MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthModule } from '@app/modules/auth/auth.module';
import { DashboardModule } from '@app/modules/dashboard/dashboard.module';
import { InstanceBadgeComponent } from '@app/modules/main/components/instance-badge/instance-badge.component';
import { AppInterceptor } from '@app-lib';
import { WebSocketEffects } from '@core';
import { DashboardsSelectorComponent } from '@dashboards';
import { DirectivesModule } from '@directives';
import { ENVIRONMENT, environment } from '@env';
import { Keepalive, NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { popperVariation, provideTippyConfig, TippyDirective, tooltipVariation } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  AppSettingsEffects,
  AuthEffects,
  ClientsManagementsEffects,
  ClientUsersEffects,
  DevicesEffects,
  IncidentsEffects,
  LocationsEffects,
  metaReducers,
  reducers,
} from '@ngrx-store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IsFeatureEnabledPipe, PipesModule } from '@pipes';
import { RouterHistoryService } from '@services';
import { CopyrightFooterComponent } from '@standalone/copyright-footer/copyright-footer.component';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastrModule } from 'ngx-toastr';
import type { Observable } from 'rxjs';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { UserActivityModalComponent } from './components/user-activity-modal/user-activity-modal.component';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
};

export function initializeRouterHistory(routerHistoryService: RouterHistoryService): () => Observable<void> {
  return () => routerHistoryService.init();
}

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  disableTooltipInteractivity: true,
  hideDelay: 0,
  showDelay: 0,
  touchendHideDelay: 0,
};

export const HttpLoaderFactory = (httpClient: HttpClient) => {
  return new TranslateHttpLoader(httpClient);
};

// test
@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, MainMenuComponent, UserActivityModalComponent, InstanceBadgeComponent],
  exports: [CopyrightFooterComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    AuthModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: true,
        strictActionSerializability: false,
        strictStateImmutability: true,
        strictStateSerializability: true,
      },
    }),
    StoreDevtoolsModule.instrument({ connectInZone: true, logOnly: !isDevMode(), maxAge: 25 }),
    EffectsModule.forRoot([
      LocationsEffects,
      AuthEffects,
      AppSettingsEffects,
      ClientUsersEffects,
      DevicesEffects,
      IncidentsEffects,
      ClientsManagementsEffects,
      WebSocketEffects,
    ]),
    MatInputModule,
    MatCardModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    DashboardModule,
    MatTooltipModule,
    MatDialogModule,
    ToastrModule.forRoot({
      disableTimeOut: false,
      enableHtml: true,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      timeOut: 3000,
    }),
    MatSelectModule,
    FormsModule,
    TippyDirective,
    PipesModule,
    DirectivesModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'loading...' }),
    NgxMaskDirective,
    NgxMaskPipe,
    NgIdleKeepaliveModule,
    CopyrightFooterComponent,
    SearchInputComponent,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
    }),
    IsFeatureEnabledPipe,
    DashboardsSelectorComponent,
  ],
  providers: [
    RouterHistoryService,
    {
      deps: [RouterHistoryService],
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: initializeRouterHistory,
    },
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    provideEnvironmentNgxMask(),
    HttpClient,
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
    },
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        popper: popperVariation,
        tooltip: tooltipVariation,
      },
    }),
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    Keepalive,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
}
