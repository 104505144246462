import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  DataPointOptionsComponent,
  LocationFullOptionsComponent,
  LocationOptionsComponent,
  WidgetConfig,
  WidgetDeviceStatsOptionsComponent,
} from '@dashboards';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    LocationFullOptionsComponent,
    LocationOptionsComponent,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    WidgetDeviceStatsOptionsComponent,
    DataPointOptionsComponent,
  ],
  selector: 'avs-li-widget-dialog',
  standalone: true,
  templateUrl: './widget-dialog.component.html',
})
export class WidgetDialogComponent {
  protected data = inject<WidgetConfig>(MAT_DIALOG_DATA);
  protected form: FormGroup;

  private dialogRef = inject(MatDialogRef);
  private fb = inject(FormBuilder);

  constructor() {
    this.form = this.fb.group({
      name: [this.data?.name, Validators.required],
      type: [this.data?.type, Validators.required],
      widgetConfig: [this.data, Validators.required],
    });

    this.form.get('type')!
      .valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.form.patchValue({ widgetConfig: null }));
  }

  protected onSubmit() {
    const { name, type, widgetConfig } = this.form.value;

    this.dialogRef.close({ ...widgetConfig, name, type });
  }
}
