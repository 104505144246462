@if (!isLoading) {
  <div
    class="grid grid-cols-2 gap-1 w-full h-full"
    [class.grid-cols-1]="displayedItemsCount() == 1"
  >
    @if (config().displayOptions; as options) {
      @for (item of energyConsumption; track item.name) {
        @if (options[item.name]) {
          <li class="flex items-center rounded">
            <div
              class="icon text-xl"
              [class.max]="item.name === 'max'"
              [class.min]="item.name === 'min'"
            >
              <i
                [class.icon-ic_power]="config().dataType === StatsSupportedTypes.POWER"
                [class.icon-ic_temp]="config().dataType === StatsSupportedTypes.THERMOSTAT"
              ></i>
            </div>
            <div>
              <div class="text-lg font-bold">
                {{ item.value }}{{ config().dataType === StatsSupportedTypes.THERMOSTAT ? KELVIN : item.measurements }}
              </div>
              <div class="text-gray-600 text-sm">
                <span>{{ item.name | titlecase }} </span>
                <span class="text-gray-400 text-sm">(by {{ config().dateRange }})</span>
              </div>
            </div>
          </li>
        }
      }
    }
  </div>
} @else {
  <ngx-skeleton-loader [theme]="{ height: '200px' }" />
}
