import { CommonModule } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router, RouterModule } from '@angular/router';
import { BottomSheetMobileHeaderComponent } from '@standalone/bottom-sheet-mobile-header/bottom-sheet-mobile-header.component';

import { Dashboard } from '../../../models';

@Component({
  imports: [CommonModule, BottomSheetMobileHeaderComponent, RouterModule],
  selector: 'avs-li-mobile-dashboards-selector-options',
  standalone: true,
  templateUrl: './mobile-dashboards-selector-sheet.component.html',
})
export class MobileDashboardsSelectorSheetComponent {
  private router = inject(Router);
  constructor(
    private bottomSheetRef: MatBottomSheetRef<MobileDashboardsSelectorSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    protected data: {
      dashboards: Dashboard[];
    },
  ) {}

  protected close() {
    this.bottomSheetRef.dismiss();
  }

  protected async navigateToDashboard(id: string) {
    await this.router.navigate(['dashboards', id]);
    this.close();
  }
}
