@if (floorList()) {
  <div class="building-plan-wrapper">
    <div class="flex">
      <div class="floors-sidebar">
        <ul>
          @for (floor of floorList(); track floor.id) {
            <li
              class="floor-name text-ellipsis overflow-hidden px-2 whitespace-nowrap w-[101px]"
              [routerLink]="['/dashboard', config().locationId, floor.id]"
            >
              {{ floor.friendlyName }}
            </li>
          }
        </ul>
      </div>
      <div class="rooms-container w-full">
        <ul>
          @for (floor of floorList(); track floor.id) {
            <li>
              <ul class="test-floor-{{ floor.id }}">
                @for (room of floor.rooms; track room.id) {
                  <li
                    class="inline-block hover:cursor-pointer"
                    [class.incident-inProgress]="room.status === EntityStatus.Active && room.incidentCountByStatuses.inProgressCount &&!room.incidentCountByStatuses.newCount"
                    [class.incident-new]="room.status === EntityStatus.Active && room.incidentCountByStatuses.newCount"
                    [matTooltip]="room.friendlyName + ':&#10;' +(room.incidentCountByStatuses.newCount + room.incidentCountByStatuses.inProgressCount) + ' inc | ' +room.devicesCount +' dev'"
                    [ngClass]="floor.status !== EntityStatus.Archived ? room.status : floor.status"
                    [routerLink]="['/dashboard', config().locationId, floor.id, room.id]"
                    matTooltipClass="multiline-tooltip"
                  ></li>
                }
                @for (_ of (floor.rooms?.length | emptySpacesBuildingPlan : roomFitsNumber); track $index) {
                  <li class="empty-room inline-block"></li>
                }
              </ul>
            </li>
          }
        </ul>
      </div>
    </div>
  </div>
} @else {
  <app-no-data noDataText="No Floors yet" />
}


