import { CdkFixedSizeVirtualScroll, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import type { AfterViewInit, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef, Component, inject, Input, signal, ViewChild, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { RouterLink } from '@angular/router';
import {
  exportDataToCSV,
  exportDataToJSON,
  IncidentColumn,
  incidentColumnTitle,
  naturalSorting,
  normalizeIncidents,
  prepareDataForDownloading,
  staticIncidentColumnTitle,
} from '@app-lib';
import { SocketEvents, SocketEventType } from '@core';
import { DirectivesModule } from '@directives';
import { FixedSizeItemVirtualViewportTableDataSource } from '@layout';
import type { IncidentModel, NormalizedIncident } from '@models';
import { IncidentsFilterService } from '@services';
import { TableZoomComponent } from '@standalone/_tables/table-zoom/table-zoom.component';
import { IncidentStatusComponent } from '@standalone/incident-status/incident-status.component';
import { NoDataComponent } from '@standalone/no-data/no-data.component';

const ROW_HEIGHT = 44;

enum IncidentTabStatusFilter {
  OPEN = 'open',
  HISTORY = 'history',
}

@Component({
  imports: [
    AsyncPipe,
    MatTable,
    MatSortHeader,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatCellDef,
    MatSort,
    RouterLink,
    IncidentStatusComponent,
    MatHeaderCellDef,
    NgClass,
    DirectivesModule,
    NoDataComponent,
    MatHeaderRow,
    MatRow,
    NgIf,
    MatRowDef,
    MatHeaderRowDef,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    TableZoomComponent,
  ],
  selector: 'app-incidents-table',
  standalone: true,
  styleUrls: ['./incidents-table.component.scss'],
  templateUrl: './incidents-table.component.html',
})
export class IncidentsTableComponent implements AfterViewInit, OnChanges, OnInit {
  @ViewChild(MatSort) sort!: MatSort;
  @Input() noDataText = 'Data is Empty';
  @Input() noResolvedInicidentsText = 'No resolved incidents for selected period';
  @Input() isNoDataBigSize = false;
  @Input() incidentsData: IncidentModel[] = [];
  @Input() isReportMode = false;
  @Input() isInjected = false;
  @Input() dataIsLoading = false;
  @Input() isMobile = false;
  @Input() tableId = '';
  incidentTabStatusFilterEnum = IncidentTabStatusFilter;
  dataSource = new FixedSizeItemVirtualViewportTableDataSource<NormalizedIncident>();
  dataSourceIndex: { [id: string]: number } = {};
  public incidentsFilterService = inject(IncidentsFilterService);
  readonly staticIncidentColumnTitle = staticIncidentColumnTitle;
  readonly incidentColumnTitle = incidentColumnTitle;
  readonly IncidentColumn = IncidentColumn;
  protected zoom = signal<string | null>(null);
  protected viewport = viewChild(CdkVirtualScrollViewport);
  protected TYPICAL_COL_HEIGHT = ROW_HEIGHT;
  private changeDetectionRef = inject(ChangeDetectorRef);

  constructor() {
    this.incidentsFilterService.filteredIncidents$.pipe(takeUntilDestroyed()).subscribe(incidents => {
      this.initTableData(incidents);
    });
    SocketEvents.pipe(takeUntilDestroyed()).subscribe(event => {
      if (event.type === SocketEventType.INCIDENT_RESOLVED) {

      }
      this.incidentsFilterService.refreshIncidentCounter();
    });
  }

  @ViewChild('table') set tableEl(element: MatTable<any>) {
    if (element) {
      this.dataSource.sort = this.sort;
      this.changeDetectionRef.detectChanges();
    }
  }

  ngOnInit(): void {
    this.dataSource.itemSize = this.TYPICAL_COL_HEIGHT;
    this.dataSource.viewport = this.viewport();
  }


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['incidentsData']) {
      this.initTableData(normalizeIncidents(changes['incidentsData'].currentValue));
      this.getTableIndexes(this.dataSource.data);

    }
  }

  getTableIndexes(incidents: NormalizedIncident[]) {
    this.dataSourceIndex = {};
    for (let i = 0; i < incidents.length; i++) {
      this.dataSourceIndex[incidents[i].id] = i + 1;
    }
  }

  initTableData(incidents: NormalizedIncident[]) {
    this.dataSource.data = incidents;
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = naturalSorting;
    this.getTableIndexes(incidents);
  }

  prepareIncidentsDataForDownloading() {
    const columns = this.incidentsFilterService.displayedColumns.map(column => (column === 'index' ? 'id' : column));

    return prepareDataForDownloading(columns, this.dataSource.filteredData);
  }

  exportIncidentsToCSV() {
    exportDataToCSV(this.prepareIncidentsDataForDownloading(), 'incidents report');
  }

  exportIncidentsToJSON() {
    exportDataToJSON(this.prepareIncidentsDataForDownloading(), 'incidents report');
  }

  protected placeholderWhen(index: number, _: any) {
    return index === 0;
  }

}
