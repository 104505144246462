import { CustomHTTPResponseError, IncidentModel } from '@models';
import { createAction, props } from '@ngrx/store';

export const loadAllIncidents = createAction('[Incidents] Load all client incidents');

export const loadAllIncidentSuccess = createAction(
  '[Incidents] Load all client incidents success',
  props<{ incidents: IncidentModel[] }>()
);

export const loadAllIncidentError = createAction(
  '[Incidents] Load all client incidents error',
  props<{ error: CustomHTTPResponseError }>()
);

export const resetIncidents = createAction('[Incidents] Reset client incidents');

export const addIncident = createAction(
  '[Incidents] Update incident from socket',
  props<{ incident: IncidentModel }>()
);

export const resolveIncidents = createAction('[Incidents] Resolve incidents', props<{ incidents: IncidentModel[] }>());
