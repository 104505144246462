import type { MenuItemModel } from '@models';

export const ConditionalMenuItems = [
  {
    icon: 'icon-dashboard',
    itemIndex: 1,
    title: 'Dashboard',
    url: 'dashboard',
  },
] as MenuItemModel[];

export const MenuItems: MenuItemModel[] = [
  {
    icon: 'icon-devices',
    itemIndex: 2,
    title: 'Devices',
    url: 'devices',
  },
  {
    icon: 'icon-incidents',
    itemIndex: 3,
    showIncidentsAmount: true,
    title: 'Incidents',
    url: 'incidents',
  },
  {
    icon: 'icon-reporting',
    itemIndex: 4,
    title: 'Reporting',
    url: 'reporting',
  },
  {
    icon: 'icon-users-management',
    itemIndex: 5,
    title: 'User Management',
    url: 'user-management',
  },
];

export const AdditionalMenuItems: MenuItemModel[] = [
  {
    class: 'separator',
    icon: 'icon-star',
    itemIndex: 1,
    superAdminOnly: true,
    title: 'Client Management',
    url: 'client-management',
  },
  {
    icon: 'icon-my-profile',
    itemIndex: 2,
    title: 'My Profile',
    url: 'user-profile',
  },
];
