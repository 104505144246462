@if(isLoading()) {
  <ngx-skeleton-loader [theme]="{ height: '100%' }" />
} @else {
  <div class="flex flex-col h-full items-center justify-center">
    <p class="value text-ui-dark font-poppins font-semibold text-3xl capitalize whitespace-nowrap text-ellipsis overflow-hidden">
      {{ datapoint().value }}
      <span class="full-unit">{{ datapoint().unit?.display ?? datapoint().unit?.displayShort }}</span>
      <span class="short-unit">{{ datapoint().unit?.displayShort ?? datapoint().unit?.display }}</span>
    </p>
    <p class="datapoint text-ui-gray-100 font-nunito text-sm font-semibold break-words text-center overflow-hidden">
      {{ datapointName() }}
      @if (datapoint().date) {
        : {{ datapoint().date }}
      }
    </p>
  </div>
}
