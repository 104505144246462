import { ChangeDetectionStrategy, Component, computed, effect, input, output, signal } from '@angular/core';
import { SpaceType } from '@models';

import { SpaceSelectorComponent } from '../../selectors';
import { LocationOptionsComponent } from '../location-options/location-options.component';

interface FormValue {
  floorId?: string;
  locationId?: string;
  roomId?: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LocationOptionsComponent,
    SpaceSelectorComponent,
  ],
  selector: 'avs-li-location-full-options',
  standalone: true,
  templateUrl: './location-full-options.component.html',
})
export class LocationFullOptionsComponent {
  allRequired = input<boolean>(true);
  config = input<FormValue | null>();
  change = output<Partial<FormValue> | null>();

  protected eSpaceType = SpaceType;
  protected form = signal<FormValue>({});
  protected isValid = computed(() => {
    if (this.allRequired()) {
      return this.form().locationId !== undefined &&
        this.form().floorId !== undefined &&
        this.form().roomId !== undefined;
    }

    return this.form().locationId !== undefined;
  });

  constructor() {
    const initialEffect = effect(
      () => {
        this.form.set(this.config() ?? {});
        initialEffect.destroy();
      },
      { allowSignalWrites: true, manualCleanup: true },
    );
  }

  protected patchForm(value: Partial<FormValue>) {
    this.form.set({ ...this.form(), ...value });

    this.change.emit(this.isValid() ? this.form() : null);
  }

  protected patchLocationForm(form: Partial<FormValue> | null) {
    this.form.set({});
    this.patchForm(form ?? {});
  }
}
