import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DeviceChart } from '@models';

export enum EnergyConsumptionEntry {
  min,
  average,
  max,
  total,
}

const TOTAL_THRESHOLD = 1000;
const KWH_MEASUREMENT = 'kWh';
const WH_MEASUREMENT = 'Wh';

@Component({
  imports: [],
  selector: 'app-energy-consumption-widget',
  standalone: true,
  styles: [],
  templateUrl: './energy-consumption-widget.component.html',
})
export class EnergyConsumptionWidgetComponent implements OnChanges {
  @Input() chartData: DeviceChart | null = null;
  energyConsumption: {
    measurements: 'kWh' | 'Wh';
    name: string;
    value: number;
  }[] = [
    {
      measurements: 'Wh',
      name: 'Total',
      value: 0,
    },
    {
      measurements: 'Wh',
      name: 'Average',
      value: 0,
    },
    {
      measurements: 'Wh',
      name: 'Max',
      value: 0,
    },
    {
      measurements: 'Wh',
      name: 'Min',
      value: 0,
    },
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartData']) {
      this.calculateEnergyConsumption();
    }
  }

  wattsToKwh(value: number) {
    return this.roundToTwoDecimals(value / (value > TOTAL_THRESHOLD ? TOTAL_THRESHOLD : 1));
  }

  roundToTwoDecimals(value: number): number {
    return Math.round(value * 100) / 100;
  }

  calculateEnergyConsumption() {
    if (!this.chartData) {
      return;
    }

    const stats = this.computeStatistics(this.chartData.timeseries);

    this.energyConsumption[EnergyConsumptionEntry.total].measurements =
      stats.total > TOTAL_THRESHOLD ? KWH_MEASUREMENT : WH_MEASUREMENT;
    this.energyConsumption[EnergyConsumptionEntry.total].value = this.wattsToKwh(stats.total);

    this.energyConsumption[EnergyConsumptionEntry.max].measurements =
      stats.max > TOTAL_THRESHOLD ? KWH_MEASUREMENT : WH_MEASUREMENT;
    this.energyConsumption[EnergyConsumptionEntry.max].value = this.wattsToKwh(stats.max);

    this.energyConsumption[EnergyConsumptionEntry.min].measurements =
      stats.min && stats.min > TOTAL_THRESHOLD ? KWH_MEASUREMENT : WH_MEASUREMENT;
    this.energyConsumption[EnergyConsumptionEntry.min].value = this.wattsToKwh(stats.min || 0);

    this.energyConsumption[EnergyConsumptionEntry.average].measurements =
      stats.average > TOTAL_THRESHOLD ? KWH_MEASUREMENT : WH_MEASUREMENT;
    this.energyConsumption[EnergyConsumptionEntry.average].value = this.wattsToKwh(stats.average || 0);
  }


  private computeStatistics(timeseries: { value: number }[]): {
    average: number;
    max: number;
    min: number | null;
    total: number;
  } {
    let total = 0;
    let max = 0;
    let min: number | null = null;

    timeseries.forEach(entry => {
      const value = Number(entry.value);

      total += value;
      max = Math.max(max, value);
      min = min === null ? value : Math.min(min, value);
    });

    const average = total / timeseries.length;

    return {
      average,
      max,
      min: min !== null ? this.roundToTwoDecimals(min) : null,
      total,
    };
  }

}
