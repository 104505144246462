<mat-form-field class="select-classic mb-[22px]">
  <mat-select
    (valueChange)="change.emit($event)"
    [value]="value()"
    panelClass="single-select-overlay"
    placeholder="Select device"
  >
    @for (device of devices(); track device.id) {
      <mat-option [value]="device.id">
        {{ device.deviceName }}
      </mat-option>
    }
  </mat-select>
  <mat-icon
    class="arrow-down"
    matSuffix
  >keyboard_arrow_down</mat-icon>
  <mat-icon
    class="arrow-up"
    matSuffix
  >keyboard_arrow_up</mat-icon>
</mat-form-field>
