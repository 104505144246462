import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  imports: [CommonModule],
  selector: 'avs-li-power-consumption-table',
  standalone: true,
  styleUrl: './power-consumption-table.component.css',
  templateUrl: './power-consumption-table.component.html',
})
export class PowerConsumptionTableComponent {}
