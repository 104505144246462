import { Component, computed, inject, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { BottomSheetMobileHeaderComponent } from '@standalone/bottom-sheet-mobile-header/bottom-sheet-mobile-header.component';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { MatCheckbox } from '@angular/material/checkbox';
import { PipesModule } from '@pipes';

import { FiltersStore } from '../../stores';

@Component({
  selector: 'avs-li-devices-filters-sheet',
  standalone: true,
  imports: [
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatExpansionPanelHeader,
    MatCheckbox,
    PipesModule,
    BottomSheetMobileHeaderComponent,
  ],
  templateUrl: './devices-filters-sheet.component.html',
})
export class DevicesFiltersSheetComponent {
  readonly filtersStore = inject(FiltersStore);

  hasSelectedFilters = computed(() =>
    this.data.showLocationFilter
      ? this.filtersStore.hasSelectedFilters()
      : this.filtersStore.deviceMaker().length || this.filtersStore.deviceType().length
  );
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<DevicesFiltersSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: { tableId: string; showLocationFilter: boolean }
  ) {}

  close() {
    this._bottomSheetRef.dismiss();
  }
}
