@if (myClient$ | async; as myClient) {
  <div
    class="flex items-center justify-between"
    [ngClass]="{ 'mb-4': showHeader || showBreadcrumbs || form.valid }"
  >
    <div>
      @if (showHeader) {
        <app-page-header> {{ !device ? 'Add' : 'Edit' }} Device</app-page-header>
      }
      @if (showBreadcrumbs) {
        <app-breadcrumbs [routeNames]="[!device ? 'Add device' : 'Edit device']" />
      }
    </div>
    @if (device ? !form.pristine && form.valid : form.valid) {
      <button
        class="test-btn-save btn-green btn-medium"
        (click)="submit()"
        [disabled]="(isDeviceCreating$ | async)|| (isDeviceUpdating$ | async)"
      >
        @if ((isDeviceCreating$ | async) || (isDeviceUpdating$ | async)) {
          <mat-spinner
            class="mr-1"
            diameter="20"
            matSuffix
          />
        }
        Save
      </button>
    }
  </div>
  <mat-card class="card">
    <mat-card-title class="card-title-small pb-4">{{ cardTitle }}</mat-card-title>
    <mat-card-content class="p-0">
      <form
        (ngSubmit)="submit()"
        [formGroup]="form"
      >
        <div class="flex flex-row">
          <label class="input-main-label">Device name:*</label>
          <mat-form-field class="input-classic">
            <input
              type="text"
              formControlName="deviceName"
              matInput
              placeholder="Enter device name"
            />
            <mat-error>Input is not valid</mat-error>
          </mat-form-field>
        </div>

        <div
          class="flex flex-row"
          formGroupName="tags"
        >
          <label class="input-main-label">Description:</label>
          <mat-form-field class="input-classic">
            <input
              type="text"
              formControlName="description"
              matInput
              placeholder="Enter description"
            />
            <mat-error>Input is not valid</mat-error>
          </mat-form-field>
        </div>

        <div class="flex flex-row">
          <label class="input-main-label">Serial №:*</label>
          <mat-form-field class="input-classic">
            <input
              type="text"
              formControlName="physicalDeviceId"
              matInput
              placeholder="Enter serial №"
            />
          </mat-form-field>
        </div>

        <div class="flex flex-row items-center mb-5">
          <label class="input-main-label mt-0">Device type:*</label>
          <div>
            <mat-form-field class="select-classic">
              <mat-select
                (closed)="modelFilterValue = ''"
                appNoSpaceSelect
                formControlName="deviceType"
                panelClass="single-select-overlay"
                placeholder="Select device type"
              >
                <button
                  class="btn-small btn-green mb-4 ml-3"
                  (click)="openAddDeviceTypeDialog()"
                >
                  <mat-icon>add</mat-icon>
                  Add new device type
                </button>
                <div class="mb-1 mx-3">
                  <app-search-input
                    (searchDataEvent)="modelFilterValue=$event"
                    [inputValue]="modelFilterValue"
                    inputWidth="100%"
                    placeholder="Search device type"
                  ></app-search-input>
                </div>
                @for (deviceType of deviceTypeOptions | filterSelectOptions : modelFilterValue | sort : 'asc' : 'title'; track deviceType) {
                  <mat-option
                    class="full-width-option"
                    [value]="deviceType.value"
                  >
                    <div>
                      {{ deviceType.title }}
                    </div>
                  </mat-option>
                }

                <!-- Fake options are used here because if there are no options available, select is not opening. -->
                @for (fakeOption of fakeSelectOptions; track fakeOption) {
                  <mat-option class="hidden">
                    {{ fakeOption.title }}
                  </mat-option>
                }

              </mat-select>
              <mat-icon
                class="arrow-down"
                matSuffix
              >keyboard_arrow_down
              </mat-icon>
              <mat-icon
                class="arrow-up"
                matSuffix
              >keyboard_arrow_up
              </mat-icon>
            </mat-form-field>
          </div>
        </div>

        <div class="flex flex-row items-center mb-5">
          <label class="input-main-label mt-0">Manufacturer:*</label>
          <div>
            <mat-form-field class="select-classic">
              <mat-select
                (closed)="manufacturerFilterValue = ''"
                appNoSpaceSelect
                formControlName="manufacturer"
                panelClass="single-select-overlay"
                placeholder="Select manufacturer"
              >
                <button
                  class="btn-small btn-green mb-4 ml-3"
                  (click)="openAddManufacturerDialog()"
                >
                  <mat-icon>add</mat-icon>
                  Add new manufacturer
                </button>
                <div class="mb-1 mx-3">
                  <app-search-input
                    (searchDataEvent)="manufacturerSearch($event)"
                    [inputValue]="manufacturerFilterValue"
                    inputWidth="100%"
                    placeholder="Search manufacturer"
                  ></app-search-input>
                </div>
                @for (manufacturer of manufacturerOptions
                  | filterSelectOptions : manufacturerFilterValue
                  | sort : 'asc' : 'title'; track manufacturer) {
                  <mat-option
                    class="full-width-option"
                    [value]="manufacturer.value"
                  >
                    <div class="flex justify-between items-center">
                      {{ manufacturer.title }}
                      <i
                        class="icon-button icon-edit text-3xl cursor-pointer text-ui-green-100 mx-2"
                        (click)="$event.stopPropagation()"
                      ></i>
                    </div>
                  </mat-option>
                }
                <!-- Fake options are used here because if no options available, select is not opening. -->
                @for (fakeOption of fakeSelectOptions; track fakeOption) {
                  <mat-option class="hidden">
                    {{ fakeOption.title }}
                  </mat-option>
                }
              </mat-select>
              <mat-icon
                class="arrow-down"
                matSuffix
              >keyboard_arrow_down
              </mat-icon>
              <mat-icon
                class="arrow-up"
                matSuffix
              >keyboard_arrow_up
              </mat-icon>
            </mat-form-field>
          </div>
        </div>

        <div class="flex flex-row items-center mb-5">
          <label class="input-main-label mt-0">Model:*</label>
          <div>
            <mat-form-field class="select-classic">
              <mat-select
                formControlName="model"
                panelClass="single-select-overlay"
                placeholder="Select model"
              >
                <button
                  class="btn-small btn-green mb-4 ml-3"
                  (click)="openAddDeviceModelDialog()"
                >
                  <mat-icon>add</mat-icon>
                  Add new model
                </button>
                @for (model of modelOptions; track model) {
                  <mat-option [value]="model.value">
                    {{ model.title }}
                  </mat-option>
                }

                <!-- Fake options are used here because if no options available, select is not opening. -->
                @for (fakeOption of fakeSelectOptions; track fakeOption) {
                  <mat-option class="hidden">
                    {{ fakeOption.title }}
                  </mat-option>
                }

              </mat-select>
              <mat-icon
                class="arrow-down"
                matSuffix
              >keyboard_arrow_down
              </mat-icon>
              <mat-icon
                class="arrow-up"
                matSuffix
              >keyboard_arrow_up
              </mat-icon>
            </mat-form-field>
          </div>
        </div>

        @if (selectedFullModel) {
          <div class="flex flex-row mb-5">
            <label class="input-main-label">Image:</label>
            <div class="classic-form-item-wrapper">
              @if (selectedFullModel.documentReference) {
                <app-img-loader
                  [imgSrc]="
                    'clientId/' +
                    myClient.id +
                    '/library/standardMake/' +
                    selectedFullModel.make.id +
                    '/standardModel/' +
                    selectedFullModel.id +
                    '/document/' +
                    selectedFullModel.documentReference
                  "
                  altText="Device Model"
                  errorSrc="assets/icons/image-error.svg"
                  loader="skeleton"
                />
              } @else {
                <img
                  [src]="placeholderImg"
                  alt="Device Image"
                />
              }
            </div>
          </div>
        }

        @if (selectedFullModel) {
          <div class="test-property-attribute-controls">
            <div
              class="flex flex-row"
              formGroupName="tags"
            >
              <label class="input-main-label">Firmware:</label>
              <mat-form-field class="input-classic">
                <input
                  type="text"
                  formControlName="firmware"
                  matInput
                  placeholder="Enter firmware"
                />
                <mat-error>Input is not valid</mat-error>
              </mat-form-field>
            </div>
            <div
              class="flex flex-row"
              formGroupName="tags"
            >
              <label class="input-main-label">Ip Address:</label>
              <mat-form-field class="input-classic">
                <input
                  type="text"
                  [dropSpecialCharacters]="false"
                  [validation]="false"
                  formControlName="ipAddress"
                  mask="IP"
                  matInput
                  placeholder="Enter IP address"
                />
                <mat-error>Input is not valid</mat-error>
              </mat-form-field>
            </div>

            <div
              class="flex flex-row"
              formGroupName="tags"
            >
              <label class="input-main-label">Hyperlink:</label>
              <div>
                @if (form.get('tags.ipAddress')?.value; as ipAddress) {
                  <span class="false-form-input">http://{{ ipAddress }}/</span>
                } @else {
                  <span class="false-form-input">http://___.___.___.___/</span>
                }

                <mat-form-field class="input-classic w-[246px]">
                  <input
                    type="text"
                    formControlName="hyperlink"
                    matInput
                    placeholder="Enter hyperlink"
                  />
                  <mat-error>Input is not valid</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div
              class="flex flex-row"
              formGroupName="tags"
            >
              <label class="input-main-label">Mac Address:</label>
              <mat-form-field class="input-classic">
                <input
                  type="text"
                  formControlName="macAddress"
                  matInput
                  placeholder="Enter MAC address"
                />
                <mat-error>Input is not valid</mat-error>
              </mat-form-field>
            </div>
          </div>
        }

        @if (dataPointsFormArray) {

          @if (dataPointsFormArray.controls?.length) {
            <div class="flex flex-row test-realtime-attribute-controls">
              <label class="input-main-label">Data points: </label>
              <ul
                class="classic-form-item-wrapper flex flex-wrap"
                formArrayName="dataPoints"
              >
                @for (attribute of dataPointsFormArray.controls || []; track $index) {
                  <li
                    class="w-1/2 overflow-hidden overflow-ellipsis"
                    [formGroupName]="$index"
                  >
                    <mat-checkbox
                      class="main-checkbox max-w-full"
                      (change)="realtimeAttributeChange(attribute.value, $index)"
                      formControlName="isChecked"
                    >{{ attribute.get('name')?.value }}
                    </mat-checkbox>
                  </li>
                }
              </ul>
            </div>
          }
        }
        <input
          type="submit"
          hidden="hidden"
        />
      </form>
    </mat-card-content>
    <mat-card-title class="card-title-small pb-4 mt-6">Device Location</mat-card-title>
    <mat-card-content class="p-0">
      <form
        (ngSubmit)="submit()"
        [formGroup]="form"
      >
        <div class="flex flex-row items-center mb-5">
          <label class="input-main-label mt-0">Building*</label>
          <div>
            <mat-form-field class="select-classic">
              <mat-select
                formControlName="building"
                panelClass="single-select-overlay"
                placeholder="Select Building"
              >
                @for (building of buildingOptions; track building) {
                  <mat-option [value]="building.value">
                    {{ building.title }}
                  </mat-option>
                }

              </mat-select>
              <mat-icon
                class="arrow-down"
                matSuffix
              >keyboard_arrow_down
              </mat-icon>
              <mat-icon
                class="arrow-up"
                matSuffix
              >keyboard_arrow_up
              </mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-row items-center mb-5">
          <label class="input-main-label mt-0">Floor</label>
          <div class="flex">
            <mat-form-field class="select-classic">
              <mat-select
                formControlName="floor"
                panelClass="single-select-overlay"
                placeholder="Select Floor"
              >
                @for (floor of floorOptions; track floor) {
                  <mat-option [value]="floor.value">
                    {{ floor.title }}
                  </mat-option>
                }

              </mat-select>
              <mat-icon
                class="arrow-down"
                matSuffix
              >keyboard_arrow_down
              </mat-icon>
              <mat-icon
                class="arrow-up"
                matSuffix
              >keyboard_arrow_up
              </mat-icon>
            </mat-form-field>
            @if (isSpaceListLoading) {
              <mat-spinner
                class="ml-2"
                color="primary"
                diameter="40"
              />
            }
          </div>
        </div>
        <div class="flex flex-row items-center">
          <label class="input-main-label mt-0">Room</label>
          <div>
            <mat-form-field class="select-classic">
              <mat-select
                formControlName="room"
                panelClass="single-select-overlay"
                placeholder="Select Room"
              >
                @for (room of roomOptions; track room) {
                  <mat-option [value]="room.value">
                    {{ room.title }}
                  </mat-option>
                }
              </mat-select>
              <mat-icon
                class="arrow-down"
                matSuffix
              >keyboard_arrow_down
              </mat-icon>
              <mat-icon
                class="arrow-up"
                matSuffix
              >keyboard_arrow_up
              </mat-icon>
            </mat-form-field>
          </div>
        </div>
        <input
          type="submit"
          hidden="hidden"
        />
      </form>
    </mat-card-content>
  </mat-card>
}

