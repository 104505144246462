import { Component, DestroyRef, EventEmitter, inject, Output, QueryList, ViewChildren } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { defaultSelectOption } from '@app-lib';
import { incidentStatusMock } from '@mocks';
import { IncidentModel, IncidentStatus, SelectOption, SpaceType } from '@models';
import { Store } from '@ngrx/store';
import { AppState, selectAllIncidents } from '@ngrx-store';
import { PipesModule } from '@pipes';
import { AppService, IncidentsService } from '@services';
import { MultipleSelectComponent } from '@standalone/multiple-select/multiple-select.component';

type LocationType = {
  building: string;
  floor: string;
  floorOptions: SelectOption[];
  roomOptions: SelectOption[];
  rooms: string[];
};

@Component({
  imports: [MultipleSelectComponent, MatIconModule, MatSelectModule, MatProgressSpinnerModule, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, ReactiveFormsModule, PipesModule],
  selector: 'avs-fe-report-filters',
  standalone: true,
  templateUrl: './report-filters.component.html',
})
export class ReportFiltersComponent {
  @ViewChildren('roomSelectRef') roomSelects!: QueryList<MultipleSelectComponent>;
  @Output() exportIncidents = new EventEmitter<IncidentModel[] | null>();
  public commonIncidents: IncidentModel[] | null = null;
  public resolvedDateRange = new FormGroup({
    end: new FormControl<Date | null>(null),
    start: new FormControl<Date | null>(null),
  });
  public locations: LocationType[] = [];
  public deviceTypeValue: string[] = [];
  public incidentStatusValue: string[] = [];
  public assignedUserValue: string[] = [];
  public manufacturerValue: string[] = [];
  public modelValue: string[] = [];
  public firmwareValue: string[] = [];

  protected resolvedIncidentsLoading = false;
  protected allBuildingOptions: SelectOption[] = [];
  protected defaultBuildingOptions: SelectOption[] = [];
  protected floorOptions: SelectOption[] = [];
  protected roomOptions: SelectOption[] = [];
  protected deviceTypeOptions: SelectOption[] = [];
  protected incidentStatusOptions = [...incidentStatusMock, { title: 'Resolved', value: IncidentStatus.RESOLVED }];

  private filteredIncidents: IncidentModel[] | null = null;
  private openIncidents: IncidentModel[] = [];
  private destroyRef = inject(DestroyRef);

  constructor(
    private store: Store<AppState>,
    private appService: AppService,
    private incidentsService: IncidentsService,
  ) {
    this.locations.push(this.createLocation());

    this.store
      .select(selectAllIncidents)
      .pipe(takeUntilDestroyed())
      .subscribe(openIncidents => {
        this.openIncidents = openIncidents || [];
      });
  }

  filterOpenIncidentsByDate() {
    if (
      this.openIncidents.length &&
      this.resolvedDateRange.valid &&
      this.resolvedDateRange.value.start &&
      this.resolvedDateRange.value.end
    ) {
      const { startDateTime, endDateTime } = this.getDateRangeInISOFormat(
        this.resolvedDateRange.value.start,
        this.resolvedDateRange.value.end,
      );
      const startDate = new Date(startDateTime).getTime();
      const endDate = new Date(endDateTime).getTime();

      return this.openIncidents.filter(incident => {
        const incidentDate = new Date(incident.resolvedTimestamp || incident.createdTimestamp).getTime();

        return incidentDate >= startDate && incidentDate <= endDate;
      });
    }

    return [];
  }

  multiSelectChange(
    name:
      | 'deviceTypeValue'
      | 'incidentStatusValue'
      | 'assignedUserValue'
      | 'manufacturerValue'
      | 'modelValue'
      | 'firmwareValue',
    value: string[],
  ) {
    this[name] = value;
    this.filterCommonIncidents();
  }

  createLocation(): LocationType {
    return {
      building: '',
      floor: '',
      floorOptions: [],
      roomOptions: [],
      rooms: [],
    };
  }

  addLocation() {
    this.locations.push(this.createLocation());
  }

  deleteLocation(i: number) {
    this.locations = this.locations.filter((_, index) => i !== index);
    this.filterCommonIncidents();
  }

  generateReport() {
    this.resolvedIncidentsRequest();
  }

  buildingChange({ value }: MatSelectChange, index: number) {
    const location = this.locations[index];

    location.building = value;
    if (!value) {
      this.locations = [this.createLocation()];
    } else {
      const floorOptions: SelectOption[] = [];

      this.commonIncidents?.forEach(incident => {
        if (incident.device.location.id === value) {
          const floor = incident.device.spacePath?.find(space => space.type === SpaceType.floor);

          if (floor && !floorOptions.some(floorOption => floorOption.value === floor.id)) {
            floorOptions.push({ title: floor.friendlyName || floor.name, value: floor.id });
          }
        }
      });

      location.floorOptions = [defaultSelectOption, ...floorOptions];
      location.floor = '';
      location.rooms = [];
    }

    this.filterCommonIncidents();
  }

  floorChange({ value }: MatSelectChange, index: number) {
    const location = this.locations[index];

    location.floor = value;
    const roomOptions: SelectOption[] = [];

    this.commonIncidents?.forEach(incident => {
      if (incident.device.spacePath?.find(space => space.id === value)) {
        const room = incident.device.spacePath?.find(space => space.type === SpaceType.room);

        if (room && !roomOptions.some(roomOption => roomOption.value === room.id)) {
          roomOptions.push({ title: room.friendlyName || room.name, value: room.id });
        }
      }
    });

    location.roomOptions = roomOptions;
    location.rooms = [];

    this.filterCommonIncidents();
  }

  roomChange(rooms: string[], index: number) {
    const location = this.locations[index];

    location.rooms = rooms;
    this.filterCommonIncidents();
  }

  reset(resetDate = true) {
    if (resetDate) {
      this.resolvedDateRange.reset();
    }
    this.locations = [this.createLocation()];
    this.deviceTypeValue = [];
    this.incidentStatusValue = [];
    this.assignedUserValue = [];
    this.manufacturerValue = [];
    this.modelValue = [];
    this.firmwareValue = [];
    this.commonIncidents = null;
    this.filteredIncidents = null;
    this.exportIncidents.emit(this.filteredIncidents);
  }

  getDateRangeInISOFormat(start: Date, end: Date) {
    const startDate = new Date(start);
    const endDate = new Date(end);
    // datepicker returns start of the day, so we need to add one day to include whole day

    endDate.setDate(endDate.getDate() + 1);

    return {
      endDateTime: endDate.toISOString(),
      startDateTime: startDate.toISOString(),
    };
  }

  resolvedIncidentsRequest() {
    if (this.resolvedDateRange.valid && this.resolvedDateRange.value.start && this.resolvedDateRange.value.end) {
      this.reset(false);
      this.getResolvedIncidents(
        this.getDateRangeInISOFormat(this.resolvedDateRange.value.start, this.resolvedDateRange.value.end),
      );
    }
  }

  getResolvedIncidents(dateRange: { endDateTime: string; startDateTime: string; }) {
    this.resolvedIncidentsLoading = true;
    this.incidentsService
      .getAllResolvedIncidents(this.appService.currentClient, { ...dateRange })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        error: () => {
          this.resolvedIncidentsLoading = false;
        },
        next: data => {
          this.resolvedIncidentsLoading = false;
          this.commonIncidents = [...this.filterOpenIncidentsByDate(), ...data];
          this.filteredIncidents = this.commonIncidents;
          this.collectIncidentFilterData();
          this.exportIncidents.emit(this.filteredIncidents);
        },
      });
  }

  collectIncidentFilterData() {
    if (this.commonIncidents) {
      const buildings: SelectOption[] = [];
      const deviceTypes = new Set<string>();

      this.commonIncidents.forEach(incident => {
        if (!buildings.some(building => building.value === incident.device.location.id)) {
          buildings.push({
            title: incident.device.location.friendlyName || incident.device.location.name,
            value: incident.device.location.id,
          });
        }

        if (incident.device.deviceModelInformation?.deviceType) {
          deviceTypes.add(incident.device.deviceModelInformation?.deviceType);
        }
      });

      this.allBuildingOptions = [defaultSelectOption, ...buildings];
      this.defaultBuildingOptions = buildings;
      this.deviceTypeOptions = [...deviceTypes].map(deviceType => ({ title: deviceType, value: deviceType }));
    }
  }

  filterCommonIncidents() {
    if (this.commonIncidents) {
      this.filteredIncidents =
        this.commonIncidents.length > 0
          ? this.commonIncidents?.filter(incident => {
            const statusMatch =
              this.incidentStatusValue.length > 0
                ? this.incidentStatusValue.some(
                  incidentStatusFilterValue => incident.status === incidentStatusFilterValue,
                )
                : true;

            const deviceTypeMatch =
              this.deviceTypeValue.length > 0
                ? this.deviceTypeValue.some(
                  deviceTypeFilterValue =>
                    incident.device.deviceModelInformation?.deviceType === deviceTypeFilterValue,
                )
                : true;

            const buildingMatch = this.locations.some(locationFilter => {
              if (!locationFilter.building) {
                return true;
              }

              if (locationFilter.rooms.length) {
                return locationFilter.rooms.some(roomId =>
                  incident.device.spacePath?.find(space => space.id === roomId),
                );
              }

              if (locationFilter.floor) {
                return incident.device.spacePath?.some(space => space.id === locationFilter.floor);
              }

              return locationFilter.building === incident.device.location.id;
            });

            return statusMatch && deviceTypeMatch && buildingMatch;
          })
          : [];
    }
    this.exportIncidents.emit(this.filteredIncidents);
  }
}
