import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, getLocationState, getLocationStateSuccess, locationState } from '@ngrx-store';
import { tap } from 'rxjs';

export function locationStateResolverFactory(excludeArchived: boolean): ResolveFn<any> {
  return (route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
    const locId = route.paramMap.get('locationId');
    const store = inject(Store<AppState>);
    if (!locId) return;
    return store.select(locationState).pipe(
      tap(location => {
        if (!location || location.id !== locId) {
          store.dispatch(getLocationState({ locationId: locId, excludeArchived }));
        } else if (location) {
          store.dispatch(getLocationStateSuccess({ locationState: location }));
        }
      })
    );
  };
}

export const locationStateResolver = locationStateResolverFactory(false);
export const mobileLocationStateResolver = locationStateResolverFactory(true);
