<!--<div class="test-header flex flex-row items-center">-->
<!--  <div class="flex-grow">-->
<!--    <h1 class="mb-0">Add new user</h1>-->
<!--    <xng-breadcrumb separator=">"></xng-breadcrumb>-->
<!--  </div>-->
<!--  <div class="flex flex-grow flex-row-reverse">-->
<!--    <button *ngIf="stepper.selectedIndex === 0" (click)="goToStepTwo(stepper)" class="btn-green btn-medium">-->
<!--      <span>Next</span>-->
<!--      <mat-icon class="text-2xl">chevron_right</mat-icon>-->
<!--    </button>-->
<!--    <button *ngIf="stepper.selectedIndex > 0" (click)="createUser()" class="btn-green btn-medium">-->
<!--      <span>Confirm</span>-->
<!--    </button>-->
<!--    <button-->
<!--      *ngIf="stepper.selectedIndex > 0"-->
<!--      (click)="backToPrevStep(stepper)"-->
<!--      class="btn-green-stroked btn-medium mr-3">-->
<!--      <mat-icon>chevron_left</mat-icon>-->
<!--      <span>Previous</span>-->
<!--    </button>-->
<!--  </div>-->
<!--</div>-->
<!--<mat-card class="card pt-1 relative">-->
<!--  <app-search-input-->
<!--    *ngIf="stepper.selectedIndex == 1"-->
<!--    class="icon-input py-2 absolute pr-6 right-0"-->
<!--    placeholder="Enter Building"-->
<!--    (searchDataEvent)="buildingSearchChanged($event)" />-->
<!--  <mat-stepper class="app-stepper" headerPosition="top" [linear]="true" #stepper>-->
<!--    <mat-step completed="false">-->
<!--      <ng-template matStepLabel>-->
<!--        <p><strong>Step 1</strong></p>-->
<!--        <p>User info</p>-->
<!--      </ng-template>-->
<!--      <app-user-data-form></app-user-data-form>-->
<!--    </mat-step>-->
<!--    <mat-step completed="false">-->
<!--      <ng-template matStepLabel>-->
<!--        <p><strong>Step 2</strong></p>-->
<!--        <p>Alert settings</p>-->
<!--      </ng-template>-->
<!--      <app-alert-settings [buildingFilterValue]="buildingSearchValue"></app-alert-settings>-->
<!--    </mat-step>-->
<!--  </mat-stepper>-->
<!--</mat-card>-->
<avs-fe-user-data-form />
