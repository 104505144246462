@if (locations()) {
  <google-map
    (mapInitialized)="mapInitialized($event)"
    [options]="mapOptions"
    height="100%"
    width="100%"
  >
    @for (markerData of filteredMarkers; track markerData.id) {
      <map-advanced-marker
        (mapClick)="openInfo(advancedMarker, markerData, infoWindow)"
        [content]="markerData | dashboardMapMarkerIcon"
        [gmpDraggable]="false"
        [options]="markerData.options"
        [position]="markerData.position"
        #advancedMarker="mapAdvancedMarker"
      />
    }

    <div #infoWindow>
      @if (selectedSite) {
        <i
          class="icon-close absolute top-0 right-0 text-base text-ui-gray-100 p-1 cursor-pointer"
          (click)="hideInfo()"
        ></i>
        <app-site-map-marker [site]="selectedSite" />
      }
    </div>
    <map-info-window />
  </google-map>
}
