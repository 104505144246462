import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, Signal, signal, untracked } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { removeSpacesValidator } from '@app-lib';
import { DirectivesModule } from '@directives';
import { AppService, NotificationsService } from '@services';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';
import cloneDeep from 'lodash.clonedeep';
import { filter, Observable, switchMap } from 'rxjs';

import { MobileDashboardsSelectorComponent } from '../../components';
import { Dashboard, LayoutMode, Widget } from '../../models';
import { WidgetConfig } from '../../models/widget';
import { DashboardService } from '../../services';
import { LayoutComponent } from '../layout';
import { LayoutHelper } from '../layout/config';
import { WidgetDialogComponent } from '../widget-dialog';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    LayoutComponent,
    MatButtonModule,
    MatCard,
    MatIcon,
    MatInputModule,
    ReactiveFormsModule,
    RouterLink,
    MobileDashboardsSelectorComponent,
    DirectivesModule,
  ],
  selector: 'avs-li-dashboard-page',
  standalone: true,
  styleUrl: './dashboard-page.component.scss',
  templateUrl: './dashboard-page.component.html',
})
export class DashboardPageComponent {
  protected cloneId: Signal<string>;
  protected id: Signal<string>;
  protected mode: Signal<LayoutMode>;
  protected dashboard = signal<Dashboard | null>(null);
  protected nameControl = new FormControl('', [removeSpacesValidator]);
  protected widgets = signal<Widget[]>([]);
  protected appService = inject(AppService);

  private dialog = inject(MatDialog);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private notifications = inject(NotificationsService);
  private service = inject(DashboardService);

  constructor() {
    const queryParams = toSignal(this.route.queryParams);
    const params = toSignal(this.route.params);

    this.id = computed(() => params()!['dashboardId']);
    this.cloneId = computed(() => queryParams()!['cloneId']);
    this.mode = computed(() => queryParams()!['mode'] ?? 'view');

    effect(() => {
      const id = this.id();
      const cloneId = this.cloneId();

      this.mode();

      if (!id) {
        return;
      }

      if (id === 'new' && !cloneId) {
        const newDashboard = {
          clientId: this.appService.currentClient,
          config: {
            widgets: [],
          },
          id,
          name: '',
        } as Dashboard;

        untracked(() => {
          this.dashboard.set(newDashboard);
          this.nameControl.patchValue(newDashboard.name);
          this.widgets.set([]);
        });

        return;
      }

      untracked(() => {
        this.service.getDashboardById(cloneId ?? id)
          .subscribe(dashboard => {
            this.dashboard.set({ ...dashboard, id });
            this.widgets.set(cloneDeep(dashboard.config.widgets));
            this.nameControl.patchValue(cloneId ? `${dashboard.name} (cloned)` : dashboard.name);
          });
      });
    });
  }

  protected addWidget() {
    this.dialog.open(WidgetDialogComponent, {
      panelClass: 'app-dialog',
    })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((config: WidgetConfig) => {
        this.widgets.set([
          ...this.widgets(),
          LayoutHelper.createWidget(config),
        ]);
      });
  }

  protected async cancelEditing() {
    this.widgets.set(cloneDeep(this.dashboard()!.config.widgets));

    await this.router.navigate(this.cloneId() ? ['dashboards', this.cloneId()] : [], {
      queryParams: {
        cloneId: undefined,
        mode: undefined,
      },
      queryParamsHandling: 'merge',
    });
  }

  protected delete() {
    const dashboard = this.dashboard()!;

    ConfirmationDialogComponent.open(this.dialog, {
      description: `Are you sure you want to delete [${dashboard.name}] dashboard?`,
      title: 'Delete dashboard',
    })
      .pipe(
        filter(Boolean),
        switchMap(() => this.service.deleteDashboard(dashboard.id)),
      )
      .subscribe(async () => {
        await this.router.navigateByUrl('/dashboard');
        this.notifications.showSuccessMessage('Dashboard has been removed');
        this.service.changes$.next();
      });
  }

  protected deleteWidget(widget: Widget) {
    ConfirmationDialogComponent.open(this.dialog, {
      description: `Are you sure you want to delete [${widget.config.name}] widget?`,
      title: 'Delete widget',
    })
      .pipe(filter(Boolean))
      .subscribe(() => {
        const widgets = this.widgets()
          .filter(w => w.id !== widget.id);

        this.widgets.set(widgets);
      });
  }

  protected editWidget(widget: Widget) {
    this.dialog.open(WidgetDialogComponent, {
      data: widget.config,
      panelClass: 'app-dialog',
    })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((config: WidgetConfig) => {
        const widgets = [...this.widgets()];

        widgets.splice(widgets.indexOf(widget), 1, {
          ...widget,
          config,
        });

        this.widgets.set(widgets);
      });
  }

  protected layoutChanged(widgets: Widget[]) {
    this.widgets.set(widgets);
  }

  protected saveDashboard() {
    const dashboard = this.dashboard()!;
    const update = {
      config: {
        widgets: this.widgets(),
      },
      name: this.nameControl.value,
    } as Dashboard;

    const obs$ = dashboard.id === 'new'
      ? this.service.createDashboard(update)
      : this.service.updateDashboard(dashboard.id, update);

    (obs$ as Observable<Dashboard | null>).subscribe(async (newDashboard) => {
      this.dashboard.set(newDashboard ?? { ...dashboard, ...update });
      await this.router.navigate(newDashboard ? ['dashboards', newDashboard.id] : [], {
        queryParams: {
          cloneId: undefined,
          mode: undefined,
        },
        queryParamsHandling: 'merge',
      });
      this.notifications.showSuccessMessage('Dashboard has been saved');
      this.service.changes$.next();
    });
  }
}
