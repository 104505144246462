import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CURRENT_CLIENT_TEMPLATE_VAR } from '@models';
import { map, Subject } from 'rxjs';

import { Dashboard } from '../models';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  changes$ = new Subject<void>();

  private http = inject(HttpClient);
  private apiUrl = `client/${CURRENT_CLIENT_TEMPLATE_VAR}/dashboards`;

  createDashboard(dashboardData: Partial<Dashboard>) {
    return this.http.post<Dashboard>(this.apiUrl, dashboardData);
  }

  getDashboards() {
    return this.http.get<{ data: Dashboard[]; }>(this.apiUrl)
      .pipe(map(({ data }) => data));
  }

  getDashboardById(dashboardId: string) {
    return this.http.get<Dashboard>(`${this.apiUrl}/${dashboardId}`);
  }

  updateDashboard(dashboardId: string, dashboardData: Partial<Dashboard>) {
    return this.http.put<void>(`${this.apiUrl}/${dashboardId}`, dashboardData)
      .pipe(map(() => null));
  }

  deleteDashboard(dashboardId: string) {
    return this.http.delete<Dashboard>(`${this.apiUrl}/${dashboardId}`);
  }
}
