import { Component, inject, signal, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { DevicesFiltersV2Component, DevicesTableV2Component } from '@devices';
import { deviceColumnOptions } from '@mocks';
import { DeviceTableCols } from '@models';
import { SessionStorageService } from '@services';
import { ExportReportMenuComponent } from '@standalone/export-report-menu/export-report-menu.component';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';

@Component({
  imports: [PageHeaderComponent, DevicesTableV2Component, DevicesFiltersV2Component, MatCardModule, MatIconModule, ExportReportMenuComponent, RouterModule],
  standalone: true,
  styleUrl: './devices-main-page.component.scss',
  templateUrl: './devices-main-page.component.html',
})
export class DevicesMainPageComponent {
  protected columnOptions = deviceColumnOptions;
  protected displayedColumns = [
    DeviceTableCols.INDEX,
    DeviceTableCols.DEVICE_NAME,
    ...deviceColumnOptions.map(column => column.value),
    DeviceTableCols.ACTIONS,
  ];

  @ViewChild(DevicesTableV2Component)
  protected deviceTable!: DevicesTableV2Component;
  protected filters = signal<Record<string, string | string[]> | null>(null);

  protected readonly TABLE_ID = 'device-main-table';
  private sessionStorageService = inject(SessionStorageService);

  constructor() {
    const columns = this.sessionStorageService.getColumns(this.TABLE_ID);

    if (columns) {
      this.displayedColumns = [...new Set(columns)];
    }
  }

  protected columnsChanged(columns: string[]) {
    this.sessionStorageService.saveColumnsSettingsToSessionStorage(this.TABLE_ID, columns);

    this.displayedColumns = [...new Set([
      DeviceTableCols.INDEX,
      DeviceTableCols.DEVICE_NAME,
      ...columns,
      DeviceTableCols.ACTIONS,
    ] as DeviceTableCols[])];
  }

  protected exportDevices(format: 'json' | 'csv') {
    return this.deviceTable.export(format);
  }
}
