<div
  *ngIf="filtersEnabled"
  class="border-b-ui-gray-light border-b flex items-center justify-between"
>
  <mat-button-toggle-group
    (change)="incidentsFilterService.incidentTabStatusFilter = $event.value"
    [value]="incidentsFilterService.incidentTabStatusFilter"
    aria-label="Incident Status"
    class="desktop-toggle-group"
    name="incident-status"
  >
    <mat-button-toggle
      [value]="incidentTabStatusFilterEnum.OPEN"
      class="test-incident-status-open"
    >
      <span class="item-status-title">Open</span>
      <span
        *ngIf="incidentsFilterService.newIncidentsAmount"
        class="incidents-amount-indicator mr-1"
      >{{
          incidentsFilterService.newIncidentsAmount
        }}</span>
      <span
        *ngIf="incidentsFilterService.inProgressIncidentsAmount"
        class="incidents-amount-indicator bg-ui-yellow-100 text-ui-yellow-350"
      >{{ incidentsFilterService.inProgressIncidentsAmount }}</span
      >
    </mat-button-toggle>
    <mat-button-toggle
      [value]="incidentTabStatusFilterEnum.HISTORY"
      class="test-incident-status-history"
    >
      <span class="item-status-title">History</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <div
    *ngIf="filtersEnabled && incidentsFilterService.incidentsData.length"
    class="flex justify-center"
  >
    <app-search-input
      (searchDataEvent)="incidentsFilterService.ticketNumberSearch($event)"
      placeholder="Enter Ticket №"
    />
  </div>
</div>

<div
  *ngIf="
    incidentsFilterService.incidentTabStatusFilter === incidentTabStatusFilterEnum.OPEN &&
    incidentsFilterService.incidentsData.length &&
    filtersEnabled
  "
  class="test-open-incidents-filters flex items-start justify-between mt-3"
>
  <div class="flex flex-wrap">
    <app-multiple-select
      (selectDataEvent)="incidentsFilterService.multiSelectChange('deviceTypeValue', $event)"
      [options]="incidentsFilterService.deviceTypeOptions"
      [value]="incidentsFilterService.deviceTypeValue"
      class="mr-2"
      label="Device Type"
      panelClass="device-type-overlay"
      selectClass="stroked-select-multiple mb-3"
    ></app-multiple-select>

    <app-multiple-select
      (selectDataEvent)="incidentsFilterService.multiSelectChange('statusFilter', $event)"
      [options]="incidentsFilterService.statusOptions"
      [value]="incidentsFilterService.statusFilter"
      class="mr-2"
      label="Status"
      panelClass="status-overlay"
      selectClass="stroked-select-multiple mb-3"
    ></app-multiple-select>

    <mat-form-field
      *ngIf="!incidentsFilterService.isBuildingTable"
      class="stroked-select-single mr-2 mb-3"
    >
      <mat-label>Building</mat-label>
      <mat-select
        [(ngModel)]="incidentsFilterService.selectedBuilding"
        panelClass="single-select-overlay"
      >
        <mat-option
          *ngFor="let building of incidentsFilterService.buildingOptions | sort : 'asc' : 'title' : 'all'"
          [value]="building.value"
        >
          {{ building.title }}
        </mat-option>
      </mat-select>
      <mat-icon
        class="arrow-down"
        matSuffix
      >keyboard_arrow_down
      </mat-icon>
      <mat-icon
        class="arrow-up"
        matSuffix
      >keyboard_arrow_up
      </mat-icon>
    </mat-form-field>

    <mat-form-field
      *ngIf="incidentsFilterService.selectedBuilding && !incidentsFilterService.isFloorTable"
      class="stroked-select-single mr-2 mb-3"
    >
      <mat-label>Floor</mat-label>
      <mat-select
        [(ngModel)]="incidentsFilterService.selectedFloor"
        panelClass="single-select-overlay"
      >
        <mat-option
          *ngFor="let floor of incidentsFilterService.floorOptions | sort : 'asc' : 'title' : 'all'"
          [value]="floor.value"
        >
          {{ floor.title }}
        </mat-option>
      </mat-select>
      <mat-icon
        class="arrow-down"
        matSuffix
      >keyboard_arrow_down
      </mat-icon>
      <mat-icon
        class="arrow-up"
        matSuffix
      >keyboard_arrow_up
      </mat-icon>
    </mat-form-field>

    <mat-form-field
      *ngIf="incidentsFilterService.selectedFloor && !incidentsFilterService.isRoomTable"
      class="stroked-select-single mr-2 mb-3"
    >
      <mat-label>Room</mat-label>
      <mat-select
        [(ngModel)]="incidentsFilterService.selectedRoom"
        panelClass="single-select-overlay"
      >
        <mat-option
          *ngFor="let room of incidentsFilterService.roomOptions | sort : 'asc' : 'title' : 'all'"
          [value]="room.value"
        >
          {{ room.title }}
        </mat-option>
      </mat-select>
      <mat-icon
        class="arrow-down"
        matSuffix
      >keyboard_arrow_down
      </mat-icon>
      <mat-icon
        class="arrow-up"
        matSuffix
      >keyboard_arrow_up
      </mat-icon>
    </mat-form-field>

    <button
      (click)="incidentsFilterService.clearOpenIncidentsFilters()"
      *ngIf="incidentsFilterService.selectedFilters"
      class="font-nunito text-sm text-ui-dark font-normal underline h-9 ml-3 mb-3 test-clear-all-open-incidents-filters"
    >
      Clear All
    </button>
  </div>

  <ng-container *ngTemplateOutlet="columnSelect"></ng-container>
</div>
<div
  *ngIf="incidentsFilterService.incidentTabStatusFilter === incidentTabStatusFilterEnum.HISTORY"
  class="test-resolved-incidents-filters flex items-start justify-between mt-3"
>
  <div class="flex flex-wrap">
    <mat-form-field
      (click)="resolvedPicker.open()"
      class="date-range-picker no-validation-message mr-2 mb-3"
    >
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input
        [formGroup]="incidentsFilterService.resolvedDateRange"
        [rangePicker]="resolvedPicker"
      >
        <input
          [readonly]="true"
          formControlName="start"
          matStartDate
          placeholder="mm/dd/yyyy"
        />
        <input
          [readonly]="true"
          formControlName="end"
          matEndDate
          placeholder="mm/dd/yyyy"
        />
      </mat-date-range-input>
      <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
      <mat-datepicker-toggle
        [for]="resolvedPicker"
        matIconSuffix
      />
      <mat-date-range-picker
        #resolvedPicker
        (closed)="incidentsFilterService.calendarClose()"
      />
    </mat-form-field>
    <mat-spinner
      *ngIf="resolvedIncidentsLoading"
      class="mr-2"
      color="primary"
      diameter="36"
      matSuffix
    />

    <span
      *ngIf="dropdownFilters && incidentsFilterService.resolvedIncidentsData.length"
      class="mr-2 h-9 border-l border-ui-gray-light"
    ></span>

    <app-multiple-select
      (selectDataEvent)="incidentsFilterService.multiSelectChange('resolvedIncidentsDeviceTypeValue', $event)"
      *ngIf="dropdownFilters && incidentsFilterService.resolvedIncidentsData.length"
      [options]="incidentsFilterService.resolvedIncidentsDeviceTypeOptions"
      [value]="incidentsFilterService.resolvedIncidentsDeviceTypeValue"
      class="mr-2"
      label="Device Type"
      panelClass="device-type-overlay"
      selectClass="stroked-select-multiple mb-3"
    />

    <mat-form-field
      *ngIf="
        dropdownFilters &&
        !incidentsFilterService.isBuildingTable &&
        incidentsFilterService.resolvedIncidentsData.length
      "
      class="stroked-select-single mr-2 mb-3"
    >
      <mat-label>Building</mat-label>
      <mat-select
        [(ngModel)]="incidentsFilterService.resolvedIncidentsSelectedBuilding"
        panelClass="single-select-overlay"
      >
        <mat-option
          *ngFor="
            let building of incidentsFilterService.resolvedIncidentsBuildingOptions | sort : 'asc' : 'title' : 'all'
          "
          [value]="building.value"
        >
          {{ building.title }}
        </mat-option>
      </mat-select>
      <mat-icon
        class="arrow-down"
        matSuffix
      >keyboard_arrow_down
      </mat-icon>
      <mat-icon
        class="arrow-up"
        matSuffix
      >keyboard_arrow_up
      </mat-icon>
    </mat-form-field>

    <mat-form-field
      *ngIf="incidentsFilterService.resolvedIncidentsSelectedBuilding && !incidentsFilterService.isFloorTable"
      class="stroked-select-single mr-2 mb-3"
    >
      <mat-label>Floor</mat-label>
      <mat-select
        [(ngModel)]="incidentsFilterService.resolvedIncidentsSelectedFloor"
        panelClass="single-select-overlay"
      >
        <mat-option
          *ngFor="let floor of incidentsFilterService.resolvedIncidentsFloorOptions | sort : 'asc' : 'title' : 'all'"
          [value]="floor.value"
        >
          {{ floor.title }}
        </mat-option>
      </mat-select>
      <mat-icon
        class="arrow-down"
        matSuffix
      >keyboard_arrow_down
      </mat-icon>
      <mat-icon
        class="arrow-up"
        matSuffix
      >keyboard_arrow_up
      </mat-icon>
    </mat-form-field>

    <mat-form-field
      *ngIf="incidentsFilterService.resolvedIncidentsSelectedFloor && !incidentsFilterService.isRoomTable"
      class="stroked-select-single mr-2 mb-3"
    >
      <mat-label>Room</mat-label>
      <mat-select
        [(ngModel)]="incidentsFilterService.resolvedIncidentsSelectedRoom"
        panelClass="single-select-overlay"
      >
        <mat-option
          *ngFor="let room of incidentsFilterService.resolvedIncidentsRoomOptions | sort : 'asc' : 'title' : 'all'"
          [value]="room.value"
        >
          {{ room.title }}
        </mat-option>
      </mat-select>
      <mat-icon
        class="arrow-down"
        matSuffix
      >keyboard_arrow_down
      </mat-icon>
      <mat-icon
        class="arrow-up"
        matSuffix
      >keyboard_arrow_up
      </mat-icon>
    </mat-form-field>

    <button
      (click)="incidentsFilterService.clearResolvedIncidentsFilters()"
      *ngIf="incidentsFilterService.resolvedIncidentsSelectedFilters"
      class="font-nunito text-sm text-ui-dark font-normal underline h-9 ml-3 mb-3 test-clear-all-resolved-incidents-filters"
      mat-button
    >
      Clear All
    </button>
  </div>

  <ng-container *ngTemplateOutlet="columnSelect"></ng-container>
</div>

<ng-template #columnSelect>
  <mat-form-field class="column-select">
    <mat-label matTooltip="Column Select"><i class="icon-ic_column text-3xl"></i></mat-label>
    <mat-select
      #columnSelectRef
      (selectionChange)="columnListUpdated($event)"
      [formControl]="incidentsFilterService.columnSelect"
      multiple
      panelClass="multi-select-overlay column-select-overlay"
    >
      <div class="options-action-wrapper">
        <button
          (click)="selectAllColumns()"
          class="options-action-button"
        >Select All
        </button>
      </div>
      <mat-option
        *ngFor="let option of incidentsFilterService.columnOptions"
        [value]="option.value"
      >{{
          option.title
        }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>
