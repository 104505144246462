<div>
  <a
    class="inline-flex items-center font-nunito text-ui-dark text-sm font-semibold hover:underline"
    [routerLink]="['/devices']"
  >
    <mat-icon>chevron_left</mat-icon>
    Back to Devices
  </a>
  <app-page-header>Device Management</app-page-header>

  <mat-button-toggle-group
    class="main-toggle-group my-4"
    [(ngModel)]="tab"
  >
    <mat-button-toggle [value]="deviceTabs.MANUFACTURER"> Manufacturer </mat-button-toggle>
    <mat-button-toggle [value]="deviceTabs.DATA_POINTS"> Data Points </mat-button-toggle>
    <mat-button-toggle [value]="deviceTabs.MODEL"> Model </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<app-manufacturer-list [hidden]="tab !== deviceTabs.MANUFACTURER" />

<app-data-points [hidden]="tab !== deviceTabs.DATA_POINTS" />

<avs-fe-model-list
  class="overflow-visible"
  [hidden]="tab !== deviceTabs.MODEL"
/>
