import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  DestroyRef,
  effect,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { defaultDocumentTab, generateDocumentType } from '@app-lib';
import { DirectivesModule } from '@directives';
import { DeviceData, ImageDocument, RoomSpaceModel, UserRolesIds } from '@models';
import { Content } from '@ngneat/overview';
import { Store } from '@ngrx/store';
import {
  AppState,
  areRoomDocumentsLoading,
  getDeviceBySpaceId,
  getRoomDocuments,
  getRoomImages,
  isRoomplanLoading,
  userRole,
} from '@ngrx-store';
import { AppService } from '@services';
import { NoDataComponent } from '@standalone/no-data/no-data.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Observable } from 'rxjs';

import { SpaceplanComponent } from '../spaceplan/spaceplan.component';
import { UploadSpaceplanComponent } from '../upload-spaceplan/upload-spaceplan.component';

@Component({
  imports: [
    CommonModule,
    SpaceplanComponent,
    NgxSkeletonLoaderModule,
    MatButtonToggleModule,
    UploadSpaceplanComponent,
    MatIconModule,
    NoDataComponent,
    MatProgressSpinner,
    DirectivesModule,
  ],
  selector: 'app-spaceplan-tabs',
  standalone: true,
  templateUrl: './spaceplan-tabs.component.html',
})
export class SpaceplanTabsComponent implements OnChanges {
  @Input() locationId = '';
  @Input() room: RoomSpaceModel | undefined;
  @Input() popover: Content | null = null;
  @Input() canEdit = false;
  @Output() tabSelect = new EventEmitter<string>();

  userRoleIds = UserRolesIds;
  currentUserRoleId = '';
  areRoomDocumentsLoading$: Observable<boolean>;
  isRoomplanLoading$: Observable<boolean>;
  deviceList$: Observable<DeviceData[]> | undefined;
  defaultTab = defaultDocumentTab;
  selectedDocumentTab = signal('');
  selectedDocumentTabValue = computed(() => this.selectedDocumentTab());
  documentTabs: string[] = [];
  documents: ImageDocument[] = [];

  constructor(private store: Store<AppState>, private appService: AppService, private destroyRef: DestroyRef) {
    this.store
      .select(userRole)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(roleId => (this.currentUserRoleId = roleId));

    this.areRoomDocumentsLoading$ = this.store.select(areRoomDocumentsLoading);
    this.isRoomplanLoading$ = this.store.select(isRoomplanLoading);

    effect(() => {
      const currentValue = this.selectedDocumentTab();

      this.tabSelect.emit(currentValue);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.room && this.room.parentSpaceId && changes['room'].currentValue.id !== changes['room'].previousValue?.id) {
      this.store.dispatch(
        getRoomDocuments({
          locationId: this.locationId,
          floorId: this.room.parentSpaceId,
          roomId: this.room.id,
        }),
      );

      this.deviceList$ = this.store.select(getDeviceBySpaceId(this.room.id));
      this.store
        .select(getRoomImages)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(documents => {
          this.documents = documents;
          this.generateDocumentTabs(documents);
        });
    }
  }

  documentTabChange(change: MatButtonToggleChange) {
    this.appService.activeAttachmentId.set(null);
    this.selectedDocumentTab.set(change.value);
  }

  generateDocumentTabs(documents: ImageDocument[]) {
    const docTabs = documents.map(({ tab }, index) => tab || generateDocumentType(index));

    this.documentTabs = [...new Set([...this.documentTabs, ...docTabs].filter(item => docTabs.includes(item)))];
    this.selectedDocumentTab.set(this.documentTabs[0] || '');
  }

  addNewTabClick = () => {
    this.selectedDocumentTab.set(defaultDocumentTab);
  };
}
