import { computed, inject } from '@angular/core';
import { clearObject, defaultSelectOption, hasValue } from '@app-lib';
import { EntityStatus, type FilterOption } from '@models';
import { patchState, signalStore, signalStoreFeature, withComputed, withMethods, withState } from '@ngrx/signals';
import { SessionStorageService } from '@services';
import { firstValueFrom } from 'rxjs';

import { DeviceService } from '../services';

export type FiltersOptions = {
  deviceMakers: FilterOption[];
  deviceTypes: FilterOption[];
  locations: FilterOption[];
  spaces: FilterOption[];
};

type FiltersValue = {
  building: string;
  deviceMaker: string[];
  deviceType: string[];
  floor: string;
  room: string;
  status: EntityStatus;
};

type FiltersState = {
  query: string;
  tableId: string;
} & FiltersValue;

export const initialState: FiltersState = {
  building: '',
  deviceMaker: [],
  deviceType: [],
  floor: '',
  query: '',
  room: '',
  status: EntityStatus.Active,
  tableId: '',
};

function withOptions() {
  return signalStoreFeature(
    withState<{ _options: FiltersOptions; }>({
      _options: {
        deviceMakers: [],
        deviceTypes: [],
        locations: [],
        spaces: [],
      },
    }),
    withMethods((store, deviceService = inject(DeviceService)) => ({
      async fetchOptions(params?: { space: string; }) {
        const { data } = await firstValueFrom(deviceService.filters(params));

        patchState(store, () => ({ _options: data as FiltersOptions }));
      },
    })),
  );
}

export const FiltersStore = signalStore(
  withState(initialState),
  withOptions(),
  withComputed(store => ({
    appliedFilters: computed(() => {
      let filters = {
        location: store.building(),
        space: store.room() || store.floor(),
        status: [store.status()],
      } as Record<string, string | string[]>;

      if (store.status() !== EntityStatus.Archived) {
        filters = {
          ...filters,
          deviceMaker: store.deviceMaker(),
          deviceType: store.deviceType(),
          query: store.query(),
          status: [EntityStatus.Active, EntityStatus.Paused, EntityStatus.Deactivated],
        };
      }

      return clearObject(filters) as Record<string, string | string[]>;
    }),
    deviceMakerOptions: computed(() => store._options.deviceMakers()),
    deviceTypeOptions: computed(() => store._options.deviceTypes()),
    floorOptions: computed(() => [
      defaultSelectOption,
      ...store._options.spaces().filter(space => space.parentId === store.building()),
    ]),
    hasSelectedFilters: computed(() =>
      hasValue({
        building: store.building(),
        deviceMaker: store.deviceMaker(),
        deviceType: store.deviceType(),
        floor: store.floor(),
        room: store.room(),
      }),
    ),
    locationOptions: computed(() => [defaultSelectOption, ...store._options.locations()]),
    roomOptions: computed(() => [
      defaultSelectOption,
      ...store._options.spaces().filter(space => space.parentId === store.floor()),
    ]),
    selectedFloorName: computed(() => store._options.spaces().find(floor => floor.value === store.floor())?.title),
    selectedLocationName: computed(
      () => store._options.locations().find(location => location.value === store.building())?.title,
    ),
    selectedRoomName: computed(() => store._options.spaces().find(room => room.value === store.room())?.title),
  })),
  withMethods((store, sessionStorageService = inject(SessionStorageService)) => ({
    clearFilters(showLocationFilters = true) {
      const filters = showLocationFilters
        ? {
          building: '',
          deviceMaker: [],
          deviceType: [],
          floor: '',
          room: '',
        }
        : {
          deviceMaker: [],
          deviceType: [],
        };

      this.patchFilters(filters);
    },

    getSessionFilters() {
      return sessionStorageService.getFilters(store.tableId());
    },

    initDefaultFilters(defaultFilters: Partial<FiltersValue> = {}) {
      const sessionData = this.getSessionFilters() ?? {};

      this.patchFilters({ ...sessionData, ...defaultFilters });
    },

    onCheckboxChange(config: {
      checked: boolean;
      field: 'deviceType' | 'deviceMaker';
      value: string;
    }) {
      this.patchFilters({
        [config.field]: config.checked
          ? [...store[config.field](), config.value]
          : store[config.field]().filter(value => value !== config.value),
      });
    },

    patchFilters(value: Omit<Partial<FiltersState>, 'options' | 'tableId'>) {
      patchState(store, value);

      sessionStorageService.saveFiltersToSessionStorage(store.tableId(), {
        building: store.building(),
        deviceMaker: store.deviceMaker(),
        deviceType: store.deviceType(),
        floor: store.floor(),
        query: store.query(),
        room: store.room(),
        status: store.status(),
      });
    },

    resetState() {
      patchState(store, () => ({ ...initialState }));
    },

    setTableId(tableId: string) {
      patchState(store, state => ({ ...state, tableId }));
    },
  })),
);
