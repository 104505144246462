@if ((isFullLocationLoaded$ | async) === false && !locationFullData) {
  <div class="test-skeleton-animation text-center">
    <app-skeleton-page-header />
    <ngx-skeleton-loader [theme]="{ height: '130px' }" />
    <ngx-skeleton-loader [theme]="{ height: '300px' }" />
    <app-skeleton-table [columnCount]="5" />
    <app-skeleton-table [columnCount]="5" />
  </div>
} @else if(locationFullData && currentFloor && currentRoom && currentFloorId && currentRoomId) {
  <div class="text-content">
    <app-dashboard-location-header
      [floorId]="currentFloorId"
      [locationData]="locationFullData"
      [roomId]="currentRoomId"
    />

    <app-building-info
      (spaceChanged)="spaceChanged($event)"
      [buildingStats]="buildingStats"
      [currentFloorId]="currentFloorId"
      [currentRoomId]="currentRoomId"
      [floorName]="currentFloor.friendlyName"
      [locationData]="locationFullData"
    />

    <mat-card class="card my-4 py-2">
      <mat-slide-toggle
        class="main-toggle is-header-toggle"
        (change)="showBlockDiagram = $event.checked"
        [checked]="showBlockDiagram"
        labelPosition="before"
      >
        <mat-card-title class="card-title">Block Diagram</mat-card-title>
        {{ showBlockDiagram ? 'Hide' : 'Show' }} Block Diagram
      </mat-slide-toggle>

      @if (currentRoom && currentLocationId) {
        <app-spaceplan-tabs
          [canEdit]="false"
          [class.is-hidden-tab]="!showBlockDiagram"
          [locationId]="currentLocationId"
          [popover]="devicePopover"
          [room]="currentRoom"
        />
      }
    </mat-card>

    <mat-card class="card my-4 py-2">
      <mat-slide-toggle
        class="main-toggle is-header-toggle"
        (change)="showFloorPlan = $event.checked"
        [checked]="showFloorPlan"
        labelPosition="before"
      >
        <mat-card-title class="card-title">Floor Plan</mat-card-title>
        {{ showFloorPlan ? 'Hide' : 'Show' }} Room on
        Floor Plan
      </mat-slide-toggle>

      <div [class.is-hidden-tab]="!showFloorPlan">
        @if (!currentFloor.documentReference && currentLocationId) {
          @if (isSuperAdmin$ | async) {
            <app-upload-spaceplan
              [floorId]="currentFloor.id"
              [locationId]="currentLocationId"
              appRestrictedEl
              uploadText="Upload Floor Plan"
            />
          } @else {
            <app-no-data noDataText="No floor plan added" />
          }
        }

        @if (currentFloor.documentReference && currentLocationId) {
          <app-spaceplan
            [canEdit]="false"
            [currentAttachmentId]="currentRoomId"
            [locationId]="currentLocationId"
            [popover]="roomPopover"
            [spaceplan]="{
              id: currentFloor.id,
              documentReference: currentFloor.documentReference,
              attachments: currentFloor.rooms || [],
              parentSpaceId: currentFloor.parentSpaceId
            }"
          />
        }
      </div>
    </mat-card>

    <mat-card class="card pb-2 mb-4">
      <mat-card-title class="card-title">Room Incidents</mat-card-title>
      <mat-card-content class="p-0">
        <app-incidents-table-desktop-filters
          [filtersEnabled]="true"
          [resolvedIncidentsLoading]="resolvedIncidentsLoading"
          tableId="room-details-incidents-table"
        />
        <app-incidents-table
          [dataIsLoading]="resolvedIncidentsLoading"
          [isInjected]="true"
          noDataText="All the Room devices are working properly"
          tableId="room-details-incidents-table"
        />
      </mat-card-content>
    </mat-card>

    <mat-card class="card pb-2">
      <mat-card-title class="card-title flex items-center justify-between">
        Devices
        <a
          class="btn-base border border-ui-gray-light px-2 py-1 text-ui-dark font-normal cursor-pointer"
          [queryParams]="{ locationId: locationFullData.id, floorId: currentFloorId, roomId: currentRoomId }"
          [routerLink]="['/devices', 'add-device']"
          appRestrictedEl
        >
          <mat-icon matPrefix>add</mat-icon>
          Add Device
        </a>
      </mat-card-title>

      <mat-card-content class="card-content p-0">
        <avs-li-devices-filters
          (columnsChanged)="columnsChanged($event)"
          (filtersChanged)="filters.set($event)"
          [columns]="devicesTableDisplayedColumns"
          [config]="{
            hide: true,
            filtersEnabled : false,
            columnSelectOptions: devicesTableColumnOptions
          }"
          [filters]="{
            floor: currentFloorId,
            room: currentRoomId,
          }"
          [tableId]="TABLE_ID"
        />

        <avs-li-devices-table
          class="overflow-hidden fixed-scroll"
          [displayedColumns]="devicesTableDisplayedColumns"
          [filters]="filters()"
          [tableId]="TABLE_ID"
          #devicesTable
        />
      </mat-card-content>
    </mat-card>

    <div class="card-separator"></div>
  </div>
}
