import { animate, state, style, transition, trigger } from '@angular/animations';
import { MAIN_MENU_ANIMATION_DURATION } from '@app-lib';

export const menuAnimation = [
  trigger('inOutAnimation', [
    state(
      'open',
      style({
        width: '160px',
        opacity: 1,
      })
    ),
    state(
      'closed',
      style({
        width: '0',
        opacity: 0,
      })
    ),
    transition('* => closed', [animate(`${MAIN_MENU_ANIMATION_DURATION}ms`)]),
    transition('* => open', [animate(`${MAIN_MENU_ANIMATION_DURATION}ms`)]),
  ]),
  trigger('inOutAnimationLogo', [
    state(
      'big',
      style({
        width: '200px',
        opacity: 1,
      })
    ),
    state(
      'small',
      style({
        width: '60px',
      })
    ),
    transition('* => small', [animate(`${MAIN_MENU_ANIMATION_DURATION}ms`)]),
    transition('* => big', [animate(`${MAIN_MENU_ANIMATION_DURATION}ms`)]),
  ]),
];
