<gridster
  [class.editable]="mode() === 'edit'"
  [options]="options()"
  #gridster
>
  @for (widget of items(); track widget.id) {
    <gridster-item
      (itemChange)="widgetContainer.itemChanged()"
      (itemInit)="widgetContainer.itemChanged()"
      [item]="widget"
    >
      <avs-li-widget-container
        (delete)="deleteWidget.emit(widget)"
        (edit)="editWidget.emit(widget)"
        [mode]="mode()"
        [widget]="widget"
        #widgetContainer
      />
    </gridster-item>
  }
</gridster>
