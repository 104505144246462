<div class="flex flex-row">
  <label class="input-main-label capitalize">
    Building *
  </label>

  <avs-li-location-selector
    (change)="patchForm({ locationId: $event })"
    [value]="form().locationId"
  />
</div>
