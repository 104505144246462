import { Injectable, signal } from '@angular/core';
import { CURRENT_CLIENT_ID_KEY, DeviceData, EntityStatus, RoomSpaceModel, ScreenOrientations } from '@models';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  activeAttachmentId = signal<string | null>(null);
  private _contentLoading = false;
  private _managingClientId!: string;
  private _isInitailizedAppSubscription = false;
  private _isMobile = false;
  private _screenOrientation: ScreenOrientations = ScreenOrientations.PORTRAIT;

  get screenOrientation(): ScreenOrientations {
    return this._screenOrientation;
  }

  set screenOrientation(val) {
    this._screenOrientation = val;
  }

  get managingClientId() {
    return this._managingClientId;
  }

  set managingClientId(val) {
    this._managingClientId = val;
  }

  get contentLoading() {
    return this._contentLoading;
  }

  set contentLoading(val: boolean) {
    this._contentLoading = val;
  }

  get isInitailizedAppSubscription() {
    return this._isInitailizedAppSubscription;
  }

  set isInitailizedAppSubscription(val: boolean) {
    this._isInitailizedAppSubscription = val;
  }

  get currentClient() {
    return localStorage.getItem(CURRENT_CLIENT_ID_KEY) || '';
  }

  set currentClient(clientId: string) {
    localStorage.setItem(CURRENT_CLIENT_ID_KEY, clientId);
  }

  get isMobile() {
    return this._isMobile;
  }

  set isMobile(val) {
    this._isMobile = val;
  }

  public static SortRoomsSpacesByParentId(
    sortingCollection: RoomSpaceModel[],
    excludeArchived = false,
  ): {
    [key: string]: RoomSpaceModel[];
  } {
    const res: { [key: string]: RoomSpaceModel[] } = {};

    sortingCollection.forEach(item => {
      if (excludeArchived && item.status === EntityStatus.Archived) {
        return;
      }
      const parentId = item.parentSpaceId;

      if (parentId) {
        if (!res[parentId]) {
          res[parentId] = [];
        }
        res[parentId].push(item);
      }
    });

    return res;
  }

  public static SortDevicesByRoomId(sortingCollection: DeviceData[]): { [key: string]: DeviceData[] } {
    const res: { [key: string]: DeviceData[] } = {};

    sortingCollection.forEach(item => {
      const parentId = item.spacePath?.[1]?.id;

      if (parentId && !res[parentId]) {
        res[parentId] = [];
      }
      if (parentId && res[parentId]) {
        res[parentId].push(item);
      }
    });

    return res;
  }
}
