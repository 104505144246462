import type { FloorSpaceModel, LocationFullData, RoomSpaceModel } from '@models';
import { EntityStatus, SpaceType } from '@models';

export const locationFullDataItems: LocationFullData[] = [
  {
    address: {
      city: '21312312',
      country: '21312312',
      postalCode: '21312312',
      stateProvince: '21312312',
      streetAddress: '21312312',
      streetAddress2: '21312312',
    },
    clientFriendlyId: '21312312',
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 1,
    floors: [
      {
        createdOn: '2023-09-13T20:41:19.216169Z',
        devicesByStatuses: {
          activeCount: 22,
          deactivatedCount: 0,
          pausedCount: 0,
        },
        devicesCount: 0,
        documentReference: '',
        externalReference: '',
        friendlyName: 'floor 1',
        id: '1',
        incidentCountByStatuses: {
          inProgressCount: 0,
          newCount: 2,
        },
        name: 'floor 1',
        parentSpaceId: '21312312',
        rooms: [
          {
            createdOn: '2023-09-13T20:41:19.216161Z',
            devicesByStatuses: {
              activeCount: 22,
              deactivatedCount: 0,
              pausedCount: 0,
            },
            devicesCount: 0,
            documentReference: '',
            externalReference: '',
            friendlyName: 'room 1',
            id: '1',
            incidentCountByStatuses: {
              inProgressCount: 1,
              newCount: 2,
            },
            name: 'room 1',
            parentSpaceId: '21312312',
            status: EntityStatus.Active,
            type: SpaceType.room,
            unstructuredDataReference: '',
          },
        ],
        status: EntityStatus.Active,
        type: SpaceType.floor,
        unstructuredDataReference: '',
      },
      {
        createdOn: '2023-09-13T20:41:19.216162Z',
        devicesByStatuses: {
          activeCount: 22,
          deactivatedCount: 0,
          pausedCount: 0,
        },
        devicesCount: 0,
        documentReference: '',
        externalReference: '',
        friendlyName: 'floor 2',
        id: '2',
        incidentCountByStatuses: {
          inProgressCount: 0,
          newCount: 2,
        },
        name: 'floor 2',
        parentSpaceId: '21312312',
        rooms: [
          {
            createdOn: '2023-09-13T20:41:19.216161Z',
            devicesByStatuses: {
              activeCount: 22,
              deactivatedCount: 0,
              pausedCount: 0,
            },
            devicesCount: 0,
            documentReference: '',
            externalReference: '',
            friendlyName: 'room 1',
            id: '1',
            incidentCountByStatuses: {
              inProgressCount: 0,
              newCount: 2,
            },
            name: 'room 1',
            parentSpaceId: '21312312',
            status: EntityStatus.Active,
            type: SpaceType.room,
            unstructuredDataReference: '',
          },
          {
            createdOn: '2023-09-13T20:41:19.216162Z',
            devicesByStatuses: {
              activeCount: 22,
              deactivatedCount: 0,
              pausedCount: 0,
            },
            devicesCount: 0,
            documentReference: '',
            externalReference: '',
            friendlyName: 'room 1',
            id: '1',
            incidentCountByStatuses: {
              inProgressCount: 1,
              newCount: 0,
            },
            name: 'room 1',
            parentSpaceId: '21312312',
            status: EntityStatus.Active,
            type: SpaceType.room,
            unstructuredDataReference: '',
          },
          {
            createdOn: '2023-09-13T20:41:19.216163Z',
            devicesByStatuses: {
              activeCount: 22,
              deactivatedCount: 0,
              pausedCount: 0,
            },
            devicesCount: 0,
            documentReference: '',
            externalReference: '',
            friendlyName: 'room 1',
            id: '1',
            incidentCountByStatuses: {
              inProgressCount: 0,
              newCount: 0,
            },
            name: 'room 1',
            parentSpaceId: '21312312',
            status: EntityStatus.Active,
            type: SpaceType.room,
            unstructuredDataReference: '',
          },
        ],
        status: EntityStatus.Active,
        type: SpaceType.floor,
        unstructuredDataReference: '',
      },
    ],
    friendlyName: '21312312',
    geographic: {
      lat: 0,
      long: 0,
    },
    id: '21312312',
    importantDates: {
      comissioningInfo: {
        dateTime: '21312312',
      },
      decomissionedInfo: {
        dateTime: '21312312',
      },
      installationInfo: {
        dateTime: '21312312',
      },
      provisioningInfo: {
        dateTime: '21312312',
      },
    },
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: '21312312',
    status: EntityStatus.Active,
    tags: {},
  },
];

export const rooms: RoomSpaceModel[] = [
  {
    createdOn: '2023-09-13T20:41:19.216161Z',
    devices: [],
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 0,
    documentReference: '',
    externalReference: 'test',
    friendlyName: 'room 1',
    id: '1',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'name',
    parentSpaceId: '1',
    status: EntityStatus.Active,
    type: SpaceType.room,
    unstructuredDataReference: '{"coordinates":{"width":14.64,"left":32.77,"height":10.54,"top":18.28}}',
  },
  {
    createdOn: '2023-09-13T20:41:19.216162Z',
    devices: [],
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 0,
    documentReference: '',
    externalReference: 'test',
    friendlyName: 'room 2',
    id: '2',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'name',
    parentSpaceId: '2',
    status: EntityStatus.Active,
    type: SpaceType.room,
    unstructuredDataReference: '',
  },
  {
    createdOn: '2023-09-13T20:41:19.216163Z',
    devices: [],
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 0,
    documentReference: '',
    externalReference: '',
    friendlyName: 'room 3',
    id: '3',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'name',
    parentSpaceId: '3',
    status: EntityStatus.Active,
    type: SpaceType.room,
    unstructuredDataReference: '',
  },
  {
    createdOn: '2023-09-13T20:41:19.216164Z',
    devices: [],
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 0,
    documentReference: '',
    externalReference: '',
    friendlyName: 'room 4',
    id: '4',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'name',
    parentSpaceId: '4',
    status: EntityStatus.Active,
    type: SpaceType.room,
    unstructuredDataReference: '',
  },
  {
    createdOn: '2023-09-13T20:41:19.216165Z',
    devices: [],
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 0,
    documentReference: '',
    externalReference: '',
    friendlyName: 'room 5',
    id: '5',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'name',
    parentSpaceId: '5',
    status: EntityStatus.Active,
    type: SpaceType.room,
    unstructuredDataReference: '',
  },
  {
    createdOn: '2023-09-13T20:41:19.216166Z',
    devices: [],
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 0,
    documentReference: '',
    externalReference: '',
    friendlyName: 'room 6',
    id: '6',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'name',
    parentSpaceId: '6',
    status: EntityStatus.Active,
    type: SpaceType.room,
    unstructuredDataReference: '',
  },
  {
    createdOn: '2023-09-13T20:41:19.216167Z',
    devices: [],
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 0,
    documentReference: '',
    externalReference: '',
    friendlyName: 'room 7',
    id: '7',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'name',
    parentSpaceId: '7',
    status: EntityStatus.Active,
    type: SpaceType.room,
    unstructuredDataReference: '',
  },
  {
    createdOn: '2023-09-13T20:41:19.216168Z',
    devices: [],
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 0,
    documentReference: '',
    externalReference: '',
    friendlyName: 'room 8',
    id: '8',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'name',
    parentSpaceId: '8',
    status: EntityStatus.Archived,
    type: SpaceType.room,
    unstructuredDataReference: '',
  },
  {
    createdOn: '2023-09-13T20:41:19.216169Z',
    devices: [],
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 0,
    documentReference: '',
    externalReference: '',
    friendlyName: 'room 9',
    id: '9',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'name',
    parentSpaceId: '9',
    status: EntityStatus.Paused,
    type: SpaceType.room,
    unstructuredDataReference: '',
  },
];
export const floors: FloorSpaceModel[] = [
  {
    createdOn: '2023-09-13T20:41:19.216161Z',
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 0,
    documentReference: '',
    externalReference: '',
    friendlyName: 'floor 1',
    id: '1',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'name',
    parentSpaceId: null,
    rooms,
    status: EntityStatus.Active,
    type: SpaceType.room,
    unstructuredDataReference: '',
  },
  {
    createdOn: '2023-09-13T20:41:19.216162Z',
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 0,
    documentReference: '',
    externalReference: '',
    friendlyName: 'floor 2',
    id: '2',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'name',
    parentSpaceId: null,
    rooms,
    status: EntityStatus.Active,
    type: SpaceType.room,
    unstructuredDataReference: '',
  },
  {
    createdOn: '2023-09-13T20:41:19.216163Z',
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 0,
    documentReference: '',
    externalReference: '',
    friendlyName: 'floor 3',
    id: '3',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'name',
    parentSpaceId: null,
    rooms,
    status: EntityStatus.Active,
    type: SpaceType.room,
    unstructuredDataReference: '',
  },
  {
    createdOn: '2023-09-13T20:41:19.216164Z',
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 0,
    documentReference: '',
    externalReference: '',
    friendlyName: 'floor 4',
    id: '4',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'name',
    parentSpaceId: null,
    rooms,
    status: EntityStatus.Active,
    type: SpaceType.room,
    unstructuredDataReference: '',
  },
  {
    createdOn: '2023-09-13T20:41:19.216165Z',
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 0,
    documentReference: '',
    externalReference: '',
    friendlyName: 'floor 5',
    id: '5',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'name',
    parentSpaceId: null,
    rooms,
    status: EntityStatus.Active,
    type: SpaceType.room,
    unstructuredDataReference: '',
  },
  {
    createdOn: '2023-09-13T20:41:19.216166Z',
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 0,
    documentReference: '',
    externalReference: '',
    friendlyName: 'floor 6',
    id: '6',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'name',
    parentSpaceId: null,
    rooms,
    status: EntityStatus.Active,
    type: SpaceType.room,
    unstructuredDataReference: '',
  },
  {
    createdOn: '2023-09-13T20:41:19.216167Z',
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 0,
    documentReference: '',
    externalReference: '',
    friendlyName: 'floor 7',
    id: '7',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'name',
    parentSpaceId: null,
    rooms,
    status: EntityStatus.Active,
    type: SpaceType.room,
    unstructuredDataReference: '',
  },
  {
    createdOn: '2023-09-13T20:41:19.216168Z',
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 0,
    documentReference: '',
    externalReference: '',
    friendlyName: 'floor 8',
    id: '8',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'name',
    parentSpaceId: null,
    rooms,
    status: EntityStatus.Active,
    type: SpaceType.room,
    unstructuredDataReference: '',
  },
  {
    createdOn: '2023-09-13T20:41:19.216169Z',
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 0,
    documentReference: '',
    externalReference: '',
    friendlyName: 'floor 9',
    id: '9',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'name',
    parentSpaceId: null,
    rooms,
    status: EntityStatus.Active,
    type: SpaceType.room,
    unstructuredDataReference: '',
  },
];
export const locations: LocationFullData[] = [
  {
    address: {
      city: 'NY',
      country: 'USA',
      postalCode: '13424',
      stateProvince: 'NY',
      streetAddress: '2118 Thornridge Cir. Syracuse, Connecticut 35624',
      streetAddress2: 'string',
    },
    clientFriendlyId: '',
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 22,
    floors,
    friendlyName: 'location 1',
    geographic: {
      lat: 0,
      long: 0,
    },
    id: '0',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 2,
    },
    name: 'Test Empty Building',
    status: EntityStatus.Active,
    tags: {},
  },
  {
    address: {
      city: 'NY',
      country: 'USA',
      postalCode: '13424',
      stateProvince: 'NY',
      streetAddress: '2118 Thornridge Cir. Syracuse, Connecticut 35624',
      streetAddress2: 'string',
    },
    clientFriendlyId: '',
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 22,
    floors,
    friendlyName: 'location 2',
    geographic: {
      lat: 0,
      long: 0,
    },
    id: '1',
    incidentCountByStatuses: {
      inProgressCount: 1,
      newCount: 2,
    },
    name: 'Test Empty Building',
    status: EntityStatus.Active,
    tags: {},
  },
  {
    address: {
      city: 'NY',
      country: 'USA',
      postalCode: '13424',
      stateProvince: 'NY',
      streetAddress: '2118 Thornridge Cir. Syracuse, Connecticut 35624',
      streetAddress2: 'string',
    },
    clientFriendlyId: '',
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 22,
    floors,
    friendlyName: 'location 3',
    geographic: {
      lat: 0,
      long: 0,
    },
    id: '2',
    incidentCountByStatuses: {
      inProgressCount: 3,
      newCount: 2,
    },
    name: 'Test Empty Building',
    status: EntityStatus.Active,
    tags: {},
  },
  {
    address: {
      city: 'NY',
      country: 'USA',
      postalCode: '13424',
      stateProvince: 'NY',
      streetAddress: '2118 Thornridge Cir. Syracuse, Connecticut 35624',
      streetAddress2: 'string',
    },
    clientFriendlyId: '',
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 22,
    floors,
    friendlyName: 'location 4',
    geographic: {
      lat: 1,
      long: -24,
    },
    id: '3',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 0,
    },
    name: 'Test Empty Building',
    status: EntityStatus.Active,
    tags: {},
  },
  {
    address: {
      city: 'NY',
      country: 'USA',
      postalCode: '13424',
      stateProvince: 'NY',
      streetAddress: '2118 Thornridge Cir. Syracuse, Connecticut 35624',
      streetAddress2: 'string',
    },
    clientFriendlyId: '',
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 22,
    floors,
    friendlyName: 'location 5',
    geographic: {
      lat: 0,
      long: 0,
    },
    id: '4',
    incidentCountByStatuses: {
      inProgressCount: 0,
      newCount: 0,
    },
    name: 'Test Empty Building',
    status: EntityStatus.Paused,
    tags: {},
  },
  {
    address: {
      city: 'NY',
      country: 'USA',
      postalCode: '13424',
      stateProvince: 'NY',
      streetAddress: '2118 Thornridge Cir. Syracuse, Connecticut 35624',
      streetAddress2: 'string',
    },
    clientFriendlyId: '',
    devicesByStatuses: {
      activeCount: 22,
      deactivatedCount: 0,
      pausedCount: 0,
    },
    devicesCount: 22,
    floors,
    friendlyName: 'location 6',
    geographic: {
      lat: 1,
      long: 1,
    },
    id: '5',
    incidentCountByStatuses: {
      inProgressCount: 1,
      newCount: 0,
    },
    name: 'Test Empty Building',
    status: EntityStatus.Archived,
    tags: {},
  },
];
