@if (device()) {
  <mat-card class="card mt-4">
    <app-page-header>{{ device().friendlyName }}</app-page-header>
    <p
      class="font-nunito font-semibold text-base leading-[22px] text-ui-dark mt-2 mb-0"
      *ngIf="device().tags?.['description']"
    >
      {{ device().tags?.['description'] }}
    </p>

    <div
      class="test-incident-status my-3"
      *ngIf="device().status !== EntityStatus.Archived && device().incidentCountByStatuses as incidents"
    >
      <app-incident-status
        [status]="incidentStatus.IN_QUEUE"
        *ngIf="incidents.newCount"
      />
      <app-incident-status
        [status]="incidentStatus.IN_PROGRESS"
        *ngIf="!incidents.newCount && incidents.inProgressCount"
      />

      <span
        class="inline-flex justify-center items-center h-6 min-w-[24px] capitalize text-xs px-2 rounded-xl text-white bg-ui-green-light"
        *ngIf="!incidents.newCount && !incidents.inProgressCount"
      >
      Good
    </span>
    </div>

    <div class="flex">
      <div class="image-container mb-3 w-[320px]">
        @if (device().deviceModelInformation && device().deviceModelInformation?.documentReference) {
          <app-img-loader
            [imgSrc]="imgUrl()"
            altText="floorplan"
            errorSrc="assets/icons/image-error.svg"
            loader="skeleton"
          />
        } @else {
          <img
            [src]="defaultImgUrl"
            alt="Device Image"
          />
        }

        <button
          class="mt-2 rounded border w-full py-2 flex align-middle justify-center active:bg-ui-gray-900 disabled:bg-ui-gray-900 disabled:cursor-not-allowed"
          (click)="rebootDevice()"
          [disabled]="deviceIsUpdating"
          [showForClientAdmin]="true"
          appRestrictedEl
        >
          @if (!deviceIsUpdating) {
            <mat-icon fontIcon="refresh" />
          } @else {
            <mat-spinner
              class="mr-1"
              diameter="20"
              matSuffix
            />
          }
          <span class="pl-2">Reboot Device</span>
        </button>
      </div>
      <ul class="w-[calc(100% - 320px)]">
        <li>
          <span>Device type:</span>
          {{ device().deviceModelInformation?.deviceType }}
        </li>

        <li
          class="test-manufacturer"
          *ngIf="device().deviceModelInformation?.make?.name"
        >
          <span>Manufacturer:</span>
          {{ device().deviceModelInformation?.make?.name }}
        </li>

        <li
          class="test-model"
          *ngIf="device().deviceModelInformation?.name"
        >
          <span>Model:</span>
          {{ device().deviceModelInformation?.name }}
        </li>

        <li
          class="test-serial-number"
          *ngIf="device().physicalDeviceId"
        >
          <span>Serial №:</span>
          {{ device().physicalDeviceId }}
        </li>

        <li
          class="test-serial-number"
          *ngIf="device().tags?.['ipAddress']"
        >
          <span>Ip Address:</span>
          @if (device().tags?.['ipAddress'] === "N/A") {
            {{ device().tags?.['ipAddress'] }}
          } @else {
            <a
              class="underline"
              [href]="'http://' + getDeviceUrl(device())"
              [matTooltip]="'http://' + getDeviceUrl(device())"
              matTooltipPosition="above"
              target="_blank"
            >
              {{ device().tags?.['ipAddress'] }}
            </a>
          }
        </li>
        <li
          class="test-serial-number"
          *ngIf="device().tags?.['macAddress']"
        >
          <span>Mac Address:</span>
          {{ device().tags?.['macAddress'] }}
        </li>

        <li
          class="test-firmware"
          *ngIf="device().tags?.['firmware']"
        >
          <span>Firmware:</span>
          {{ device().tags?.['firmware'] }}
        </li>

        <li
          class="test-temperature"
          *ngIf="tempSensor"
        >
          <div>
            <span>Current temperature : </span>
            {{ tempSensor }} &deg;F
          </div>
        </li>

        <li
          class="test-reported-time"
          *ngIf="device().latestStreamingTimestamp"
        >
          <div>
            <span>Last reported : </span>
            {{ (device().latestStreamingTimestamp || "") | currentDate }}
          </div>
        </li>

        <li
          class="test-device-id break-all"
          appRestrictedEl
        >
          <span>Device ID : </span>
          {{ device().id }}
          <button
            class="px-1 pt-1 text-ui-gray-light cursor-pointer h-[30px] w-[30px] align-middle"
            (click)="copiedToClipboard()"
            [cdkCopyToClipboard]="device().id"
          >
            <mat-icon
              class="text-base cursor-pointer p-0 pt-0.5 text-ui-gray-dark active:text-ui-green-light"
              fontIcon="content_copy"
            />
          </button>
        </li>

        <li
          class="test-add-properties-loading"
          *ngIf="!device().additionalAttributes"
        >
          <ngx-skeleton-loader
            appearance="line"
            count="2"
          />
        </li>
      </ul>
    </div>
  </mat-card>
}

