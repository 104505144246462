@if(space(); as spaceWidget) {
  <div class="h-full flex flex-col justify-center">
    <div class="flex justify-between">
      <div class="flex flex-col">
        <span class="font-nunito text-sm text-ui-dark mb-2">Status:</span>
        <app-entity-status
          [status]="spaceWidget.status"
          fontSize="base"
          fontWeight="medium"
        />
      </div>

      <div class="flex">
        <div class="flex flex-col">
          <span class="font-nunito text-sm text-ui-dark mb-2">Incidents:</span>
          <div>
            @if (spaceWidget.incidentCountByStatuses) {
              <app-incident-status-count
                class="mr-2"
                [amount]="spaceWidget.incidentCountByStatuses.newCount"
                [status]="IncidentStatus.IN_QUEUE"
              />
              <app-incident-status-count
                [amount]="spaceWidget.incidentCountByStatuses.inProgressCount"
                [status]="IncidentStatus.IN_PROGRESS"
              />
            }
          </div>
        </div>

        <span class="border-r border-ui-gray-light mx-4 my-2"></span>

        <div class="flex flex-col">
          <span class="font-nunito text-sm text-ui-dark mb-2">Devices:</span>
          <div>
            @if (spaceWidget.devicesByStatuses) {
              <app-device-status-count
                [activeAmount]="spaceWidget.devicesByStatuses.activeCount"
                [pausedAmount]="spaceWidget.devicesByStatuses.pausedCount"
              />
            }
          </div>
        </div>
      </div>
    </div>
  </div>
} @else {
  <ngx-skeleton-loader [theme]="{ height: '100%' }" />
}
