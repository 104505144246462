import { Component, OnDestroy } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { LocationData } from '@models';
import { select, Store } from '@ngrx/store';
import { AppState, areLocationsLoaded, selectAllLocations } from '@ngrx-store';
import { MapFilterService } from '@services';
import { Observable } from 'rxjs';

@Component({
  selector: 'avs-fe-dashboard-main',
  templateUrl: './dashboard-main.component.html',
})
export class DashboardMainComponent implements OnDestroy {
  locationsLoaded$: Observable<boolean>;
  locationsData$: Observable<LocationData[]>;
  mapEnabled = true;

  constructor(private store: Store<AppState>, public mapFilterService: MapFilterService) {
    this.locationsLoaded$ = this.store.select(areLocationsLoaded);
    this.locationsData$ = this.store.pipe(select(selectAllLocations));
  }

  ngOnDestroy(): void {
    this.mapFilterService.mapFilter = [];
  }

  toggleMap(event: MatSlideToggleChange) {
    this.mapEnabled = event.checked;
  }

  mapFilterChange(value: string[]) {
    this.mapFilterService.mapFilter = value;
  }
}
