<mat-card-title
  class="card-title"
  *ngIf="showHeader"
>Device List</mat-card-title>
<div class="border-b-ui-gray-light border-b flex justify-between items-center mb-3">
  <mat-button-toggle-group
    class="desktop-toggle-group"
    (change)="onDeviceStatusFilterChange($event)"
    [value]="deviceStatus"
    aria-label="Device Status"
    name="device-status"
  >
    <mat-button-toggle value="current">
      <span class="item-status-title">Current</span>
    </mat-button-toggle>
    <mat-button-toggle value="archived">
      <span class="item-status-title">Archived</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <app-search-input
    (searchDataEvent)="deviceSearchChanged($event)"
    [inputValue]="deviceSearch"
    placeholder="Device name"
  />
</div>
<div class="border border-ui-gray-light">
  <p class="bg-ui-gray-50 border-b border-b-ui-gray-light font-nunito text-sm text-ui-gray-100 font-semibold h-8 flex px-3 mb-0 items-center">
    Devices
  </p>
  <ul
    class="overflow-auto max-h-[440px]"
    *ngIf="deviceList"
  >
    <li
      class="flex items-center justify-between px-3 border-t border-t-ui-gray-light h-[44px] font-nunito text-sm text-ui-dark"
      *ngFor="
        let device of deviceList
          | filterDeviceByName : deviceSearch
          | filterDeviceByStatus : deviceStatus
          | sort : 'desc' : 'createdOn'
      "
    >
      @let hasCoordinates = hasDeviceCoordinates(device);
      <a
        class="underline"
        [routerLink]="['/devices', locationId, device.id]"
      >{{ device.friendlyName }}</a>
      <div class="flex items-center">
        <ng-container *ngIf="device.status !== entityStatus.Archived">
          <button
            class="btn-blue btn-small"
            (click)="assignDevice(device.id)"
            [class.bg-ui-blue-150]="appService.activeAttachmentId()"
            [disabled]="defaultDocumentTab === documentTab || !documentTab"
            *ngIf="!hasCoordinates"
            appRestrictedEl
          >
            <i class="icon-Assign-a-Room text-3xl mr-1"></i>
            Assign a Device
          </button>
          <div
            class="flex items-center test-floorplan-button-block"
            *ngIf="hasCoordinates"
          >
            <button
              class="btn-white-stroked hover:text-white hover:bg-ui-blue-100 active:bg-ui-blue-150 btn-small mr-2"
              (click)="assignDevice(device.id)"
              [class]="appService.activeAttachmentId() === device.id ? 'bg-ui-blue-150 text-white' : ''"
              [disabled]="defaultDocumentTab === documentTab || !documentTab"
              appRestrictedEl
            >
              <i class="icon-move text-3xl mr-1"></i>
              Move/Resize
            </button>
            <button
              class="btn-white-stroked btn-small hover:bg-ui-red-50 hover:text-white active:bg-ui-red-150"
              (click)="removeCoordinates(device)"
              appRestrictedEl
            >
              <i class="icon-Unassign text-3xl mr-1"></i>
              Unassign
            </button>
          </div>
        </ng-container>
        <i
          class="test-edit-button icon-edit text-ui-gray-100 text-3xl cursor-pointer ml-2"
          (click)="editDeviceAction(device)"
          *ngIf="device.status !== entityStatus.Archived"
          appRestrictedEl
          matTooltip="Edit"
        ></i>
        <i
          class="test-delete-button icon-add-to-archive text-ui-gray-100 text-3xl cursor-pointer ml-2"
          (click)="archiveDevice(device)"
          *ngIf="device.status !== entityStatus.Archived"
          appRestrictedEl
          matTooltip="Archive"
        ></i>
        <i
          class="icon-restore text-ui-gray-100 text-3xl cursor-pointer inline-flex items-center ml-2"
          (click)="restoreDevice(device)"
          *ngIf="device.status === entityStatus.Archived"
          appRestrictedEl
          matTooltip="Restore"
        ></i>
      </div>
    </li>
  </ul>
</div>
