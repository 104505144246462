@if (device$|async; as device) {
  <div class="test-device-details">
    <div class="test-header flex flex-row items-center pb-3">
      <div class="flex-grow">
        <a
          class="cursor-pointer text-sm inline-flex text-ui-gray-dark font-semibold items-center hover:underline"
          [routerLink]="backButtonUrl"
        >
          <mat-icon>chevron_left</mat-icon>
          Back to {{ backButtonType }}
        </a>
        <app-page-header>{{ device.friendlyName }}</app-page-header>
      </div>
      <div class="flex">
        @if (device.status === EntityStatus.Paused) {
          <button
            class="test-resume-btn btn-green btn-medium mr-3"
            (click)="resumeDevice(device)"
            [showForClientAdmin]="true"
            appRestrictedEl
          >
            <i class="icon-play-16 text-3xl mr-2"></i>
            Resume
          </button>
        }
        @if (device.status === EntityStatus.Active) {
          <button
            class="test-pause-btn btn-gray btn-medium mr-3"
            (click)="pauseDevice(device)"
            [showForClientAdmin]="true"
            appRestrictedEl
          >
            <i class="icon-pause-16 text-3xl mr-2"></i>
            Pause
          </button>
        }
        @if (device.status !== EntityStatus.Archived) {
          <button
            class="test-archive-btn btn-black btn-medium mr-3"
            (click)="archiveDevice(device)"
            appRestrictedEl
          >
            <i class="icon-add-to-archive text-3xl mr-2"></i>
            Archive
          </button>
        }
        @if (device.status === EntityStatus.Archived) {
          <button
            class="test-restore-btn btn-green btn-medium mr-3"
            (click)="restoreDevice(device)"
            appRestrictedEl
          >
            <i class="icon-restore text-3xl mr-2"></i>
            Restore
          </button>
        }
        @if (device.status !== EntityStatus.Archived) {
          <button
            class="test-edit-btn btn-blue btn-medium"
            [routerLink]="['/devices', 'edit', device.location.id || device.locationId, device.id]"
            appRestrictedEl
          >
            <i class="icon-edit text-3xl"></i>
            Edit
          </button>
        }
      </div>
    </div>

    <section class="flex flex-row align-top max-h-[100%]">
      @if (myClient$ | async; as myClient) {
        <avs-fe-device-management-sidebar
          [clientId]="myClient.id"
          [device]="device"
          [incidents]="(incidents$ | async) || []"
        />
      }

      <div class="ml-4 w-[calc(100%-400px)]">
        <avs-fe-device-location-widget [device]="device" />
        <app-device-stats-chart
          [device]="device"
          [isMobile]="false"
        />

        <mat-card class="card pb-2">
          <mat-card-title class="card-title">Incidents</mat-card-title>
          <mat-card-content class="p-0">
            <app-incidents-table-desktop-filters
              [dropdownFilters]="false"
              [filtersEnabled]="true"
              [resolvedIncidentsLoading]="resolvedIncidentsLoading"
              tableId="device-details-incidents-table"
            />
            <app-incidents-table
              [dataIsLoading]="resolvedIncidentsLoading"
              [isInjected]="true"
              noDataText="This device is working properly"
              tableId="device-details-incidents-table"
            />
          </mat-card-content>
        </mat-card>
        <div class="card-separator"></div>
      </div>
    </section>
  </div>
} @else {
  <avs-fe-device-details-skeleton-animation />
}
