import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatCardModule } from '@angular/material/card';
import { createIncidentManufacturerChart } from '@app-lib';
import { IncidentModel } from '@models';
import { Store } from '@ngrx/store';
import { AppState, isIncidentLoaded, selectAllIncidents } from '@ngrx-store';
import * as Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Observable } from 'rxjs';

exporting(Highcharts);
offlineExporting(Highcharts);

@Component({
  imports: [NgxSkeletonLoaderModule, HighchartsChartModule, MatCardModule, AsyncPipe],
  selector: 'avs-fe-incident-manufacturer-chart',
  standalone: true,
  templateUrl: './incident-manufacturer-chart.component.html',
})
export class IncidentManufacturerChartComponent {
  protected Highcharts: typeof Highcharts = Highcharts;
  protected incidentManufacturerChartData: Highcharts.Options | null = null;
  protected isIncidentLoaded$: Observable<boolean>;

  private openIncidents: IncidentModel[] = [];

  constructor(private store: Store<AppState>) {
    this.store
      .select(selectAllIncidents)
      .pipe(takeUntilDestroyed())
      .subscribe(incidents => {
        this.openIncidents = incidents;
        this.prepareManufacturerChartsData();
      });
    this.isIncidentLoaded$ = this.store.select(isIncidentLoaded);
  }

  prepareManufacturerChartsData() {
    const deviceMakes: { id: string; name: string; y: number }[] = [];

    this.openIncidents?.forEach(incident => {
      const deviceModel = deviceMakes.find(deviceMake => deviceMake.id === incident.device.deviceModelInformation?.id);

      if (deviceModel) {
        deviceModel.y += 1;
      } else if (incident.device.deviceModelInformation) {
        deviceMakes.push({
          id: incident.device.deviceModelInformation.id,
          name: incident.device.deviceModelInformation.make.name,
          y: 1,
        });
      }
    });

    this.incidentManufacturerChartData = createIncidentManufacturerChart(deviceMakes);
  }
}
