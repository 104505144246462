import { inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { Sort } from '@angular/material/sort';
import { SocketEvents } from '@core';
import { DeviceTableCols, type EntityStatus, type NormalizedDeviceData } from '@models';
import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { addEntities, removeAllEntities, removeEntity, updateEntity, withEntities } from '@ngrx/signals/entities';
import type { Subscription } from 'rxjs';
import { map } from 'rxjs';

import { DeviceService } from '../services';

const defaultSort = {
  active: DeviceTableCols.DEVICE_NAME,
  direction: 'desc',
} as Sort;

export const DevicesStore = signalStore(
  withEntities<NormalizedDeviceData & { _index: number; }>(),
  withState({
    _requestSub: null as Subscription | null,
    count: null as number | null,
    offset: 0 as number,
    sort: defaultSort,
  }),
  withMethods((store, service = inject(DeviceService)) => ({
    clear() {
      patchState(store, removeAllEntities(), {
        count: null,
        offset: 0,
      });
    },

    async loadDevices(params: { filters?: object; query?: string; sort?: Sort; }) {
      if (store.count() === store.offset()) {
        return;
      }

      const offset = store.offset() ?? 0;
      const { promise, resolve } = Promise.withResolvers<void>();

      store._requestSub()?.unsubscribe();

      const requestSub = service.list({
        ...params,
        limit: 70,
        offset: store.offset(),
      }).subscribe(({ count, data }) => {
        const devices = data.map((device, index) => ({
          ...device,
          _index: offset + index,
        }));

        patchState(store, addEntities(devices), { count, offset: offset + data.length });

        resolve();
      });

      patchState(store, { _requestSub: requestSub });

      return promise;
    },

    removeDevice(id: string) {
      patchState(store, removeEntity(id));
    },

    updateIncidentCounters(id: string, counters: NormalizedDeviceData['incidentCountByStatuses']) {
      patchState(store, updateEntity({
        changes: { incidentCountByStatuses: counters },
        id,
      }));
    },

    updateSort(sort: Sort) {
      if (sort.direction === '') {
        sort = defaultSort;
      }

      patchState(store, { sort });
    },

    updateStatus(id: string, status: EntityStatus) {
      patchState(store, updateEntity({
        changes: { status },
        id,
      }));
    },
  })),

  withHooks((store) => {
    SocketEvents
      .pipe(
        map(({ data }) => Array.isArray(data) ? data : [data]),
        takeUntilDestroyed(),
      )
      .subscribe((incidents) => {
        for (const incident of incidents) {
          const device = store.entityMap()[incident.device.id];

          if (device) {
            store.updateIncidentCounters(device.id, incident.device.incidentCountByStatuses);
          }
        }
      });

    return {};
  }),
);
