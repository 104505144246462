@if (isLoading()) {
  <ngx-skeleton-loader [theme]="{ height: '18px', marginBottom: 0 }" />
} @else {
  <a
    class="font-nunito text-xs font-semibold overflow-hidden text-ellipsis whitespace-nowrap underline hover:no-underline cursor-pointer"
    [routerLink]="url()"
  >
    {{ title() }}
  </a>
}
