<avs-li-location-full-options
  (change)="patchLocationForm($event)"
  [config]="locationConfig()"
/>

@if (form.value?.roomId) {
  <div class="flex flex-row">
    <label class="input-main-label capitalize">
      Devices *
    </label>

    <avs-li-device-selector
      (change)="patchForm({
        deviceId: $event,
        datapointName: undefined,
        dateRange: undefined
      })"
      [filters]="{space : form.value?.roomId}"
      [value]="form.value.deviceId"
    />
  </div>
}

@if (form.value.deviceId) {
  <div class="flex flex-row">
    <label class="input-main-label capitalize">
      Data Point *
    </label>
    <avs-li-data-point-selector
      (change)="patchForm({
        datapointName: $event,
        dateRange: hideDateRangeInput() ? ePeriodFilterEnum.WEEK : undefined
      })"
      [deviceId]="form.value.deviceId"
      [locationId]="form.value?.locationId"
      [value]="form.value?.datapointName"
    />
  </div>
}

@if (form.value?.datapointName) {
  <div
    class="flex flex-row"
    [ngClass]="{ 'invisible': hideDateRangeInput() }"
  >
    <label class="input-main-label capitalize">
      Period *
    </label>
    <avs-li-date-range-selector
      (change)="patchForm({ dateRange: $event })"
      [value]="form.get('dateRange')?.value"
    />
  </div>
}
