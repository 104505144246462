import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CURRENT_CLIENT_TEMPLATE_VAR,
  DeviceAttribute,
  DeviceChart,
  DeviceChartsPayload,
  DeviceData,
  DeviceFullModel,
  DeviceManufacturer,
  DeviceModel,
  DeviceStatusActions,
  DeviceType,
  ImageDocument,
  NewDeviceModelData,
} from '@models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(private http: HttpClient) {
  }

  getAllDevicesByClientId(clientId: string, params: Record<string, string> = {}): Observable<DeviceData[]> {
    return this.http
      .get<{ data: DeviceData[] }>(`clientId/${clientId}/devices`, { params })
      .pipe(map(response => response.data));
  }

  getAllDevicesByLocationId(clientId: string, locationId: string): Observable<DeviceData[]> {
    return this.http
      .get<{
        data: DeviceData[];
      }>(`clientId/${clientId}/location/${locationId}/devices/flat`)
      .pipe(map(response => response.data));
  }

  getDeviceDetailsByDeviceId(locationId: string, deviceId: string): Observable<DeviceData> {
    return this.http
      .get<{ data: DeviceData }>(`clientId/${CURRENT_CLIENT_TEMPLATE_VAR}/location/${locationId}/device/${deviceId}`)
      .pipe(map(response => response.data));
  }

  addNewDevice(clientId: string, locationId: string, data: Partial<DeviceData>): Observable<DeviceData> {
    return this.http
      .post<{ data: DeviceData }>(`clientId/${clientId}/location/${locationId}/device`, data)
      .pipe(map(response => response.data));
  }

  updateDeviceData(options: {
    clientId: string;
    data: Partial<DeviceData>;
    deviceId: string;
    locationId: string;
  }) {
    const { clientId, data, deviceId, locationId } = options;

    const action = data.status
      ? this.http.post<{ data: DeviceData }>(
        `clientId/${clientId}/location/${locationId}/device/${deviceId}/${DeviceStatusActions[data.status]}`,
        {},
      )
      : this.http.patch<{ data: DeviceData }>(
        `clientId/${clientId}/location/${locationId}/device/${deviceId}/withAttributes`,
        data,
      );

    return action.pipe(
      map(response => {
        return response.data;
      }),
    );
  }

  getChartsData(options: { clientId: string; data: DeviceChartsPayload; deviceId: string }) {
    return this.http
      .post<{ data: DeviceChart[] }>(`clientId/${options.clientId}/device/${options.deviceId}/timeseries`, options.data)
      .pipe(map(response => response.data));
  }

  getManufacturers(clientId: string) {
    return this.http
      .get<{ data: DeviceManufacturer[] }>(`clientId/${clientId}/library/standardMakes`)
      .pipe(map(response => response.data));
  }

  getDeviceTypes(clientId: string) {
    return this.http
      .get<{ data: DeviceType[] }>(`clientId/${clientId}/device-types`)
      .pipe(map(response => response.data));
  }

  getModels(clientId: string) {
    return this.http
      .get<{ data: DeviceModel[] }>(`clientId/${clientId}/library/standardModels`)
      .pipe(map(response => response.data));
  }

  getAttributes(clientId: string) {
    return this.http
      .get<{ data: DeviceAttribute[] }>(`clientId/${clientId}/library/standardAttributes`)
      .pipe(map(response => response.data));
  }

  getFullModel({ clientId, manufacturerId, modelId }: { clientId: string; manufacturerId: string; modelId: string }) {
    return this.http
      .get<{ data: DeviceFullModel }>(
        `clientId/${clientId}/library/standardMake/${manufacturerId}/standardModel/${modelId}`,
      )
      .pipe(map(response => response.data));
  }

  addNewManufacturer(clientId: string, data: Pick<DeviceManufacturer, 'name'>): Observable<DeviceManufacturer> {
    return this.http
      .post<{ data: DeviceManufacturer }>(`clientId/${clientId}/library/standardMake`, data)
      .pipe(map(response => response.data));
  }

  addNewDeviceType(clientId: string, data: Pick<DeviceType, 'name'>): Observable<DeviceType> {
    return this.http
      .post<{ data: DeviceType }>(`clientId/${clientId}/device-types`, data)
      .pipe(map(response => response.data));
  }

  addNewDeviceModel(clientId: string, makeId: string, data: NewDeviceModelData): Observable<DeviceModel> {
    return this.http
      .post<{ data: DeviceModel }>(`clientId/${clientId}/library/standardMake/${makeId}/standardModel`, data)
      .pipe(map(response => response.data));
  }

  rebootDevice(clientId: string, locationId: string, deviceId: string) {
    return this.http.post(`clientId/${clientId}/location/${locationId}/device/${deviceId}/reboot`, {});
  }

  uploadDocumentToDeviceModel({
                                clientId,
                                deviceMakeId,
                                deviceModelId,
                                body,
                              }: {
    body: File;
    clientId: string;
    deviceMakeId: string;
    deviceModelId: string;
  }) {
    return this.http
      .put<{ data: ImageDocument }>(
        `clientId/${clientId}/library/standardMake/${deviceMakeId}/standardModel/${deviceModelId}/document?setAsReferenceDocument=true`,
        body,
        {
          headers: new HttpHeaders({
            'Content-Type': body.type,
            FileName: body.name,
          }),
        },
      )
      .pipe(map(({ data }) => data));
  }

  addNewAttribute(
    clientId: string,
    data: Pick<DeviceAttribute, 'name' | 'attributeType'>,
  ): Observable<DeviceAttribute> {
    return this.http
      .post<{ data: DeviceAttribute }>(`clientId/${clientId}/library/standardAttribute`, data)
      .pipe(map(response => response.data));
  }
}
