import { CompactType, DisplayGrid, type GridsterConfig, GridType } from 'angular-gridster2';

import type { Widget, WidgetConfig } from '../../models';

export class LayoutHelper {
  static createConfig() {
    return {
      addEmptyRowsCount: 1,
      compactType: CompactType.CompactLeftAndUp,

      defaultItemCols: 2,
      defaultItemRows: 2,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      disableScrollHorizontal: true,
      disableWarnings: true,
      disableWindowResize: true,
      displayGrid: DisplayGrid.Always,
      draggable: {
        delayStart: 100,
        dragHandleClass: 'gridster-drag-handle',
        enabled: false,
        ignoreContent: true,
      },

      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,

      enableBoundaryControl: true,

      fixedColWidth: 150,
      fixedRowHeight: 150,

      gridType: GridType.VerticalFixed,

      margin: 16,
      maxCols: 6,
      maxItemCols: 6,
      maxItemRows: 3,
      maxRows: 100,
      minCols: 6,
      minItemArea: 1,
      minItemCols: 1,
      minItemRows: 1,
      minRows: 6,

      mobileBreakpoint: 640,

      outerMargin: false,

      pushDirections: {
        east: false,
        north: false,
        south: true,
        west: false,
      },
      pushItems: true,
      pushResizeItems: false,

      resizable: {
        delayStart: 100,
        enabled: false,
        handles: {
          e: false,
          n: false,
          ne: false,
          nw: false,
          s: false,
          se: true,
          sw: false,
          w: false,
        },
      },

      scrollSensitivity: 10,
      scrollSpeed: 20,
      scrollToNewItems: false,
      setGridSize: true,
      swap: true,

      useTransformPositioning: false,
    } as GridsterConfig;
  }

  static createWidget(config: WidgetConfig) {
    const overrides = WidgetConfigOverrides[config?.type] ?? {};

    return {
      cols: 2,
      config,
      id: Date.now(),
      rows: 2,
      x: 0,
      y: 0,
      ...overrides,
    } as Widget;
  }

  static getGridItemWidth() {
    const width = window.innerWidth;

    if (width >= GridItemSizeBreakpoint.xxl) {
      return GridItemSize.xxl;
    }

    if (width >= GridItemSizeBreakpoint.xl) {
      return GridItemSize.xl;
    }

    if (width >= GridItemSizeBreakpoint.lg) {
      return GridItemSize.lg;
    }

    if (width >= GridItemSizeBreakpoint.md) {
      return GridItemSize.md;
    }

    return GridItemSize.sm;
  }

  static getClassBreakpoint(size: number) {
    if (size >= GridItemSize.xxl) {
      return 'gridster-item-xxl';
    }

    if (size >= GridItemSize.xl) {
      return 'gridster-item-xl';
    }

    if (size >= GridItemSize.lg) {
      return 'gridster-item-lg';
    }

    if (size >= GridItemSize.md) {
      return 'gridster-item-md';
    }

    return 'gridster-item-sm';
  }
}

const GridItemSize = {
  lg: 250,
  md: 150,
  sm: 100,
  xl: 350,
  xxl: 500,
};

const GridItemSizeBreakpoint = {
  lg: 1900,
  md: 1000,
  sm: 600,
  xl: 3000,
  xxl: 5000,
};

const WidgetConfigOverrides = {
  dataPoint: {
    cols: 1,
    rows: 1,
  },
  deviceChart: {
    cols: 2,
    rows: 2,
  },
  deviceStats: {
    cols: 2,
    rows: 2,
  },
  devices: {
    cols: 1,
    rows: 1,
  },
  map: {
    cols: 1,
    rows: 1,
  },
  spaceDetails: {
    cols: 2,
    maxItemRows: 1,
    rows: 1,
  },
} as Record<WidgetConfig['type'], Partial<Widget>>;
