<mat-card class="card">
  @if(editor.id === user.id) {
    <app-personal-profile
      [isMobile]="isMobile"
      [user]="editor"
    />
  } @else {
    @if(editor.role.name !== UserRolesTypes.ReadOnly) {
      <div class="test-admin-content">
        <mat-card-title class="card-title-small pb-4">Basic Info</mat-card-title>
        <app-main-input-form
          (updateData)="updateUserData($event, user)"
          [value]="user.firstName"
          controlName="firstName"
          formItemType="text"
          label="First Name"
          placeholder="First Name"
        />
        <app-main-input-form
          (updateData)="updateUserData($event, user)"
          [value]="user.lastName"
          controlName="lastName"
          formItemType="text"
          label="Last Name"
          placeholder="Last Name"
        />

        <div
          class="flex-row pb-6"
          [class.flex]="!isMobile"
        >
          <label class="input-main-label">User Status</label>
          <div
            class="font-bold border py-2 px-3 w-[404px] rounded bg-ui-gray-50"
            [class.w-full]="isMobile"
          >
            <app-user-status
              [status]="user.status"
              fontSize="0.875rem"
            />
          </div>
        </div>

        <div
          class="flex-row pb-6"
          [class.flex]="!isMobile"
          appRestrictedEl
        >
          <label
            class="input-main-label"
            for="status"
          >Client Status</label>
          <div
            class="font-bold border py-2 px-3 w-[404px] rounded bg-ui-gray-50"
            [class.w-full]="isMobile"
          >
            @if((currentClient$ | async); as client) {
              <app-user-status
                id="status"
                [status]="client.status"
                fontSize="0.875rem"
              />
            }
          </div>
        </div>

        <div class="pb-4">
          @if((currentClient$ | async); as client) {
            <div
              class="flex-row"
              [class.flex]="!isMobile"
            >
              <label class="input-main-label">Role:</label>
              <mat-form-field
                class="select-classic"
                [class.w-full]="isMobile"
              >
                <mat-select
                  id="role"
                  (valueChange)="roleUpdated($event, user)"
                  [value]="user.role.id"
                  panelClass="single-select-overlay"
                  placeholder="Select role"
                >
                  @for (role of roleOptions[editor.role.name]; track role.id) {
                    <mat-option
                      [isManagingClient]="client.isManagingClient"
                      [value]="role.id"
                      appSuperAdminRoleFilter
                    >
                      {{ role.name }}
                    </mat-option>
                  }
                </mat-select>
                <mat-icon
                  class="arrow-down"
                  matSuffix
                >keyboard_arrow_down</mat-icon>
                <mat-icon
                  class="arrow-up"
                  matSuffix
                >keyboard_arrow_up</mat-icon>
              </mat-form-field>
            </div>
          }
        </div>

        <mat-card-title class="card-title-small pb-4">Contact Info</mat-card-title>
        <app-main-input-form
          (updateData)="updateUserData($event, user)"
          [disabled]="true"
          [value]="user.email"
          controlName="email"
          formItemType="text"
          label="E-mail"
          placeholder="E-mail"
        />
        <app-main-input-form
          (updateData)="updatePhoneNumber($event, user)"
          [applyPhoneMask]="true"
          [hasPrefix]="true"
          [phoneMask]="phoneMask()"
          [phoneMaskExpression]="phoneMaskExpression()"
          [value]="user.phone.number"
          controlName="phoneNumber"
          formItemType="string"
          label="Phone number"
          placeholder="Phone number"
        >
          <div class="flex justify-between">
            <mat-form-field class="input-classic max-w-[150px] mr-2">
              <mat-select
                (selectionChange)="updateCountryCode($event, user)"
                [value]="user.phone.countryCode || DEFAULT_COUNTRY.code"
              >
                @for (country of COUNTRY_LIST | sort:'asc':'code'; track country.code) {
                  <mat-option [value]="country.code">{{ country.code }} ({{ country.phoneCode }})</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </app-main-input-form>
      </div>

      } @else {
        <div class="test-read-only-content">
          <mat-card-title class="card-title-small pb-4">Basic Info</mat-card-title>
          <div
            class="flex flex-row"
            [class.justify-between]="isMobile"
          >
            <label class="input-main-label">First Name</label>
            <div class="mt-2 font-bold">{{ user.firstName }}</div>
          </div>
          <div
            class="flex flex-row"
            [class.justify-between]="isMobile"
          >
            <label class="input-main-label">Last Name</label>
            <div class="mt-2 font-bold">{{ user.lastName }}</div>
          </div>
          @if(user.status) {
            <div
              class="flex flex-row"
              [class.justify-between]="isMobile"
            >
              <label class="input-main-label">User Status</label>
              <div class="mt-2 font-bold">
                <app-user-status [status]="user.status" />
              </div>
            </div>
          }
          @if(user.role.name) {
            <div
              class="flex flex-row"
              [class.justify-between]="isMobile"
            >
              <label class="input-main-label">Role</label>
              <div class="mt-2 font-bold">{{ RoleNames[user.role.name] }}</div>
            </div>
          }
          <mat-card-title class="card-title-small py-4">Contact Info</mat-card-title>
          <div
            class="flex flex-row"
            [class.justify-between]="isMobile"
          >
            <label class="input-main-label">Email</label>
            <div class="mt-2 font-bold">{{ user.email }}</div>
          </div>
          <div
            class="flex flex-row pb-6"
            [class.justify-between]="isMobile"
          >
            <label class="input-main-label">Phone Number</label>
            <div class="mt-2 font-bold">{{ user.phone.number | mask : '(000) 000-0000' }}</div>
          </div>
        </div>
      }

      <div
        class="flex-row items-center my-6"
        [class.flex]="!isMobile"
        [showForClientAdmin]="true"
        appRestrictedEl
      >
        <mat-card-title
          class="card-title-small w-52 mr-6"
          [class.pb-4]="isMobile"
        >Password</mat-card-title>
        <div
          class="classic-form-item-wrapper justify-between items-center"
          [class.flex]="!isMobile"
          [class.w-full]="isMobile"
        >
          <button
            class="btn-medium"
            (click)="resetPassword(user)"
            [class.w-full]="isMobile"
            [disabled]="resetPasswordLoading"
            [ngClass]="user.status === UserStatus.BLOCKED ? 'btn-red' : 'btn-yellow'"
          >
            @if(resetPasswordLoading) {
              <mat-spinner
                class="mr-1"
                diameter="20"
              />
            }
            RESET PASSWORD
          </button>
          @if(user.status === UserStatus.BLOCKED) {
            <span
              class="w-[190px] font-nunito text-[10px] text-ui-red-100 leading-3"
              [ngClass]="{ 'block text-center w-full text-sm leading-5': isMobile }"
            >The user has reached the maximum permissible number of login attempts.</span>
          }
        </div>
      </div>
      <div>
        <div class="flex items-center">
          <mat-card-title class="card-title-small mr-6 w-52">Notifications</mat-card-title>
          @if(!isMobile) {
            <mat-checkbox
              class="opt-in-checkbox -ml-2.5"
              [checked]="user.phone.smsOptIn"
              disabled="true"
            >
              SMS Opt-in message:
            </mat-checkbox>
          }
        </div>
        <div [ngClass]="isMobile ? 'pt-5' : 'flex flex-row ml-56 mb-2.5 w-[420px] px-2 justify-between'">
          <mat-slide-toggle
            class="test-mail-toggle main-toggle"
            (change)="toggleNotification($event, 'email', user)"
            [checked]="user.notificationsPreferences.emailEnabled"
            [disabled]="editor.role.name === UserRolesTypes.ReadOnly"
            labelPosition="before"
          >
            <span>Email:</span>
            {{ user.notificationsPreferences.emailEnabled ? 'On' : 'Off' }}
          </mat-slide-toggle>
          @if(isMobile) {
            <mat-checkbox
              class="opt-in-checkbox -ml-2.5 mb-6"
              [checked]="user.phone.smsOptIn"
              disabled="true"
            >
              SMS Opt-in message:
            </mat-checkbox>
          }
          <mat-slide-toggle
            class="test-sms-toggle main-toggle"
            (change)="toggleNotification($event, 'sms', user)"
            [checked]="user.notificationsPreferences.smsEnabled"
            [disabled]="editor.role.name === UserRolesTypes.ReadOnly || !user.phone.smsOptIn"
            labelPosition="before"
          >
            <span>SMS:</span>
            {{ user.notificationsPreferences.smsEnabled ? 'On' : 'Off' }}
          </mat-slide-toggle>
        </div>
      </div>
      }
</mat-card>
