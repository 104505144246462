import { AsyncPipe } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCardTitle } from '@angular/material/card';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogConfig,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatOption, MatSelect } from '@angular/material/select';
import { attributeNameValidationPattern, removeSpacesValidator } from '@app-lib';
import { DeviceAttribute, DeviceAttributeType, DeviceModel, SelectOption, UserClient } from '@models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  addNewAttribute,
  addNewDeviceModel,
  AppState,
  DevicesActions,
  getAttributes,
  getDeviceManufacturers,
  getDeviceModels,
  getDeviceTypes,
  getMyClient,
  isDeviceModelCreating,
  isDeviceModelImageUploading,
  isNewAttributeCreating,
  uploadDeviceModelImage,
} from '@ngrx-store';
import { PipesModule } from '@pipes';
import { NewAttributeFormComponent } from '@standalone/new-attribute-form/new-attribute-form.component';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { filter, Observable } from 'rxjs';

import { openAddDeviceTypeDialog } from '../add-device-type-dialog/add-device-type-dialog.component';
import { openAddManufacturerDialog } from '../add-manufacturer-dialog/add-manufacturer-dialog.component';

type DeviceModelDialogProps = {
  selectedDeviceType: string;
  selectedManufacturer: string;
};

@Component({
  imports: [
    MatDialogContent,
    MatFormField,
    MatSelect,
    MatIcon,
    MatError,
    PipesModule,
    ReactiveFormsModule,
    MatDialogClose,
    MatInput,
    MatDialogActions,
    MatOption,
    SearchInputComponent,
    MatSuffix,
    MatProgressSpinner,
    AsyncPipe,
    MatCheckbox,
    MatCardTitle,
    NewAttributeFormComponent,
  ],
  selector: 'avs-li-add-device-model-dialog',
  standalone: true,
  templateUrl: './add-device-model-dialog.component.html',
})
export class AddDeviceModelDialogComponent {
  @ViewChild(NewAttributeFormComponent) newAttributeFormComponent: NewAttributeFormComponent | undefined;
  protected form: FormGroup;
  protected newAttributeForm: FormGroup;
  protected deviceTypeOptions: SelectOption[] = [];
  protected manufacturerOptions: SelectOption[] = [];
  protected manufacturerFilterValue = '';
  protected deviceTypeFilterValue = '';
  protected isDeviceModelCreating$: Observable<boolean | undefined>;
  protected isDeviceModelImageUploading$: Observable<boolean | undefined>;
  protected attributes: DeviceAttribute[] = [];
  protected file: File | undefined;
  protected imagePreviewSrc = '';
  protected isNewAttributeCreating$: Observable<boolean | undefined>;

  private client: UserClient | undefined;
  private deviceModels: DeviceModel[] = [];

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<AddDeviceModelDialogComponent>,
    private dialog: MatDialog,
    private actions$: Actions,
    @Inject(MAT_DIALOG_DATA) public data: DeviceModelDialogProps,
  ) {
    this.isDeviceModelCreating$ = this.store.select(isDeviceModelCreating);
    this.isDeviceModelImageUploading$ = this.store.select(isDeviceModelImageUploading);
    this.isNewAttributeCreating$ = this.store.select(isNewAttributeCreating);

    this.form = this.fb.group({
      attributes: new FormArray([]),
      deviceType: [data.selectedDeviceType, [Validators.required]],
      manufacturer: [data.selectedManufacturer, [Validators.required]],
      name: ['', [Validators.required, removeSpacesValidator]],
    });

    this.store
      .select(getDeviceManufacturers)
      .pipe(
        filter(manufacturers => manufacturers.length > 0),
        takeUntilDestroyed(),
      )
      .subscribe(manufacturers => {
        this.manufacturerOptions = manufacturers.map(({ id, name }) => ({ title: name, value: id }));
      });

    this.store
      .select(getMyClient)
      .pipe(takeUntilDestroyed())
      .subscribe(client => (this.client = client));

    this.store
      .select(getDeviceTypes)
      .pipe(
        filter(deviceTypes => deviceTypes.length > 0),
        takeUntilDestroyed(),
      )
      .subscribe(deviceTypes => {
        this.deviceTypeOptions = deviceTypes.map(({ id, name }) => ({ title: name, value: id }));
      });

    this.store
      .select(getAttributes)
      .pipe(
        filter(attributes => attributes.length > 0),
        takeUntilDestroyed(),
      )
      .subscribe(attributes => {
        this.attributes = attributes.filter(attribute => attribute.attributeType === DeviceAttributeType.REALTIME);
      });

    this.store
      .select(getDeviceModels)
      .pipe(takeUntilDestroyed())
      .subscribe(models => {
        this.deviceModels = models;
      });

    actions$.pipe(ofType(DevicesActions.addNewDeviceModelSuccess), takeUntilDestroyed()).subscribe(action => {
      if (this.file) {
        this.store.dispatch(
          uploadDeviceModelImage({
            clientId: action.deviceModel.ownerClientId,
            deviceMakeId: action.deviceModel.make.id,
            deviceModelId: action.deviceModel.id,
            file: this.file,
          }),
        );
      }
    });

    actions$.pipe(ofType(DevicesActions.uploadDeviceModelImageSuccess), takeUntilDestroyed()).subscribe(() => {
      this.file = undefined;
      this.imagePreviewSrc = '';
    });

    actions$.pipe(ofType(DevicesActions.addNewAttributeSuccess), takeUntilDestroyed()).subscribe(() => {
      this.newAttributeFormComponent?.resetForm();
    });

    this.newAttributeForm = this.fb.group({
      name: ['', [Validators.required, removeSpacesValidator, Validators.pattern(attributeNameValidationPattern)]],
      type: [{ disabled: true, value: DeviceAttributeType.REALTIME }],
    });
  }

  realtimeAttributeChange(event: MatCheckboxChange) {
    const formArray: FormArray = this.form.get('attributes') as FormArray;

    if (event.checked) {
      formArray.push(new FormControl(event.source.value));
    } else {
      formArray.controls.forEach((ctrl, i) => {
        if (ctrl.value === event.source.value) {
          formArray.removeAt(i);

          return;
        }
      });
    }
  }

  onFileChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files?.[0];

    if (file) {
      this.file = file;
      this.imagePreviewSrc = URL.createObjectURL(file);
    }
  }

  deleteFile() {
    this.file = undefined;
    this.imagePreviewSrc = '';
  }

  createModel() {
    if (this.form.valid && this.client) {
      if (this.checkModelNameAlreadyExist()) {
        this.form.controls['name'].setErrors({ alreadyExist: true });

        return;
      }

      this.store.dispatch(
        addNewDeviceModel({
          clientId: this.client.id,
          makeId: this.form.value.manufacturer,
          newDevicdeModelData: {
            deviceType: this.form.value.deviceType,
            name: this.form.value.name,
            standardAttributeIds: this.form.value.attributes,
          },
        }),
      );
    }
  }

  editDeviceType(event: Event) {
    // prevents option selection
    event.stopPropagation();
  }

  editManufacturer(event: Event) {
    // prevents option selection
    event.stopPropagation();
  }

  openAddManufacturerDialog() {
    openAddManufacturerDialog(this.dialog);
  }

  manufacturerSearch(value: string) {
    this.manufacturerFilterValue = value;
  }

  openAddDeviceTypeDialog() {
    openAddDeviceTypeDialog(this.dialog);
  }

  deviceTypeSearch(value: string) {
    this.deviceTypeFilterValue = value;
  }

  createAttribute(data: Pick<DeviceAttribute, 'name' | 'attributeType'>) {
    if (this.client) {
      this.store.dispatch(addNewAttribute({ clientId: this.client.id, data }));
    }
  }

  checkAttributeAlreadyExist() {
    return this.attributes.some(({ name }) => name.toLowerCase() === this.newAttributeForm.value.name.toLowerCase());
  }

  checkModelNameAlreadyExist() {
    return this.deviceModels.some(({ name }) => name.toLowerCase() === this.form.value.name.toLowerCase());
  }
}

export function openAddDeviceModelDialog(dialog: MatDialog, props: DeviceModelDialogProps) {
  const config = new MatDialogConfig();

  config.data = props;
  config.disableClose = true;
  config.panelClass = 'app-dialog';
  config.backdropClass = 'backdrop-modal-panel';
  const dialogRef = dialog.open(AddDeviceModelDialogComponent, config);

  return dialogRef.afterClosed();
}
