import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, input, signal, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import type { Sort } from '@angular/material/sort';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { exportDataToCSV, exportDataToJSON, prepareDataForDownloading } from '@app-lib';
import { signalHelpers } from '@core';
import { DirectivesModule } from '@directives';
import { FixedSizeItemVirtualViewportTableDataSource } from '@layout';
import type { NormalizedDeviceData } from '@models';
import { EntityStatus, IncidentStatus, UserRolesIds } from '@models';
import { Store } from '@ngrx/store';
import { updateDeviceData, userRole } from '@ngrx-store';
import { SessionStorageService } from '@services';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';
import { TableZoomComponent } from '@standalone/_tables/table-zoom/table-zoom.component';
import { EntityStatusComponent } from '@standalone/entity-status/entity-status.component';
import { IncidentStatusCountComponent } from '@standalone/incident-status-count/incident-status-count.component';
import { NoDataComponent } from '@standalone/no-data/no-data.component';
import { SkeletonTableComponent } from '@standalone/skeleton-table/skeleton-table.component';
import type { Observable } from 'rxjs';

import { DeviceService } from '../../services';
import { DevicesStore } from '../../stores';

interface Config {
  isMobile?: boolean;
  isNoDataBigSize?: boolean;
  isReportMode?: boolean;
  showZoomControls?: true;
}

interface Filters {
  query?: string;
  space?: string;
  status?: string[];
}

interface TableItem extends NormalizedDeviceData {
  _index: number;
}

const ROW_HEIGHT = 44;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'flex flex-col',
  },
  imports: [
    CommonModule,
    DirectivesModule,
    EntityStatusComponent,
    IncidentStatusCountComponent,
    MatSortModule,
    MatTableModule,
    MatTooltip,
    NoDataComponent,
    RouterLink,
    ScrollingModule,
    SkeletonTableComponent,
    TableZoomComponent,
  ],
  providers: [DevicesStore],
  selector: 'avs-li-devices-table',
  standalone: true,
  styleUrl: './devices-table.component.scss',
  templateUrl: './devices-table.component.html',
})
export class DevicesTableV2Component implements OnInit {
  config = input<Config>({
    isMobile: false,
    isNoDataBigSize: false,
    isReportMode: false,
    showZoomControls: true,
  });
  displayedColumns = input.required<string[]>();
  filters = input<Filters>();
  tableId = input.required<string>();

  data = computed(() => this.devicesStore.entities());
  isDataLoading = signal(false);
  isReady = signal<boolean>(false);

  protected dataSource = new FixedSizeItemVirtualViewportTableDataSource<TableItem>([]);
  protected devicesStore = inject(DevicesStore);
  protected destroyRef = inject(DestroyRef);
  protected eEntityStatus = EntityStatus;
  protected eIncidentStatus = IncidentStatus;
  protected eUserRolesIds = UserRolesIds;

  protected filtersApplied = computed(() => {
    const { space, status, query, ...filters } = this.filters() ?? {};

    return Object.keys(filters).length > 0 || (query?.length ?? 0) > 0;
  });
  protected TYPICAL_COL_HEIGHT = ROW_HEIGHT;
  protected userRole$: Observable<UserRolesIds>;
  protected viewport = viewChild(CdkVirtualScrollViewport);
  protected zoom = signal<string | null>(null);

  private deviceService = inject(DeviceService);
  private dialog = inject(MatDialog);
  private router = inject(Router);
  private sessionStorageService = inject(SessionStorageService);
  private store = inject(Store);

  constructor() {
    this.dataSource.itemSize = ROW_HEIGHT;
    this.userRole$ = this.store.select(userRole);

    signalHelpers.untrackedEffect([this.devicesStore.sort, this.filters], async (sort, filters) => {
      this.devicesStore.clear();
      await this.loadDevices(sort, filters);
    }, { skipFirst: true });

    effect(() => {
      this.dataSource.data = this.devicesStore.entities();
    }, { allowSignalWrites: true });

    this.dataSource.thresholdReached$
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.loadDevices(this.devicesStore.sort(), this.filters()));
  }

  export(format: 'json' | 'csv') {
    const filters = this.filters();
    const sort = this.devicesStore.sort();

    this.deviceService
      .list({
        filters,
        query: filters?.query,
        sort: sort?.direction ? sort : undefined,
      })
      .subscribe(({ data }) => {
        const fileName = 'devices report';
        const columns = this.displayedColumns()
          .filter(column => column !== 'actions' && column !== 'status')
          .map(column => (column === 'index' ? 'id' : column));
        const reportData = prepareDataForDownloading(columns, data);

        if (format === 'csv') {
          exportDataToCSV(reportData, fileName);
        } else {
          exportDataToJSON(reportData, fileName);
        }
      });
  }

  ngOnInit() {
    this.dataSource.viewport = this.viewport();
  }

  protected archiveDevice(device: NormalizedDeviceData) {
    this.openConfirmationDialog({
      data: { status: EntityStatus.Archived },
      description: `Are you sure you want to add ${device.deviceName} to archive?`,
      deviceId: device.id,
      locationId: device.buildingId,
      title: 'Add to Archive',
    });
  }

  protected deleteDevicePermanently(id: string) {
    // TODO(serhiisol): add implementation later
    console.log(`device with id: ${id} was permanently deleted`);
  }

  protected async goToDevice(device: NormalizedDeviceData) {
    if (this.config().isReportMode) {
      return;
    }

    const url = ['/devices', device.buildingId, device.id];

    if (this.tableId) {
      this.sessionStorageService.saveLastSelectedItems(this.tableId(), { title: device.deviceName, url });
    }

    await this.router.navigate(url);
  }

  protected pauseDevice(device: NormalizedDeviceData) {
    this.openConfirmationDialog({
      data: { status: EntityStatus.Paused },
      description: `Are you sure you want to pause ${device.deviceName} ?`,
      deviceId: device.id,
      locationId: device.buildingId,
      title: 'Pause',
    });
  }

  protected placeholderWhen(index: number, _: any) {
    return index === 0;
  }

  protected restoreDevice(device: NormalizedDeviceData) {
    this.openConfirmationDialog({
      data: { status: EntityStatus.Active },
      description: `Are you sure you want to restore ${device.deviceName} from archive?`,
      deviceId: device.id,
      locationId: device.buildingId,
      title: 'Restore',
    });
  }

  protected resumeDevice(device: NormalizedDeviceData) {
    this.openConfirmationDialog({
      data: { status: EntityStatus.Active },
      description: `Are you sure you want to resume ${device.deviceName} ?`,
      deviceId: device.id,
      locationId: device.buildingId,
      title: 'Resume',
    });
  }

  private async loadDevices(sort?: Sort | null, filters?: Filters) {
    this.isDataLoading.set(true);

    await this.devicesStore.loadDevices({
      filters,
      query: filters?.query,
      sort: sort?.direction ? sort : undefined,
    });

    this.isDataLoading.set(false);
    this.isReady.set(true);
  }

  private openConfirmationDialog({
    title,
    description,
    locationId,
    deviceId,
    data,
  }: {
    data: { [_: string]: string; };
    description: string;
    deviceId: string;
    locationId: string;
    title: string;
  }) {
    ConfirmationDialogComponent.open(this.dialog, {
      description,
      title,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation && locationId && deviceId && data) {
          const shouldBeRemoved = !(this.filters()?.status || []).includes(data['status']);

          this.devicesStore.updateStatus(deviceId, data['status'] as EntityStatus);
          if (shouldBeRemoved) {
            this.devicesStore.removeDevice(deviceId);
          }

          // TODO: legacy action, to be removed someday later
          this.store.dispatch(updateDeviceData({ data, deviceId, locationId }));
        }
      });
  }
}
