import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@directives';
import { UsersTableComponent } from '@standalone/_tables/users-table/users-table.component';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';

@Component({
  imports: [UsersTableComponent, MatCardModule, MatIconModule, DirectivesModule, PageHeaderComponent, RouterModule],
  selector: 'avs-fe-user-management-main-page',
  standalone: true,
  templateUrl: './user-management-main-page.component.html',
})
export class UserManagementMainPageComponent {}
