import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, Injector, input, OnInit, output, runInInjectionContext, signal } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIcon } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { EntityStatus } from '@models';
import { PipesModule } from '@pipes';
import { TableSearchInputComponent } from '@standalone/_tables/table-search-input/table-search-input.component';
import { MultipleSelectComponent } from '@standalone/multiple-select/multiple-select.component';

import { FiltersStore } from '../../stores';

interface Config {
  columnSelectOptions?: { title: string; value: string; }[];
  deviceTypeFilter?: boolean;
  filtersEnabled?: boolean;
  hide?: boolean;
  locationFilter?: boolean;
  manufacturerFilter?: boolean;
  reportMode?: boolean;
}

@Component({
  imports: [
    CommonModule,
    MatButtonToggleModule,
    MatIcon,
    MatSelectModule,
    MatTooltip,
    MultipleSelectComponent,
    PipesModule,
    TableSearchInputComponent,
  ],
  providers: [FiltersStore],
  selector: 'avs-li-devices-filters',
  standalone: true,
  templateUrl: './devices-filters.component.html',
})
export class DevicesFiltersV2Component implements OnInit {
  columns = input<string[]>([]);
  columnsChanged = output<string[]>();
  config = input.required<Config>();
  filters = input<Record<string, string>>();
  filtersChanged = output<Record<string, string | string[]>>();
  tableId = input.required<string>();

  protected eEntityStatus = EntityStatus;
  protected filtersEnabled = computed(() => {
    if (this.filtersStore.status() === EntityStatus.Archived) {
      return false;
    }

    return this.filtersStore.hasSelectedFilters() || this.filtersStore.query().length > 0 || this.config().filtersEnabled!;
  });
  protected filtersStore = inject(FiltersStore);
  protected queryFilterEnabled = computed(() => this.filtersStore.status() !== EntityStatus.Archived);
  protected selectedColumns = signal<string[]>([]);

  private injector = inject(Injector);

  async ngOnInit() {
    const filters = this.filters() ?? {};
    const floor = filters['floor'];
    const room = filters['room'];

    this.filtersStore.setTableId(this.tableId());
    this.filtersStore.initDefaultFilters({
      ...floor && { floor },
      ...room && { room },
    });
    this.selectedColumns.set(this.columns());

    await this.filtersStore.fetchOptions(room ? { space: room } : undefined);

    runInInjectionContext(this.injector, () => {
      effect(() => this.columnsChanged.emit(this.selectedColumns()));
      effect(() => this.filtersChanged.emit(this.filtersStore.appliedFilters()));
    });
  }

  protected selectAllColumns() {
    this.selectedColumns.set(
      this.config().columnSelectOptions!.map(({ value }) => value),
    );
  }
}
