import { Component, effect, inject, input, output, signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { DeviceAdditionalAttribute } from '@models';
import { DeviceService } from '@services';
import { map } from 'rxjs';

@Component({
  imports: [
    MatIcon,
    MatSelectModule,
  ],
  selector: 'avs-li-data-point-selector',
  standalone: true,
  templateUrl: './device-data-point-selector.component.html',
})

export class DeviceDataPointSelectorComponent {
  change = output<string>();
  deviceId = input.required<string>();
  locationId = input.required<string>();
  value = input<string>();
  textFilter = input<string>();

  protected deviceService = inject(DeviceService);
  protected dataPoints = signal<DeviceAdditionalAttribute[]>([]);

  constructor() {
    effect(() => {
      this.deviceService.getDeviceDetailsByDeviceId(this.locationId(), this.deviceId())
        .pipe(
          map(device => device.additionalAttributes),
          map(additionalAttributes =>
            additionalAttributes?.filter(
              attribute =>
                this.textFilter() ? attribute.name.toLowerCase().includes(this.textFilter()!.toLowerCase()) : additionalAttributes)),
        )
        .subscribe((additionalAttributes) => {
          if (additionalAttributes) {
            this.dataPoints.set(additionalAttributes);
          }
        });
    });
  }
}
