import { Component, effect, inject, input, output, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { Space, SpaceType } from '@models';
import { AppService, LocationService } from '@services';

@Component({
  imports: [
    MatSelectModule,
    MatIconModule,
  ],
  selector: 'avs-li-space-selector',
  standalone: true,
  templateUrl: './space-selector.component.html',
})
export class SpaceSelectorComponent {
  change = output<string>();
  location = input.required<string>();
  parent = input<string>();
  type = input.required<SpaceType>();
  value = input<string>();

  protected appService = inject(AppService);
  protected eSpaceType = SpaceType;
  protected locationService = inject(LocationService);
  protected spaces = signal<Space[]>([]);

  constructor() {
    effect(() => {
      const options = {
        includeNestedDevices: 'true',
        type: this.type(),
        ...this.parent() && { parentId: this.parent() },
      };

      this.locationService.getSpacesList(this.location(), options)
        .subscribe(locations => this.spaces.set(locations));
    });
  }
}
