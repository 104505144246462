<h1 class="dialog-title">
  <span>Add new device type</span>
  <button mat-dialog-close>
    <i class="icon-close"></i>
  </button>
</h1>
<form
  (ngSubmit)="addDeviceType()"
  [formGroup]="form"
  #ngForm="ngForm"
>
  <mat-dialog-content class="dialog-content">
    <div class="flex flex-row">
      <label
        class="input-main-label"
        for="deviceType"
      >Device type name:*</label>
      <mat-form-field class="input-main">
        <input
          id="deviceType"
          type="text"
          formControlName="deviceType"
          matInput
          placeholder="Enter device type"
        />
        @if (form.get('deviceType')?.hasError('required')) {
          <mat-error class="test-required-error">Input is not valid</mat-error>
        }
        @if (form.get('deviceType')?.hasError('alreadyExist')) {
          <mat-error class="test-already-exist-error">Value already exist</mat-error>
        }
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    class="dialog-actions"
    align="end"
  >
    <button
      class="test-btn-cancel btn-transparent btn-medium mr-4 font-bold"
      mat-dialog-close
    >CANCEL</button>
    <button
      type="submit"
      class="test-submit-btn btn-green btn-medium font-bold"
      [disabled]="isLoading$ | async"
    >
      @if (isLoading$ | async) {
        <mat-spinner
          class="mr-1"
          diameter="20"
        ></mat-spinner>
      }
      SAVE
    </button>
  </mat-dialog-actions>
</form>
