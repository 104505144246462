import type { Signal } from '@angular/core';
import { effect, untracked } from '@angular/core';

type UntrackedSignals<T> = { [K in keyof T]: Signal<T[K]>; };
type UntrackedValues<T> = { [K in keyof T]: T[K]; };

export function untrackedEffect<T extends any[]>(
  sources: UntrackedSignals<T>,
  action: (...value: UntrackedValues<T>) => void,
  options?: { skipFirst?: boolean; },
) {
  let skipFirst = options?.skipFirst ?? false;

  effect(() => {
    const s = sources.map(s => s()) as UntrackedValues<T>;

    if (!skipFirst) {
      untracked(() => action(...s));
    } else {
      skipFirst = false;
    }
  });
}
