import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, signal } from '@angular/core';
import { IncidentStatus, LocationData, Space } from '@models';
import { AppService, LocationService } from '@services';
import { DeviceStatusCountComponent } from '@standalone/device-status-count/device-status-count.component';
import { EntityStatusComponent } from '@standalone/entity-status/entity-status.component';
import { IncidentStatusCountComponent } from '@standalone/incident-status-count/incident-status-count.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { WidgetSpaceDetails } from '../../../../models';

@Component({
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
    EntityStatusComponent,
    IncidentStatusCountComponent,
    DeviceStatusCountComponent,
  ],
  selector: 'avs-li-widget-space-details',
  standalone: true,
  styleUrl: './widget-space-details.component.scss',
  templateUrl: './widget-space-details.component.html',
})
export class WidgetSpaceDetailsComponent {
  config = input.required<WidgetSpaceDetails>();
  protected readonly IncidentStatus = IncidentStatus;
  protected space = signal<LocationData | Space | null>(null);
  private appService = inject(AppService);
  private locationService = inject(LocationService);

  constructor() {
    effect(() => {
      this.space.set(null);
      const spaceId = this.config().roomId || this.config().floorId;

      if (spaceId) {
        this.locationService.getSpaceInfoById(this.appService.currentClient, this.config().locationId, spaceId)
          .subscribe(space => this.space.set(space));
      } else {
        this.locationService.getLocationInfoById(this.appService.currentClient, this.config().locationId)
          .subscribe(location => this.space.set(location));
      }
    }, { allowSignalWrites: true });
  }
}
