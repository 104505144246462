import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, input, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppService, LocationService } from '@services';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { forkJoin, of } from 'rxjs';

type Config = {
  floorId?: string;
  locationId: string;
  roomId?: string;
}

@Component({
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
    RouterModule,
  ],
  selector: 'avs-li-space-location',
  standalone: true,
  templateUrl: './space-location.component.html',
})
export class SpaceLocationComponent {
  config = input.required<Config>();
  protected appService = inject(AppService);
  protected isLoading = signal<boolean>(false);
  protected locationService = inject(LocationService);
  protected title = signal<string>('');
  protected url = computed(() => {
    if (this.config().roomId) {
      return ['/dashboard', this.config().locationId, this.config().floorId, this.config().roomId];
    }

    if (this.config().floorId && !this.config().roomId) {
      return ['/dashboard', this.config().locationId, this.config().floorId];
    }

    return ['/dashboard', this.config().locationId];
  });

  constructor() {
    effect(() => {
      this.fetchTitleInfo(this.config());
    }, { allowSignalWrites: true });
  }

  private fetchTitleInfo(config: Config) {
    const client = this.appService.currentClient;

    this.isLoading.set(true);

    const location$ = this.locationService.getLocationInfoById(client, config.locationId);
    const floor$ = config.floorId
      ? this.locationService.getSpaceInfoById(client, config.locationId, config.floorId)
      : of(null);
    const room$ = config.roomId
      ? this.locationService.getSpaceInfoById(client, config.locationId, config.roomId)
      : of(null);

    forkJoin({ floor: floor$, location: location$, room: room$ }).subscribe(({ location, floor, room }) => {
      const title = [location?.friendlyName, floor?.friendlyName, room?.friendlyName]
        .filter(name => !!name)
        .join(', ');

      this.isLoading.set(false);
      this.title.set(title);
    });
  }
}
