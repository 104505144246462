@if (isLoading()) {
  <ngx-skeleton-loader [theme]="{ height: '200px' }" />
} @else {
  <highcharts-chart
    class="h-full w-full block"
    (chartInstance)="chartRef.set($event)"
    [Highcharts]="Highcharts"
    [oneToOne]="true"
    [options]="highchartOptions()!"
  />
}
