<form
  [formGroup]="form"
  class="pt-5"
>
  <mat-card-title class="card-title pb-4">Building Info</mat-card-title>
  <div class="flex">
    <label class="input-main-label">Building name*:</label>
    <mat-form-field class="input-classic">
      <input
        formControlName="friendlyName"
        matInput
        placeholder="Enter building name"
        type="text"
      />
      <mat-error>Input is not valid</mat-error>
    </mat-form-field>
  </div>
  <div class="flex">
    <label class="input-main-label">Client building ID:</label>
    <mat-form-field class="input-classic">
      <input
        formControlName="clientFriendlyId"
        matInput
        placeholder="Enter client building ID"
        type="text"
      />
      <mat-error *ngIf="form.get('clientFriendlyId')?.hasError('required')">Input is not valid</mat-error>
      <mat-error *ngIf="form.get('clientFriendlyId')?.hasError('alreadyExist')">Value already exist</mat-error>
    </mat-form-field>
  </div>
  <div
    class="flex"
    formGroupName="tags"
  >
    <label class="input-main-label">Ticketing building ID:</label>
    <mat-form-field class="input-classic">
      <input
        formControlName="ticketingBuildingId"
        matInput
        placeholder="Enter ticketing building ID"
        type="text"
      />
      <mat-error>Input is not valid</mat-error>
    </mat-form-field>
  </div>
  <!--  Address -->
  <mat-card-title class="card-title pb-4">Address</mat-card-title>

  <div formGroupName="address">
    <div class="flex">
      <label class="input-main-label">Country:*</label>
      <mat-form-field class="select-classic has-validation">
        <mat-select
          formControlName="country"
          placeholder="Select country"
          panelClass="single-select-overlay"
        >
          <mat-option
            *ngFor="let country of countryList | sort : 'asc' : 'name'"
            [value]="country.code"
          >
            {{ country.name }}
          </mat-option>
        </mat-select>
        <mat-icon
          matSuffix
          class="arrow-down"
        >keyboard_arrow_down</mat-icon>
        <mat-icon
          matSuffix
          class="arrow-up"
        >keyboard_arrow_up</mat-icon>
        <mat-error>Input is not valid</mat-error>
      </mat-form-field>
    </div>
    <app-google-place-autocomplete
      (addressChanged)="updateAddress($event)"
      class="w-full"
      [restrictionArea]="[form.get('address.country')?.value]"
    />
    @if (form.controls["geographic"].valid) {
      <div class="flex mt-5">
        <label class="input-main-label">State:*</label>
        <mat-form-field class="input-classic">
          <input
            formControlName="stateProvince"
            matInput
            placeholder="State name"
            type="text"
          />
          <mat-error>Input is not valid</mat-error>
        </mat-form-field>
      </div>
      <div class="flex">
        <label class="input-main-label">City:*</label>
        <mat-form-field class="input-classic">
          <input
            formControlName="city"
            matInput
            placeholder="City"
            type="text"
          />
          <mat-error>Input is not valid</mat-error>
        </mat-form-field>
      </div>
      <div class="flex">
        <label class="input-main-label">Street line:*</label>
        <mat-form-field class="input-classic">
          <input
            formControlName="streetAddress"
            matInput
            placeholder="Street line"
            type="text"
          />
          <mat-error>Input is not valid</mat-error>
        </mat-form-field>
      </div>
      <div class="flex">
        <label class="input-main-label">ZIP code:*</label>
        <mat-form-field class="input-classic">
          <input
            formControlName="postalCode"
            matInput
            placeholder="ZIP code"
            type="text"
          />
          <mat-error>Input is not valid</mat-error>
        </mat-form-field>
      </div>
    }
  </div>
  @if (form.controls["geographic"].valid) {
    <div formGroupName="geographic">
      <div class="flex mb-5">
        <label class="input-main-label">Latitude:*</label>
        <div class="input-classic-simulation px-3">
          {{ form.get('geographic.lat')?.value }}
        </div>
      </div>
      <div class="flex">
        <label class="input-main-label">Longitude:*</label>
        <div class="input-classic-simulation px-3">
          {{ form.get('geographic.long')?.value }}
        </div>
      </div>
    </div>
  }
</form>
