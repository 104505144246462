<div *ngIf="locationId && floor; else loading">
  <a
    [routerLink]="['/dashboard', locationId, floor.id]"
    class="inline-flex items-center font-nunito text-ui-dark text-sm font-semibold"
  >
    <mat-icon>chevron_left</mat-icon>
    Back to {{ floor.friendlyName }}
  </a>
  <app-page-header>Edit Floor</app-page-header>
  <mat-card class="p-6 mt-3 shadow-card">
    <mat-card-content class="p-0">
      <app-main-input-form
        label="Floor friendly name:"
        controlName="friendlyName"
        formItemType="text"
        placeholder="Floor friendly name"
        [value]="floor.friendlyName"
        (updateData)="onNameChange($event)"
      />
      <hr class="mb-6 border-ui-gray-light" />
      <app-upload-spaceplan
        *ngIf="!floor.documentReference && floor.id"
        uploadText="Upload Floor Plan"
        [locationId]="locationId"
        [floorId]="floor.id"
      />
      <app-spaceplan
        *ngIf="floor.documentReference"
        [spaceplan]="{
          id: floor.id,
          documentReference: floor.documentReference,
          attachments: floor.rooms || [],
          parentSpaceId: floor.parentSpaceId
        }"
        [locationId]="locationId"
        [popover]="editRoomPopover"
        [canEdit]="true"
      />
    </mat-card-content>
  </mat-card>

  <mat-card class="p-6 mt-3 shadow-card">
    <mat-card-content class="p-0">
      <div class="">
        <avs-fe-handle-rooms
          [floor]="floor"
          [locationId]="locationId"
        ></avs-fe-handle-rooms>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #loading>
  <div class="text-center">
    <mat-spinner
      class="mx-auto"
      color="primary"
    >LOADING</mat-spinner>
  </div>
</ng-template>
