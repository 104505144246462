<mat-form-field
  class="{{ selectClass }}"
  [ngClass]="value && value.length ? 'has-selected-value' : ''"
  #wrapper
>
  <mat-label>
    @if (showLabelPlaceholder ? true : !(value && value.length)) {
      <span>{{ label }}</span>
    }
    {{ showSelectedValues ? labelDetails : '' }}
  </mat-label>
  <mat-select
    (selectionChange)="selectionChange($event)"
    [class]="selectElementClass"
    [panelClass]="cssPanelClass"
    [value]="value"
    #selectRef
    multiple
  >
    <div class="options-action-wrapper">
      <button
        class="options-action-button test-select-all"
        (click)="selectAll()"
      >Select All</button>
      <button
        class="options-action-button test-clear-all"
        (click)="clearAll()"
      >Clear All</button>
    </div>
    @for (option of sortingEnabled ? (options | sort : 'asc' : 'title') : options; track $index) {
      <mat-option
        [attr.data-value]="option.value"
        [value]="option.value"
      >{{ option.title }}</mat-option>
    }
  </mat-select>
  <mat-icon
    class="arrow-down"
    matSuffix
  >keyboard_arrow_down</mat-icon>
  <mat-icon
    class="arrow-up"
    matSuffix
  >keyboard_arrow_up</mat-icon>
</mat-form-field>
