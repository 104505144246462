import { inject, Pipe, PipeTransform } from '@angular/core';
import { ENVIRONMENT } from '@env';

@Pipe({
  standalone: true,
  name: 'isFeatureEnabled',
})
export class IsFeatureEnabledPipe implements PipeTransform {
  private environment = inject<{ featureToggles: Record<string, boolean> }>(ENVIRONMENT);

  transform(feature: string) {
    const featureToggles = (this.environment.featureToggles ?? {}) as Record<string, boolean>;

    return featureToggles[feature] === true;
  }
}
