<div class="flex justify-between items-center mb-4">
  <app-page-header>
    <div class="leading-10">Devices</div>
  </app-page-header>
  <div class="flex">
    <a
      class="btn-white-outlined btn-medium mr-3"
      appRestrictedEl
      routerLink="/devices/device-management"
    >
      <i class="icon-settings text-3xl mr-2"></i>
      <span class="text-ui-dark">Device Management</span>
    </a>
    <app-export-report-menu
      class="mr-3"
      (exportDataType)="exportDevices($event)"
    />
    <a
      class="btn-yellow btn-medium"
      appRestrictedEl
      routerLink="/devices/add-device"
    >
      <mat-icon>add</mat-icon>
      Add Device
    </a>
  </div>
</div>

<mat-card class="card pt-0 pb-2">
  <mat-card-content class="card-content p-0">
    <avs-li-devices-filters
      (columnsChanged)="columnsChanged($event)"
      (filtersChanged)="filters.set($event)"
      [class.hidden]="!devicesTable.isReady()"
      [columns]="displayedColumns"
      [config]="{
        columnSelectOptions: columnOptions,
        deviceTypeFilter: true,
        filtersEnabled: devicesTable.data().length > 0 || devicesTable.isDataLoading(),
        locationFilter: true,
        manufacturerFilter: true,
      }"
      [tableId]="TABLE_ID"
    />

    <avs-li-devices-table
      class="overflow-hidden"
      [config]="{
        isNoDataBigSize: true,
        showZoomControls: true,
      }"
      [displayedColumns]="displayedColumns"
      [filters]="filters()!"
      [tableId]="TABLE_ID"
      #devicesTable
    />
  </mat-card-content>
</mat-card>
