<avs-li-location-options
  (change)="patchLocationForm($event)"
  [config]="{ locationId: form().locationId }"
/>

@if (form().locationId) {
  <div class="flex flex-row">
    <label class="input-main-label">
      Floor {{ allRequired() ? '*' : '' }}
    </label>

    <avs-li-space-selector
      (change)="patchForm({ floorId: $event, roomId: undefined })"
      [location]="form().locationId!"
      [type]="eSpaceType.floor"
      [value]="form().floorId"
    />
  </div>
}

@if (form().floorId) {
  <div class="flex flex-row">
    <label class="input-main-label">
      Room {{ allRequired() ? '*' : '' }}
    </label>

    <avs-li-space-selector
      (change)="patchForm({ roomId: $event })"
      [location]="form().locationId!"
      [parent]="form().floorId"
      [type]="eSpaceType.room"
      [value]="form().roomId"
    />
  </div>
}
