<form [formGroup]="form">
  <avs-li-location-options
    (change)="patchForm('locationId', $event.locationId!)"
    [config]="{ locationId: form.value?.locationId }"
  />

  @if (form.get('locationId')?.value; as locationId) {
    <div class="flex flex-row">
      <label class="input-main-label capitalize">
        Data type *
      </label>
      <mat-form-field class="select-classic has-validation">
        <mat-select
          (valueChange)="createDisplayOptions($event)"
          [value]="form.get('dataType')?.value"
          panelClass="single-select-overlay"
          placeholder="Select type"
        >
          @for (type of dataTypes; track type) {
            <mat-option [value]="type.value">
              {{ type.title | titlecase }}
            </mat-option>
          }
        </mat-select>
        <mat-icon
          class="arrow-down"
          matSuffix
        >
          keyboard_arrow_down
        </mat-icon>
        <mat-icon
          class="arrow-up"
          matSuffix
        >
          keyboard_arrow_up
        </mat-icon>
        <mat-error>Input is not valid</mat-error>
      </mat-form-field>
    </div>

    @if (form.get('dataType')?.value) {
      <div class="flex flex-row">
        <label class="input-main-label capitalize">
          Devices *
        </label>

        <avs-li-device-selector
          (change)="patchForm('deviceId', $event); patchForm('datapointName', '')"
          [filters]="{
            dataPoint: form.value?.dataType,
            location: form.value?.locationId
          }"
          [value]="form.get('deviceId')?.value"
        />
      </div>
    }

    @if (form.get('deviceId')?.value) {
      <div class="flex flex-row">
        <label class="input-main-label capitalize">
          Data Point *
        </label>
        <avs-li-data-point-selector
          (change)="patchForm('datapointName',$event)"
          [deviceId]="form.get('deviceId')?.value"
          [locationId]="locationId"
          [textFilter]="form.value?.dataType"
          [value]="form.get('datapointName')?.value"
        />
      </div>

      @if (form.get('datapointName')?.value) {
        <div class="flex flex-row">
          <label class="input-main-label capitalize">
            Period *
          </label>
          <avs-li-date-range-selector
            (change)="patchForm('dateRange',$event)"
            [value]="form.get('dateRange')?.value"
          />
        </div>

        <div
          class="flex"
          formGroupName="displayOptions"
        >
          <div class="flex">
            <label class="input-main-label capitalize pt-2">
              Displayed parameters
            </label>
          </div>
          <div class="grid grid-cols-2">
            @for (option of selectedOptionList; track option) {
              <div>
                <label class="input-main-label capitalize flex items-center">
                  <mat-checkbox
                    (change)="change.emit(form.valid ? form.value : null)"
                    [formControlName]="option"
                  />
                  {{ option | titlecase }}
                </label>
              </div>
            }
          </div>
        </div>
      }
    }
  }
</form>
