import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, FormGroupDirective, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogConfig,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { capitalize, removeSpacesValidator } from '@app-lib';
import { SelectOption, UserClient } from '@models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { addNewDeviceType, AppState, DevicesActions, getDeviceTypes, getMyClient, isDeviceTypeCreating, isManufacturerCreating } from '@ngrx-store';
import { filter, Observable } from 'rxjs';

@Component({
  imports: [
    MatDialogClose,
    MatDialogContent,
    MatFormField,
    MatError,
    MatDialogActions,
    ReactiveFormsModule,
    MatInput,
    MatProgressSpinnerModule,
    CommonModule,
  ],
  selector: 'avs-li-add-device-type-dialog',
  standalone: true,
  templateUrl: './add-device-type-dialog.component.html',
})
export class AddDeviceTypeDialogComponent {
  @ViewChild('ngForm') ngForm: FormGroupDirective | undefined;
  public form: FormGroup;
  public deviceTypeOptions: SelectOption[] = [];
  protected isLoading$: Observable<boolean | undefined>;
  private client: UserClient | undefined;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private actions$: Actions,
    public dialogRef: MatDialogRef<AddDeviceTypeDialogComponent>,
  ) {
    this.form = this.fb.group({
      deviceType: ['', [Validators.required, removeSpacesValidator]],
    });

    this.store
      .select(getMyClient)
      .pipe(takeUntilDestroyed())
      .subscribe(client => (this.client = client));

    this.store
      .select(getDeviceTypes)
      .pipe(
        filter(deviceTypes => deviceTypes.length > 0),
        takeUntilDestroyed(),
      )
      .subscribe(deviceTypes => {
        this.deviceTypeOptions = deviceTypes.map(({ id, name }) => ({ title: name, value: id }));
      });

    this.isLoading$ = this.store.select(isDeviceTypeCreating);

    actions$.pipe(ofType(DevicesActions.addNewDeviceTypeSuccess), takeUntilDestroyed()).subscribe(() => {
      this.ngForm?.resetForm();
    });
  }

  addDeviceType() {
    if (this.form.valid && this.client) {
      if (this.checkDeviceTypeAlreadyExist()) {
        this.form.controls['deviceType'].setErrors({ alreadyExist: true });

        return;
      }

      this.store.dispatch(
        addNewDeviceType({
          clientId: this.client.id,
          newDeviceTypeData: { name: capitalize(this.form.value.deviceType) },
        }),
      );
    } else {
      this.form.markAsTouched();
    }
  }

  checkDeviceTypeAlreadyExist() {
    return this.deviceTypeOptions.some(({ title }) => title.toLowerCase() === this.form.value.deviceType.toLowerCase());
  }
}

export function openAddDeviceTypeDialog(dialog: MatDialog) {
  const config = new MatDialogConfig();

  config.disableClose = true;
  config.autoFocus = true;
  config.panelClass = 'app-dialog';
  config.backdropClass = 'backdrop-modal-panel';
  const dialogRef = dialog.open(AddDeviceTypeDialogComponent, config);

  return dialogRef.afterClosed();
}
