<mat-form-field class="select-classic mb-[22px]">
  <mat-select
    (valueChange)="change.emit($event)"
    [value]="value()"
    panelClass="single-select-overlay"
    placeholder="Select data point"
  >
    @for (dataPoint of dataPoints(); track dataPoint.name) {
      <mat-option [value]="dataPoint.name">
        {{ dataPoint.friendlyName || dataPoint.name }}
      </mat-option>
    }
  </mat-select>
  <mat-icon
    class="arrow-down"
    matSuffix
  >keyboard_arrow_down</mat-icon>
  <mat-icon
    class="arrow-up"
    matSuffix
  >keyboard_arrow_up</mat-icon>
</mat-form-field>
