import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-bottom-sheet-mobile-header',
  standalone: true,
  template: ` <div class="relative flex justify-end px-2 min-h-9">
    <span class="absolute t-0 left-1/2 transform -translate-x-1/2 w-[55px] h-2 rounded-full bg-ui-gray-650"></span>
    @if(showCloseButton) {
    <button (click)="closeButtonClick()" class="flex items-center justify-center w-9 h-9">
      <i class="icon-close text-ui-gray-700 text-[28px]"></i>
    </button>
    }
  </div>`,
})
export class BottomSheetMobileHeaderComponent {
  @Input() showCloseButton = true;
  @Output() closeEvent = new EventEmitter();

  closeButtonClick() {
    this.closeEvent.emit('close');
  }
}
