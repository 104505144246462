import { TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, computed, effect, inject, input, OnInit } from '@angular/core';
import { dateHelpers } from '@core';
import { CURRENT_CLIENT_TEMPLATE_VAR } from '@models';
import { DeviceService } from '@services';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { finalize, map } from 'rxjs';

import { StatsSupportedTypes, WidgetDeviceStats } from '../../../../models';

const TOTAL_THRESHOLD = 1000;
const KWH = 'kWh';
const WH = 'Wh';
const KELVIN = 'K';

enum EnergyConsumptionEntry {
  average,
  max,
  total,
  min,
}

@Component({
  imports: [
    HighchartsChartModule,
    TitleCasePipe,
    NgxSkeletonLoaderModule,
  ],
  selector: 'avs-li-widget-device-stats',
  standalone: true,
  styleUrls: ['./device-stats.component.scss'],
  templateUrl: './device-stats.component.html',
})
export class WidgetDeviceStatsComponent implements OnInit {
  config = input.required<WidgetDeviceStats>();
  protected displayedItemsCount = computed(() => Object.values(this.config().displayOptions).filter(item => item).length);
  protected readonly StatsSupportedTypes = StatsSupportedTypes;
  protected readonly KELVIN = KELVIN;
  protected displayedPeriod = '';
  protected energyConsumption: {
    measurements: typeof KELVIN | typeof KWH | typeof WH;
    name: string;
    value: number;
  }[] = [
      {
        measurements: 'Wh',
        name: 'average',
        value: 0,
      },
      {
        measurements: 'Wh',
        name: 'max',
        value: 0,
      },
      {
        measurements: 'Wh',
        name: 'total',
        value: 0,
      },
      {
        measurements: 'Wh',
        name: 'min',
        value: 0,
      },
    ];
  protected isLoading = false;
  protected hasErrors = false;
  protected timeseriesData!: { timeStamp: string; value: number; }[];
  private deviceService = inject(DeviceService);
  private cdr = inject(ChangeDetectorRef);

  constructor() {
    effect(() => {
      this.getDatapointData();
    });
  }

  ngOnInit(): void {
    this.getDatapointData();
  }

  private getDatapointData() {
    this.isLoading = true;
    this.deviceService
      .getChartsData(this.buildRequestPayload())
      .pipe(
        finalize(() => this.isLoading = false),
        map(charts => charts[0]),
      )
      .subscribe({
        error: () => this.hasErrors = true,
        next: timeseriesData => {
          this.timeseriesData = timeseriesData.timeseries;
          this.isLoading = false;
          this.calculateEnergyConsumption();
          this.cdr.detectChanges();
        },
      });
  }

  private buildRequestPayload() {
    const {
      fromDateTimeUtc,
      toDateTimeUtc,
    } = dateHelpers.getDateRangeByPeriodName(this.config().dateRange);

    return {
      clientId: CURRENT_CLIENT_TEMPLATE_VAR,
      data: {
        fromDateTimeUtc,
        points: [
          {
            name: this.config().datapointName,
          },
        ],
        toDateTimeUtc,
      },
      deviceId: this.config().deviceId,
    };
  }

  private wattsToKwh(value: number) {
    return this.roundToTwoDecimals(value / (value > TOTAL_THRESHOLD ? TOTAL_THRESHOLD : 1));
  }

  private roundToTwoDecimals(value: number): number {
    return Math.round(value * 100) / 100;
  }

  private calculateEnergyConsumption() {
    if (!this.timeseriesData) {
      return;
    }

    const stats = this.computeStatistics(this.timeseriesData);

    this.energyConsumption[EnergyConsumptionEntry.total].value = this.wattsToKwh(stats.total);
    this.energyConsumption[EnergyConsumptionEntry.max].value = this.wattsToKwh(stats.max);
    this.energyConsumption[EnergyConsumptionEntry.min].value = this.wattsToKwh(stats.min || 0);
    this.energyConsumption[EnergyConsumptionEntry.average].value = this.wattsToKwh(stats.average || 0);

    if (this.config().dataType === StatsSupportedTypes.THERMOSTAT) {
      this.energyConsumption[EnergyConsumptionEntry.total].measurements =
        stats.total > TOTAL_THRESHOLD ? KWH : WH;
      this.energyConsumption[EnergyConsumptionEntry.max].measurements =
        stats.max > TOTAL_THRESHOLD ? KWH : WH;
      this.energyConsumption[EnergyConsumptionEntry.min].measurements =
        stats.min && stats.min > TOTAL_THRESHOLD ? KWH : WH;
      this.energyConsumption[EnergyConsumptionEntry.average].measurements =
        stats.average > TOTAL_THRESHOLD ? KWH : WH;
    }
  }

  private computeStatistics(timeseries: { value: number; }[]): {
    average: number;
    max: number;
    min: number | null;
    total: number;
  } {
    let total = 0;
    let max = 0;
    let min: number | null = null;

    timeseries.forEach(entry => {
      const value = Number(entry.value);

      total += value;
      max = Math.max(max, value);
      min = min === null ? value : Math.min(min, value);
    });

    const average = total / timeseries.length;

    return {
      average,
      max,
      min: min !== null ? this.roundToTwoDecimals(min) : null,
      total,
    };
  }
}
