import { Component, inject, OnDestroy, signal, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { IncidentColumn } from '@app-lib';
import { DevicesFiltersV2Component, DevicesTableV2Component, PowerConsumptionTableComponent } from '@devices';
import { DeviceTableCols, IncidentModel, IncidentTabStatusFilter } from '@models';
import { IncidentsFilterService } from '@services';
import { IncidentsTableComponent } from '@standalone/_tables/incidents-table/incidents-table.component';
import { ExportReportMenuComponent } from '@standalone/export-report-menu/export-report-menu.component';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';

import { IncidentChartsComponent } from '../../components/incident-charts/incident-charts.component';
import { IncidentManufacturerChartComponent } from '../../components/incident-manufacturer-chart/incident-manufacturer-chart.component';
import { IncidentsMonthlyChartComponent } from '../../components/incidents-monthly-chart/incidents-monthly-chart.component';
import { ReportFiltersComponent } from '../../components/report-filters/report-filters.component';

export enum ReportType {
  INCIDENT = 'incident',
  FIRMWARE = 'firmware',
  POWER_CONSUMPTION = 'power-consumption',
}

@Component({
  imports: [ PowerConsumptionTableComponent, DevicesTableV2Component, DevicesFiltersV2Component, MatCardModule, IncidentsMonthlyChartComponent, IncidentManufacturerChartComponent, IncidentsTableComponent, IncidentChartsComponent, ReportFiltersComponent, ExportReportMenuComponent, MatButtonToggleModule, MatButtonModule, PageHeaderComponent, FormsModule ],
  standalone: true,
  styleUrl: './reporting-main-page.component.scss',
  templateUrl: './reporting-main-page.component.html',
})
export class ReportingMainPageComponent implements OnDestroy {
  @ViewChild(DevicesTableV2Component)
  protected deviceTable!: DevicesTableV2Component;
  protected filters = signal<Record<string, string | string[]> | null>(null);
  protected firmwareDisplayedColumns = [
    DeviceTableCols.FIRMWARE,
    DeviceTableCols.DEVICE_TYPE,
    DeviceTableCols.MANUFACTURER,
    DeviceTableCols.BUILDING,
    DeviceTableCols.FLOOR,
    DeviceTableCols.ROOM,
    DeviceTableCols.MODEL,
    DeviceTableCols.SERIAL_NUMBER,
  ];
  protected incidents?: IncidentModel[] | null;
  @ViewChild(IncidentsTableComponent)
  protected incidentsTable!: IncidentsTableComponent;
  protected incidentDisplayedColumns = [
    IncidentColumn.INDEX,
    IncidentColumn.TICKET_NUMBER,
    IncidentColumn.STATUS,
    IncidentColumn.DEVICE_ID,
    IncidentColumn.DEVICE_TYPE,
    IncidentColumn.BUILDING,
    IncidentColumn.FLOOR,
    IncidentColumn.ROOM,
    IncidentColumn.REPORTED,
    IncidentColumn.FAULT_NAME,
  ];
  protected incidentsFilterService = inject(IncidentsFilterService);
  protected reportingType: ReportType = ReportType.INCIDENT;
  protected readonly eReportTab = ReportType;
  protected readonly TABLE_ID = 'reporting-device-table';

  constructor() {
    this.incidentsFilterService.displayedColumns = this.incidentDisplayedColumns;
    this.incidentsFilterService.incidentTabStatusFilter = IncidentTabStatusFilter.HISTORY;
  }

  ngOnDestroy() {
    this.incidentsFilterService.resetState();
  }

  exportIncidents(type: 'json' | 'csv') {
    if (type === 'json') {
      this.incidentsTable.exportIncidentsToJSON();
    }
    if (type === 'csv') {
      this.incidentsTable.exportIncidentsToCSV();
    }
  }

  setIncidents(incidents: IncidentModel[] | null) {
    this.incidents = incidents;
  }

  protected exportDevices(format: 'json' | 'csv') {
    return this.deviceTable.export(format);
  }
}
