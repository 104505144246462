import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { EntityStatus, IncidentStatus } from '@models';
import { DeviceStatusCountComponent } from '@standalone/device-status-count/device-status-count.component';
import { IncidentStatusCountComponent } from '@standalone/incident-status-count/incident-status-count.component';

@Component({
  imports: [RouterLink, DeviceStatusCountComponent, IncidentStatusCountComponent],
  selector: 'app-space-card-info',
  standalone: true,
  templateUrl: './space-card-info.component.html',
})
export class SpaceCardInfoComponent {
  @Input() status: EntityStatus = EntityStatus.Active;
  @Input() incidentNew = 0;
  @Input() incidentInProgress = 0;
  @Input() spaceLink: string[] = [];
  @Input() name = '';
  @Input() address = '';
  @Input() deviceActive = 0;
  @Input() devicePaused = 0;

  protected readonly eEntityStatus = EntityStatus;
  protected readonly eIncidentStatus = IncidentStatus;
}
