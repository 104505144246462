import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLabel, MatPrefix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { DirectivesModule } from '@directives';
import { filter, take } from 'rxjs';

import { Dashboard } from '../../../models';
import { DashboardService } from '../../../services';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIcon,
    MatLabel,
    MatPrefix,
    MatTooltip,
    RouterModule,
    DirectivesModule,
  ],
  selector: 'avs-li-dashboards-selector',
  standalone: true,
  styleUrl: './dashboards-selector.component.scss',
  templateUrl: './dashboards-selector.component.html',
})
export class DashboardsSelectorComponent {
  menuShrunk = input.required<boolean>();

  protected dashboards = signal<Dashboard[]>([]);
  protected hasChildActive = signal(false);

  private dashboardService = inject(DashboardService);
  private router = inject(Router);

  constructor() {
    this.refresh();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe(event =>
        this.hasChildActive.set(event.url.includes('/dashboards/')),
      );

    this.dashboardService.changes$
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.refresh());
  }

  refresh() {
    this.dashboards.set([]);
    this.dashboardService.getDashboards()
      .subscribe(dashboards => this.dashboards.set(dashboards));
  }
}
