<div class="mb-3 flex items-center">
  <app-search-input
    style="width: 100%"
    [inputValue]="filtersStore.query()"
    placeholder="Device Name, type & other"
    (searchDataEvent)="filtersStore.patchFilters({ query: $event })"
    inputWidth="100%" />
  <button
    class="test-toggle-table-filter-btn toggle-table-filter-btn ml-3"
    [class.has-selected-filters]="hasSelectedFilters()"
    (click)="openDeviceFiltersBottomSheet()">
    <i class="icon-filter_Menu text-3xl"></i>
  </button>
</div>
