<div class="overflow-hidden pt-2">
  <div class="flex flex-row-reverse">
    <button (click)="changeZoomValue('1')" [disabled]="currentStep >= countOfSteps">+</button>
    <button (click)="changeZoomValue('-1')" [disabled]="currentStep <= 0">-</button>
    <mat-form-field class="select-classic max-w-[100px] mr-2 text-center">
      <mat-select
        panelClass="single-select-overlay"
        class="text-sm"
        [value]="currentValue"
        (selectionChange)="changeZoomValue($event.value, true)">
        @for (item of zoomValues; track item) {
        <mat-option [value]="item">{{ item }}%</mat-option>
        }
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
      <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
    </mat-form-field>
  </div>
</div>
