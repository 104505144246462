<app-bottom-sheet-mobile-header (closeEvent)="close()" />
<ul class="mb-6">
  @for (dashboard of data.dashboards; track $index) {
    <li>
      <a
        class="flex items-center px-6 text-ui-dark font-nunito text-base leading-6 py-2.5"
        (click)="close()"
        [routerLink]="['dashboards', dashboard.id]"
        #rla="routerLinkActive"
        routerLinkActive="font-bold"
      >{{ dashboard.name }}</a>
    </li>
  }
  <li>
    <a
      class="flex items-center px-6 text-ui-dark font-nunito text-base leading-6 py-2.5"
      (click)="close()"
      [routerLink]="['dashboard']"
      #rla="routerLinkActive"
      routerLinkActive="font-bold"
    >Dashboard</a>
  </li>
</ul>
