@if (device()) {
  <mat-card class="card w-[400px] top-0 sticky">
    <app-page-header>{{ device().friendlyName }}</app-page-header>
    @if (device().tags?.['description']) {
      <p class="font-nunito font-semibold text-base leading-[22px] text-ui-dark mt-2 mb-0">
        {{ device().tags?.['description'] }}
      </p>
    }

    @if (device().incidentCountByStatuses; as incidents) {
      @if (device().status !== EntityStatus.Archived) {
        <div class="test-incident-status my-3">
          @if (incidents.newCount) {
            <app-incident-status [status]="incidentStatus.IN_QUEUE" />
          }
          @if (!incidents.newCount && incidents.inProgressCount) {
            <app-incident-status [status]="incidentStatus.IN_PROGRESS" />
          }
          @if (!incidents.newCount && !incidents.inProgressCount) {
            <span class="inline-flex justify-center items-center h-6 min-w-[24px] capitalize text-xs px-2 rounded-xl text-white bg-ui-green-light">Good</span>
          }
        </div>
      }
    }

    <div class="image-container mb-3">
      @if (device().deviceModelInformation && device().deviceModelInformation?.documentReference) {
        <app-img-loader
          [imgSrc]="imgUrl()"
          altText="floorplan"
          errorSrc="assets/icons/image-error.svg"
          loader="skeleton"
        />
      } @else {
        <img
          [src]="defaultImgUrl"
          alt="Device Image"
        />
      }

    </div>
    <ul>
      <li>
        <span>Device type:</span>
        {{ device().deviceModelInformation?.deviceType }}
      </li>

      @if (device().deviceModelInformation?.make?.name) {
        <li class="test-manufacturer">
          <span>Manufacturer:</span>
          {{ device().deviceModelInformation?.make?.name }}
        </li>
      }
      @if (device().deviceModelInformation?.name) {
        <li class="test-model">
          <span>Model:</span>
          {{ device().deviceModelInformation?.name }}
        </li>
      }
      @if (device().physicalDeviceId) {
        <li class="test-serial-number">
          <span>Serial №:</span>
          {{ device().physicalDeviceId }}
        </li>
      }
      @if (device().tags?.['ipAddress']) {
        <li class="test-serial-number">
          <span>Ip Address:</span>
          @if (device().tags?.['ipAddress'] === "N/A") {
            {{ device().tags?.['ipAddress'] }}
          } @else {
            <a
              class="underline"
              [href]="'http://' + getDeviceUrl(device())"
              [matTooltip]="'http://' + getDeviceUrl(device())"
              matTooltipPosition="above"
              target="_blank"
            >
              {{ device().tags?.['ipAddress'] }}
            </a>
          }
        </li>
      }
      @if (device().tags?.['macAddress']) {
        <li class="test-serial-number">
          <span>Mac Address:</span>
          {{ device().tags?.['macAddress'] }}
        </li>
      }
      @if (device().tags?.['firmware']) {
        <li class="test-firmware">
          <span>Firmware:</span>
          {{ device().tags?.['firmware'] }}
        </li>
      }
      @if (tempSensor) {
        <li class="test-temperature">
          <div>
            <span>Current temperature : </span>
            {{ tempSensor }} &deg;F
          </div>
        </li>
      }
      @if (device().latestStreamingTimestamp) {
        <li class="test-reported-time">
          <div>
            <span>Last reported : </span>
            {{ (device().latestStreamingTimestamp || "") | currentDate }}
          </div>
        </li>
      }
      <li
        class="test-device-id break-all whitespace-nowrap overflow-hidden text-ellipsis relative pr-8"
        appRestrictedEl
      >
        <span>Device ID : </span>
        {{ device().id }}
        <button
          class="px-1 pt-1 text-ui-gray-light cursor-pointer h-[30px] w-[30px] align-middle absolute right-0 top-[-3px] bg-white"
          (click)="copiedToClipboard()"
          [cdkCopyToClipboard]="device().id"
        >
          <mat-icon
            class="text-base cursor-pointer p-0 pt-0.5 text-ui-gray-dark active:text-ui-green-light"
            fontIcon="content_copy"
          />
        </button>
      </li>
      @if (!device().additionalAttributes) {
        <li class="test-add-properties-loading">
          <ngx-skeleton-loader
            appearance="line"
            count="2"
          />
        </li>
      }
    </ul>

    <button
      class="mt-2 rounded border w-full py-2 flex align-middle justify-center active:bg-ui-gray-900 disabled:bg-ui-gray-900 disabled:cursor-not-allowed"
      (click)="rebootDevice()"
      [disabled]="deviceIsUpdating"
      [showForClientAdmin]="true"
      appRestrictedEl
    >
      @if (!deviceIsUpdating) {
        <mat-icon fontIcon="refresh" />
      } @else {
        <mat-spinner
          class="mr-1"
          diameter="20"
          matSuffix
        />
      }
      <span class="pl-2">Reboot Device</span>
    </button>
  </mat-card>
}

