import { Component, DestroyRef, inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { isElementInViewport } from '@app-lib';
import { EntityStatus, RoomSpaceModel } from '@models';
import { Store } from '@ngrx/store';
import { AppState, updateRoom } from '@ngrx-store';
import { AppService } from '@services';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';
import { scrollIntoView } from 'seamless-scroll-polyfill';

import { openEditRoomDialog } from '../edit-room-dialog/edit-room-dialog.component';

@Component({
  selector: 'app-room-item',
  styleUrl: './room-item.component.scss',
  templateUrl: './room-item.component.html',
})
export class RoomItemComponent {
  @Input() locationId!: string;
  @Input() floorplan: string | undefined;
  @Input() room!: RoomSpaceModel;
  @Input() floorId = '';
  destroyRef = inject(DestroyRef);
  entityStatus = EntityStatus;

  constructor(private dialog: MatDialog, protected appService: AppService, private store: Store<AppState>) { }

  openModal({ title, description, data }: {
    data: Partial<RoomSpaceModel>;
    description: string;
    title: string;
  }) {
    ConfirmationDialogComponent.open(this.dialog, {
      description,
      title,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.updateRoom(data);
        }
      });
  }

  archiveRoom() {
    this.openModal({
      data: {
        status: EntityStatus.Archived,
      },
      description: `Are you sure you want to archive room ${this.room.friendlyName}?`,
      title: 'Archive room',
    });
  }

  unarchiveRoom() {
    this.openModal({
      data: {
        status: EntityStatus.Active,
      },
      description: `Are you sure you want to restore ${this.room.friendlyName} from archive?`,
      title: 'Restore',
    });
  }

  assignRoom() {
    this.appService.activeAttachmentId.set(this.room.id);

    const element = document.querySelector('app-spaceplan');

    if (element && element instanceof HTMLElement && !isElementInViewport(element)) {
      scrollIntoView(element, { behavior: 'smooth', block: 'start' });
    }
  }

  edit() {
    openEditRoomDialog(this.dialog, this.room, this.locationId);
  }

  removeCoordinates() {
    this.openModal({
      data: {
        unstructuredDataReference: '',
      },
      description: `Are you sure you want to remove the ${this.room.friendlyName} assignment from the floor plan?`,
      title: 'Remove',
    });
  }

  updateRoom(data: Partial<RoomSpaceModel>) {
    this.store.dispatch(
      updateRoom({
        data,
        locationId: this.locationId,
        spaceId: this.room.id,
      }),
    );
  }
}
