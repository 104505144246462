import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatLabel, MatSelect, MatSelectChange } from '@angular/material/select';
import { PipesModule } from '@pipes';

@Component({
  imports: [NgClass, MatFormField, MatSelect, MatOption, MatIcon, PipesModule, MatLabel],
  selector: 'app-multiple-select',
  standalone: true,
  templateUrl: './multiple-select.component.html',
})
export class MultipleSelectComponent {
  @Input() sortingEnabled = true;
  @Input() label = '';
  @Input() selectClass = '';
  @Input() panelClass = '';
  @Input() showLabelPlaceholder = true;
  @Input() options: { title: string; value: string }[] = [];
  @Input() showSelectedValues = true;
  @Output() selectDataEvent = new EventEmitter<string[]>();
  @ViewChild('selectRef') selectRef!: MatSelect;
  @ViewChild('wrapper') wrapper!: MatFormField;
  @Input() value: string[] = [];
  @Input() selectElementClass = '';

  protected get labelDetails() {
    const selectedValues = this.value;
    const separator = `${this.showLabelPlaceholder ? ': ' : ''}`;
    let details = '';

    if (selectedValues?.length) {
      if (selectedValues.length === this.options.length) {
        details = `${separator}All`;
      } else {
        details = `${separator}${this.options.find(option => option.value === selectedValues[0])?.title} ${selectedValues.length > 1 ? `(+${selectedValues.length - 1})` : ''
          }`;
      }
    }

    return details;
  }

  protected get cssPanelClass() {
    return `multi-select-overlay ${this.panelClass}`;
  }

  protected selectionChange(change: MatSelectChange) {
    this.selectDataEvent.emit(change.value);
    requestAnimationFrame(() => {
      const selectPosition = this.wrapper._elementRef.nativeElement.getBoundingClientRect();
      const panelElement = this.selectRef.panel.nativeElement.parentElement;

      if (!panelElement.classList.contains('mat-mdc-select-panel-above')) {
        panelElement.style.top = selectPosition.top + selectPosition.height + 'px';
      }
    });
  }

  protected clearAll() {
    this.selectRef?.options.forEach((item: MatOption) => item.deselect());
  }

  protected selectAll() {
    this.selectRef?.options.forEach((item: MatOption) => item.select());
  }

}
