import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

import { Dashboard } from '../../../models';
import { DashboardService } from '../../../services';
import { MobileDashboardsSelectorSheetComponent } from './mobile-dashboards-selector-sheet.component';

@Component({
  imports: [CommonModule],
  selector: 'avs-li-mobile-dashboards-selector',
  standalone: true,
  templateUrl: './mobile-dashboards-selector.component.html',
})
export class MobileDashboardsSelectorComponent {
  protected header = computed(() => {
    const dashboardId = this.dashboardId();
    const dashboards = this.dashboards();

    return dashboardId ? dashboards.find(dashboard => dashboard.id === dashboardId)?.name : 'Dashboard';
  });
  protected isLoading = signal<boolean>(false);
  protected dashboards = signal<Dashboard[]>([]);
  private dashboardId = signal<string | null>(null);
  private dashboardService = inject(DashboardService);

  constructor(private bottomSheet: MatBottomSheet, private route: ActivatedRoute, private router: Router) {
    this.initDashboardsId(this.route.snapshot.paramMap.get('dashboardId'));
    this.fetchDashboards();

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd), takeUntilDestroyed())
      .subscribe(() => {
        this.initDashboardsId(this.route.snapshot.paramMap.get('dashboardId'));
      });
  }

  protected openSheet() {
    this.bottomSheet.open(MobileDashboardsSelectorSheetComponent, { data: { dashboards: this.dashboards() } });
  }

  private initDashboardsId(id: string | null) {
    this.dashboardId.set(id);
  }

  private fetchDashboards() {
    this.dashboards.set([]);
    this.dashboardService.getDashboards()
      .subscribe(dashboards => { this.dashboards.set(dashboards); });
  }

}
