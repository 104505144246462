import { UserDetailsClass } from '@abstraction';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { DirectivesModule } from '@directives';
import { Store } from '@ngrx/store';
import { AppState } from '@ngrx-store';
import { BreadcrumbsComponent } from '@standalone/breadcrumbs/breadcrumbs.component';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';
import { SkeletonPageHeaderComponent } from '@standalone/skeleton-page-header/skeleton-page-header.component';
import { UserDetailsComponent } from '@standalone/user-details/user-details.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  imports: [NgxSkeletonLoaderModule, SkeletonPageHeaderComponent, UserDetailsComponent, MatProgressSpinnerModule, CommonModule, DirectivesModule, BreadcrumbsComponent, PageHeaderComponent ],
  selector: 'avs-fe-user-details-page',
  standalone: true,
  templateUrl: './user-details-page.component.html',
})
export class UserDetailsPageComponent extends UserDetailsClass {
  constructor(override route: ActivatedRoute, override store: Store<AppState>, override dialog: MatDialog) {
    super(route, store, dialog);
  }
}
