@let areRoomDocumentsLoading = areRoomDocumentsLoading$ | async; @let isRoomplanLoading = isRoomplanLoading$ | async;
@let devices = (deviceList$ | async); @let selectedTab = selectedDocumentTabValue(); @if(areRoomDocumentsLoading) {
  <ngx-skeleton-loader [theme]="{ height: '400px' }" />
} @else if (room && room.parentSpaceId) {
  <div class="mb-2 flex justify-between items-center">
    <div class="flex items-center">
      @if(isRoomplanLoading) {
        <mat-spinner
          class="mr-1"
          diameter="40"
        ></mat-spinner>
      }
      <mat-button-toggle-group
        class="main-toggle-group"
        (change)="documentTabChange($event)"
        [value]="selectedTab"
      >
        @for (tab of documentTabs; track tab; let index = $index) {
          <mat-button-toggle [value]="tab">
            <span class="capitalize">{{ tab }}</span>
          </mat-button-toggle>
        }
      </mat-button-toggle-group>
    </div>
    <button
      class="btn-white-outlined btn-medium"
      (click)="addNewTabClick()"
      appRestrictedEl
    >
      <mat-icon matIconPrefix>add</mat-icon>
      <span>Add new tab</span>
    </button>
  </div>

  @if(documentTabs.length === 0) {
    <app-no-data noDataText="No block diagram added" />
  } @else {
    @for (document of documents; track document.tab) {
      <app-spaceplan
        [canEdit]="canEdit"
        [documentTab]="selectedTab"
        [hidden]="!(devices && document.tab === selectedTab)"
        [locationId]="locationId"
        [popover]="popover"
        [spaceplan]="{
          id: room.id,
          documentReference: document.fileName,
          attachments: devices || [],
          parentSpaceId: room.parentSpaceId
        }"
      />
    } }
  <app-upload-spaceplan
    [existedTabs]="documentTabs"
    [floorId]="room.parentSpaceId"
    [hidden]="selectedTab !== defaultTab"
    [locationId]="locationId"
    [roomId]="room.id"
    [tabEnabled]="true"
    uploadText="Upload Block Diagram"
  />
}
