<cdk-virtual-scroll-viewport
  [hidden]="dataIsLoading"
  [itemSize]="TYPICAL_COL_HEIGHT"
  [ngClass]="{ 'fixed-scroll': isInjected }"
  [style.zoom]="zoom()"
>
  <table
    #table
    [dataSource]="dataSource"
    [multiTemplateDataRows]="true"
    class="main-table"
    id="incidents-table"
    mat-table
    matSort
  >

    <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

    <!-- Number Column -->
    <ng-container
      [matColumnDef]="IncidentColumn.INDEX"
      [sticky]="!isMobile"
    >
      <th
        *matHeaderCellDef
        mat-header-cell
      >{{ staticIncidentColumnTitle[IncidentColumn.INDEX] }}
      </th>
      <td
        *matCellDef="let element"
        mat-cell
      >
        {{ dataSourceIndex[element.id] }}
      </td>
    </ng-container>

    <!-- Ticket Number Column -->
    <ng-container
      [matColumnDef]="IncidentColumn.TICKET_NUMBER"
      [sticky]="!isMobile"
    >
      <th
        *matHeaderCellDef
        [ngClass]="{ 'left-[40px]': !isMobile }"
        class="table-border-last-left-sticky"
        mat-header-cell
        mat-sort-header
        sortActionDescription="Sort by ticker number"
      >
        {{ staticIncidentColumnTitle[IncidentColumn.TICKET_NUMBER] }}
      </th>
      <td
        *matCellDef="let element"
        [ngClass]="{ 'left-[40px]': !isMobile }"
        class="table-border-last-left-sticky"
        mat-cell
      >
        <a
          [class.underline]="!isReportMode"
          [routerLink]="isReportMode ? null : ['/incidents', element.id]"
        >{{
            element.ticketNumber
          }}</a>
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container [matColumnDef]="IncidentColumn.STATUS">
      <th
        *matHeaderCellDef
        mat-header-cell
        mat-sort-header
        sortActionDescription="Sort by status"
      >
        {{ incidentColumnTitle[IncidentColumn.STATUS] }}
      </th>
      <td
        *matCellDef="let element"
        mat-cell
      >
        <app-incident-status [status]="element.status"></app-incident-status>
      </td>
    </ng-container>

    <!-- Device Name Column -->
    <ng-container [matColumnDef]="IncidentColumn.DEVICE_ID">
      <th
        *matHeaderCellDef
        mat-header-cell
        mat-sort-header
        sortActionDescription="Sort by device name"
      >
        {{ incidentColumnTitle[IncidentColumn.DEVICE_ID] }}
      </th>
      <td
        *matCellDef="let element"
        mat-cell
      >
        <a
          [class.underline]="!isReportMode"
          [routerLink]="isReportMode ? null : ['/devices', element.buildingId, element.deviceId]"
        >
          {{ element.deviceName }}
        </a>
      </td>
    </ng-container>

    <!-- Device Type Column -->
    <ng-container [matColumnDef]="IncidentColumn.DEVICE_TYPE">
      <th
        *matHeaderCellDef
        mat-header-cell
        mat-sort-header
        sortActionDescription="Sort by device type"
      >
        {{ incidentColumnTitle[IncidentColumn.DEVICE_TYPE] }}
      </th>
      <td
        *matCellDef="let element"
        mat-cell
      >{{ element.deviceType }}
      </td>
    </ng-container>

    <!-- Building Name Column -->
    <ng-container [matColumnDef]="IncidentColumn.BUILDING">
      <th
        *matHeaderCellDef
        mat-header-cell
        mat-sort-header
        sortActionDescription="Sort by building name"
      >
        {{ incidentColumnTitle[IncidentColumn.BUILDING] }}
      </th>
      <td
        *matCellDef="let element"
        mat-cell
      >
        <a
          [class.underline]="!isReportMode"
          [routerLink]="isReportMode ? null : ['/dashboard', element.buildingId]"
        >{{
            element.building
          }}</a>
      </td>
    </ng-container>

    <!-- Floor Column -->
    <ng-container [matColumnDef]="IncidentColumn.FLOOR">
      <th
        *matHeaderCellDef
        mat-header-cell
        mat-sort-header
        sortActionDescription="Sort by floor"
      >
        {{ incidentColumnTitle[IncidentColumn.FLOOR] }}
      </th>
      <td
        *matCellDef="let element"
        mat-cell
      >
        <a
          [class.underline]="!isReportMode"
          [routerLink]="isReportMode ? null : ['/dashboard', element.buildingId, element.floorId]"
        >{{ element.floor }}</a
        >
      </td>
    </ng-container>

    <!-- Room Column -->
    <ng-container [matColumnDef]="IncidentColumn.ROOM">
      <th
        *matHeaderCellDef
        mat-header-cell
        mat-sort-header
        sortActionDescription="Sort by room"
      >
        {{ incidentColumnTitle[IncidentColumn.ROOM] }}
      </th>
      <td
        *matCellDef="let element"
        mat-cell
      >
        <a
          [class.underline]="!isReportMode"
          [routerLink]="isReportMode ? null : ['/dashboard', element.buildingId, element.floorId, element.roomId]"
        >{{ element.room }}</a
        >
      </td>
    </ng-container>

    <!-- Manufacturer Column -->
    <ng-container [matColumnDef]="IncidentColumn.MANUFACTURER">
      <th
        *matHeaderCellDef
        mat-header-cell
        mat-sort-header
        sortActionDescription="Sort by manufacturer"
      >
        {{ incidentColumnTitle[IncidentColumn.MANUFACTURER] }}
      </th>
      <td
        *matCellDef="let element"
        mat-cell
      >{{ element.manufacturer }}
      </td>
    </ng-container>

    <!-- Model Column -->
    <ng-container [matColumnDef]="IncidentColumn.MODEL">
      <th
        *matHeaderCellDef
        mat-header-cell
        mat-sort-header
        sortActionDescription="Sort by model"
      >
        {{ incidentColumnTitle[IncidentColumn.MODEL] }}
      </th>
      <td
        *matCellDef="let element"
        mat-cell
      >{{ element.model }}
      </td>
    </ng-container>

    <!-- Serial Number Column -->
    <ng-container [matColumnDef]="IncidentColumn.SERIAL_NUMBER">
      <th
        *matHeaderCellDef
        mat-header-cell
        mat-sort-header
        sortActionDescription="Sort by serial number"
      >
        {{ incidentColumnTitle[IncidentColumn.SERIAL_NUMBER] }}
      </th>
      <td
        *matCellDef="let element"
        mat-cell
      >{{ element.serialNumber }}
      </td>
    </ng-container>

    <!-- Reported Column -->
    <ng-container [matColumnDef]="IncidentColumn.REPORTED">
      <th
        *matHeaderCellDef
        mat-header-cell
        mat-sort-header
        sortActionDescription="Sort by reported"
      >
        {{ incidentColumnTitle[IncidentColumn.REPORTED] }}
      </th>
      <td
        *matCellDef="let element"
        mat-cell
      >{{ element.reported }}
      </td>
    </ng-container>

    <!-- Faultname Column -->
    <ng-container [matColumnDef]="IncidentColumn.FAULT_NAME">
      <th
        *matHeaderCellDef
        mat-header-cell
        mat-sort-header
        sortActionDescription="Sort by fault name"
      >
        {{ incidentColumnTitle[IncidentColumn.FAULT_NAME] }}
      </th>
      <td
        *matCellDef="let element"
        mat-cell
      >{{ element.faultname }}
      </td>
    </ng-container>

    <!-- Resolved Column -->
    <ng-container [matColumnDef]="IncidentColumn.RESOLVED">
      <th
        *matHeaderCellDef
        mat-header-cell
        mat-sort-header
        sortActionDescription="Sort by resolved"
      >
        {{ incidentColumnTitle[IncidentColumn.RESOLVED] }}
      </th>
      <td
        *matCellDef="let element"
        mat-cell
      >
        {{ element.resolved || '-' }}
      </td>
    </ng-container>

    <tr
      *matHeaderRowDef="incidentsFilterService.displayedColumns; sticky: true"
      mat-header-row
    ></tr>
    <tr
      *matRowDef="let row; columns: []; when: placeholderWhen"
      [style.height.px]="dataSource.offset$ | async"
      mat-row
    ></tr>
    <tr
      *matRowDef="let row; columns: incidentsFilterService.displayedColumns"
      mat-row
    ></tr>

  </table>
  <app-no-data
    *ngIf="!dataSource.filteredData.length"
    [hasBigPadding]="isNoDataBigSize"
    [noDataText]="
      incidentsFilterService.incidentTabStatusFilter === incidentTabStatusFilterEnum.HISTORY
        ? noResolvedInicidentsText
        : noDataText
    "
    [resultContent]="incidentsFilterService.filtersApplied()"
  />
</cdk-virtual-scroll-viewport>
<app-table-zoom
  (zoomChanged)="zoom.set($event)"
  [tableId]="tableId"
/>
