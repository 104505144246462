<h1 class="dialog-title">
  <span>
    @if (data) {
      Edit widget
    } @else {
      Add widget
    }
  </span>
  <button mat-dialog-close>
    <i class="icon-close"></i>
  </button>
</h1>

<section
  class="dialog-content"
  [formGroup]="form"
  mat-dialog-content
>
  <div class="flex flex-row">
    <label class="input-main-label capitalize">
      Widget name*
    </label>
    <mat-form-field class="input-classic">
      <input
        formControlName="name"
        matInput
        placeholder="Enter widget name"
      />
      <mat-error>Input is not valid</mat-error>
    </mat-form-field>
  </div>

  <div class="flex flex-row">
    <label class="input-main-label capitalize">
      Widget type*
    </label>
    <mat-form-field class="select-classic has-validation">
      <mat-select
        formControlName="type"
        panelClass="single-select-overlay"
        placeholder="Select widget type"
      >
        <mat-option value="buildingPlan">Building Plan</mat-option>
        <mat-option value="dataPoint">Data Point</mat-option>
        <mat-option value="deviceChart">Device Chart</mat-option>
        <mat-option value="devices">Devices</mat-option>
        <mat-option value="deviceStats">Device Stats</mat-option>
        <mat-option value="map">Map</mat-option>
        <mat-option value="spaceDetails">Space Details</mat-option>
      </mat-select>
      <mat-icon
        class="arrow-down"
        matSuffix
      >keyboard_arrow_down
      </mat-icon>
      <mat-icon
        class="arrow-up"
        matSuffix
      >keyboard_arrow_up
      </mat-icon>
      <mat-error>Input is not valid</mat-error>
    </mat-form-field>
  </div>

  @switch (form.value.type) {
    @case ('buildingPlan') {
      <avs-li-location-options
        (change)="form.patchValue({ widgetConfig: $event })"
        [config]="form.value?.widgetConfig"
      />
    }
    @case ('dataPoint') {
      <avs-li-data-point-options
        (change)="form.patchValue({ widgetConfig: $event })"
        [config]="form.value?.widgetConfig"
        [hideDateRangeInput]="true"
      />
    }
    @case ('deviceChart') {
      <avs-li-data-point-options
        (change)="form.patchValue({ widgetConfig: $event })"
        [config]="form.value?.widgetConfig"
      />
    }
    @case ('devices') {
      <avs-li-location-full-options
        (change)="form.patchValue({ widgetConfig: $event })"
        [allRequired]="false"
        [config]="form.value?.widgetConfig"
      />
    }
    @case ('deviceStats') {
      <avs-li-device-stats-options
        (change)="form.patchValue({ widgetConfig: $event })"
        [config]="form.value?.widgetConfig"
      />
    }
    @case ('map') {
      <avs-li-location-options
        (change)="form.patchValue({ widgetConfig: $event })"
        [config]="form.value?.widgetConfig"
      />
    }
    @case ('spaceDetails') {
      <avs-li-location-full-options
        (change)="form.patchValue({ widgetConfig: $event })"
        [allRequired]="false"
        [config]="form.value?.widgetConfig"
      />
    }
  }
</section>

<mat-dialog-actions
  class="dialog-actions"
  align="end"
>
  <button
    class="btn-transparent btn-medium font-bold mr-4"
    mat-dialog-close
  >
    CANCEL
  </button>
  <button
    class="btn-green btn-medium font-bold"
    (click)="onSubmit()"
    [disabled]="form.invalid"
  >
    @if (data) {
      <span>SAVE</span>
    } @else {
      <span>ADD</span>
    }
  </button>
</mat-dialog-actions>
