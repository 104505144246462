<div
  class="resizable-draggable"
  (mousedown)="editMode ? setStatus($event, 2) : null"
  (window:mouseup)="setStatus($event, 0)"
  [class.active]="status === Status.RESIZE || status === Status.MOVE"
  [class.attachment-paused]="attachment.status === entityStatus.Paused"
  [class.cursor-pointer]="allowNavigation"
  [class.edit-mode]="editMode"
  [class.incident-inProgress]="
    attachment.status === entityStatus.Active &&
    !attachment.incidentCountByStatuses.newCount &&
    attachment.incidentCountByStatuses.inProgressCount
  "
  [class.incident-new]="attachment.status === entityStatus.Active && attachment.incidentCountByStatuses.newCount"
  [class.pointer-events-none]="!canInteract"
  [style.height.px]="height"
  [style.transform]="'translate3d(' + left + 'px,' + top + 'px,' + '0px)'"
  [style.width.px]="width"
  *ngIf="width && height && attachment.status !== entityStatus.Archived"
  #box
>
  @if (editMode) {
    <div
      class="resize-action"
      (mousedown)="setStatus($event, 1)"
    ></div>
  }
  <span
    class="popover-element"
    [class.hidden]="editMode"
    [routerLink]="allowNavigation && !editMode ? routerLink : []"
    [tp]="popover"
    [tpData]="popoverData"
    tpPlacement="bottom"
    tpTrigger="mouseenter"
    tpVariation="popper"
  ></span>
  <!-- keep "dev-info" elements for development purposes -->
  <!-- <span class="dev-info" *ngIf="editMode">{{ width | number : '1.0-0' }}px</span>
  <span class="dev-info" *ngIf="editMode">{{ height | number : '1.0-0' }}px</span>
  <span class="dev-info" *ngIf="editMode">({{ left }}, {{ top }})</span> -->

  <div class="attachment-info">
    <button
      class="test-confirm-btn btn-green mr-2"
      (click)="confirm()"
    >
      <mat-icon>check</mat-icon>
    </button>
    <button
      class="test-close-btn btn-red"
      (click)="cancel()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
