import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserDataFormComponent } from '@app/modules/user-management/components/user-data-form/user-data-form.component';
import { AddNewUserComponent } from '@app/modules/user-management/containers/add-new-user/add-new-user.component';
import { RoleGuard } from '@app-lib';
import { clientUsersResolver } from '@ngrx-store';

import { UserDetailsPageComponent } from './containers/user-details-page/user-details-page.component';
import { UserManagementMainPageComponent } from './containers/user-management-main-page/user-management-main-page.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        children: [
          {
            component: UserManagementMainPageComponent,
            path: '',
          },
          {
            canActivate: [RoleGuard],
            component: AddNewUserComponent,
            data: {
              activeForAdmin: true,
            },
            path: 'add-new-user',
          },
          {
            component: UserDetailsPageComponent,
            path: ':id',
          },
        ],
        path: '',
        resolve: {
          userClientList: clientUsersResolver,
        },
      },
    ]),
    UserManagementMainPageComponent,
    UserDetailsPageComponent,
    AddNewUserComponent,
    UserDataFormComponent,
  ],
})
export class UserManagementModule { }
