<mat-form-field class="select-classic has-validation">
  <mat-select
    (valueChange)="change.emit($event)"
    [value]="value()"
    panelClass="single-select-overlay"
    placeholder="Select location"
  >
    @for (location of locations(); track location.id) {
      <mat-option [value]="location.id">
        {{ location.friendlyName }}
      </mat-option>
    }
  </mat-select>
  <mat-icon
    class="arrow-down"
    matSuffix
  >keyboard_arrow_down</mat-icon>
  <mat-icon
    class="arrow-up"
    matSuffix
  >keyboard_arrow_up</mat-icon>
  <mat-error>Input is not valid</mat-error>
</mat-form-field>
