import { AfterViewInit, Component, DestroyRef, effect, inject, Injector, input, runInInjectionContext, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GoogleMap, MapAdvancedMarker, MapAnchorPoint, MapInfoWindow } from '@angular/google-maps';
import { DEFAULT_COUNTRY, getMapBoundariesByCountry } from '@app-lib';
import { EntityStatus, IncidentStatus, LocationData } from '@models';
import { Store } from '@ngrx/store';
import { AppState } from '@ngrx-store';
import { PipesModule } from '@pipes';
import { MapFilterService } from '@services';
import { SiteMapMarkerComponent } from '@standalone/site-map-marker/site-map-marker.component';

type DashboardMapMarker = LocationData & {
  options: google.maps.marker.AdvancedMarkerElementOptions;
  position: google.maps.LatLngLiteral;
};

@Component({
  imports: [GoogleMap, MapAdvancedMarker, PipesModule, MapInfoWindow, SiteMapMarkerComponent],
  selector: 'avs-li-map',
  standalone: true,
  templateUrl: './map.component.html',
})
export class MapComponent implements AfterViewInit {
  locations = input.required<LocationData[]>();
  @ViewChild(MapInfoWindow) protected infoWindow!: MapInfoWindow;
  protected mapFilterService = inject(MapFilterService);
  protected store: Store<AppState> = inject(Store);
  protected destroyRef = inject(DestroyRef);
  protected selectedSite: LocationData | null = null;

  protected mapOptions: google.maps.MapOptions = {
    disableDoubleClickZoom: true,
    fullscreenControl: false,
    mapId: '2a6238c26271516b',
    mapTypeControl: false,
    scrollwheel: true,
    streetViewControl: false,
  };
  protected markers: DashboardMapMarker[] = [];
  protected filteredMarkers: DashboardMapMarker[] = [];
  protected map: google.maps.Map | undefined = undefined;

  private injector = inject(Injector);

  ngAfterViewInit() {
    runInInjectionContext(this.injector, () => {
      effect(async () => {
        this.locations();

        await this.updateMarkers();
      });
    });
  }

  protected isValidLatitude = (lat: number) => isFinite(lat) && Math.abs(lat) <= 90;
  protected isValidLongitude = (lng: number) => isFinite(lng) && Math.abs(lng) <= 180;

  protected async mapInitialized(map: google.maps.Map) {
    this.map = map;
    await this.createMarkers();
    this.mapFilterService.mapFilter$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(selectedFilters => {
      this.filterMarkers(selectedFilters);
    });
  }

  protected async createMarkers() {
    if (this.map) {
      const bounds = new google.maps.LatLngBounds();
      const markers: DashboardMapMarker[] = [];

      this.locations().forEach(location => {
        if (
          !this.isValidLatitude(location.geographic.lat) ||
          !this.isValidLongitude(location.geographic.long) ||
          location.status === EntityStatus.Archived
        ) {
          return;
        }
        const latLng = new google.maps.LatLng(location.geographic.lat, location.geographic.long);
        const marker = {
          ...location,
          options: {},
          position: latLng.toJSON(),
        };

        markers.push(marker);
        bounds.extend(latLng);
      });
      this.markers = markers;
      this.filterMarkers(this.mapFilterService.mapFilter);

      if (this.markers.length > 1) {
        this.map.fitBounds(bounds);
      }

      if (this.markers.length === 1) {
        this.map.setCenter(bounds.getCenter());
        this.map.setZoom(13);
      }

      if (!this.markers.length) {
        await this.initCountryOnMap();
      }
    }
  }

  protected async initCountryOnMap() {
    const geocodeResult = await getMapBoundariesByCountry(DEFAULT_COUNTRY.name);

    if (geocodeResult.geometry.bounds) {
      this.map?.fitBounds(geocodeResult.geometry.bounds);
    }
  }

  protected openInfo(marker: MapAdvancedMarker, site: LocationData, window: Element) {
    const element: MapAnchorPoint = {
      getAnchor() {
        return marker.advancedMarker;
      },
    };

    if (site.devicesByStatuses && site.incidentCountByStatuses) {
      this.selectedSite = site;
      this.infoWindow.open(element, false, window);
    }
  }

  protected hideInfo() {
    this.infoWindow.close();
  }

  protected filterMarkers(selectedMapFilters: string[]): void {
    this.filteredMarkers = selectedMapFilters.length > 0
      ? this.markers.filter(marker => this.doesMarkerMatchFilters(marker, selectedMapFilters))
      : this.markers;
  }

  private doesMarkerMatchFilters(marker: DashboardMapMarker, selectedMapFilters: string[]): boolean {
    if (!marker.incidentCountByStatuses) {
      return true; // If there's no status data, include the marker
    }

    const isPaused = selectedMapFilters.includes(EntityStatus.Paused) && marker.status === EntityStatus.Paused;
    const hasNewIncidents = selectedMapFilters.includes(IncidentStatus.IN_QUEUE) &&
      marker.status === EntityStatus.Active &&
      marker.incidentCountByStatuses.newCount > 0;
    const hasInProgressIncidents = selectedMapFilters.includes(IncidentStatus.IN_PROGRESS) &&
      marker.status === EntityStatus.Active &&
      marker.incidentCountByStatuses.inProgressCount > 0 &&
      !marker.incidentCountByStatuses.newCount;
    const isGood = selectedMapFilters.includes('good') &&
      marker.status === EntityStatus.Active &&
      !marker.incidentCountByStatuses.inProgressCount &&
      !marker.incidentCountByStatuses.newCount;

    return isPaused || hasNewIncidents || hasInProgressIncidents || isGood;
  }

  private async updateMarkers() {
    this.markers = [];
    this.filteredMarkers = [];

    await this.createMarkers();
  }
}
