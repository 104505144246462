<div class="p-3.5 relative flex flex-col h-full">
  <div
    class="flex mb-1"
    [ngClass]="{
      '-ml-1 -mr-1': isEditMode(),
    }"
  >
    @if (isEditMode()) {
      <div>
        <i class="text-ui-gray-100 text-2xl icon-ic_drag-n-drop gridster-drag-handle"></i>
      </div>
    }

    <div class="flex flex-1 flex-col overflow-hidden">
      <h4 class="font-poppins text-sm font-semibold text-ui-dark leading-6 overflow-hidden whitespace-nowrap text-ellipsis">
        {{ widget().config.name }}
      </h4>
      <avs-li-space-location [config]="{
        locationId: widget().config.locationId,
        floorId: $any(widget().config).floorId,
        roomId: $any(widget().config).roomId
      }" />
    </div>

    @if (isEditMode()) {
      <div>
        <i
          class="icon-edit text-2xl action-icon mr-0"
          (click)="edit.emit(widget().id)"
          matTooltip="Edit Widget"
        ></i>
        <i
          class="icon-remove text-2xl action-icon"
          (click)="delete.emit(widget().id)"
          matTooltip="Remove Widget"
        ></i>
      </div>
    }
  </div>

  @if (isEditMode()) {
    <i class="icon-ic_rezise absolute bottom-0 right-0 text-ui-gray-100 z-[1]"></i>
  }

  <div
    class="flex flex-1 overflow-auto"
    [class]="classBreakpoint()"
  >
    @switch (widget().config.type) {
      @case ('buildingPlan') {
        <avs-li-building-plan
          class="widget"
          [config]="$any(widget().config)"
          #widgetRef
        />
      }
      @case ('dataPoint') {
        <avs-li-widget-data-point
          class="widget"
          [config]="$any(widget().config)"
          #widgetRef
        />
      }
      @case ('deviceChart') {
        <avs-li-device-chart
          class="widget"
          [config]="$any(widget().config)"
          #widgetRef
        />
      }
      @case ('devices') {
        <avs-li-widget-devices
          class="widget"
          [config]="$any(widget().config)"
          #widgetRef
        />
      }
      @case ('deviceStats') {
        <avs-li-widget-device-stats
          class="widget"
          [config]="$any(widget().config)"
          #widgetRef
        />
      }
      @case ('map') {
        <avs-li-widget-map
          class="widget"
          [config]="$any(widget().config)"
          #widgetRef
        />
      }
      @case ('spaceDetails') {
        <avs-li-widget-space-details
          class="widget"
          [config]="$any(widget().config)"
          #widgetRef
        />
      }
    }
  </div>
</div>
