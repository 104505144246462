import { Component, effect, input, signal } from '@angular/core';
import { DevicesTableV2Component } from '@devices';
import { DeviceTableCols, EntityStatus } from '@models';

import { WidgetDevices } from '../../../../models';

@Component({
  host: {
    class: 'overflow-y-auto relative',
  },
  imports: [
    DevicesTableV2Component,
  ],
  selector: 'avs-li-widget-devices',
  standalone: true,
  styleUrl: './widget-devices.component.scss',
  templateUrl: './widget-devices.component.html',
})
export class WidgetDevicesComponent {
  config = input.required<WidgetDevices>();

  protected columns = [
    DeviceTableCols.INDEX,
    DeviceTableCols.DEVICE_NAME,
    DeviceTableCols.BUILDING,
    DeviceTableCols.FLOOR,
    DeviceTableCols.ROOM,
    DeviceTableCols.DEVICE_TYPE,
    DeviceTableCols.MANUFACTURER,
    DeviceTableCols.MODEL,
    DeviceTableCols.SERIAL_NUMBER,
    DeviceTableCols.IP_ADDRESS,
    DeviceTableCols.STATUS,
    DeviceTableCols.INCIDENTS,
    DeviceTableCols.ACTIONS,
  ];

  protected filters = signal({});

  constructor() {
    effect(() => {
      this.filters.set({
        location: this.config().locationId,
        space: this.config().roomId ?? this.config().floorId,
        status: [EntityStatus.Active, EntityStatus.Paused, EntityStatus.Deactivated],
      });
    }, { allowSignalWrites: true });
  }
}
