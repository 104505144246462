@if (isIncidentLoaded$ | async) {
  <app-page-header>
    <div class="mb-4 leading-10">Incidents</div>
  </app-page-header>
  <mat-card class="card py-0 pb-2">
    <mat-card-content class="p-0">
      <app-incidents-table-desktop-filters
        [filtersEnabled]="true"
        [resolvedIncidentsLoading]="resolvedIncidentsLoading"
        tableId="incidents-main-table"
      />

      <app-incidents-table
        [dataIsLoading]="resolvedIncidentsLoading"
        [isNoDataBigSize]="true"
        noDataText="All devices are working properly"
        tableId="incidents-main-table"
      />
    </mat-card-content>
  </mat-card>
} @else {
  <app-skeleton-table />
}
