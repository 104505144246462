<div class="border-x border-b border-ui-gray-light py-2 pr-2 pl-3 flex justify-between items-center h-11">
  <a
    class="test-roomname text-sm font-nunito text-ui-dark overflow-hidden w-[155px] text-ellipsis whitespace-nowrap underline"
    [routerLink]="['/dashboard', locationId, floorId, room.id]"
  >{{ room.friendlyName }}</a>
  <div class="flex items-center">
    <ng-container *ngIf="room.status !== entityStatus.Archived">
      <button
        class="btn-blue btn-small"
        (click)="assignRoom()"
        [class.bg-ui-blue-150]="appService.activeAttachmentId()"
        [disabled]="!floorplan"
        *ngIf="!room.unstructuredDataReference"
      >
        <i class="icon-Assign-a-Room text-3xl mr-1"></i>
        Assign a Room
      </button>
      <div
        class="flex items-center test-floorplan-button-block"
        *ngIf="room.unstructuredDataReference"
      >
        <button
          class="btn-white-stroked hover:text-white hover:bg-ui-blue-100 active:bg-ui-blue-150 btn-small mr-2"
          (click)="assignRoom()"
          [class]="appService.activeAttachmentId() === room.id ? 'bg-ui-blue-150 text-white' : ''"
          [disabled]="!floorplan"
        >
          <i class="icon-move text-3xl mr-1"></i>
          Move/Resize
        </button>
        <button
          class="btn-white-stroked btn-small hover:bg-ui-red-50 hover:text-white active:bg-ui-red-150"
          (click)="removeCoordinates()"
        >
          <i class="icon-Unassign text-3xl mr-1"></i>
          Unassign
        </button>
      </div>
      <a
        class="inline-flex items-center ml-2"
        [href]="room.externalReference"
        [matTooltip]="'Doc link'"
        *ngIf="room.externalReference"
        target="_blank"
      >
        <i class="icon-link text-3xl text-ui-gray-100"></i>
      </a>
      <i
        class="icon-edit text-ui-gray-100 text-3xl cursor-pointer inline-flex items-center mr-1 ml-2"
        (click)="edit()"
        [matTooltip]="'Edit'"
      ></i>
      <i
        class="test-delete-button icon-add-to-archive text-ui-gray-100 text-3xl cursor-pointer"
        (click)="archiveRoom()"
        [matTooltip]="'Archive'"
      ></i>
    </ng-container>
    <i
      class="icon-restore text-ui-gray-100 text-3xl cursor-pointer inline-flex items-center"
      (click)="unarchiveRoom()"
      [matTooltip]="'Restore'"
      *ngIf="room.status === entityStatus.Archived"
    ></i>
  </div>
</div>
