import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {inject, Injectable, signal} from "@angular/core";
import {filter} from "rxjs";

@Injectable({providedIn: 'root'})
export class NavigationService {
  route = signal<ActivatedRoute | null>(null);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);

  constructor() {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => {
        let route = this.activatedRoute
        while (route.firstChild) {
          route = route.firstChild;
        }
        this.route.set(route);
      });
  }
}