import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, input, output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PeriodFilterEnum } from '@core';
import { WidgetDeviceChart } from '@dashboards';

import { DateRangeSelectorComponent, DeviceDataPointSelectorComponent, DeviceSelectorComponent } from '../../selectors';
import { LocationFullOptionsComponent } from '../location-full-options/location-full-options.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DateRangeSelectorComponent,
    DeviceDataPointSelectorComponent,
    DeviceSelectorComponent,
    LocationFullOptionsComponent,
    NgClass,
  ],
  selector: 'avs-li-data-point-options',
  standalone: true,
  templateUrl: './data-point-options.component.html',
})
export class DataPointOptionsComponent {
  change = output<Partial<WidgetDeviceChart>>();
  config = input<WidgetDeviceChart>();
  hideDateRangeInput = input<boolean>(false);

  protected locationConfig = computed(() => {
    if (this.config()?.locationId) {
      return {
        floorId: this.config()!.floorId,
        locationId: this.config()!.locationId,
        roomId: this.config()!.roomId,
      };
    }

    return null;
  });

  protected ePeriodFilterEnum = PeriodFilterEnum;
  protected form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      datapointName: ['', Validators.required],
      dateRange: [PeriodFilterEnum, Validators.required],
      deviceId: ['', Validators.required],
      floorId: ['', Validators.required],
      locationId: ['', Validators.required],
      roomId: ['', Validators.required],
    });

    const initialEffect = effect(() => {
      this.form.patchValue(this.config() as { [key: string]: any; });
      initialEffect.destroy();
    }, { manualCleanup: true });
  }

  protected patchForm(value: Record<string, undefined | string>) {
    this.form.patchValue(value);
    this.change.emit(this.form.valid ? this.form.value : null);
  }

  protected patchLocationForm(form: Partial<WidgetDeviceChart> | null) {
    this.form.reset();
    this.patchForm(form ?? {});
  }
}
