import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, ElementRef, inject, input, OnDestroy, OnInit, output, signal, viewChild, viewChildren } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridsterComponent, type GridsterConfig, GridsterModule } from 'angular-gridster2';
import { debounceTime, filter, Subject } from 'rxjs';

import { LayoutMode, Widget } from '../../models';
import { WidgetContainerComponent } from '../widget-container';
import { LayoutHelper } from './config';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    GridsterModule,
    WidgetContainerComponent,
  ],
  selector: 'avs-li-layout',
  standalone: true,
  styleUrl: './layout.component.scss',
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit, OnDestroy {
  change = output<Widget[]>();
  deleteWidget = output<Widget>();
  editWidget = output<Widget>();
  mode = input<LayoutMode>('view');
  widgets = input.required<Widget[]>();

  protected containers = viewChildren(WidgetContainerComponent);
  protected items = signal<Widget[]>([]);
  protected options = signal<GridsterConfig>({});

  private document = inject(DOCUMENT);
  private el = inject<ElementRef<HTMLElement>>(ElementRef);
  private gridster = viewChild(GridsterComponent);
  private observer?: ResizeObserver;
  private resize$ = new Subject<void>();

  constructor() {
    const options = LayoutHelper.createConfig();

    options.initCallback = this.gridSizeChanged.bind(this);
    options.gridSizeChangedCallback = this.gridSizeChanged.bind(this);
    options.itemChangeCallback = this.onGridsterChange.bind(this);

    this.options.set(options);

    effect(() => {
      const mode = this.mode();

      options.resizable!.enabled = mode === 'edit';
      options.draggable!.enabled = mode === 'edit';
      options.addEmptyRowsCount = mode === 'edit' ? 2 : 0;

      this.options.set({ ...options });
    }, { allowSignalWrites: true });

    effect(() => this.items.set(this.widgets()), { allowSignalWrites: true });

    this.resize$
      .pipe(
        filter(() => !!this.gridster()),
        debounceTime(200),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.updateGridItemWidth();
        this.gridster()!.onResize();

        this.containers().forEach((container) => container.itemChanged());
      });
  }

  ngOnDestroy() {
    this.observer?.disconnect();
  }

  ngOnInit() {
    this.observer = new ResizeObserver(() => this.resize$.next());
    this.observer.observe(this.el.nativeElement);
  }

  private gridSizeChanged() {
    setTimeout(() => this.renderGrid());
  }

  private onGridsterChange() {
    this.change.emit(this.items());
  }

  private renderGrid() {
    const el = this.gridster()!.el;
    const rows = Array.from(el.querySelectorAll('.gridster-row'));

    for (const row of rows) {
      if (row.innerHTML.trim() === '') {
        this.renderRowItems(row as HTMLElement);
      }
    }
  }

  private renderRowItems(row: HTMLElement) {
    const gridster = this.gridster()!;
    const cols = gridster.columns;

    for (let i = 0; i < cols; i++) {
      const cell = this.document.createElement('div');

      cell.classList.add('grid-item');

      row.appendChild(cell);
    }
  }

  private updateGridItemWidth() {
    const width = LayoutHelper.getGridItemWidth();

    this.options.set({
      ...this.options(),
      fixedRowHeight: width,
    });
  }
}
