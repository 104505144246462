import type { OnDestroy } from '@angular/core';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { deviceColumnOptions } from '@mocks';
import type { DeviceData, FloorSpaceModel, IncidentModel, LocationFullData, RoomSpaceModel, SpaceStats } from '@models';
import { DeviceTableCols, EntityStatus, initialStats } from '@models';
import { Store } from '@ngrx/store';
import {
  getDeviceBySpaceId,
  isFullLocationLoaded,
  isSuperAdmin,
  loadDeviceCollectionBySpaceId,
  locationState,
  selectSpaceIncidents,
} from '@ngrx-store';
import { AppService, IncidentsFilterService, IncidentsService } from '@services';
import { RoomPopoverComponent } from '@standalone/room-popover/room-popover.component';
import type { Observable } from 'rxjs';
import { filter } from 'rxjs';

@Component({
  templateUrl: './space-details.component.html',
})
export class SpaceDetailsComponent implements OnDestroy {
  currentFloor: FloorSpaceModel | undefined;
  currentFloorId: string | null = null;
  currentRoomId: string | null = null;
  isFullLocationLoaded$: Observable<boolean>;
  locationFullData!: LocationFullData;
  isSuperAdmin$: Observable<boolean>;
  protected currentLocationId: string | null = null;
  protected currentRoom: RoomSpaceModel | undefined;
  protected devicesTableColumnOptions = deviceColumnOptions;
  protected devicesTableDisplayedColumns = [
    DeviceTableCols.INDEX,
    DeviceTableCols.DEVICE_NAME,
    DeviceTableCols.DEVICE_TYPE,
    DeviceTableCols.MANUFACTURER,
    DeviceTableCols.MODEL,
    DeviceTableCols.SERIAL_NUMBER,
    DeviceTableCols.STATUS,
    DeviceTableCols.INCIDENTS,
    DeviceTableCols.ACTIONS,
  ];
  protected roomList: RoomSpaceModel[] = [];
  protected buildingStats: SpaceStats = initialStats();
  protected incidentTableColumns = ['status', 'faultname', 'deviceId', 'room', 'deviceType', 'reported'];
  protected floorplanEnabled = true;
  protected resolvedIncidents: IncidentModel[] = [];
  protected resolvedIncidentsLoading = false;
  protected destroyRef = inject(DestroyRef);
  protected roomPopover = RoomPopoverComponent;
  protected roomDevices: DeviceData[] | undefined;
  protected isDeviceTableInitialized = false;
  protected isIncidentsTableInitialized = false;
  private appService = inject(AppService);
  private incidentsFilterService = inject(IncidentsFilterService);
  private incidentsService = inject(IncidentsService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private store = inject(Store);

  constructor() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntilDestroyed(),
      )
      .subscribe(() => this.defineCurrentFloor());

    this.route.paramMap
      .pipe(takeUntilDestroyed())
      .subscribe(paramMap => {
        this.currentLocationId = paramMap.get('locationId');
        this.currentFloorId = paramMap.get('floorId');
        this.currentRoomId = paramMap.get('roomId');
        if (this.currentRoomId) {
          this.floorplanEnabled = false;
          this.isDeviceTableInitialized = false;
          this.isIncidentsTableInitialized = false;
          this.store
            .select(getDeviceBySpaceId(this.currentRoomId))
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(devices => {
              this.isDeviceTableInitialized = true;
              this.roomDevices = devices;
            });
        }
        this.getSpaceIncidents();
        this.normalizeBuildingStats();
        this.resolvedIncidents = [];
      });

    this.isSuperAdmin$ = this.store.select(isSuperAdmin);
    this.isFullLocationLoaded$ = this.store.select(isFullLocationLoaded);
    this.store.select(locationState)
      .pipe(
        filter(Boolean),
        takeUntilDestroyed(),
      )
      .subscribe(res => {
        this.locationFullData = res;
        this.defineCurrentFloor();
      });

    this.incidentsFilterService.resolvedDates$
      .pipe(takeUntilDestroyed())
      .subscribe(value => this.getResolvedIncidents(value));
  }

  ngOnDestroy() {
    this.incidentsFilterService.resetState();
  }

  protected async spaceChanged({ spaceId, isFloorSwitch }: { isFloorSwitch: boolean; spaceId: string; }) {
    let path = this.router.url.split('/');

    if (isFloorSwitch && this.currentRoomId) {
      path.pop();
    }
    path[path.length - 1] = spaceId;
    path = path.filter(n => n);
    await this.router.navigate(path);
  }

  protected toggleFloorplan(event: MatSlideToggleChange) {
    this.floorplanEnabled = event.checked;
  }

  protected defineCurrentFloor() {
    if (this.locationFullData) {
      const currentFloor = this.locationFullData.floors.find(f => f.id === this.currentFloorId);

      if (currentFloor) {
        this.currentFloor = currentFloor;
        this.normalizeBuildingStats();
        this.roomList = this.currentFloor?.rooms || [];
      }

      if (this.currentRoomId && this.currentFloor && this.currentLocationId) {
        this.currentRoom = this.roomList.find(({ id }) => this.currentRoomId === id);
        this.store.dispatch(loadDeviceCollectionBySpaceId({
          locationId: this.currentLocationId,
          roomId: this.currentRoomId,
        }));
      }
    }
  }

  protected normalizeBuildingStats() {
    if (this.currentFloor) {
      if (this.currentRoomId) {
        const room = this.currentFloor.rooms?.find(({ id }) => id === this.currentRoomId);

        if (room) {
          const isRoomArchived = room.status === EntityStatus.Archived;

          this.buildingStats.incidentAlert = isRoomArchived ? 0 : room.incidentCountByStatuses.newCount;
          this.buildingStats.incidentInProgress = isRoomArchived ? 0 : room.incidentCountByStatuses.inProgressCount;
          this.buildingStats.deviceActive = isRoomArchived ? 0 : room.devicesByStatuses.activeCount;
          this.buildingStats.devicePaused = isRoomArchived ? 0 : room.devicesByStatuses.pausedCount;
        }
      } else {
        this.buildingStats.rooms =
          this.currentFloor.rooms?.filter(({ status }) => status !== EntityStatus.Archived).length || 0;
        this.buildingStats.incidentAlert =
          this.currentFloor.status === EntityStatus.Archived ? 0 : this.currentFloor.incidentCountByStatuses.newCount;
        this.buildingStats.incidentInProgress =
          this.currentFloor.status === EntityStatus.Archived
            ? 0
            : this.currentFloor.incidentCountByStatuses.inProgressCount;
        this.buildingStats.deviceActive =
          this.currentFloor.status === EntityStatus.Archived ? 0 : this.currentFloor.devicesByStatuses.activeCount;
        this.buildingStats.devicePaused =
          this.currentFloor.status === EntityStatus.Archived ? 0 : this.currentFloor.devicesByStatuses.pausedCount;
      }
    }
  }

  protected getSpaceIncidents() {
    const currentSpaceId = this.currentRoomId || this.currentFloorId;

    if (currentSpaceId) {
      this.store
        .select(selectSpaceIncidents(currentSpaceId))
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(incidents => {
          this.incidentsFilterService.initOpenIncidents(incidents);
          if (!this.isIncidentsTableInitialized) {
            this.incidentsFilterService.initFilterTableType({
              displayedColumns: this.incidentTableColumns,
              isFloorTable: true,
              isRoomTable: !!this.currentRoomId,
            });
            this.isIncidentsTableInitialized = true;
          }
        });
    }
  }

  protected getResolvedIncidents(dateRange: { endDateTime: string; startDateTime: string; }) {
    const currentSpaceId = this.currentRoomId || this.currentFloorId;

    if (dateRange.startDateTime && dateRange.endDateTime && currentSpaceId) {
      this.resolvedIncidentsLoading = true;
      this.incidentsService
        .getAllResolvedIncidents(this.appService.currentClient, {
          ...dateRange,
          spaceId: currentSpaceId,
        })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          error: () => {
            this.resolvedIncidentsLoading = false;
          },
          next: data => {
            this.resolvedIncidents = data;
            this.incidentsFilterService.initResolvedIncidents(data);
            this.resolvedIncidentsLoading = false;
          },
        });
    }
  }
}
