<mat-card class="card h-full px-3">
  <div class="border-b-ui-gray-light border-b flex mb-2">
    <mat-button-toggle-group
      class="desktop-toggle-group"
      (change)="changePeriod($event)"
      [disabled]="isLoading"
      [value]="periodFilterValue"
      aria-label="period of time"
      name="period"
    >
      <mat-button-toggle [value]="eReportYear.CURRENT">
        <span class="item-status-title">Current Year</span>
      </mat-button-toggle>
      <mat-button-toggle [value]="eReportYear.PREVIOUS">
        <span class="item-status-title">Previous Year</span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  @if (!isLoading && incidentMonthChartData) {
    <highcharts-chart
      class="highcharts-no-legend highcharts-small-title highcharts-classic-axes"
      [Highcharts]="Highcharts"
      [options]="incidentMonthChartData"
    />
  } @else {
    <ngx-skeleton-loader [theme]="{ height: '400px', marginBottom: 0 }" />
  }

  @if (!incidentMonthChartData) {
    <div class="h-[400px]"></div>
  }
</mat-card>
