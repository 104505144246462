<app-bottom-sheet-mobile-header (closeEvent)="close()" />
<div class="mobile-action-sheet">
  <div class="mx-6 my-3 flex justify-between items-center">
    <h2 class="sheet-title">Filters</h2>
    @if(hasSelectedFilters()) {
    <button class="mobile-filter-clear-all-btn" (click)="filtersStore.clearFilters(data.showLocationFilter)">
      Clear All
    </button>
    }
  </div>
  <div class="mobile-action-sheet-content">
    <mat-accordion class="bottom-sheet-accordion">
      @if (filtersStore.deviceTypeOptions().length) {
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            @let deviceTypeSelectedOptionsLength = filtersStore.deviceType().length;
            <div>
              Device Type @if(deviceTypeSelectedOptionsLength) {
              <span class="test-mobile-multiselect-device-type-inicator mobile-multiselect-filter-inicator"
                >+{{ deviceTypeSelectedOptionsLength }}</span
              >
              }
            </div>
            @if(deviceTypeSelectedOptionsLength) {
            <button
              class="test-mobile-device-type-clear-btn mobile-filter-clear-btn"
              (click)="$event.stopPropagation(); filtersStore.patchFilters({ deviceType: [] })">
              Clear
            </button>
            }
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="test-device-type-filter">
          @for (deviceType of filtersStore.deviceTypeOptions() | sort : 'asc' : 'title'; track deviceType.value) {
          <li>
            <mat-checkbox
              class="full-width-checkbox"
              [class]="'test-device-type-option-' + deviceType.value"
              [value]="deviceType.value"
              (change)="
                filtersStore.onCheckboxChange({
                  value: $event.source.value,
                  checked: $event.checked,
                  field: 'deviceType'
                })
              "
              [checked]="filtersStore.deviceType().includes(deviceType.value)">
              {{ deviceType.title }}
            </mat-checkbox>
          </li>
          }
        </ul>
      </mat-expansion-panel>
      } @if (filtersStore.deviceMakerOptions().length) {
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            @let deviceMakerSelectedOptionsLength = filtersStore.deviceMaker().length;
            <div>
              Manufacturer @if (deviceMakerSelectedOptionsLength) {
              <span class="test-mobile-multiselect-manufacturer-inicator mobile-multiselect-filter-inicator"
                >+{{ deviceMakerSelectedOptionsLength }}</span
              >
              }
            </div>
            @if (deviceMakerSelectedOptionsLength) {
            <button
              class="test-mobile-manufacturer-clear-btn mobile-filter-clear-btn"
              (click)="$event.stopPropagation(); filtersStore.patchFilters({ deviceMaker: [] })">
              Clear
            </button>
            }
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="test-manufacturer-filter">
          @for (deviceMaker of filtersStore.deviceMakerOptions() | sort : 'asc' : 'title'; track deviceMaker.value) {
          <li>
            <mat-checkbox
              class="full-width-checkbox"
              [class]="'test-manufacturer-option-' + deviceMaker.value"
              [value]="deviceMaker.value"
              (change)="
                filtersStore.onCheckboxChange({
                  value: $event.source.value,
                  checked: $event.checked,
                  field: 'deviceMaker'
                })
              "
              [checked]="filtersStore.deviceMaker().includes(deviceMaker.value)">
              {{ deviceMaker.title }}
            </mat-checkbox>
          </li>
          }
        </ul>
      </mat-expansion-panel>
      } @if (data.showLocationFilter) {
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="mobile-single-select-title">
              Building @if(filtersStore.selectedLocationName()) {:
              <span class="test-building-selected-value font-normal">{{ filtersStore.selectedLocationName() }}</span
              >}
            </div>
            @if (filtersStore.building()) {
            <button
              class="test-building-filter-clear-btn mobile-filter-clear-btn"
              (click)="$event.stopPropagation(); filtersStore.patchFilters({ building: '', floor: '', room: '' })">
              Clear
            </button>
            }
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="test-building-select mobile-single-filter-select">
          @for (location of filtersStore.locationOptions() | sort : 'asc' : 'title' : 'all'; track location.value) {
          <li
            [class]="'test-building-option-' + (location.value || 'all')"
            [class.checked]="location.value === filtersStore.building()"
            (click)="filtersStore.patchFilters({ building: location.value })">
            <span>{{ location.title }}</span>
            <i class="icon-check text-ui-green-light text-3xl"></i>
          </li>
          }
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel [disabled]="!filtersStore.building()" class="test-floor-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="mobile-single-select-title">
              Floor @if(filtersStore.floor()) {:
              <span class="test-floor-selected-value font-normal">{{ filtersStore.selectedFloorName() }}</span
              >}
            </div>
            @if (filtersStore.floor()) {
            <button
              class="test-floor-filter-clear-btn mobile-filter-clear-btn"
              (click)="$event.stopPropagation(); filtersStore.patchFilters({ floor: '', room: '' })">
              Clear
            </button>
            }
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="test-floor-select mobile-single-filter-select">
          @for (floor of filtersStore.floorOptions() | sort : 'asc' : 'title' : 'all'; track floor.value) {
          <li
            [class]="'test-floor-option-' + (floor.value || 'all')"
            [class.checked]="floor.value === filtersStore.floor()"
            (click)="filtersStore.patchFilters({ floor: floor.value })">
            <span>{{ floor.title }}</span>
            <i class="icon-check text-ui-green-light text-3xl"></i>
          </li>
          }
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel [disabled]="!filtersStore.floor()" class="test-room-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="mobile-single-select-title">
              Room @if(filtersStore.room()) {:
              <span class="test-room-selected-value font-normal">{{ filtersStore.selectedRoomName() }}</span
              >}
            </div>
            @if (filtersStore.room()) {
            <button
              class="test-room-filter-clear-btn mobile-filter-clear-btn"
              (click)="$event.stopPropagation(); filtersStore.patchFilters({ room: '' })">
              Clear
            </button>
            }
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="test-room-select mobile-single-filter-select">
          @for (room of filtersStore.roomOptions() | sort : 'asc' : 'title' : 'all'; track room.value) {
          <li
            [class]="'test-room-option-' + (room.value || 'all')"
            [class.checked]="room.value === filtersStore.room()"
            (click)="filtersStore.patchFilters({ room: room.value })">
            <span>{{ room.title }}</span>
            <i class="icon-check text-ui-green-light text-3xl"></i>
          </li>
          }
        </ul>
      </mat-expansion-panel>
      }
    </mat-accordion>
  </div>
</div>
