import { DestroyRef, inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppService } from '@services';
import {
  addIncident,
  allClientDevicesLoaded,
  AppSettingsActions,
  AppState,
  login,
  logout,
  resolveIncidents,
  updateDevice,
} from '@ngrx-store';
import { tap } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';
import { environment } from '@env';
import { fromEvent, Observable, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IncidentModel } from '@models';
import { Store } from '@ngrx/store';
import { SocketEventType } from '../models';
export const SocketEvents = new Subject<{type:SocketEventType, data: IncidentModel | IncidentModel[]}>();
@Injectable()
export class WebSocketEffects {
  private socket?: Socket;
  private appService = inject(AppService);
  private actions$ = inject(Actions);
  private store = inject(Store<AppState>);
  private destroyRef = inject(DestroyRef);

  private connect() {
    const user = localStorage.getItem('user');
    const accessToken = user && JSON.parse(user)['accessToken'];

    this.socket = io(environment.wsUrl, {
      transports: ['websocket'],
      reconnection: true,
      reconnectionDelay: 3000,
      autoConnect: true,
      path: '/ws',
      auth: {
        token: accessToken,
      },
    });

    this.socket?.on('connect', () => {
      this.emit('client:join', this.appService.currentClient);
    });
    this.socket?.on('error', error => {
      console.error(`Socket error: ${error}`);
    });
  }

  private emit<T>(event: string, data: T) {
    this.socket?.emit(event, data);
  }

  private on<T>(event: string): Observable<T> {
    return fromEvent<T>(this.socket as Socket, event);
  }

  private disconnect() {
    this.socket?.disconnect();
  }

  private connectSocketAnotherRoom() {
    this.disconnect();
    this.getIncidentsData();
  }

  private handleIncident(message: unknown) {
    const incident = message as IncidentModel;
    const device = incident.device;
    this.store.dispatch(updateDevice({ device }));
    this.store.dispatch(addIncident({ incident }));
  }

  private resolveIncident(message: unknown) {
    const incidents = message as IncidentModel[];
    const devices = incidents.map(i => i.device);
    this.store.dispatch(resolveIncidents({ incidents }));
    this.store.dispatch(allClientDevicesLoaded({ devices }));
  }

  private getIncidentsData() {
    this.connect();
    this.on(SocketEventType.INCIDENT_CREATED)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(incident => {
        this.handleIncident(incident);
        SocketEvents.next({type:SocketEventType.INCIDENT_CREATED, data : incident as IncidentModel})
      });

    this.on(SocketEventType.INCIDENT_IN_PROGRESS)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(incident => {
        this.handleIncident(incident);
        SocketEvents.next({type:SocketEventType.INCIDENT_IN_PROGRESS, data : incident as IncidentModel})
      });

    this.on(SocketEventType.INCIDENT_RESOLVED)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(incidents => {
        this.resolveIncident(incidents);
        SocketEvents.next({type:SocketEventType.INCIDENT_RESOLVED, data : incidents as IncidentModel})
      });
  }

  private clientIdChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppSettingsActions.clientIdChanged),
        tap(_ => {
          this.connectSocketAnotherRoom();
        }),
      ),
    { dispatch: false },
  );

  private userLoggedOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        tap(_ => {
          this.disconnect();
        }),
      ),
    { dispatch: false },
  );

  private userSignedIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(login),
        tap(_ => {
          this.getIncidentsData();
        }),
      ),
    { dispatch: false },
  );
}
