import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  DeviceManagementComponent,
} from '@app/modules/devices/containers/device-management/device-management.component';
import { RoleGuard } from '@app-lib';
import { addDeviceFormResolver, deviceDetailsResolver } from '@ngrx-store';
import { DeviceService } from '@services';
import { HighchartsChartComponent } from 'highcharts-angular';

import { AddDeviceComponent } from './containers/add-device/add-device.component';
import { DeviceDetailsComponent } from './containers/device-details/device-details.component';
import { DevicesMainPageComponent } from './containers/devices-main-page/devices-main-page.component';
import { EditDeviceComponent } from './containers/edit-device/edit-device.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        component: DevicesMainPageComponent,
        path: '',
      },
      {
        canActivate: [RoleGuard],
        component: AddDeviceComponent,
        data: { breadcrumb: 'Add Device' },
        path: 'add-device',
        resolve: { addDeviceFormResolver },
      },
      {
        canActivate: [RoleGuard],
        component: DeviceManagementComponent,
        data: { breadcrumb: 'Device Management' },
        path: 'device-management',
        resolve: { addDeviceFormResolver },
      },
      {
        canActivate: [RoleGuard],
        component: EditDeviceComponent,
        data: { breadcrumb: 'Edit Device' },
        path: 'edit/:locationId/:deviceId',
        resolve: { addDeviceFormResolver, deviceDetailsResolver },
      },
      {
        component: DeviceDetailsComponent,
        path: ':locationId/:deviceId',
        resolve: { deviceDetailsResolver },
      },
    ]),
  ],
  providers: [HighchartsChartComponent, DeviceService],
})
export class DevicesModule {
}
