import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserInfoComponent } from '@app/modules/user-profile/containers/user-info/user-info.component';

@NgModule({
  imports: [
    RouterModule.forChild([{ component: UserInfoComponent, path: '' }]),
  ],
})
export class UserProfileModule {
  static forRoot(): ModuleWithProviders<UserProfileModule> {
    return {
      ngModule: UserProfileModule,
      providers: [],
    };
  }
}
