import { Component, computed, input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DeviceData } from '@models';

@Component({
  imports: [RouterModule, MatTooltipModule, MatCardModule],
  selector: 'avs-fe-device-location-widget',
  standalone: true,
  styleUrl: './device-location-widget.component.scss',
  templateUrl: './device-location-widget.component.html',
})
export class DeviceLocationWidgetComponent {
  device = input.required<DeviceData>();
  address = computed(() => {
    const device = this.device();

    return device.location?.address
      ? `${device.location.address.streetAddress || ''} \
        ${device.location.address?.city || ''} \
        ${device.location.address.stateProvince || ''} \
        ${device.location.address?.postalCode || ''} \
        ${device.location.address.country || ''}`
      : '';
  });
}
