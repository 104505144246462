<app-page-header>
  <div class="mb-4 leading-10">My Profile</div>
</app-page-header>
<mat-card class="card pt-2">
  <mat-tab-group
    dynamicHeight
    mat-align-tabs="start"
    mat-stretch-tabs="false"
  >
    <mat-tab label="Personal Info">
      @if (user$ | async; as user) {
        <app-personal-profile [user]="user" />
      }
    </mat-tab>
    @if (isSuperAdmin$ | async) {
      <mat-tab label="Alert Settings">
        <app-alert-settings />
      </mat-tab>
    }
  </mat-tab-group>
</mat-card>
<div class="card-separator"></div>
