<div class="flex justify-between items-center mb-4">
  <app-page-header>
    <div class="leading-10">Users Management</div>
  </app-page-header>
  <a
    class="btn-green btn-medium"
    [showForClientAdmin]="true"
    appRestrictedEl
    routerLink="add-new-user"
  >
    <mat-icon fontIcon="add" />
    Add New User
  </a>
</div>
<mat-card class="card shadow-card pb-0 pt-0">
  <mat-card-content class="pb-2 pt-0 px-0">
    <app-users-table tableId="users-main-table" />
  </mat-card-content>
</mat-card>
