<div class="flex justify-between items-center mb-5">
  <div>
    <app-page-header>Add New User</app-page-header>
    <app-breadcrumbs [routeNames]="['Add New User']"></app-breadcrumbs>
  </div>
  <label
    class="cursor-pointer btn-medium btn-base px-4 py-2"
    [class]="(isLoading$ | async) === false ? 'btn-green' : 'disabled-base'"
    for="create-user-submit"
  >
    @if (isLoading$ | async) {
      <mat-spinner
        class="mr-1"
        diameter="20"
      />
    } @else {
      <mat-icon>add</mat-icon>
    }
    Create New User
  </label>
</div>
<input
  type="text"
  class="hidden"
/>
@if (user) {
  <mat-card class="card">
    <mat-card-content class="p-0">
      <form
        (ngSubmit)="submitForm()"
        [formGroup]="form"
      >
        <mat-card-title class="card-title pb-4">Basic Info</mat-card-title>
        <mat-card-content class="p-0">
          <div class="flex flex-row">
            <label class="input-main-label">First name:*</label>
            <mat-form-field class="input-classic">
              <input
                type="text"
                formControlName="firstName"
                matInput
                placeholder="Enter first name"
              />
              <mat-error>Input is not valid</mat-error>
            </mat-form-field>
          </div>

          <div class="flex flex-row">
            <label class="input-main-label">Last name:*</label>
            <mat-form-field class="input-classic">
              <input
                type="text"
                formControlName="lastName"
                matInput
                placeholder="Enter last name"
              />
              <mat-error>Input is not valid</mat-error>
            </mat-form-field>
          </div>

          @if (roleOptions[user.role.name]) {
            <div class="pb-4">
              <div class="flex flex-row">
                <label class="input-main-label">Role:*</label>
                @if (currentClient | async; as client) {
                  <mat-form-field class="select-classic">
                    <mat-select
                      formControlName="roleId"
                      panelClass="single-select-overlay"
                      placeholder="Select role"
                    >
                      @for (role of roleOptions[user.role.name]; track role.id) {
                        <mat-option
                          [isManagingClient]="client?.isManagingClient"
                          [value]="role.id"
                          appSuperAdminRoleFilter
                        >
                          {{ role.name }}
                        </mat-option>
                      }
                    </mat-select>
                    <mat-icon
                      class="arrow-down"
                      matSuffix
                    >keyboard_arrow_down</mat-icon>
                    <mat-icon
                      class="arrow-up"
                      matSuffix
                    >keyboard_arrow_up</mat-icon>
                  </mat-form-field>
                }
              </div>
            </div>
          }

          <mat-card-title class="card-title pb-4 pt-12">Contact Info</mat-card-title>

          <div class="flex flex-row">
            <label class="input-main-label">E-mail:*</label>
            <mat-form-field class="input-classic">
              <input
                type="text"
                formControlName="email"
                matInput
                placeholder="Enter e-mail"
              />
              <mat-error>Input is not valid</mat-error>
            </mat-form-field>
          </div>

          <div
            class="flex flex-row"
            formGroupName="phone"
          >
            <label class="input-main-label">Phone number:*</label>
            <div class="flex justify-between">
              <mat-form-field class="input-classic max-w-[150px] mr-2">
                <mat-select
                  (selectionChange)="changeMaskSettings($event)"
                  formControlName="countryCode"
                >
                  @for (country of COUNTRY_LIST; track country.code) {
                    <mat-option [value]="country.code">{{ country.code }} ({{ country.phoneCode }})</mat-option>
                    }
                </mat-select>
              </mat-form-field>
              <mat-form-field class="input-classic flex-grow max-w-[245px]">
                <input
                  type="text"
                  [mask]="phoneMask()"
                  [placeholder]="phoneMask()"
                  [shownMaskExpression]="phoneMaskExpression()"
                  [validation]="true"
                  formControlName="number"
                  matInput
                />
                <mat-error>Input is not valid</mat-error>
              </mat-form-field>
            </div>
          </div>
          <mat-card-title class="card-title pt-7">Notifications</mat-card-title>
          <div
            class="flex flex-row ml-56 w-[420px] px-2 justify-between"
            formGroupName="notificationsPreferences"
          >
            <mat-slide-toggle
              class="test-mail-toggle main-toggle"
              formControlName="emailEnabled"
              labelPosition="before"
            >
              <span>Email:</span>
              {{ form.get('notificationsPreferences.emailEnabled')?.value ? 'On' : 'Off' }}
            </mat-slide-toggle>
            <mat-slide-toggle
              class="test-sms-toggle main-toggle"
              formControlName="smsEnabled"
              labelPosition="before"
            >
              <span>SMS:</span>
              {{ form.get('notificationsPreferences.smsEnabled')?.value ? 'On' : 'Off' }}
            </mat-slide-toggle>
          </div>

          <input
            id="create-user-submit"
            type="submit"
            hidden="hidden"
          />
        </mat-card-content>
      </form>
    </mat-card-content>
  </mat-card>
  }
