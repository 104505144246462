import { Component, input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { removeSpacesValidator } from '@app-lib';
import { EntityStatus, FloorSpaceModel, RoomSpaceModel, SpaceType } from '@models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { addRoom, AppState, isRoomLoading,LocationActions } from '@ngrx-store';
import { Observable, Subscription } from 'rxjs';

type RoomStatusFilter = 'current' | 'archived';

@Component({
  selector: 'avs-fe-handle-rooms',
  templateUrl: './handle-rooms.component.html',
})
export class HandleRoomsComponent implements OnDestroy, OnChanges {
  floor = input.required<FloorSpaceModel>();
  locationId = input.required<string>();
  isPartOfExpandableItem = input<boolean>(false);
  form: FormGroup;
  roomSuccessSub$: Subscription;
  roomIsLoading$: Observable<boolean>;
  roomStatusFilter: RoomStatusFilter = 'current';
  protected filteredRooms: RoomSpaceModel[] = [];
  private search = '';

  constructor(private fb: FormBuilder, private store: Store<AppState>, actions$: Actions) {
    this.form = this.fb.group({
      externalReference: ['', [removeSpacesValidator]],
      friendlyName: ['', [removeSpacesValidator]],
    });

    this.roomIsLoading$ = this.store.select(isRoomLoading);

    this.roomSuccessSub$ = actions$.pipe(ofType(LocationActions.addRoomSuccess)).subscribe(() => {
      this.form.reset({
        externalReference: '',
        friendlyName: '',
      });
      this.form.markAsPristine();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['floor']) {
      this.filterRooms();
    }
  }

  ngOnDestroy() {
    this.roomSuccessSub$?.unsubscribe();
  }

  submit() {
    const friendlyName = this.form.value.friendlyName.trim();
    const externalReference = this.form.value.externalReference.trim();

    if (this.locationId()) {
      this.store.dispatch(
        addRoom({
          data: {
            externalReference,
            friendlyName,
            parentSpaceId: this.floor().id,
            type: SpaceType.room,
          },
          locationId: this.locationId(),
        }),
      );
    }
  }

  roomSearch(value: string) {
    this.search = value;
    this.filterRooms();
  }

  onRoomStatusFilterChange(change: MatButtonToggleChange) {
    this.roomStatusFilter = change.value;
    this.filterRooms();
  }

  filterRooms() {
    const rooms = this.floor().rooms;

    this.filteredRooms = rooms
      ? rooms.filter(({ friendlyName, status }) => {
          const friendlyNameMatch = friendlyName.toLowerCase().includes(this.search.toLowerCase());
          const statusMatch =
            this.roomStatusFilter === 'archived' ? status === EntityStatus.Archived : status !== EntityStatus.Archived;

          return friendlyNameMatch && statusMatch;
        })
      : [];
  }
}
