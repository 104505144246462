<mat-form-field class="select-classic mb-[22px]">
  <mat-select
    (valueChange)="change.emit($event)"
    [placeholder]="type() === eSpaceType.floor ? 'Select floor' : 'Select room'"
    [value]="value()"
    panelClass="single-select-overlay"
  >
    @for (space of spaces(); track space.id) {
      <mat-option [value]="space.id">
        {{ space.friendlyName }}
      </mat-option>
    }
  </mat-select>
  <mat-icon
    class="arrow-down"
    matSuffix
  >keyboard_arrow_down</mat-icon>
  <mat-icon
    class="arrow-up"
    matSuffix
  >keyboard_arrow_up</mat-icon>
</mat-form-field>
