import { StartSettingsClass } from '@abstraction';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { AppState, loadAllIncidents, logout } from '@ngrx-store';
import { AppService, IdleUserService } from '@services';

import {
  openUserActivityDialog,
  UserActivityModalComponent,
} from './components/user-activity-modal/user-activity-modal.component';

@Component({
  selector: 'app-root',
  styleUrl: './app.component.scss',
  templateUrl: './app.component.html',
})
export class AppComponent extends StartSettingsClass implements OnInit, OnDestroy {
  @ViewChild('main') main: ElementRef | undefined;
  showActivityModal = false;
  dialogRef: MatDialogRef<UserActivityModalComponent> | null = null;
  userSessionTimeoutMinutes = 0;

  constructor(
    public override store: Store<AppState>,
    public override router: Router,
    private dialog: MatDialog,
    public override appService: AppService,
    private idleUserService: IdleUserService,
  ) {
    super(store, router, appService);
    this.checkThePlatform();
    this.onUserSelected = (isLoggedIn, user) => {
      if (isLoggedIn && !this.appService.isInitailizedAppSubscription) {
        this.appService.isInitailizedAppSubscription = true;
        this.userSessionTimeoutMinutes = user.homeClientUserSettings.userSessionTimeoutMinutes;
        this.initIdleWatching(this.userSessionTimeoutMinutes * 60);
        this.store.dispatch(loadAllIncidents());
      }
    };
    this.onNavigationEnd = previousUrl => {
      this.previousUrl = previousUrl;
      if (this.main) {
        this.main.nativeElement.scrollTop = 0;
      }
    };
  }

 scrollListener() {
    const autocompleteInput = document.querySelector('.autocomplete-input');

    if(autocompleteInput instanceof HTMLElement) {
      autocompleteInput.blur();
    }
  };

  checkThePlatform() {
    const redirectUrl = environment.mobileAppUrl;
    const userAgent = navigator.userAgent;
    const isMobile = /android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent);

    if (isMobile && redirectUrl) {
      window.location.href = redirectUrl;
    }
  }

  ngOnInit(): void {
    this.init();

    this.idleUserService.idle.onIdleStart.subscribe(() => {
      if (!this.showActivityModal) {
        this.showActivityModal = true;
        this.dialogRef = openUserActivityDialog(this.dialog, 31, this.userSessionTimeoutMinutes);
      }
    });

    this.idleUserService.idle.onIdleEnd.subscribe(() => {
      this.dialogRef?.close();
      this.dialogRef = null;
      this.showActivityModal = false;
    });

    this.idleUserService.idle.onTimeout.subscribe(() => {
      this.showActivityModal = false;
      this.idleUserService.stopWatching();
      this.dialog.closeAll();
      this.store.dispatch(logout());
    });
  }

  ngOnDestroy() {
    this.idleUserService.stopWatching();
  }

  initIdleWatching(idleTimeSeconds: number) {
    this.idleUserService.setIdleTime(idleTimeSeconds);
    this.idleUserService.startWatching();
  }
}
