@if (dashboard(); as dashboard) {
  @if (mode() === 'view') {
    @if (appService.isMobile) {
      <avs-li-mobile-dashboards-selector />
    } @else {
      <div class="flex justify-between items-center">
        <h2 class="flex-1 font-poppins text-2xl font-semibold">{{ dashboard.name }}</h2>
        <button
          class="btn-blue btn-medium"
          [queryParams]="{ mode: 'edit' }"
          [routerLink]="['.']"
          [showForClientAdmin]="true"
          appRestrictedEl
          queryParamsHandling="merge"
        >
          <mat-icon>edit</mat-icon>
          Edit Dashboard
        </button>
      </div>
    }
  } @else {
    <div class="flex gap-2 justify-between items-center mb-4">
      <div class="flex-1">
        <button
          class="inline-flex items-center font-nunito text-ui-dark text-sm font-semibold hover:underline"
          (click)="cancelEditing()"
        >
          <mat-icon>chevron_left</mat-icon>
          Back to {{ dashboard.name }}
        </button>

        @if (dashboard.id === 'new') {
          @if (cloneId()) {
            <h2 class="font-poppins text-2xl font-semibold">Clone Dashboard</h2>
          } @else {
            <h2 class="font-poppins text-2xl font-semibold">New Dashboard</h2>
          }
        } @else {
          <h2 class="font-poppins text-2xl font-semibold">Edit Dashboard</h2>
        }
      </div>

      @if (dashboard.id !== 'new') {
        <button
          class="btn-red btn-medium w-10 px-1"
          (click)="delete()"
        >
          <mat-icon class="material-icons-outlined">delete</mat-icon>
        </button>

        <a
          class="btn-gray btn-medium w-10 px-1"
          [queryParams]="{ mode: 'edit', cloneId: dashboard.id }"
          queryParamsHandling="merge"
          routerLink="/dashboards/new"
        >
          <mat-icon class="rotate-y-180">content_copy</mat-icon>
        </a>
      }

      <button
        class="btn-blue btn-medium"
        (click)="addWidget()"
      >
        <mat-icon>add</mat-icon>
        Add Widget
      </button>

      <button
        class="btn-green btn-medium"
        (click)="saveDashboard()"
        [disabled]="nameControl.invalid"
      >
        Save
      </button>
    </div>

    <mat-card class="px-5 pt-5 card pb-1">
      <div class="flex flex-row">
        <label class="input-main-label">Dashboard name:*</label>
        <mat-form-field class="input-classic">
          <input
            [formControl]="nameControl"
            matInput
            placeholder="Enter dashboard name"
            required
          />
          <mat-error>Dashboard name is required</mat-error>
        </mat-form-field>
      </div>
    </mat-card>
  }

  <avs-li-layout
    class="block mt-5"
    (change)="layoutChanged($event)"
    (deleteWidget)="deleteWidget($event)"
    (editWidget)="editWidget($event)"
    [mode]="mode()"
    [widgets]="widgets()"
  />
}
