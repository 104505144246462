import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { EntityStatus } from '@models';

@Component({
  imports: [NgClass],
  selector: 'app-entity-status',
  standalone: true,
  styleUrls: ['./entity-status.component.scss'],
  template: ` <span
    class="status-block"
    [ngClass]="{
    'status-active': status() === EntityStatus.Active,
    'status-paused': status() === EntityStatus.Paused,
    'status-archived': status() === EntityStatus.Archived,
  }"
    >{{ status() }}</span
  >`,
})
export class EntityStatusComponent {
  status = input<EntityStatus>();
  protected readonly EntityStatus = EntityStatus;
}
