import { type PeriodFilterEnum } from '@core';
import type { GridsterItem } from 'angular-gridster2';

export interface BaseWidgetConfig<Type> {
  locationId: string;
  name: string;
  type: Type;
}

export interface BaseWidgetDeviceConfig<Type> extends BaseWidgetConfig<Type> {
  datapointName: string;
  dateRange: PeriodFilterEnum;
  deviceId: string;
  floorId: string;
  roomId: string;
}

export interface BaseWidgetLocationConfig<Type> extends BaseWidgetConfig<Type> {
  floorId?: string;
  roomId?: string;
}

export type WidgetBuildingPlan = BaseWidgetConfig<'buildingPlan'>;
export type WidgetDataPoint = BaseWidgetDeviceConfig<'dataPoint'>;
export type WidgetDeviceChart = BaseWidgetDeviceConfig<'deviceChart'>;
export type WidgetDevices = BaseWidgetLocationConfig<'devices'>;

export interface WidgetDeviceStats extends BaseWidgetDeviceConfig<'deviceStats'> {
  dataType: StatsSupportedTypes,
  displayOptions: { [key: string]: boolean; };
}

export type WidgetMap = BaseWidgetConfig<'map'>;
export type WidgetSpaceDetails = BaseWidgetLocationConfig<'spaceDetails'>;

export type WidgetConfig
  = WidgetBuildingPlan
  | WidgetDataPoint
  | WidgetDeviceChart
  | WidgetDevices
  | WidgetDeviceStats
  | WidgetMap
  | WidgetSpaceDetails;

export interface Widget extends GridsterItem {
  cols: number;
  config: WidgetConfig;
  id: number;
  rows: number;
  x: number;
  y: number;
}


export enum StatsSupportedTypes {
  THERMOSTAT = 'temp',
  POWER = 'wattage',
}
