import { CommonModule, NgClass } from '@angular/common';
import { Component, computed, ElementRef, inject, input, output, signal, viewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { BuildingPlanComponent, DeviceChartComponent, SpaceLocationComponent, WidgetDataPointComponent, WidgetDevicesComponent, WidgetDeviceStatsComponent, WidgetMapComponent, WidgetSpaceDetailsComponent } from '../../components';
import { LayoutMode, Widget } from '../../models';
import { LayoutHelper } from '../layout/config';

@Component({
  imports: [
    BuildingPlanComponent,
    CommonModule,
    DeviceChartComponent,
    MatTooltip,
    NgClass,
    NgxSkeletonLoaderModule,
    SpaceLocationComponent,
    WidgetDataPointComponent,
    WidgetDevicesComponent,
    WidgetDeviceStatsComponent,
    WidgetMapComponent,
    WidgetSpaceDetailsComponent,
  ],
  selector: 'avs-li-widget-container',
  standalone: true,
  styleUrl: './widget-container.component.scss',
  templateUrl: './widget-container.component.html',
})
export class WidgetContainerComponent {
  delete = output<number>();
  edit = output<number>();
  mode = input.required<LayoutMode>();
  widget = input.required<Widget>();

  protected classBreakpoint = signal<string>('');
  protected isEditMode = computed(() => this.mode() === 'edit');
  protected widgetRef = viewChild<{ refresh?: () => void; }>('widgetRef');

  private el = inject<ElementRef<HTMLElement>>(ElementRef);

  itemChanged() {
    const { width } = this.el.nativeElement.getBoundingClientRect();

    this.classBreakpoint.set(LayoutHelper.getClassBreakpoint(width));
    this.widgetRef()?.refresh?.();
  }
}
