<app-page-header>
  <div class="leading-10">Reporting</div>
</app-page-header>

<div class="flex items-center justify-between mt-4">
  <mat-button-toggle-group
    class="main-toggle-group"
    [(ngModel)]="reportingType"
    aria-label="type of reports"
  >
    <mat-button-toggle [value]="eReportTab.INCIDENT">Incident</mat-button-toggle>
    <!-- <mat-button-toggle [value]="eReportTab.POWER_CONSUMPTION">Power Consumption</mat-button-toggle> -->
    <mat-button-toggle [value]="eReportTab.FIRMWARE">Firmware</mat-button-toggle>
  </mat-button-toggle-group>

  @if (reportingType === 'firmware') {
    <app-export-report-menu (exportDataType)="exportDevices($event)" />
  }

  @if (reportingType === 'incident') {
    <app-export-report-menu
      (exportDataType)="exportIncidents($event)"
      [disabled]="!incidents?.length"
    />
  }
</div>

@if (reportingType === eReportTab.INCIDENT) {
  <div class="test-incident-report-filters">
    <mat-card class="bg-white card mt-6">
      <mat-card-content class="p-0">
        <avs-fe-report-filters (exportIncidents)="setIncidents($event)" />
      </mat-card-content>
    </mat-card>
  </div>

  @if (incidents) {
    <div class="test-incident-table grid max-w-full">
      @if (incidents.length) {
        <avs-fe-incident-charts [incidents]="incidents" />
      } @else {
        <div class="card-separator"></div>
      }
      <mat-card class="card py-0 mb-3">
        <mat-card-title class="card-title-small test-table-title border-b-ui-gray-light border-b py-3.5 mb-3">Incident
          List
        </mat-card-title>
        <mat-card-content class="p-0 pb-2">
          <app-incidents-table
            [incidentsData]="incidents || []"
            [isInjected]="true"
            [isReportMode]="true"
            noResolvedInicidentsText="No Results"
            tableId="reporting-incidents-table"
          />
        </mat-card-content>
      </mat-card>
    </div>
  }

  <div class="test-default-charts pb-4">
    <div class="grid grid-cols-2 gap-4 my-4 mx-auto">
      <avs-fe-incident-manufacturer-chart />
      <avs-fe-incidents-monthly-chart />
    </div>
  </div>
} @if (reportingType === eReportTab.FIRMWARE) {
  <mat-card class="card pt-0 mt-6 pb-2 test-firmware-table">
    <mat-card-title class="card-title-small test-table-title border-b-ui-gray-light border-b py-3.5">Firmware List
    </mat-card-title>

    <mat-card-content class="card-content p-0">
      <avs-li-devices-filters
        (filtersChanged)="filters.set($event)"
        [class.hidden]="!devicesTable.isReady()"
        [config]="{
          deviceTypeFilter: true,
          filtersEnabled: devicesTable.data().length > 0 || devicesTable.isDataLoading(),
          locationFilter: true,
          manufacturerFilter: true,
          reportMode: true,
        }"
        [tableId]="TABLE_ID"
      />

      <avs-li-devices-table
        class="overflow-hidden"
        [config]="{ isNoDataBigSize: true }"
        [displayedColumns]="firmwareDisplayedColumns"
        [filters]="filters()!"
        [tableId]="TABLE_ID"
        #devicesTable
      />
    </mat-card-content>
  </mat-card>
} @if (reportingType === eReportTab.POWER_CONSUMPTION) {
  <avs-li-power-consumption-table />
}
