import { Component, DestroyRef, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IncidentModel } from '@models';
import { Store } from '@ngrx/store';
import { AppState, selectLocationIncidents } from '@ngrx-store';
import { AppService, IncidentsFilterService, IncidentsService } from '@services';

@Component({
  selector: 'app-building-plan-incidents',
  templateUrl: './building-plan-incidents.component.html',
})

export class BuildingPlanIncidentsComponent implements OnInit, OnDestroy {
  destroyRef = inject(DestroyRef);
  @Input() locationId = '';
  incidentTableColumns = ['status', 'faultname', 'deviceId', 'floor', 'room', 'deviceType', 'reported'];
  resolvedIncidents: IncidentModel[] = [];
  resolvedIncidentsLoading = false;
  lastDateRange: { endDateTime: string; startDateTime: string } | null = null;

  constructor(
    private store: Store<AppState>,
    private incidentsService: IncidentsService,
    private appService: AppService,
    private incidentsFilterService: IncidentsFilterService,
  ) {
  }

  ngOnInit(): void {
    this.store
      .select(selectLocationIncidents(this.locationId))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(incidents => {
        this.incidentsFilterService.initOpenIncidents(incidents);
      });
    this.incidentsFilterService.initFilterTableType({
      displayedColumns: this.incidentTableColumns,
      isBuildingTable: true,
    });

    this.incidentsFilterService.resolvedDates$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => this.getResolvedIncidents(value));
  }

  ngOnDestroy(): void {
    this.incidentsFilterService.resetState();
  }

  getResolvedIncidents(dateRange: { endDateTime: string; startDateTime: string }) {
    if (dateRange.startDateTime && dateRange.endDateTime) {
      this.lastDateRange = dateRange;
      this.resolvedIncidentsLoading = true;
      this.incidentsService
        .getAllResolvedIncidents(this.appService.currentClient, {
          ...dateRange,
          locationId: this.locationId,
        })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          error: () => {
            this.resolvedIncidentsLoading = false;
          },
          next: data => {
            this.resolvedIncidents = data;
            this.incidentsFilterService.initResolvedIncidents(data);
            this.resolvedIncidentsLoading = false;
          },
        });
    }
  }
}
