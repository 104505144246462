@if (room) {
  <div class="p-4">
    <app-space-card-info
      [deviceActive]="room.devicesByStatuses.activeCount"
      [devicePaused]="room.devicesByStatuses.pausedCount"
      [incidentInProgress]="room.incidentCountByStatuses.inProgressCount"
      [incidentNew]="room.incidentCountByStatuses.newCount"
      [name]="room.friendlyName || room.name"
      [spaceLink]="allowNavigation ? ['/dashboard', locationId, floorId, room.id] : []"
      [status]="room.status"
    />
  </div>
}
