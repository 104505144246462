import { Component, effect, inject, input, signal } from '@angular/core';
import { LocationData } from '@models';
import { AppService, LocationService } from '@services';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { WidgetMap } from '../../../../models';
import { MapComponent } from '../map/map.component';

@Component({
  imports: [
    MapComponent,
    NgxSkeletonLoaderModule,
  ],
  selector: 'avs-li-widget-map',
  standalone: true,
  styleUrl: './widget-map.component.scss',
  templateUrl: './widget-map.component.html',
})
export class WidgetMapComponent {
  config = input.required<WidgetMap>();

  protected appService = inject(AppService);
  protected locationService = inject(LocationService);
  protected location = signal<LocationData | null>(null);

  constructor() {
    effect(() => {
      this.location.set(null);
      this.locationService.getLocationInfoById(this.appService.currentClient, this.config().locationId)
        .subscribe(location => this.location.set(location));
    }, { allowSignalWrites: true });
  }
}
