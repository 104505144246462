import { ChangeDetectionStrategy, Component, effect, input, output, signal } from '@angular/core';
import { SpaceType } from '@models';

import { LocationSelectorComponent } from '../../selectors';

interface FormValue {
  locationId?: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LocationSelectorComponent,
  ],
  selector: 'avs-li-location-options',
  standalone: true,
  templateUrl: './location-options.component.html',
})
export class LocationOptionsComponent {
  config = input<FormValue>();
  change = output<Partial<FormValue>>();

  protected eSpaceType = SpaceType;
  protected form = signal<FormValue>({});

  constructor() {
    effect(
      () => this.form.set(this.config() ?? {}),
      { allowSignalWrites: true },
    );
  }

  protected patchForm(value: Partial<FormValue>) {
    this.form.set({ ...this.form(), ...value });
    this.change.emit(this.form());
  }
}
