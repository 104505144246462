import { type DateRange, DAY, MONTH, PeriodFilterEnum, WEEK } from '@core';

export function getDateRangeByPeriodName(changes: PeriodFilterEnum = PeriodFilterEnum.DAY) {
  const now = new Date();
  const end = new Date();

  switch (changes) {
    case PeriodFilterEnum.WEEK:
      end.setDate(now.getDate() - WEEK);
      break;
    case PeriodFilterEnum.MONTH:
      end.setDate(now.getDate() - MONTH);
      break;
    default:
      end.setDate(now.getDate() - DAY);
      break;
  }

  return { fromDateTimeUtc: end.toISOString(), toDateTimeUtc: now.toISOString() } as DateRange;
}
