import {
  type CustomHTTPResponseError,
  type DeviceAttribute,
  type DeviceData,
  type DeviceFullModel,
  type DeviceManufacturer,
  type DeviceModel,
  type DeviceType,
  type ImageDocument,
  type NewDeviceModelData,
} from '@models';
import { createAction, props } from '@ngrx/store';

export const resetDeviceEntity = createAction('[Device Entity Update] Reset device entity collection');
export const loadAllClientDevices = createAction('[Devices Resolver] Load all client devices');

export const loadDevicesError = createAction(
  '[Devices Error] Load all client devices error',
  props<{ error: CustomHTTPResponseError }>(),
);
export const allClientDevicesLoaded = createAction(
  '[Load Devices Effect] All  client devices loaded',
  props<{ devices: DeviceData[] }>(),
);

export const addNewDevice = createAction(
  '[Add New Device] Add new device action',
  props<{ locationId: string; newDeviceData: Partial<DeviceData> }>(),
);
export const addNewDeviceSuccess = createAction(
  '[Add New Device] Add new device action success',
  props<{ newDevice: DeviceData }>(),
);
export const addNewDeviceError = createAction('[Add New Device] Add new device action error');

export const loadDeviceDetails = createAction(
  '[Load one device details] Get One device details action',
  props<{ deviceId: string; locationId: string; }>(),
);
export const loadDeviceDetailsSuccess = createAction(
  '[Load one device details success] Get One device details action success',
  props<{ deviceItem: DeviceData }>(),
);

export const updateDeviceData = createAction(
  '[Update device data] Updating device data values',
  props<{ data: Partial<DeviceData>; deviceId: string; locationId: string; }>(),
);

export const deviceDataSuccessfullyUpdated = createAction(
  '[Update device data success] Device data values successfully updated',
  props<{ device: DeviceData; oldData: Partial<DeviceData> }>(),
);

export const updateDeviceDataError = createAction('[Update device data error]');

export const loadDeviceCollectionBySpaceId = createAction(
  '[Load space devices by room id] Load devices by room id started',
  props<{ locationId: string; roomId: string }>(),
);

export const loadDeviceCollectionBySpaceIdSuccess = createAction(
  '[Load space devices by room id successful] Load devices by room id successfully finished',
  props<{ devices: DeviceData[] }>(),
);

export const loadDeviceCollections = createAction('[Device] Load device collections ');
export const loadDeviceCollectionsSuccess = createAction(
  '[Device] Load device collections success ',
  props<{ attributes: DeviceAttribute[]; deviceTypes: DeviceType[]; manufacturers: DeviceManufacturer[]; models: DeviceModel[]; }>(),
);
export const loadDeviceFullModel = createAction(
  '[Device] Load device full model info',
  props<{ clientId: string; manufacturerId: string; modelId: string }>(),
);

export const loadDeviceFullModelSuccess = createAction(
  '[Device] Load device full model info success',
  props<{ fullModel: DeviceFullModel | null }>(),
);

export const addNewManufacturer = createAction(
  '[Device] Add new manufacturer',
  props<{ clientId: string; newManufacturerData: Pick<DeviceManufacturer, 'name'> }>(),
);
export const addNewManufacturerSuccess = createAction(
  '[Device] Add new manufacturer success',
  props<{ manufacturer: DeviceManufacturer }>(),
);
export const addNewManufacturerError = createAction('[Device] Add new manufacturer error');

export const addNewDeviceType = createAction(
  '[Device] Add new device type',
  props<{ clientId: string; newDeviceTypeData: Pick<DeviceType, 'name'> }>(),
);
export const addNewDeviceTypeSuccess = createAction(
  '[Device] Add new device type success',
  props<{ deviceType: DeviceType }>(),
);
export const addNewDeviceTypeError = createAction('[Device] Add new device type error');

export const addNewDeviceModel = createAction(
  '[Device] Add new device model',
  props<{ clientId: string; makeId: string; newDevicdeModelData: NewDeviceModelData }>(),
);
export const addNewDeviceModelSuccess = createAction(
  '[Device] Add new device model success',
  props<{ deviceModel: DeviceModel }>(),
);
export const addNewDeviceModelError = createAction('[Device] Add new device model error');

// upload device image action creators
export const uploadDeviceModelImage = createAction(
  '[Device] image upload',
  props<{ clientId: string; deviceMakeId: string; deviceModelId: string; file: File; }>(),
);

export const uploadDeviceModelImageSuccess = createAction(
  '[Device] image upload success',
  props<{ deviceModelId: string; document: ImageDocument; }>(),
);

export const uploadDeviceModelImageError = createAction(
  '[Device] image upload error',
  props<{
    error: CustomHTTPResponseError;
  }>(),
);

export const addNewAttribute = createAction(
  '[Device] Add new attribute',
  props<{ clientId: string; data: Pick<DeviceAttribute, 'name' | 'attributeType'> }>(),
);
export const addNewAttributeSuccess = createAction(
  '[Device] Add new attribute success',
  props<{ attribute: DeviceAttribute }>(),
);
export const addNewAttributeError = createAction('[Device] Add new attribute error');
export const rebootDevice = createAction(
  '[Device] Reboot device start',
  props<{
    deviceId: string;
    locationId: string;
  }>(),
);
export const rebootDeviceSuccess = createAction('[Device] Reboot device success');
export const rebootDeviceError = createAction('[Device] Reboot device error');

export const updateDevice = createAction(
  '[Device] Update one device',
  props<{ device: DeviceData }>(),
);
