import { Component, Input, OnChanges } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { createDeviceModelChart } from '@app-lib';
import { IncidentModel } from '@models';
import * as Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import { HighchartsChartModule } from 'highcharts-angular';

exporting(Highcharts);
offlineExporting(Highcharts);

@Component({
  imports: [HighchartsChartModule, MatCardModule],
  selector: 'avs-fe-incident-charts',
  standalone: true,
  templateUrl: './incident-charts.component.html',
})
export class IncidentChartsComponent implements OnChanges {
  @Input() incidents: IncidentModel[] | null = null;
  protected Highcharts: typeof Highcharts = Highcharts;
  protected incidentDeviceModelChartData: Highcharts.Options | null = null;

  ngOnChanges() {
    this.prepareChartsData();
  }

  prepareChartsData() {
    const deviceModels: { count: number; id: string; manufacturerName: string; modelName: string;   }[] = [];

    this.incidents?.forEach(incident => {
      const deviceModel = deviceModels.find(
        deviceModel => deviceModel.id === incident.device.deviceModelInformation?.id,
      );

      if (deviceModel) {
        deviceModel.count += 1;
      } else if (incident.device.deviceModelInformation) {
        deviceModels.push({
          count: 1,
          id: incident.device.deviceModelInformation.id,
          manufacturerName: incident.device.deviceModelInformation.make.name,
          modelName: incident.device.deviceModelInformation.name,
        });
      }
    });

    this.incidentDeviceModelChartData = createDeviceModelChart(deviceModels);
  }
}
