import { AsyncPipe, CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatError, MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatOption, MatSelect } from '@angular/material/select';
import { removeSpacesValidator } from '@app-lib';
import { DirectivesModule } from '@directives';
import {
  DeviceAdditionalAttribute,
  DeviceAttribute,
  DeviceAttributeType,
  DeviceData,
  DeviceFullModel,
  DeviceLocation,
  DeviceModel,
  SelectOption,
  Space,
  SpaceType,
  UserClient,
} from '@models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  addNewDevice,
  AppState,
  clientList,
  DevicesActions,
  getAttributes,
  getDeviceManufacturers,
  getDeviceModels,
  getDeviceTypes,
  getFullModel,
  getMyClient,
  isDeviceCreating,
  isDeviceUpdating,
  loadDeviceFullModel,
  loadDeviceFullModelSuccess,
  updateDeviceData,
} from '@ngrx-store';
import { PipesModule } from '@pipes';
import { AppService, LocationService } from '@services';
import {
  openAddDeviceModelDialog,
} from '@standalone/_modals/add-device-model-dialog/add-device-model-dialog.component';
import { openAddDeviceTypeDialog } from '@standalone/_modals/add-device-type-dialog/add-device-type-dialog.component';
import {
  openAddManufacturerDialog,
} from '@standalone/_modals/add-manufacturer-dialog/add-manufacturer-dialog.component';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';
import { BreadcrumbsComponent } from '@standalone/breadcrumbs/breadcrumbs.component';
import { ImgLoaderComponent } from '@standalone/img-loader/img-loader.component';
import { NewAttributeFormComponent } from '@standalone/new-attribute-form/new-attribute-form.component';
import { PageHeaderComponent } from '@standalone/page-header/page-header.component';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { NgxMaskDirective } from 'ngx-mask';
import { filter, Observable } from 'rxjs';

interface ExtendedDataPoint extends Pick<DeviceAttribute, 'name' | 'friendlyName' | 'attributeType'> {
  isChecked?: boolean;
}

@Component({
  imports: [
    CommonModule,
    PageHeaderComponent,
    BreadcrumbsComponent,
    AsyncPipe,
    MatProgressSpinner,
    MatSuffix,
    MatError,
    MatCard,
    ReactiveFormsModule,
    MatCardTitle,
    MatCardContent,
    MatFormField,
    MatInput,
    MatSelect,
    MatIcon,
    SearchInputComponent,
    MatOption,
    PipesModule,
    ImgLoaderComponent,
    NgxMaskDirective,
    MatCheckbox,
    DirectivesModule,
  ],
  selector: 'app-new-device-form',
  standalone: true,
  templateUrl: './new-device-form.component.html',
})
export class NewDeviceFormComponent implements OnInit, OnDestroy {
  @ViewChild(NewAttributeFormComponent) newAttributeFormComponent: NewAttributeFormComponent | undefined;
  @Input() showBreadcrumbs = true;
  @Input() deviceLocation: DeviceLocation | null = null;
  @Input() device: DeviceData | null = null;
  @Input() showHeader = true;
  @Input() cardTitle = 'Device Info';
  @Output() createDeviceCallback = new EventEmitter();
  destroyRef = inject(DestroyRef);
  myClient$: Observable<UserClient | undefined>;
  form: FormGroup;

  get dataPointsFormArray() {
    return this.form.get('dataPoints') as FormArray;
  }

  protected clients$: Observable<UserClient[] | undefined>;
  protected isDeviceCreating$: Observable<boolean>;
  protected isDeviceUpdating$: Observable<boolean>;
  protected buildingOptions: SelectOption[] = [];
  protected floorOptions: SelectOption[] = [];
  protected roomOptions: SelectOption[] = [];
  protected deviceTypeOptions: SelectOption[] = [];
  protected manufacturerOptions: SelectOption[] = [];
  protected modelOptions: SelectOption[] = [];
  protected manufacturerFilterValue = '';
  protected modelFilterValue = '';
  protected placeholderImg = 'assets/icons/image-placeholder.svg';
  protected deviceModels: DeviceModel[] = [];
  protected fakeSelectOptions: SelectOption[] = [{ title: '1', value: '1' }];
  protected spaceList: Space[] = [];
  protected isSpaceListLoading = false;
  protected realtimeAttributes: DeviceAttribute[] = [];
  protected selectedFullModel: DeviceFullModel | null = null;
  protected areInitializedDeviceDynamicControls = false;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private store: Store<AppState>,
    private locationService: LocationService,
    public appService: AppService,
    actions$: Actions,
  ) {
    this.clients$ = this.store.select(clientList);
    this.myClient$ = this.store.select(getMyClient);
    this.isDeviceCreating$ = this.store.select(isDeviceCreating);
    this.isDeviceUpdating$ = this.store.select(isDeviceUpdating);

    this.form = fb.group({
      building: ['', [Validators.required]],
      deviceName: ['', [Validators.required, removeSpacesValidator]],
      deviceType: ['', [Validators.required]],
      floor: [{ disabled: true, value: '' }],
      manufacturer: ['', [Validators.required]],
      model: [{ disabled: true, value: '' }, [Validators.required]],
      physicalDeviceId: ['', [Validators.required, removeSpacesValidator]],
      room: [{ disabled: true, value: '' }],
      tags: this.fb.group({
        description: [''],
        firmware: [''],
        hyperlink: [{ disabled: true, value: '' }],
        ipAddress: [''],
        macAddress: [''],
      }),
    });

    this.store
      .select(getDeviceManufacturers)
      .pipe(takeUntilDestroyed())
      .subscribe(manufacturers => {
        if (manufacturers.length) {
          this.manufacturerOptions = manufacturers.map(({ id, name }) => ({ title: name, value: id }));
        }
      });

    this.store
      .select(getDeviceTypes)
      .pipe(
        filter(deviceTypes => deviceTypes.length > 0),
        takeUntilDestroyed(),
      )
      .subscribe(deviceTypes => {
        this.deviceTypeOptions = deviceTypes.map(({ id, name }) => ({ title: name, value: id }));
      });

    this.store
      .select(getDeviceModels)
      .pipe(takeUntilDestroyed())
      .subscribe(models => {
        if (models.length) {
          this.deviceModels = models;
          this.setModelSelectSettings(this.form.value.deviceType, this.form.value.manufacturer);
        }
      });

    this.store
      .select(getAttributes)
      .pipe(takeUntilDestroyed())
      .subscribe(attributes => {
        if (attributes.length) {
          this.realtimeAttributes = attributes.filter(
            attribute => attribute.attributeType === DeviceAttributeType.REALTIME,
          );
          this.initDynamicControls();
        }
      });

    this.locationService
      .getLocationsList(this.appService.currentClient, false)
      .pipe(takeUntilDestroyed())
      .subscribe(locations => {
        this.buildingOptions = locations.map(({ id, friendlyName }) => ({ title: friendlyName, value: id }));
      });

    this.store
      .select(getFullModel)
      .pipe(takeUntilDestroyed())
      .subscribe(fullModel => {
        if (fullModel) {
          console.log('fullModel', fullModel);
          this.selectedFullModel = fullModel;
          this.initDynamicControls();
        }
      });

    actions$.pipe(ofType(DevicesActions.addNewDeviceSuccess), takeUntilDestroyed()).subscribe(() => {
      this.createDeviceCallback.emit();
      this.resetModelControls();
      this.form.reset();
      this.form.markAsPristine();

      if (this.deviceLocation) {
        const { siteId, floorId, roomId } = this.deviceLocation;

        this.form.get('building')?.setValue(siteId);
        this.form.get('floor')?.setValue(floorId);
        this.form.get('room')?.setValue(roomId);
      }
    });

    actions$.pipe(ofType(DevicesActions.deviceDataSuccessfullyUpdated), takeUntilDestroyed()).subscribe(() => {
      this.form.markAsUntouched();
    });
  }

  ngOnInit(): void {
    const building = this.form.get('building');
    const floor = this.form.get('floor');
    const room = this.form.get('room');
    const deviceType = this.form.get('deviceType');
    const manufacturer = this.form.get('manufacturer');
    const model = this.form.get('model');
    const physicalDeviceId = this.form.get('physicalDeviceId');
    const ipAddress = this.form.get('tags.ipAddress');
    const hyperlink = this.form.get('tags.hyperlink');

    if (this.deviceLocation) {
      const { siteId, floorId, roomId } = this.deviceLocation;

      building?.setValue(siteId);
      this.loadSpaces(siteId);
      building?.disable();
      floor?.setValue(floorId);
      floor?.disable();
      room?.setValue(roomId);
      room?.disable();
    }

    ipAddress?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (value && hyperlink?.disabled) {
        hyperlink.enable();
      } else if (!value && !hyperlink?.disabled) {
        hyperlink?.disable();
      }
    });

    building?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (!value) return;
      this.loadSpaces(value);

      if (building.touched) {
        floor?.reset();
        room?.reset();
        room?.disable();
      }
    });

    floor?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (!value) return;
      this.generateRoomSelectOptions(value);
      if (!floor.pristine) {
        this.roomOptions.length ? room?.enable() : room?.disable();
        room?.reset();
      }
    });

    deviceType?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(deviceTypeValue => {
      this.resetModelControls();
      this.setModelSelectSettings(deviceTypeValue, this.form.value.manufacturer);
    });

    manufacturer?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(manufacturerValue => {
      this.resetModelControls();
      this.setModelSelectSettings(this.form.value.deviceType, manufacturerValue);
    });

    model?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(selectedModelId => {
      if (selectedModelId) {
        this.resetModelControls();
        this.store.dispatch(
          loadDeviceFullModel({
            clientId: this.appService.currentClient,
            manufacturerId: manufacturer?.value,
            modelId: selectedModelId,
          }),
        );
      }
    });

    if (this.device) {
      const floorId = this.device.spacePath?.find(space => space.type === SpaceType.floor)?.id || '';
      const roomId = this.device.spacePath?.find(space => space.type === SpaceType.room)?.id || '';
      const firmware =
        this.device.tags?.['firmware'] ||
        this.device.additionalAttributes?.find(item => item.name === 'firmware')?.value ||
        '';
      const macAddress =
        this.device.tags?.['macAddress'] ||
        this.device.additionalAttributes?.find(item => item.name === 'macAddress')?.value ||
        '';
      const ipAddress =
        this.device.tags?.['ipAddress'] ||
        this.device.additionalAttributes?.find(item => item.name === 'ipAddress')?.value ||
        '';

      physicalDeviceId?.disable();
      building?.disable();
      if (floorId) floor?.enable();
      if (roomId) room?.enable();

      this.form.setValue({
        building: this.device.locationId || this.device.location.id,
        deviceName: this.device.friendlyName,
        deviceType: this.device.deviceModelInformation?.type.id,
        floor: floorId,
        manufacturer: this.device.deviceModelInformation?.make.id,
        model: this.device.deviceModelInformation?.id,
        physicalDeviceId: this.device.physicalDeviceId,
        room: roomId,
        tags: {
          description: this.device.tags?.['description'] || '',
          firmware,
          hyperlink: this.device.tags?.['hyperlink'] || '',
          ipAddress,
          macAddress,
        },
      });
    }
  }

  ngOnDestroy() {
    this.store.dispatch(loadDeviceFullModelSuccess({ fullModel: null }));
  }

  protected initDynamicControls() {
    if (this.selectedFullModel && this.realtimeAttributes.length) {
      const modelRealtimeAttributes = this.selectedFullModel.standardAttributes.filter(
        attribute => attribute.attributeType === DeviceAttributeType.REALTIME,
      );
      const deviceRealtimeAttributes = this.device?.additionalAttributes
        ? this.device.additionalAttributes!.filter(
          attribute => attribute.attributeType === DeviceAttributeType.REALTIME,
        )
        : [];
      const initAttributes = (this.device ? deviceRealtimeAttributes : modelRealtimeAttributes).map(attr => ({
        ...attr,
        checked: true,
      }));
      const combinedAttributes = this.mergeAttributes(
        this.realtimeAttributes,
        initAttributes,
        this.getCurrentDataPointsFormState(),
      );

      this.updateDataPointsForm(combinedAttributes);

      this.areInitializedDeviceDynamicControls = true;
    }
  }

  protected mergeAttributes(
    external: ExtendedDataPoint[],
    device: ExtendedDataPoint[],
    currentFormState: ExtendedDataPoint[] = [],
  ): ExtendedDataPoint[] {
    const map = new Map<string, ExtendedDataPoint>();

    external.forEach(attr => {
      map.set(attr.name, { ...attr, isChecked: false });
    });

    device.forEach(attr => {
      if (map.has(attr.name)) {
        map.get(attr.name)!.isChecked = true;
      } else {
        map.set(attr.name, { ...attr, isChecked: true });
      }
    });

    currentFormState.forEach(attr => {
      if (map.has(attr.name)) {
        map.get(attr.name)!.isChecked = attr.isChecked;
      }
    });

    return Array.from(map.values());
  }

  protected getCurrentDataPointsFormState(): ExtendedDataPoint[] {
    return (
      this.form?.get('dataPoints')?.value.map((attr: ExtendedDataPoint) => ({
        isChecked: attr.isChecked,
        name: attr.name,
      })) || []
    );
  }

  protected updateDataPointsForm(combinedAttributes: ExtendedDataPoint[]) {
    this.form.setControl('dataPoints', this.fb.array([]));

    const attributesArray = this.form.get('dataPoints') as FormArray;

    combinedAttributes.forEach((attr, index) => {
      if (attributesArray?.at(index)) {
        attributesArray.at(index).patchValue(attr);
      } else {
        attributesArray.push(
          this.fb.group({
            attributeType: [attr.attributeType],
            friendlyName: [attr.friendlyName],
            isChecked: [attr.isChecked],
            name: [attr.name],
          }),
        );
      }
    });

    while (attributesArray.length > combinedAttributes.length) {
      attributesArray.removeAt(attributesArray.length - 1);
    }
  }


  protected loadSpaces(locationId: string) {
    const floor = this.form.get('floor');

    this.isSpaceListLoading = true;
    floor?.disable();

    this.locationService
      .getSpacesList(locationId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(spaces => {
        this.isSpaceListLoading = false;
        this.spaceList = spaces;
        this.generateFloorSelectOptions();
        if (this.floorOptions.length && !this.deviceLocation) {
          floor?.enable();
        }

        if (this.deviceLocation) {
          this.generateRoomSelectOptions(this.deviceLocation.floorId);
        }
      });
  }

  protected generateFloorSelectOptions() {
    this.floorOptions = this.spaceList
      .filter(({ type }) => type === SpaceType.floor)
      .map(({ id, friendlyName, name }) => ({ title: friendlyName || name, value: id }));
  }

  protected generateRoomSelectOptions(floorId: string) {
    this.roomOptions = this.spaceList
      .filter(({ parentSpaceId }) => parentSpaceId === floorId)
      .map(({ id, friendlyName, name }) => ({ title: friendlyName || name, value: id }));
  }

  protected setModelSelectSettings(deviceTypeValue: string, manufacturerValue: string) {
    if (manufacturerValue && deviceTypeValue) {
      const model = this.form.get('model');
      const deviceType = this.form.get('deviceType');
      const manufacturer = this.form.get('manufacturer');

      if (model?.touched || deviceType?.dirty || manufacturer?.dirty) {
        model?.setValue('');
      }
      model?.enable();
      this.modelOptions = this.deviceModels
        .filter(deviceModel => deviceModel.make.id === manufacturerValue && deviceModel.type.id === deviceTypeValue)
        .map(deviceModel => ({ title: deviceModel.name, value: deviceModel.id }));
    }
  }

  protected resetModelControls() {
    if (this.form.get('model')?.touched) {
      this.selectedFullModel = null;
      this.form.removeControl('dataPoints');
    }
  }

  protected submit() {
    if (!this.form.touched) {
      return;
    }

    const { tags, room, floor, physicalDeviceId, deviceName, model, dataPoints, building } = this.form.getRawValue();
    const additionalAttributes: DeviceAdditionalAttribute[] = (dataPoints as ExtendedDataPoint[])
      .filter(attr => attr.isChecked)
      .map(attr => ({
        attributeType: attr.attributeType,
        friendlyName: attr.friendlyName,
        name: attr.name,
        recordedTimeStamp: null,
        value: null,
      }));

    const newDeviceData = {
      additionalAttributes,
      deviceModelId: model,
      friendlyName: deviceName,
      parentSpaceId: room || floor,
      physicalDeviceId,
      tags,
    };

    if (this.device) {
      this.store.dispatch(
        updateDeviceData({
          data: newDeviceData,
          deviceId: this.device.id,
          locationId: this.device.locationId || this.device.location.id,
        }),
      );
    } else {
      this.store.dispatch(addNewDevice({ locationId: building, newDeviceData }));
    }
  }

  protected openAddDeviceTypeDialog() {
    openAddDeviceTypeDialog(this.dialog);
  }

  protected openAddManufacturerDialog() {
    openAddManufacturerDialog(this.dialog);
  }

  protected manufacturerSearch(value: string) {
    this.manufacturerFilterValue = value;
  }

  protected openAddDeviceModelDialog() {
    openAddDeviceModelDialog(this.dialog, {
      selectedDeviceType: this.form.value.deviceType,
      selectedManufacturer: this.form.value.manufacturer,
    });
  }

  protected realtimeAttributeChange(dataPoint: ExtendedDataPoint, i: number) {
    if (!dataPoint.isChecked && this.device?.additionalAttributes?.some(({ name }) => name === dataPoint.name)) {
      ConfirmationDialogComponent.open(this.dialog, {
        description: `Do you really want to remove ${dataPoint.name}?`,
        title: `Remove ${dataPoint.name}`,
      })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(confirmation => {
          if (this.realtimeAttributes.some(attr => attr.name === dataPoint.name)) {
            this.dataPointsFormArray.at(i).patchValue({ ...dataPoint, isChecked: !confirmation });
          } else {
            this.dataPointsFormArray.removeAt(i);
          }
        });
    }
  }
}
