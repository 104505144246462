import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appScrolling]',
})
export class ScrollingDirective implements OnInit {
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.renderer.addClass(this.el.nativeElement, 'forbid-children-events');
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.renderer.addClass(this.el.nativeElement, 'forbid-children-events');
  }

  @HostListener('click')
  protected onClick() {
    this.renderer.removeClass(this.el.nativeElement, 'forbid-children-events');
  }

}
