import { Component, inject, input, output, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { LocationData } from '@models';
import { AppService, LocationService } from '@services';

@Component({
  imports: [
    MatSelectModule,
    MatIconModule,
  ],
  selector: 'avs-li-location-selector',
  standalone: true,
  templateUrl: './location-selector.component.html',
})

export class LocationSelectorComponent {
  change = output<string>();
  value = input<string>();

  protected appService = inject(AppService);
  protected locationService = inject(LocationService);
  protected locations = signal<LocationData[]>([]);

  constructor() {
    this.locationService.getLocationsList(this.appService.currentClient, false)
      .subscribe(locations => this.locations.set(locations));
  }
}
