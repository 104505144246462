import { ChangeDetectionStrategy, Component, computed, effect, inject, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { createArbitraryValueChart, createPredefinedValuesChart } from '@app-lib';
import { dateHelpers } from '@core';
import { WidgetDeviceChart } from '@dashboards';
import { CURRENT_CLIENT_TEMPLATE_VAR, DeviceChart } from '@models';
import { DeviceService } from '@services';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { debounceTime, finalize, map, Subject, tap } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    HighchartsChartModule,
    NgxSkeletonLoaderModule,
  ],
  selector: 'avs-li-device-chart',
  standalone: true,
  styleUrl: './device-chart.component.scss',
  templateUrl: './device-chart.component.html',
})
export class DeviceChartComponent {
  config = input.required<WidgetDeviceChart>();

  protected chartData = signal<DeviceChart | null>(null);
  protected chartRef = signal<Highcharts.Chart | null>(null);
  protected hasErrors = false;
  protected highchartOptions = computed(() => {
    const timeseries = this.chartData();

    if (!timeseries) {
      return null;
    }

    return !timeseries.unitOFMeasure || !timeseries.unitOFMeasure.isStep
      ? createArbitraryValueChart(timeseries, true)
      : createPredefinedValuesChart(timeseries, true);
  });
  protected Highcharts: typeof Highcharts = Highcharts;
  protected isLoading = signal(true);
  private deviceService = inject(DeviceService);
  private refresh$ = new Subject<void>();

  constructor() {
    effect(() => this.loadCharts());

    this.refresh$
      .pipe(
        debounceTime(150),
        takeUntilDestroyed(),
      )
      .subscribe(() => this.chartRef()?.reflow());
  }

  refresh() {
    this.refresh$.next();
  }

  protected loadCharts() {
    this.deviceService
      .getChartsData(this.buildChartRequestPayload())
      .pipe(
        tap(() => this.isLoading.set(true)),
        finalize(() => this.isLoading.set(false)),
        map(charts => charts[0]),
      )
      .subscribe({
        error: () => this.hasErrors = true,
        next: chart => this.chartData.set(chart),
      });
  };

  private buildChartRequestPayload() {
    const {
      fromDateTimeUtc,
      toDateTimeUtc,
    } = dateHelpers.getDateRangeByPeriodName(this.config().dateRange);

    return {
      clientId: CURRENT_CLIENT_TEMPLATE_VAR,
      data: {
        fromDateTimeUtc,
        points: [
          {
            name: this.config().datapointName,
          },
        ],
        toDateTimeUtc,
      },
      deviceId: this.config().deviceId,
    };
  }
}
