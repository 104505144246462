import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, signal } from '@angular/core';
import { getUniqueArrayItemsByProperty } from '@app-lib';
import { dateHelpers, signalHelpers } from '@core';
import { CURRENT_CLIENT_TEMPLATE_VAR, DeviceChart } from '@models';
import { DeviceService } from '@services';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { forkJoin } from 'rxjs';

import { WidgetDataPoint } from '../../../../models';

@Component({
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
  ],
  selector: 'avs-li-widget-data-point',
  standalone: true,
  styleUrl: './data-point.component.scss',
  templateUrl: './data-point.component.html',
})
export class WidgetDataPointComponent {
  config = input.required<WidgetDataPoint>();

  protected chartData = signal<DeviceChart | null>(null);
  protected datapointName = signal<string | undefined>('');
  protected isLoading = signal<boolean>(false);
  protected datapoint = computed(() => {
    const chartData = this.chartData();

    if (!chartData) {
      return {
        date: '',
        value: 'No Data',
      };
    }
    const timeseries = getUniqueArrayItemsByProperty(chartData.timeseries, 'timeStamp')
      .sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime());
    const timeseriesLength = timeseries.length;

    return {
      date: timeseriesLength ? new Date(timeseries[timeseriesLength - 1].timeStamp).toLocaleString('en-US') : '',
      unit: chartData.unitOFMeasure,
      value: timeseriesLength
        ? timeseries[timeseriesLength - 1].value
        : 'No Data',
    };
  });
  private deviceService = inject(DeviceService);

  constructor() {
    signalHelpers.untrackedEffect([this.config], (_) => this.loadData());
  }

  protected loadData() {
    this.isLoading.set(true);
    this.deviceService
      .getChartsData(this.buildChartRequestPayload())
      .subscribe(data => {
        this.isLoading.set(false);
        this.chartData.set(data[0]);
      });

    forkJoin([
      this.deviceService.getChartsData(this.buildChartRequestPayload()),
      this.deviceService.getDeviceDetailsByDeviceId(this.config().locationId, this.config().deviceId),
    ])
      .subscribe(([data, device]) => {
        this.isLoading.set(false);
        this.chartData.set(data[0]);

        const datapoint = device.additionalAttributes?.find(
          attribute => attribute.name === this.config().datapointName,
        );

        this.datapointName.set(datapoint?.friendlyName || datapoint?.name);
      });

  };

  private buildChartRequestPayload() {
    const {
      fromDateTimeUtc,
      toDateTimeUtc,
    } = dateHelpers.getDateRangeByPeriodName(this.config().dateRange);

    return {
      clientId: CURRENT_CLIENT_TEMPLATE_VAR,
      data: {
        fromDateTimeUtc,
        points: [
          {
            name: this.config().datapointName,
          },
        ],
        toDateTimeUtc,
      },
      deviceId: this.config().deviceId,
    };
  }
}
